<template>
  <v-container fluid>
  </v-container>
</template>

<script>
import intersectRect from 'intersect-rect';
import $ from 'jquery';
export default {

    directives: {
        print   
    },

    props:{
      selectlist:Array, //문제리스트
      notelist:Array,   //체크리스트
      chapter:Object,   //선택된 챕터 (체크리스트)
      cnt:Number,
      type:Number,
      title:String,
      water:String,
      answerprint:Boolean,
      noteprint:Boolean,
      semester:String,
    },

    data: () => ({
        
    }),

    mounted(){
      //console.log("showpage mounted....");  
      //this.initpage(this.selectlist, this.cnt, this.type, this.title, this.water, this.answerprint, this.noteprint, this.chapter);
    },

    unmounted() {
      //console.log("showpage unmounted....");  
    },


    methods: {

      print_page(){

        //input은 문제가 된다.. div로 변경해서 출력해야할듯.
        //console.log("printtest");
        var w = window.open("", "출력");
        var htmltext = $("#prewview").html();

        //필수 이게 들어가야 vue.js의 사이즈와 동일하게 프린트 됨. 안그러면 오차생김.
        let stylesHtml = '';
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
          stylesHtml += node.outerHTML;
        }
        w.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body sytle='margin:0px pading:0px'>
            ${htmltext}
          </body>
        </html>`);        
        //$("#prewview");
        //w.document.write("<body style='margin:0px'>"+htmltext+"</body>");
        //w.document.write("<div style='-webkit-print-color-adjust: exact;width:210mm;'>"+htmltext+"</div>");
        w.document.write('<scr' + 'ipt type="text/javascript">' + 'window.onload = function() { window.print(); window.close(); };' + '</sc' + 'ript>');
        //w.document.body.setAttribute("style", "margin:0px;");  //이거 해줘야 컬러와 이미지 출력됨.
        w.document.close(); // necessary for IE >= 10
        w.focus();          // necessary for IE >= 10

      },
      

      close_page(){
        //다지우고
        var pdiv = document.getElementById("prewview"); 
        while (pdiv.hasChildNodes()) {
          pdiv.removeChild(pdiv.firstChild);
        }        

        //parent 함수 호출
        this.$emit('callback');
      },


      getImageSize(item){
        //const pxTomm = this.pxTomm;
        return new Promise((resolve, reject)=>{
          var tempimg = new Image();
          tempimg.onload = () =>resolve(tempimg);
          tempimg.onerror = reject;
          tempimg.src = item.IMAGE;
        })
      },

      getAnswerImageSize(item){
        //const pxTomm = this.pxTomm;
        return new Promise((resolve, reject)=>{
          var tempimg = new Image();
          tempimg.onload = () =>resolve(tempimg);
          tempimg.onerror = reject;
          tempimg.src = item.ANSWER_IMAGE;
        })
      },

        pxTomm(px){
            return (px * 0.2645833333).toFixed(5);
        },

        async initpage(selectlist, cnt, type, title, semester, water, answer, history, chapter, printline, name, twoside) {

          //console.log("show page initpage... " + selectlist.length + "/" + cnt +" / " + type + " / " + title + " / " + water + " / "+ answer + " / " + history);
          //console.log("twoside : " + twoside);

          //루트 div
          const prewview_div = document.createElement("div");
          prewview_div.id = "prewview_div";
          prewview_div.style.width = "100%";
          prewview_div.style.backgroundColor = "#808080ff";

            //문제생성 중단용 플래그 (무한루프 방지용)
            var stopFlag = false;
            var firstFlag = true; //첫페이지 플래그

            //환경변수
            var qcnt          = cnt;    //문항, 2,3,4,5,6
            var qtype         = type;    //가로 0, 세로 1, 가로장형 2
            var answer_print  = answer;    //정답및해설 출력
            var history_print = history;    //오답히스토리 출력

            //용지 사이즈 및 여백등
            //const toptitle      = this.pxTomm(130); //첫페이지 타이틀 높이 104px + 하단 마진 26px = 130px
            const toptitle      = 34.39583+5.525; //빈페이지 오류로 a4페이지의 top마진을 줄이고 여기 추가함.

            const secondmargin  = this.pxTomm(36); //두번째 페이지부터 상단에 마진요청함.

            const a4width       = 210;
            const a4height      = 297;
            const sidemargin    = this.pxTomm(36);  //좌,우는  36px 공백
            const topmargin     = this.pxTomm(36);
            const centermargin  = qtype == 2 ? 0 : this.pxTomm(16);  //가운데는 16px 공백인데 가로장형은 0px

            var col = 0;    //좌우에 배치되야 하는 문제의 개수
            var row = 0;    //세로 줄 수

            //타입과 문항수를 가지고 한 페이지에 들어가야 하는 문제의 영역수를 계산한다.
            col = qtype == 2 ? 1 : 2; //가로 장형이면 1줄에 1문제이고 그외에는 1줄에 2문제씩
            row = parseInt(qcnt/col);
            if(qcnt % col != 0){
              row++;
            }
            ////console.log("col : " + col  + " row : " + row);

            //문제당 넓이와 높이
            //한 문제의 영역 넓이 구하기 - a4사이즈에서 좌우 마진과 가운데 마진을 제거함.
            const question_width  = (a4width - (sidemargin*2) - (centermargin)) / col;

            //한 문제의 영역 높이 구하기 
            const question_height = (a4height - (topmargin*2) - Number(secondmargin)) / row;
            //첫페이지 문제 영역 높이 구하기
            const question_height_first = (a4height-(topmargin*2) - Number(toptitle)) / row;

            //px을mm로 변환하는 함수를 const로 변환해둔다. 콜백안에서 실행해야해서
            //const pxTomm = this.pxTomm;


            //선택된 문제는 전역변수에 있다.
            var questionList = selectlist;

            //console.log("questionList.length : " + questionList.length);

            //문제 사이즈를 미리 읽어야 한다. - 동기로 진행
            for(var k = 0; k < questionList.length ; k++){
              //이미지가 없으면 여기서 오류가 남.
              try{
                var temp = await this.getImageSize(questionList[k]);
                ////console.log("image size : " + temp.width + " / " + temp.height);
                questionList[k].width  = this.pxTomm(temp.width);
                questionList[k].height = this.pxTomm(temp.height);
              }catch(error){
                this.$alert("문제의 이미지 오류로 미리보기를 중단합니다. 문제명: " + questionList[k].QUESTION_NAME + " / 파일명 : "+questionList[k].IMAGE);
                stopFlag = true;
                break;
              }
            }

            if(stopFlag){
              return;
            }


            //문제를 돌면서 하나씩 그려보자.
            var page_div = null;
          
            //충돌 체크용 문제 영역 리스트
            var checkList = [];
            var checkpos = 0; //화면에 배치해야 하는 위치

            //console.log("=========================== start question ======================== ");

            for(var q = 0; q < questionList.length ; q++){
              
              //console.log("checkpos : " + checkpos);
              if(stopFlag) break;
              
              if(checkpos >= qcnt){
                //홀수 문항의 경우 ex) 3, 5등은 한자리 비워야함.
                checkpos = 0;
              }

              if(checkpos == 0){

                if(page_div != null){
                  this.createWater(page_div, water, true, name);
                  prewview_div.appendChild(page_div);
                  page_div = null;
                }

                checkList.splice(0, checkList.length);  //초기화

                //A4사이즈 시험지 페이지 생성
                page_div = document.createElement("div");
                page_div.style.width   = "210mm";  
                page_div.style.height   = "297mm";  
                page_div.style.backgroundColor = "#ffffff";
                
                //빈페이지 오류로 아래 주석처리하고 패딩처리함.
                //page_div.style.padding  = "9.525mm";
                page_div.style.paddingTop     = "4mm";
                page_div.style.paddingLeft    = "9.525mm";
                page_div.style.paddingRight   = "9.525mm";
                page_div.style.paddingBottom  = "9.525mm";

                //page_div.style.margin   = 0;
                page_div.style.marginTop = "-1px";
                page_div.style.marginLeft = "auto";
                page_div.style.marginRight = "auto";
                page_div.style.overflow = "hidden";
                page_div.style.pageBreakAfter = "always"; //페이지 넘김, 빈페이지가 생기는데 top 마진을 -1px을 함으로써 빈페이지를 지운다.
                page_div.style.borderRadius = "10px 10px 10px 10px";
                //page_div.style.boxShadow = "1px 1px";

                //페이지 탑 마진으로 구분해서 보기 (테스트용 나중에 주석처리 필요)
                //page_div.style.marginTop = 1+"px";
                
                //첫페이지 상단 타이틀 생성
                if(firstFlag){
                  //console.log("create title...1");
                  this.createTopTitle(page_div, title, semester);
                }
                
                var c = 0, r = 0;
                if(qtype == 0 || qtype == 2){
                  //가로 배열
                  //row for문 - 줄
                  for(r = 0; r < row ; r++ ){
                    if(stopFlag) break;

                    //col for문
                    for(c = 0; c < col ; c++ ){
                      if(stopFlag) break;

                      //console.log("r : " + r + " / c " + c + " w : " + question_width + " / h " + question_height + "y : " + (r * (question_height) ) + " / x :" + ( c*(question_width) + (c*centermargin) ));
                      const qbg = document.createElement("div");
                      qbg.model = {};
                      qbg.model.type = "bg";  
                      qbg.style.zIndex   = 1;
                      qbg.style.position = "absolute";
                      qbg.style.width  = question_width+"mm";
                      /*
                        첫페이지 문제 영역을 따로 계산하면 하단 침범을 계산하지 않아도 되고 
                        첫페이지 문제 영역을 타이틀이 없는 문제 영역것을 가져다가 쓰면 하단 침범을 계산해야 한다.
                        단 첫페이지 문제 영역을 따로 계산하면 첫페이지는 꾀나 찌그러져 보일 것이다. 

                        여기서는 첫페이지 문제 영역을 따로 계산해서 처리한다.
                      */
                      
                      if(firstFlag){
                        qbg.style.height    = question_height_first +"mm";
                        qbg.model.height    = question_height_first;
                        qbg.style.marginTop = ((r*question_height_first)+Number(toptitle)) + "mm";      
                        qbg.model.posy      = ((r*question_height_first)+Number(toptitle));
                      }else{
                        qbg.style.height    = question_height +"mm";
                        qbg.model.height    = question_height;
                        qbg.style.marginTop = ((r*question_height)+Number(secondmargin)) + "mm";                      //y좌표
                        qbg.model.posy      = ((r*question_height)+Number(secondmargin));
                      }                      
                      qbg.style.marginLeft  = ( c * (question_width) + (c * centermargin) ) + "mm";  //x좌표
                      qbg.model.width       = question_width;
                      qbg.model.posx        = ( c * (question_width) + ( c * centermargin) );

                      //배경에 공책 줄 넣기 - 이미지로 반복
                      if(printline){
                        qbg.style.backgroundImage  = `url(${require('../assets/bg_dotted.png')})`;
                        qbg.style.backgroundRepeat = "repeat";
                        //qbg.style.backgroundColor  = "#ff00ff"
                        page_div.appendChild(qbg);
                      }

                      checkList.push(qbg);

                    }

                  }

                }else if(qtype == 1){

                  //세로 배열
                  //row for문 - 줄
                  for(c = 0; c < col ; c++ ){
                    if(stopFlag) break;

                    //col for문
                    for(r = 0; r < row ; r++ ){
                      if(stopFlag) break;

                      //console.log("r : " + r + " / c " + c + " w : " + question_width + " / h " + question_height + "y : " + (r * (question_height) ) + " / x :" + ( c*(question_width) + (c*centermargin) ));
                      const qbg = document.createElement("div");
                      qbg.model = {};
                      qbg.model.type = "bg";  
                      qbg.style.position = "absolute";
                      qbg.style.zIndex   = 1;
                      qbg.style.width  = question_width+"mm";
                      if(firstFlag){
                        qbg.style.height    = question_height_first +"mm";
                        qbg.model.height    = question_height_first;
                        qbg.style.marginTop = ((r*question_height_first)+Number(toptitle)) + "mm";      
                        qbg.model.posy      = ((r*question_height_first)+Number(toptitle));
                      }else{
                        qbg.style.height    = question_height +"mm";
                        qbg.model.height    = question_height;
                        qbg.style.marginTop = ((r*question_height)+Number(secondmargin)) + "mm";                      //y좌표
                        qbg.model.posy      = ((r*question_height)+Number(secondmargin));
                      }

                      qbg.style.marginLeft = ( c * (question_width) + (c * centermargin) ) + "mm";  //x좌표
                      qbg.model.width  = question_width;
                      qbg.model.posx = ( c * (question_width) + ( c * centermargin) );

                      //배경에 공책 줄 넣기 - 이미지로 반복
                      if(printline){
                        qbg.style.backgroundImage  = `url(${require('../assets/bg_dotted.png')})`;
                        qbg.style.backgroundRepeat = "repeat";
                        //qbg.style.backgroundColor  = "#ff00ff"
                        page_div.appendChild(qbg);
                      }

                      checkList.push(qbg);

                    }

                  }

                }

                //계산 끝나면 첫페이지 false로 처리
                firstFlag = false;

              }


              //문제 생성
              const qdiv = document.createElement("div");
              qdiv.model = {};
              qdiv.style.position = "absolute";
              qdiv.style.zIndex = 2;
              qdiv.style.backgroundColor = "#fafafa";
              qdiv.style.width    = checkList[checkpos].model.width + "mm";

              //실제 문제 이미지
              var temp_img = document.createElement("img");
              temp_img.src = questionList[q].IMAGE;
              temp_img.style.width = "100%";
              qdiv.appendChild(temp_img);

              //사이즈를 문제 표시 영역의 가로 사이즈 기준으로 줄인다.
              ////console.log("w : " + questionList[q].width + " / " + questionList[q].height);
              var wmm = questionList[q].width;
              var hmm = questionList[q].height;
              var biul = wmm / hmm;
              var scale = checkList[checkpos].model.width / wmm;
              var img_width  = wmm * scale;
              var img_height = img_width / biul;

              //qdiv.style.height = pxTomm(questionList[q].height) + "mm";
              qdiv.style.height = img_height + "mm";
              qdiv.style.backgroundColor = questionList[q].backgroundColor;

              //문제의 위치를 미리 정해진 포지션을 잡아준다.
              qdiv.style.marginTop  = checkList[checkpos].model.posy+"mm"; 
              qdiv.style.marginLeft = checkList[checkpos].model.posx+"mm";  

              //충돌체크 계산용 데이터 백업
              qdiv.model.type   = "question";
              //qdiv.model.width  = pxTomm(questionList[q].width);
              //qdiv.model.height = pxTomm(questionList[q].height);
              qdiv.model.width  = img_width;
              qdiv.model.height = img_height;

              qdiv.model.posx   = checkList[checkpos].model.posx; 
              qdiv.model.posy   = checkList[checkpos].model.posy; 
              qdiv.model.title  = questionList[q].title;

              /*
              const qlabel = document.createElement("label");
              qlabel.textContent = questionList[q].title;
              qdiv.appendChild(qlabel);
              */


              //충돌체크 - 페이지 내부에서 type이 question인 객체들과 충돌체크한다.
              var check_flag = false;

              //차일드가 없는 페이지 첫 문제인 경우에 문제가 화면을 넘어가는지 체크
              var contentAreaHeight = parseInt(a4height) - parseInt(topmargin);
              if(qdiv.model.height > contentAreaHeight){
                stopFlag = true;
              }

              for(var n=0;n<page_div.children.length;n++){

                if(stopFlag) break;

                if(page_div.children[n].model.type == "question"){

                  var rect1 = {
                                  "x": parseInt(page_div.children[n].model.posx)
                                , "y": parseInt(page_div.children[n].model.posy)
                                , "width": parseInt(page_div.children[n].model.width)
                                , "height": parseInt(page_div.children[n].model.height)
                                , "top": parseInt(page_div.children[n].model.posy)
                                , "right": parseInt(page_div.children[n].model.posx)  + parseInt(page_div.children[n].model.width)
                                , "bottom": parseInt(page_div.children[n].model.posy) + parseInt(page_div.children[n].model.height)
                                , "left": parseInt(page_div.children[n].model.posx)
                              }

                  var rect2 = {
                                  "x": parseInt(qdiv.model.posx)
                                , "y": parseInt(qdiv.model.posy)
                                , "width": parseInt(qdiv.model.width)
                                , "height": parseInt(qdiv.model.height)
                                , "top": parseInt(qdiv.model.posy)
                                , "right": parseInt(qdiv.model.posx)  + parseInt(qdiv.model.width)
                                , "bottom": parseInt(qdiv.model.posy) + parseInt(qdiv.model.height)
                                , "left": parseInt(qdiv.model.posx)
                              }

                  //safe area 충돌체크 (문제가 길어서 바닥을 침범하는 경우도 체크해야한다.)
                  var rect3 = {
                                  "x": 0
                                , "y": parseInt(a4height) - parseInt(topmargin)
                                , "width": parseInt(a4width)
                                , "height": parseInt(1)
                                , "top": parseInt(a4height) - parseInt(topmargin)
                                , "right": parseInt(a4width)
                                , "bottom": parseInt(a4height) - parseInt(topmargin)
                                , "left": 0
                              }

                  ////console.log("-------------------------------------------------------------------------------------------");
                  ////console.log("crect : " + JSON.stringify(rect1));
                  ////console.log("qrect : " + JSON.stringify(rect2));
                  if(intersectRect(rect1, rect2)){
                    //충돌
                    ////console.log("qpos : " + q + " intersect checkpos : " + checkpos + " =>  " + JSON.stringify(page_div.children[n].model));
                    check_flag = true;
                    break;
                  }

                  if(intersectRect(rect3, rect2)){
                    //문제가 길어서 바닥을 넘어가는지 체크
                    ////console.log("qpos : " + q + " bottom intersect checkpos : " + checkpos + " =>  " + JSON.stringify(page_div.children[n].model));
                    check_flag = true;
                    break;
                  }

                }
              }

              //충돌 안했으면 추가
              if(check_flag){
                //충돌했으면 다음으로 넘겨라.
                checkpos = checkpos + 1 >= checkList.length ? 0 : checkpos + 1;
                q--;

              }else{
                //충돌 안했으면 추가
                ////console.log("qpos : " + q + " add complete checkpos : "+checkpos);
                page_div.appendChild(qdiv);
                //다음 순서로 변경
                checkpos = checkpos + 1 >= checkList.length ? 0 : checkpos + 1;
              }

            }


            if(stopFlag){
              this.$alert("문제의 이미지 사이즈가 A4사이즈를 초과하여 미리보기를 중단합니다. 문제명: " + questionList[q].QUESTION_NAME + " / 파일명 : "+questionList[q].IMAGE);
              return;
            }

            //워터마크 추가
            this.createWater(page_div, water, true, name);
            prewview_div.appendChild(page_div);
            
            //정답및해설 출력
            if(answer_print){

              //some...
              var answerList = [];
              questionList.forEach(element => {
                if(element.ANSWER_IMAGE != null && element.ANSWER_IMAGE != undefined && element.ANSWER_IMAGE.length > 0){
                  answerList.push(element);
                }
              });

              if(answerList.length > 0){
                await this.createAnswer(prewview_div, answerList, cnt, type, water);
              }
              //console.log("answer_print....");
            }

            //체크리스트 출력
            if(history_print){
              //console.log("history_print.... : " + JSON.stringify(chapter));
              if(chapter != null){
                await this.createCheckNote(prewview_div, chapter, water, name);
              }
            }

            //양면인쇄            
            if(twoside){
              //console.log("page len : " + prewview_div.children.length);
              if(prewview_div.children.length % 2 != 0){
                //양면인쇄 보정시 홀수로 떨어지면 빈장 한장을 추가해서 넣어줘라. 
                this.createEmptypage(prewview_div);
              }
            }
            
            var htmltext = prewview_div.outerHTML;
            //console.log("html text : " + htmltext);

            //필수 이게 들어가야 vue.js의 사이즈와 동일하게 프린트 됨. 안그러면 오차생김.
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
              stylesHtml += node.outerHTML;
            }

            ////console.log("stylesHtml : " + stylesHtml);
            
            //let btnHtml = "<button onclick='window.print()' style='width:90px; height:70px;'>인쇄하기1</button>";

            var w = window.open("", "출력", "width=1200,height=1000,left=100,top=50");
            w.document.write(`<!DOCTYPE html>
            <html>
              <head>
                <style>
                  @page {
                    size: 210mm 297mm;  /*A4 Size*/
                    margin: 0;
                    padding: 0;
                  }
                  @media print{
                    .toplayout{
                      display: none;
                    }
                  }
                </style>
                ${stylesHtml}
              </head>
              <body sytle="margin:0px pading:0px">
                <div class="toplayout" style="text-align:center;width: 100%; background-color: rgb(128, 128, 128);">
                    <button style="border-radius:5px 5px 5px 5px; background-color:#690505; color:white; width: 100px; height: 40px; margin: 5px;" onclick="window.print();">인쇄하기</button>
                </div>
                ${htmltext}
              </body>
            </html>`);        

            //w.document.write('<scr' + 'ipt type="text/javascript">' + 'function printpage(){ window.print(); }' + '</sc' + 'ript>');
            //jqeury 임포트
            w.document.write('<scr'+'ipt src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></sc'+'ript>');
            //마우스 우클릭, 선택방지, 드래그 방지
            w.document.write('<scr' + 'ipt type="text/javascript">' + 'window.onload = function() { $(document).bind("contextmenu",function(){   return false;});  $(document).bind("selectstart",function() {return false;}); $(document).bind("dragstart",function(){return false;}); };' + '</sc' + 'ript>');
            w.document.close(); // necessary for IE >= 10
            w.focus();          // necessary for IE >= 10
            




        },



        //상단 타이틀 생성
        createTopTitle(pdiv, title, semester){

          const a4width       = 210;
          const sidemargin    = this.pxTomm(36);  //좌,우는  36px 공백

          //첫페이지인 경우 타이틀 추가
          var title_div = document.createElement("div");
          title_div.style.position = "absolute";
          title_div.style.marginLeft = "0mm";

          //빈페이지 오류로 0mm에서 5.525로 변경함.
          title_div.style.marginTop  = "5.525mm";
          title_div.style.width = (a4width - (sidemargin*2)) + "mm";
          title_div.style.height = "104px";
          title_div.style.border = "2px solid black"
          title_div.style.display = 'inline';
          title_div.model = {};
          title_div.model.type = "title";

          var name_div = document.createElement("div");
          name_div.style.paddingLeft = "5px";
          name_div.style.paddingRight = "5px";
          name_div.style.overflow = "hidden";
          name_div.style.width = "60%";
          name_div.style.height = "100%";
          name_div.style.backgroundColor  = "white";
          name_div.style.justifyContent   = "center";
          name_div.style.verticalAlign    = "middle";
          name_div.style.display          = "flex";
          name_div.style.flexDirection    = "column";
          name_div.style.alignItems = "center";
          name_div.style.backgroundColor = "white";

          var title_label = document.createElement("div");
          title_label.style.textAlign = "center";
          title_label.style.fontSize = "20px";
          title_label.textContent = title;
          title_label.style.display = "block";
          title_label.style.justifyContent = "center";
          title_label.style.maxHeight = "50%";
          title_label.style.lineHeight = "20px";

          var semester_label = document.createElement("div");
          semester_label.style.textAlign = "center";
          semester_label.style.fontSize = "11px";
          semester_label.style.lineHeight = "11px";
          semester_label.style.display = "block";
          semester_label.style.justifyContent = "center";
          semester_label.textContent = semester;
          semester_label.style.maxHeight = "50%";
          semester_label.style.marginTop = "8px";

          name_div.appendChild(title_label);
          name_div.appendChild(semester_label);


          title_div.appendChild(name_div);

          var confirm_div = document.createElement("div");
          confirm_div.style.position = "absolute";
          confirm_div.style.left = "60%";
          confirm_div.style.top  = "0%";
          confirm_div.style.width = "40%";
          confirm_div.style.height = "100%"
          confirm_div.style.backgroundColor = "white";
          confirm_div.style.display = "inline";

          var box_div1 = document.createElement("div");
          box_div1.style.display = "inline";
          box_div1.style.position = "absolute";
          box_div1.style.left = "0%";
          box_div1.style.top  = "2px";
          box_div1.style.width = "25%";
          box_div1.style.height = "96px";
          box_div1.style.borderStyle = "hidden hidden hidden solid";
          box_div1.style.borderWidth = "1px";

          var box_div11 = document.createElement("label");
          box_div11.style.position = "absolute";
          box_div11.style.left = "2px";
          box_div11.style.width = "99%";
          box_div11.style.height = "50px";
          box_div11.style.backgroundColor = "#ececec";
          box_div11.style.borderStyle = "hidden hidden dotted hidden";
          box_div11.style.borderWidth = "1px";
          box_div11.textContent = "회차";
          box_div11.style.textAlign = "center";
          box_div11.style.verticalAlign = "middle";
          box_div11.style.lineHeight = "50px";

          box_div1.appendChild(box_div11);
          confirm_div.appendChild(box_div1);


          var box_div2 = document.createElement("div");
          box_div2.style.display = "inline";
          box_div2.style.position = "absolute";
          box_div2.style.left = "25%";
          box_div2.style.top  = "2px";
          box_div2.style.width = "25%";
          box_div2.style.height = "96px";
          box_div2.style.borderStyle = "hidden hidden hidden solid";
          box_div2.style.borderWidth = "1px";

          var box_div22 = document.createElement("div");
          box_div22.style.position = "absolute";
          box_div22.style.left = "2px";
          box_div22.style.width = "99%";
          box_div22.style.height = "50px";
          box_div22.style.backgroundColor = "#ececec";
          box_div22.style.borderStyle = "hidden hidden dotted hidden";
          box_div22.style.borderWidth = "1px";
          box_div22.innerHTML = "채점일<br>(채점자)";
          box_div22.style.textAlign = "center";
          box_div22.style.paddingTop = "1px";
          
          box_div2.appendChild(box_div22);
          confirm_div.appendChild(box_div2);

          var box_div3 = document.createElement("div");
          box_div3.style.display = "inline";
          box_div3.style.position = "absolute";
          box_div3.style.left = "50%";
          box_div3.style.top  = "2px";
          box_div3.style.width = "25%";
          box_div3.style.height = "96px";
          box_div3.style.borderStyle = "hidden hidden hidden solid";
          box_div3.style.borderWidth = "1px";

          var box_div33 = document.createElement("div");
          box_div33.style.position = "absolute";
          box_div33.style.left = "2px";
          box_div33.style.width = "99%";
          box_div33.style.height = "50px";
          box_div33.style.backgroundColor = "#ececec";
          box_div33.style.borderStyle = "hidden hidden dotted hidden";
          box_div33.style.borderWidth = "1px";
          box_div33.textContent = "오답개수";
          box_div33.style.textAlign = "center";
          box_div33.style.verticalAlign = "middle";
          box_div33.style.lineHeight = "50px";
          box_div3.appendChild(box_div33);
          confirm_div.appendChild(box_div3);

          var box_div4 = document.createElement("div");
          box_div4.style.display = "inline";
          box_div4.style.position = "absolute";
          box_div4.style.left = "75%";
          box_div4.style.top  = "2px";
          box_div4.style.width = "25%";
          box_div4.style.height = "96px";
          box_div4.style.borderStyle = "hidden hidden hidden solid";
          box_div4.style.borderWidth = "1px";

          var box_div44 = document.createElement("div");
          box_div44.style.position = "absolute";
          box_div44.style.left = "2px";
          box_div44.style.width = "96%";
          box_div44.style.height = "50px";
          box_div44.style.backgroundColor = "#ececec";
          box_div44.style.borderStyle = "hidden hidden dotted hidden";
          box_div44.style.borderWidth = "1px";
          box_div44.textContent = "원장확인";
          box_div44.style.textAlign = "center";
          box_div44.style.verticalAlign = "middle";
          box_div44.style.lineHeight = "50px";
          box_div4.appendChild(box_div44);
          confirm_div.appendChild(box_div4);


          title_div.appendChild(confirm_div);
          pdiv.appendChild(title_div);

        },

        //출력자 이름 + 워터마크 + 이미지로고 그리기
        // eslint-disable-next-line no-unused-vars
        createWater(pdiv, wtext, logo, name){

          if(pdiv == null || pdiv == undefined){
            return;
          }

          // eslint-disable-next-line no-unused-vars
          const a4width       = 210;
          const a4height      = 297;
          // eslint-disable-next-line no-unused-vars
          const sidemargin    = this.pxTomm(36);  //좌,우는  36px 공백
          const topmargin     = this.pxTomm(36);

            //워터마크
            var water_label = document.createElement("div");
            water_label.style.position  = "absolute";
            water_label.style.width     = "340px";
            water_label.style.zIndex    = 10;
            water_label.style.height    = (a4height - (topmargin*2)) + "mm";
            
            water_label.style.left = "50%";
            water_label.style.transform = "translateX(-50%)";

            //water_label.style.margin    = "auto";
            water_label.style.display        = "flex";    //이거 필수 그래야 가운데 정렬됨.
            water_label.style.alignItems     = "center";  //내부 텍스트 컨텐츠 세로 정렬
            water_label.style.justifyContent = "center";  //내부 텍스트 컨텐츠 가로 정렬
            water_label.style.flexDirection  = "column";

            if(wtext != null && wtext != undefined && wtext.length > 0){
              var water_text = document.createElement("div");
              water_text.style.color     = "#cccccc80";
              water_text.style.fontSize  = "30px";
              water_text.textContent     = wtext;
              water_label.appendChild(water_text);
            }

            //water_label.textContent     = wtext;
            pdiv.appendChild(water_label);


          //하단 선생님 이름 - 상관없고
          if(name != null && name != undefined && name.length > 0){

            var name_label = document.createElement("div");
            name_label.style.position  = "absolute";
            name_label.style.width     = (a4width  - sidemargin) + "mm";
            name_label.style.zIndex    = 10;
            name_label.style.height    = (a4height - (topmargin*2)) + "mm";

            if(name != null && name != undefined && name.length > 0){
              var name_text = document.createElement("div");
              name_text.style.position = "absolute";
              name_text.style.zIndex   = 30;
              name_text.style.width = "100%";
              name_text.style.bottom = "-18px";
              
              //2024년 8월 28일 - 하단 워터마크 라벨 때문에 2장 나와서 수정함.
              //name_text.src = footer_img;
              //name_text.style.left = "99%";
              //name_text.style.top  = "99%";
              //name_text.style.transform = "translateX(-100%) translateY(-100%)";

              name_text.textContent = name + " Teacher";
              name_text.style.color     = "#aaaaaa80";
              name_text.style.fontSize  = "28px";
              name_text.style.textAlign = "center";
              name_text.style.fontFamily = "나눔고딕";
              name_text.style.marginTop = "30px";
              //name_text.style.backgroundColor = "#ff0000ff";

              


              name_label.appendChild(name_text);
            }
            
            pdiv.appendChild(name_label);          

          }
         
          //우측하단 로고
          if(logo){
            var footer_img = this.$session.get("FOOTER_IMG");
            //console.log("footer img : " + footer_img);
            if(footer_img != null && footer_img != undefined && footer_img.length > 0){
              var mark_div = document.createElement("div");
              mark_div.style.position  = "absolute";
              mark_div.style.zIndex    = 15;
              //mark_div.style.backgroundColor = "red";

              //컨텐츠 영역내의 우측 하단
              //mark_div.style.width     = (a4width  - (sidemargin*2)) + "mm";
              //mark_div.style.height    = (a4height - (topmargin*2)) + "mm";

              //완전 우측하단
              mark_div.style.width     = (a4width  - sidemargin) + "mm";
              mark_div.style.height    = (a4height - topmargin) + "mm";
              //mark_div.style.backgroundColor = "#ff000080";


              
              //범인
              var mark_img = document.createElement("img");
              mark_img.style.position = "absolute";
              mark_img.style.objectFit = "contain";
              //mark_img.style.background = "red";
              mark_img.style.zIndex   = 20;
              mark_img.src = footer_img;
              mark_img.style.width  = "100px";
              mark_img.style.height = "100px";
              mark_img.style.right  = "10px";
              mark_img.style.bottom = "13px";
              //mark_img.style.transform = "translateX(-100%) translateY(-100%)";

              mark_div.appendChild(mark_img);

              pdiv.appendChild(mark_div);                  
            }
          }
          

        },

        //체크리스트 워터마크 그리기 - 이건 가로형으로 그려서 돌려야 한다.
        checkcreateWater(pdiv, wtext, name){

          if(pdiv == null || pdiv == undefined){
            return;
          }

          //const a4width       = 210;
          //const a4height      = 297;
          //const sidemargin    = this.pxTomm(36);  //좌,우는  36px 공백
          //const topmargin     = this.pxTomm(36);

          //워터마크
          
            var water_div = document.createElement("div");
            water_div.style.display   = "flex";
            water_div.style.width     = "100%";
            water_div.style.zIndex    = 10;
            water_div.style.height    = "100%";

            var water_label = document.createElement("div");
            water_label.style.display         = "flex";
            water_label.style.justifyContent  = "center";
            water_label.style.alignItems      = "center";
            water_label.style.flexDirection   = "column";


            if(name != null && name != undefined && name.length > 0){
              var name_text = document.createElement("div");
              name_text.textContent = name;
              name_text.style.color     = "#cccccc80";
              name_text.style.fontSize  = "26px";
              water_label.appendChild(name_text);
            }

            if(wtext != null && wtext != undefined && wtext.length > 0){
              var water_text = document.createElement("div");
              water_text.style.color     = "#cccccc80";
              water_text.style.fontSize  = "30px";
              water_text.textContent     = wtext;
              water_label.appendChild(water_text);
            }

            //water_label.style.left = "50%";
            //water_label.style.transform = "translateX(-50%)";

            water_label.style.margin    = "auto";
            water_label.style.zIndex    = 10;
            water_label.style.transform   = "rotate(-90deg)"
            water_div.appendChild(water_label);
            pdiv.appendChild(water_div);
            

        },



        //정답및 해설은 워터마크 필요 없고 하단 로고 필요 없음 2022년 4월 6일 요청사항 - 신대표님 요청
        async createAnswer(prewview_div, list, cnt, type, water){
          
          water = ""; //정답및해설의 워터마크는 필요 없다고 함.

            //문제생성 중단용 플래그 (무한루프 방지용)
            var stopFlag = false;

            //환경변수
            //var qcnt          = cnt;    //문항, 2,3,4,5,6
            //var qtype         = type;    //가로 0, 세로 1, 가로장형 2
            
            //무조건 세로에 8문항으로 처리 요청함 2022년 5월 6일 신대표님 요청
            var qcnt          = 8;
            var qtype         = 1;    //가로 0, 세로 1, 가로장형 2

            //용지 사이즈 및 여백등
            const a4width       = 210;
            const a4height      = 297;
            const sidemargin    = this.pxTomm(36);  //좌,우는  36px 공백
            const topmargin     = this.pxTomm(36);
            const centermargin  = qtype == 2 ? 0 : this.pxTomm(16);  //가운데는 16px 공백인데 가로장형은 0px

            var col = 0;    //좌우에 배치되야 하는 문제의 개수
            var row = 0;    //세로 줄 수

            //타입과 문항수를 가지고 한 페이지에 들어가야 하는 문제의 영역수를 계산한다.
            col = qtype == 2 ? 1 : 2; //가로 장형이면 1줄에 1문제이고 그외에는 1줄에 2문제씩
            row = parseInt(qcnt/col);
            if(qcnt % col != 0){
              row++;
            }

            //문제당 넓이와 높이
            //한 문제의 영역 넓이 구하기 - a4사이즈에서 좌우 마진과 가운데 마진을 제거함.
            const question_width  = (a4width - (sidemargin*2) - (centermargin)) / col;

            //한 문제의 영역 높이 구하기 
            const question_height = (a4height - (topmargin*2)) / row;

            //px을mm로 변환하는 함수를 const로 변환해둔다. 콜백안에서 실행해야해서
            //const pxTomm = this.pxTomm;


            //선택된 문제는 전역변수에 있다.
            var questionList = list;

            //문제 사이즈를 미리 읽어야 한다. - 동기로 진행
            for(var k = 0; k < questionList.length ; k++){
              try{
              var temp = await this.getAnswerImageSize(questionList[k]);
              ////console.log("image size : " + temp.width + " / " + temp.height);
              questionList[k].width  = this.pxTomm(temp.width);
              questionList[k].height = this.pxTomm(temp.height);
              }catch(error){
                this.$alert("정답및해설의 이미지 오류로 미리보기를 중단합니다. 문제명 : " + questionList[k].QUESTION_NAME);
                stopFlag = true;
                break;
              }
            }

            if(stopFlag){
              return;
            }

            //문제를 돌면서 하나씩 그려보자.
            var page_div = null;

            //충돌 체크용 문제 영역 리스트
            var checkList = [];
            var checkpos = 0; //화면에 배치해야 하는 위치

            //console.log("=========================== start answer ======================== ");

            for(var q = 0; q < questionList.length ; q++){
              
              ////console.log("checkpos : " + checkpos);
              if(stopFlag) break;
              
              if(checkpos >= qcnt){
                //홀수 문항의 경우 ex) 3, 5등은 한자리 비워야함.
                checkpos = 0;
              }

              if(checkpos == 0){

                if(page_div != null){
                  //this.createWater(page_div, water, false);
                  prewview_div.appendChild(page_div);
                  page_div = null;
                }

                checkList.splice(0, checkList.length);  //초기화

                //A4사이즈 시험지 페이지 생성
                page_div = document.createElement("div");
                page_div.style.width   = "210mm";  
                page_div.style.height   = "297mm";  
                page_div.style.backgroundColor = "#ffffff";
                page_div.style.padding  = "9.525mm";
                page_div.style.marginTop = "-1px";
                page_div.style.marginLeft = "auto";
                page_div.style.marginRight = "auto";
                page_div.style.overflow = "hidden";
                page_div.style.pageBreakAfter = "always"; //페이지 넘김, 빈페이지가 생기는데 top 마진을 -1px을 함으로써 빈페이지를 지운다.
                page_div.style.borderRadius = "10px 10px 10px 10px";

                //페이지 탑 마진으로 구분해서 보기 (테스트용 나중에 주석처리 필요)
                //page_div.style.marginTop = 1+"px";

                var c = 0, r = 0;
                if(qtype == 0 || qtype == 2){
                  //가로 배열
                  //row for문 - 줄
                  for(r = 0; r < row ; r++ ){
                    if(stopFlag) break;

                    //col for문
                    for(c = 0; c < col ; c++ ){
                      if(stopFlag) break;

                      ////console.log("r : " + r + " / c " + c + " w : " + question_width + " / h " + question_height + "y : " + (r * (question_height) ) + " / x :" + ( c*(question_width) + (c*centermargin) ));
                      const qbg = document.createElement("div");
                      qbg.model = {};
                      qbg.model.type = "bg";  
                      qbg.style.zIndex   = 1;
                      qbg.style.position = "absolute";
                      qbg.style.width  = question_width+"mm";
                      qbg.style.height = question_height+"mm";
                      qbg.style.marginTop  = ( r * (question_height) ) + "mm";                      //y좌표
                      qbg.style.marginLeft = ( c * (question_width) + (c * centermargin) ) + "mm";  //x좌표
                      //계산용 정보 백업
                      qbg.model.width  = question_width;
                      qbg.model.height = question_height;
                      qbg.model.posy = ( r * (question_height) );
                      qbg.model.posx = ( c * (question_width) + ( c * centermargin) );

                      //배경에 공책 줄 넣기 - 이미지로 반복
                      qbg.style.backgroundImage  = `url(${require('../assets/bg_dotted.png')})`;
                      qbg.style.backgroundRepeat = "repeat";
                      //qbg.style.backgroundColor  = "#ff00ff"
                      page_div.appendChild(qbg);

                      checkList.push(qbg);

                    }

                  }

                }else if(qtype == 1){

                  //세로 배열
                  //row for문 - 줄
                  for(c = 0; c < col ; c++ ){
                    if(stopFlag) break;

                    //col for문
                    for(r = 0; r < row ; r++ ){
                      if(stopFlag) break;

                      ////console.log("r : " + r + " / c " + c + " w : " + question_width + " / h " + question_height + "y : " + (r * (question_height) ) + " / x :" + ( c*(question_width) + (c*centermargin) ));
                      const qbg = document.createElement("div");
                      qbg.model = {};
                      qbg.model.type = "bg";  
                      qbg.style.position = "absolute";
                      qbg.style.zIndex   = 1;
                      qbg.style.width  = question_width+"mm";
                      qbg.style.height = question_height+"mm";
                      qbg.style.marginTop  = ( r * (question_height) ) + "mm";                      //y좌표
                      qbg.style.marginLeft = ( c * (question_width) + (c * centermargin) ) + "mm";  //x좌표
                      qbg.model.width  = question_width;
                      qbg.model.height = question_height;
                      qbg.model.posy = ( r * (question_height) );
                      qbg.model.posx = ( c * (question_width) + ( c * centermargin) );

                      //배경에 공책 줄 넣기 - 이미지로 반복
                      qbg.style.backgroundImage  = `url(${require('../assets/bg_dotted.png')})`;
                      qbg.style.backgroundRepeat = "repeat";
                      //qbg.style.backgroundColor  = "#ff00ff"
                      page_div.appendChild(qbg);
                      checkList.push(qbg);

                    }

                  }

                }

                

              }


              //문제 생성
              const qdiv = document.createElement("div");
              qdiv.model = {};
              qdiv.style.position = "absolute";
              qdiv.style.zIndex = 2;
              //qdiv.style.backgroundColor = "#fafafa";
              qdiv.style.width    = checkList[checkpos].model.width + "mm";

              //문제명 표시 라벨
              var qlabel = document.createElement("label");
              qlabel.style.width    = "100%";
              qlabel.style.height   = "20px";
              qlabel.style.backgroundColor = "white";
              qlabel.textContent    = questionList[q].QUESTION_NAME + " 해설";
              qlabel.style.fontSize = "15px";
              qlabel.style.color    = "#202020";
              qdiv.appendChild(qlabel);

              //실제 문제 이미지
              var temp_img = document.createElement("img");
              temp_img.src = questionList[q].ANSWER_IMAGE;
              temp_img.style.width = "100%";
              qdiv.appendChild(temp_img);

              //사이즈를 문제 표시 영역의 가로 사이즈 기준으로 줄인다.
              ////console.log("w : " + questionList[q].width + " / " + questionList[q].height);
              var wmm = questionList[q].width;
              var hmm = questionList[q].height;
              var biul = wmm / hmm;
              var scale = checkList[checkpos].model.width / wmm;
              var img_width  = wmm * scale;
              var img_height = img_width / biul;
              
              //문제명을 표시하기 위한 50px을 추가한다.
              qdiv.style.height = (img_height + parseInt(this.pxTomm(20))) + "mm";  
              qdiv.style.backgroundColor = questionList[q].backgroundColor;

              //문제의 위치를 미리 정해진 포지션을 잡아준다.
              qdiv.style.marginTop  = checkList[checkpos].model.posy+"mm"; 
              qdiv.style.marginLeft = checkList[checkpos].model.posx+"mm";  

              //충돌체크 계산용 데이터 백업
              qdiv.model.type   = "question";
              //qdiv.model.width  = pxTomm(questionList[q].width);
              //qdiv.model.height = pxTomm(questionList[q].height);
              qdiv.model.width  = img_width;
              qdiv.model.height = qdiv.style.height;  //모델의 높이도 문제명을 포함한 높이값으로 처리한다.

              qdiv.model.posx   = checkList[checkpos].model.posx; 
              qdiv.model.posy   = checkList[checkpos].model.posy; 
              qdiv.model.title  = questionList[q].title;

              /*
              const qlabel = document.createElement("label");
              qlabel.textContent = questionList[q].title;
              qdiv.appendChild(qlabel);
              */


              //충돌체크 - 페이지 내부에서 type이 question인 객체들과 충돌체크한다.
              var check_flag = false;

              //차일드가 없는 페이지 첫 문제인 경우에 문제가 화면을 넘어가는지 체크
              var contentAreaHeight = parseInt(a4height) - parseInt(topmargin);
              if(qdiv.model.height > contentAreaHeight){
                stopFlag = true;
              }

              for(var n=0;n<page_div.children.length;n++){

                if(stopFlag) break;

                if(page_div.children[n].model.type == "question"){
                  
                  var rect1 = {
                                  "x": parseInt(page_div.children[n].model.posx)
                                , "y": parseInt(page_div.children[n].model.posy)
                                , "width": parseInt(page_div.children[n].model.width)
                                , "height": parseInt(page_div.children[n].model.height)
                                , "top": parseInt(page_div.children[n].model.posy)
                                , "right": parseInt(page_div.children[n].model.posx)  + parseInt(page_div.children[n].model.width)
                                , "bottom": parseInt(page_div.children[n].model.posy) + parseInt(page_div.children[n].model.height)
                                , "left": parseInt(page_div.children[n].model.posx)
                              }

                  var rect2 = {
                                  "x": parseInt(qdiv.model.posx)
                                , "y": parseInt(qdiv.model.posy)
                                , "width": parseInt(qdiv.model.width)
                                , "height": parseInt(qdiv.model.height)
                                , "top": parseInt(qdiv.model.posy)
                                , "right": parseInt(qdiv.model.posx)  + parseInt(qdiv.model.width)
                                , "bottom": parseInt(qdiv.model.posy) + parseInt(qdiv.model.height)
                                , "left": parseInt(qdiv.model.posx)
                              }

                  //safe area 충돌체크 (문제가 길어서 바닥을 침범하는 경우도 체크해야한다.)
                  var rect3 = {
                                  "x": 0
                                , "y": parseInt(a4height) - parseInt(topmargin)
                                , "width": parseInt(a4width)
                                , "height": parseInt(1)
                                , "top": parseInt(a4height) - parseInt(topmargin)
                                , "right": parseInt(a4width)
                                , "bottom": parseInt(a4height) - parseInt(topmargin)
                                , "left": 0
                              }

                  ////console.log("-------------------------------------------------------------------------------------------");
                  ////console.log("crect : " + JSON.stringify(rect1));
                  ////console.log("qrect : " + JSON.stringify(rect2));
                  if(intersectRect(rect1, rect2)){
                    //충돌
                    ////console.log("qpos : " + q + " intersect checkpos : " + checkpos + " =>  " + JSON.stringify(page_div.children[n].model));
                    check_flag = true;
                    break;
                  }

                  if(intersectRect(rect3, rect2)){
                    //문제가 길어서 바닥을 넘어가는지 체크
                    ////console.log("qpos : " + q + " bottom intersect checkpos : " + checkpos + " =>  " + JSON.stringify(page_div.children[n].model));
                    check_flag = true;
                    break;
                  }

                }
              }

              //충돌 안했으면 추가
              if(check_flag){
                //충돌했으면 다음으로 넘겨라.
                checkpos = checkpos + 1 >= checkList.length ? 0 : checkpos + 1;
                q--;

              }else{
                //충돌 안했으면 추가
                ////console.log("qpos : " + q + " add complete checkpos : "+checkpos);
                page_div.appendChild(qdiv);
                //다음 순서로 변경
                checkpos = checkpos + 1 >= checkList.length ? 0 : checkpos + 1;
              }

            }


            if(stopFlag){
              this.$alert("정답및해설의 이미지 사이즈가 A4사이즈를 초과하여 미리보기를 중단합니다.");
              return;
            }

            //워터마크 추가
            this.createWater(page_div, water, false, null);
            prewview_div.appendChild(page_div);

        },

        //체크리스트는 워터마크 필요하고 하단 로고는 필요없다고 함. 2022년 4월 6일
        async createCheckNote(pdiv, chapter, water, name){

            //용지 사이즈 및 여백등
            const a4width       = 210;
            const a4height      = 297;
            const sidemargin    = this.pxTomm(36);  //좌,우는  36px 공백
            const topmargin     = this.pxTomm(36);

            //선택된 문제는 전역변수에 있다.
            var questionList = [];
            
            if(chapter.CHECK_IMAGE1.length > 0){
              questionList.push({IMAGE:chapter.CHECK_IMAGE1});
            }
            if(chapter.CHECK_IMAGE2.length > 0){
              questionList.push({IMAGE:chapter.CHECK_IMAGE2});
            }

            //문제 사이즈를 미리 읽어야 한다. - 동기로 진행
            for(var k = 0; k < questionList.length ; k++){
              var temp = await this.getImageSize(questionList[k]);
              //console.log("image size : " + temp.width + " / " + temp.height);
              questionList[k].width  = this.pxTomm(temp.width);
              questionList[k].height = this.pxTomm(temp.height);
            }

            //문제를 돌면서 하나씩 그려보자.
            var page_div = null;

            //console.log("=========================== start check note ======================== ");

            for(var q = 0; q < questionList.length ; q++){
              //console.log("1111");
              //A4사이즈 시험지 페이지 생성
              page_div = document.createElement("div");
              page_div.style.width   = "210mm";  
              page_div.style.height   = "297mm";  
              page_div.style.backgroundColor = "#ffffff";
              page_div.style.padding  = "9.525mm";
              page_div.style.margin   = 0;
              page_div.style.marginTop = "-1px";
              page_div.style.marginLeft = "auto";
              page_div.style.marginRight = "auto";
              page_div.style.overflow = "hidden";
              page_div.style.pageBreakAfter = "always"; //페이지 넘김, 빈페이지가 생기는데 top 마진을 -1px을 함으로써 빈페이지를 지운다.
              page_div.style.borderRadius = "10px 10px 10px 10px";
              //페이지 탑 마진으로 구분해서 보기 (테스트용 나중에 주석처리 필요)
              //page_div.style.marginTop = 1+"px";

              //체크리스트가 들어갈 배경
              const qdiv = document.createElement("div");
              qdiv.model = {};
              qdiv.style.position = "absolute";
              qdiv.style.zIndex = 2;
              //qdiv.style.backgroundColor = "#fafafa";
              qdiv.style.width  = (a4width  - (sidemargin*2)) + "mm";
              qdiv.style.height = (a4height - (topmargin*2)) + "mm";
              qdiv.style.marginTop  = "0mm"; 
              qdiv.style.marginLeft = "0mm";  
              qdiv.style.overflow = "hidden";

              //실제 문제 이미지
              var temp_img = document.createElement("img");
              temp_img.src = questionList[q].IMAGE;
              temp_img.style.width  = (a4height - (topmargin*2)) + "mm";
              //temp_img.style.height = (a4width  - (sidemargin*2)) + "mm"; //이거 넣으면 풀로 나옴.

              
              temp_img.style.transformOrigin  = "top left";
              temp_img.style.transform        = "rotate(-90deg) translate(-100%, 0%)"


              //temp_img.style.transformOrigin = "center";
              //console.log("1111");
              qdiv.appendChild(temp_img);
              //console.log("2222");
              page_div.appendChild(qdiv);
              //console.log("3333");
              pdiv.appendChild(page_div);

            }

            //체크리스트에 워터마크는 필요하고 로고는 필요 없음
            this.checkcreateWater(page_div, water, name);
            

        },

        //빈페이지 추가
        async createEmptypage(pdiv){

            var page_div = document.createElement("div");
            page_div.style.width   = "210mm";  
            page_div.style.height   = "297mm";  
            page_div.style.backgroundColor = "#ffffff";
            page_div.style.padding  = "9.525mm";
            page_div.style.margin   = 0;
            page_div.style.marginTop = "-1px";
            page_div.style.marginLeft = "auto";
            page_div.style.marginRight = "auto";
            page_div.style.overflow = "hidden";
            page_div.style.pageBreakAfter = "always"; //페이지 넘김, 빈페이지가 생기는데 top 마진을 -1px을 함으로써 빈페이지를 지운다.
            page_div.style.borderRadius = "10px 10px 10px 10px";
            pdiv.appendChild(page_div);

        },        



    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

  /* 프린트시 사이즈 (필수) */
  @page {
    size: 210mm 297mm;  /*A4 Size*/
    margin: 0;
    padding: 0;
  }

  @media print{
    .toplayout{
      display: none;
    }
  }


</style>