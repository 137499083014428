<!-- pdfjs-dist사용해서 pdf 여러 페이지의 세로 스크롤 미 확대, 축소  -->
<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn @click="prev">이전</v-btn>
        <v-btn @click="next">다음</v-btn>
        <v-btn @click="zoomout">축소</v-btn>
        <v-btn @click="zoomin">확대</v-btn>
      </v-col>
    </v-row>
  
    <!-- pdf 스크롤이 v-app-bar 내부에선 문제되어 position을 absolute로 놔야함. -->
    <div style="position:absolute;overflow-y:auto;height:800px">
      <div id="canvas_div"/>
    </div>

  </v-container>
</template>

<script>

import pdfjsLib from "pdfjs-dist/build/pdf";
//import { PDFViewer } from "pdfjs-dist/web/pdf_viewer";
import "pdfjs-dist/web/pdf_viewer.css";
pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/build/pdf.worker.min.js";



// pdf.js api 참고  - https://github.com/mozilla/pdf.js/blob/master/src/display/api.js

export default {
  
  data(){
    return{
        pdfdoc:null
      , pageNumber:0
      , curpage:1
      , scale:1.0
    }
  },

  mounted() {
    this.getPdf();
  },
  methods: {
    
    prev(){
      this.curpage = this.curpage - 1 > 1 ? this.curpage - 1 : 1;
      this.loadpage(this.curpage, this.scale);
    },

    next(){
      this.curpage = this.curpage + 1 <= this.pageNumber ? this.curpage + 1 : this.pageNumber;
      this.loadpage(this.curpage, this.scale);
    },

    zoomout(){
      this.scale = this.scale - 0.1 > 1.0 ? this.scale - 0.1 : 1.0;
      this.loadpage(this.curpage, this.scale);
    },

    zoomin(){
      this.scale = this.scale + 0.1 <= 3.0 ? this.scale + 0.1 : 3.0;
      this.loadpage(this.curpage, this.scale);
    },

    //페이지와 스케일값으로 다시 그리기
    async loadpage(p, s){

      var page = await this.pdfdoc.getPage(p);
      //console.log("page load...");
          
      var viewport  = page.getViewport(s);
      //console.log("viewport : " + JSON.stringify(viewport));

      var canvas    = document.createElement("canvas");
      canvas.style  = "display:block;"
      var context   = canvas.getContext('2d');
      canvas.width  = viewport.width;
      canvas.height = viewport.height;
          
      var renderContext = {
        canvasContext:context
      , viewport:viewport
      };
          
      await page.render(renderContext).promise;
      //console.log("page render...");

      var canvas_div = document.getElementById("canvas_div");
      canvas_div.appendChild(canvas);

    },
    
    async getPdf() {

      //pdf 로드
      let loadingTask = pdfjsLib.getDocument("https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf");
      var pdf = await loadingTask.promise;
      this.pdfdoc = pdf;                //전역으로 객체 백업
      this.pageNumber = pdf.numPages;   //전체페이지 수 

      var page = await pdf.getPage(1);  //페이지 읽기
      //console.log("page load...");
          
      var viewport  = page.getViewport(1.0);  //페이지 뷰포트 정보 읽기 스케일값은 float로 들어가야한다. 정수로 들어가면 값이 깨짐.
      //console.log("viewport : " + JSON.stringify(viewport));

      var canvas    = document.createElement("canvas");
      var context   = canvas.getContext('2d');
      canvas.width  = viewport.width;
      canvas.height = viewport.height;
          
      //var tf = [Math.cos(radian),Math.sin(radian),-Math.sin(radian),Math.cos(radian),0,0];
      //var tf = [1,0,0,1,0,0];
      var renderContext = {
        canvasContext:context
      , viewport:viewport
      };
          
      await page.render(renderContext).promise;   //그리기
      //console.log("page render...");

      var canvas_div = document.getElementById("canvas_div");
      canvas_div.appendChild(canvas);
    

    }    
  }
};
</script>

<style>
#the-canvas {
  border: 1px solid black;
  direction: ltr;
}
</style>