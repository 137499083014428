<template>

  <v-container fluid>

    <!-- 이미지 크롭 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="crop_dialog_land" max-width="500px">
        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">이미지 편집</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <div style="width:100%;height:500px;">
            <vueCropper
                ref="cropper_land"
                :img="imgSrc"
                :outputType="option.outputType"
                :fixed="option.fixed"
                :fixedNumber="option.fixedNumber"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :fixedBox="option.fixedBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :centerBox="option.centerBox"
                :high="option.high"
                :infoTrue="option.infoTrue"
                :maxImgSize="option.maxImgSize"
                :enlarge="option.enlarge"
                :mode="option.mode"
                :limitMinSize="option.limitMinSize"
                />
              </div>
          </v-card-text>

          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="crop_dialog_land">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="cropimg">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>

    <!-- 추가버튼 + 다이얼로그 -->
    <v-row>
    <v-dialog persistent scrollable overlay-color="#00000040" v-model="dialog" max-width="500px">
      <v-card class="pa-1 ma-0">

        <v-row dense class="mt-5">
          <v-col cols="12" align="center">
            <span class="dialog_title">{{ dialogTitle }}</span>
          </v-col>
        </v-row>

        <v-card-text class="ma-0 pl-5 pr-5 pt-3">
          <v-container>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">문제명</label>
              </v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="editedItem.QUESTION_NAME" dense single-line 
                  outlined height="48px" hide-details class="popupiputstyle"
                  />
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <viewer
                class="viewer"
                ref="viewer" 
                :options="options"
                >
                <img 
                  height="230" style="width:100%; height:100%; background:#808080;"
                  :src="previewImg"
                  >
              </viewer>
            </v-row>                

            <v-row  align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">문제 이미지</label>
              </v-col>
              <v-col cols="9">
                <v-file-input 
                  outlined dense height="48" class="popupiputstyle"
                  show-size hide-details v-model="uploadFile" 
                  accept="image/png, image/jpeg"
                  @change="changefile"
                  >
                  </v-file-input>
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">상태</label>
              </v-col>
              <v-col cols="9">
                <v-select v-model="editedItem.STATE" :items="STATELIST" outlined
                  item-text="name" item-value="value" class="dialogselectstyle"
                  hide-details elevation="0" dense height="48px"
                  /> 
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">{{ editedIndex == -1 ? '추가' : '수정' }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>  
    </v-row>


  <v-row align="center" justify="start" class="mt-5">
    <v-col cols="12" md="2" sm="12" xs="12">
      <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
    </v-col>
    <v-spacer/>
    <v-col cols="12" md="3" sm="6" xs="6"  align="center">
      <v-select ref="vsemester" v-model="SELECT_SEMESTER" :items="SEMESTER_LIST" label="학기" no-data-text="학기가 없습니다."
        class="basicselectstyle"
        dense hide-details outlined item-text="SEMESTER_NAME" item-value="SEMESTER_NO" return-object @change="changesemester">
        <template v-slot:append>

        </template>
      </v-select>
    </v-col>
    <v-col cols="12" md="3" sm="6" xs="6"  align="center">
      <v-row class="pa-0" align="center">
        <v-col cols="9" class="ma-0">
          <v-select v-model="SELECT_BOOK" :items="BOOK_LIST" label="교재" no-data-text="교재가 없습니다." class="basicselectstyle"
            dense hide-details outlined  item-text="BOOK_NAME" item-value="BOOK_NO" return-object @change="changebook" />
        </v-col>
        <v-col cols="3" class="pa-0 ma-0" align="center" >
          <bookdialog @callback="bookcomareacb"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="3" sm="6" xs="6" align="center">
      <v-select v-model="SELECT_CHAPTER" :items="CHAPTER_LIST" label="챕터" no-data-text="챕터가 없습니다."
      class="basicselectstyle"
        dense hide-details outlined item-text="CHAPTER_NAME" item-value="CHAPTER_NO" return-object @change="changechapter" />
    </v-col>
  </v-row>

  <v-row align="center" justify="start" dense>

    <v-col cols="12" md="1" sm="6" xs="6" class="pa-0" align="center">
      <label class="total_label">전체 : </label><label class="total_label">{{ itemList.length }}</label>
    </v-col>
    <v-col cols="6" md="1" sm="6" xs="6" align="center">
      <v-btn width="100%" class="search_btn_freesize" elevation="0" outlined large @click="selectall">{{ allselect == false ? '전체선택' : '전체해제' }}</v-btn>
    </v-col>
    <v-col cols="6" md="1" sm="6" xs="6" align="center">
      <v-btn width="100%" class="search_btn_freesize" elevation="0" outlined large @click="deleteall">선택삭제</v-btn>
    </v-col>
    <v-spacer/>
    <v-col cols="6" md="1" sm="6" xs="6" align="center">
      <v-checkbox label="크게보기" v-model="scaleup"/>
    </v-col>
    <v-col cols="6" md="1" sm="6" xs="6" align="center">
      <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="saveSort">순서저장</v-btn>        
    </v-col>
    <v-col cols="6" md="1" sm="6" xs="6" align="center">
      <v-btn width="94%" class="search_btn_freesize" elevation="0" outlined large @click="newinsert">단건추가</v-btn>
    </v-col>
    <v-col cols="6" md="1" sm="6" xs="6" align="center">
      <v-btn width="94%" class="search_btn_freesize" elevation="0" outlined large @click="pdfinsert">PDF추가</v-btn>
    </v-col>
  </v-row>

  <v-row align="start" dense>
    <v-col>
      <!-- 테이블 -->
      <draggable v-model="itemList">
        <v-data-table
          :headers="headers"
          :items="itemList"
          no-data-text="데이터가 없습니다."
          hide-default-footer
          class="datatablestyle"
          :items-per-page="99999"
          item-key="QUESTION_NO"
          height="700"
          >
          <template v-slot:body="props">
            <draggable :list="props.items" ghost-class="ghost" @change="changedrag" tag="tbody">
              <tr v-for="(item, index) in props.items" :key="index">
                <td style="width:50px; text-align:center"><v-icon small>mdi-drag</v-icon></td>
                <td style="width:50px; text-align:center"><v-row justify="center"><v-checkbox v-model="item.SELECT" /></v-row></td>                
                <td :style="scaleup ?'width:20vw; text-align:center':'width:200px; text-align:center'">
                  <v-col class="pa-0" align="center">
                    <v-img :src="item.IMAGE" :style="scaleup ? 'width:20vw;':'height:100px;width:200px'" contain/>
                  </v-col>
                </td>
                <td :style="item.STATE == 1 ? 'color:#6e3939;width:300px;text-align:center' : 'color:#909090;width:300px; text-align:center'">{{ item.QUESTION_NAME }}</td>
                <td style="width:100px; text-align:center">{{ item.STATE == 0 ? '비활성' : '활성' }}</td>
                <td style="width:80px; text-align:center"><v-icon class="mr-2" small @click="download(item)">mdi-download</v-icon></td>
                <td style="width:80px; text-align:center"><v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon><v-icon small @click="delete_item(item)">mdi-delete</v-icon></td>
              </tr>
            </draggable>
          </template>

        </v-data-table>
      </draggable>
    </v-col>
  </v-row>

  </v-container>
</template>

<script>
//import VueCropper from 'vue-cropperjs'; //이미지 크롭
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import { ref, uploadBytes, deleteObject } from "firebase/storage";
import draggable from 'vuedraggable';
import fetch from 'node-fetch';
import FileSaver from 'file-saver';
//const { createCanvas } = require('canvas')
//import imageDownload from 'image-downloader'
export default {
    components:{
      VueCropper,
      draggable,
    },

    data: () => ({

      //파일 업로드
      uploadFile : null,
      scaleup:false,
      crop_dialog_land: false,
      imgSrc:'',
      ratio: 16/9,
      
      previewImg:"",
      options: {
        focus:false,
      },      

      //loading : false,    //로딩 표시
      SEMESTER_LIST:[],
      BOOK_LIST:[],
      CHAPTER_LIST:[],

      SELECT_SEMESTER: null,
      SELECT_BOOK: null,
      SELECT_CHAPTER: null,


      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      
      defaultItem: {
          QUESTION_NAME:'',
          QUESTION_IMG: null,
          IMAGE:'',
          REFIMAGE:'',
          STATE:1, 
          SORT:1, 
          SELECT:false,
      },
      editedItem: {
      },

      //테이블 정의
      headers: [
        {text: '',  value: 'IMAGE', sortable: false, align:'center', class:"cheader_normal"},
        {text: '선택',  value: 'SELECT', sortable: false, align:'center', class:"cheader_normal"},
        {text: '이미지',  value: 'IMAGE', sortable: false, align:'center', width:300, class:"cheader_normal"},
        {text: '문제명',  value: 'QUESTION_NAME', sortable: false, align:'center', width:200, class:"cheader_normal"},
        {text: '상태',  value: 'STATE', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '다운', value: 'actions', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '수정/삭제', value: 'actions', sortable: false, align:'center', width:100, class:"cheader_normal"}
      ],
      itemList: [],
      allselect: false,

      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
         
      //추가 변수들
      STATELIST: [
          {name:'비활성', value:0}
        , {name:'활성',   value:1}
      ],

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: false,         //크롭박스 비율 고정
        fixedNumber: [16,9],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:400,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:200,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 5000,
        limitMinSize: [300, 300]
      },            

    }),

    mounted(){
      if(this.$session.get("GRADE") < 90){
        this.$alert("사용할 수 없는 등급의 페이지입니다.");
        this.$router.back();
        return;
      }

      this.SELECT_SEMESTER = this.$session.get("SELECT_SEMESTER");
      this.SELECT_BOOK     = this.$session.get("SELECT_BOOK");
      this.SELECT_CHAPTER  = this.$session.get("SELECT_CHAPTER");

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }

    },

    create(){

    },

    methods: {


      //교재검색 콜백
      async bookcomareacb(val1){
        console.log(JSON.stringify(val1));
        if(val1.BOOK_NAME.length > 0){
          this.SELECT_SEMESTER = {"SEMESTER_NAME":val1.SEMESTER_NAME, "SEMESTER_NO":val1.SEMESTER_NO};
          this.$session.set("SELECT_SEMESTER", this.SELECT_SEMESTER);
          this.SELECT_CHAPTER = null;
          await this.loadbook(true);
          this.SELECT_BOOK = {"BOOK_NAME":val1.BOOK_NAME,"BOOK_NO":val1.BOOK_NO};
          this.$session.set("SELECT_BOOK", this.SELECT_BOOK);
        }else{
          //this.editedItem.BOOK_NAME = '';
          //this.editedItem.AREA_NO   = null;
        }
      },            
      
      //선택삭제
      deleteall(){

        const selectlist = [];
        this.itemList.forEach(item=>{
          if(item.SELECT){
            selectlist.push(item);
          }
        })
        
        if(selectlist.length < 1){
          this.$alert("선택된 문제가 없습니다.");
          return;
        }

        this.$confirm('선택하신 문제를 정말 삭제하시겠습니까?')
        .then(() => {
        
          //this.loading = true;

          for(var i=0;i<selectlist.length;i++){

            var item = selectlist[i];
            const desertRef = ref(this.$firestorage, item.REFIMAGE);
            deleteObject(desertRef).then(() => {
            });
            this.$http.post(this.$host+'/QuestionDelete',{
              QUESTION_NO:item.QUESTION_NO
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
            .then((result)=>{
              //this.loading = false;
              if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
                this.$EventBus.$emit('clearsession');
                return;
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
            });                

          }
          
          //this.loading = false;
          this.$alert('삭제가 완료되었습니다.').then(()=>{
            const p = this.page;
            this.page = 0;
            this.loadpage(p);
          });

        // eslint-disable-next-line no-unused-vars
        }).catch((error) => {
        });

      },



      //전체선택
      selectall(){

        if(this.allselect){
          //전체해제
          this.itemList.forEach(item => {
            item.SELECT = false;
          });
          this.allselect = false;
        }else{
          //전체선택
          this.itemList.forEach(item => {
            item.SELECT = true;
          });
          this.allselect = true;
        }

      },

      downloadByBlob(url, name) {
        let image = new Image();
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
        image.onload = () => {
          let canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          let ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0, image.width, image.height)
          canvas.toBlob((blob) => {
            FileSaver.saveAs(blob, name);
          })
        }
      },

      download(item){
        //console.log(JSON.stringify(item));
        fetch(item.IMAGE,{mode:'cors'})
        .then((response) => response.blob())
        .then((blob) => {
          FileSaver.saveAs(blob, item.QUESTION_IMG);
        });
      },

      //학기 조회 - select가 true면 손으로 셀렉트 박스를 변경한 경우고 false면 최초에 기존 콤보박스 선택값 복원
      loadsemester(select){

        //전부 초기화
        if(select){
          this.SELECT_SEMESTER = null;
          this.SELECT_BOOK    = null;
          this.SELECT_CHAPTER = null;
        }
        this.SEMESTER_LIST.splice(0, this.SEMESTER_LIST.length);  //데이터 초기화
        this.BOOK_LIST.splice(0, this.BOOK_LIST.length);          //데이터 초기화
        this.CHAPTER_LIST.splice(0, this.CHAPTER_LIST.length);    //데이터 초기화

        this.$http.post(this.$host+'/SemesterList',{
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              //this.loading1 = false;
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              const list = result.data.resultData;
              this.SEMESTER_LIST.splice(0, this.SEMESTER_LIST.length);   //데이터 초기화
              list.forEach(element => {
                  this.SEMESTER_LIST.push(element); 
              });
              
              if(this.SEMESTER_LIST.length > 0){
                //기본값을 선택해야 하는 경우
                if(select){
                  this.SELECT_SEMESTER = this.SEMESTER_LIST[0];
                  this.$session.set("SELECT_SEMESTER", this.SELECT_SEMESTER);
                }
                this.loadbook(select);
              }

          }else if(result.data.resultCode == 2){
            //로그인 필요
            //this.loading1 = false;
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            //오류시
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          //this.loading1 = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });

      },        


      //교재 조회
      async loadbook(select){

        if(this.SELECT_SEMESTER == undefined || this.SELECT_SEMESTER == null){
          this.$alert("학기를 선택하세요.");
          return;
        }

          //교재, 챕터 초기화
          if(select){
            this.SELECT_BOOK    = null;
            this.SELECT_CHAPTER = null;
          }
          this.BOOK_LIST.splice(0, this.BOOK_LIST.length);          //데이터 초기화
          this.CHAPTER_LIST.splice(0, this.CHAPTER_LIST.length);    //데이터 초기화

          await this.$http.post(this.$host+'/BookList',{
            SEMESTER_NO: this.SELECT_SEMESTER.SEMESTER_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                //console.log(JSON.stringify(result.data.resultData));
                this.BOOK_LIST.splice(0, this.BOOK_LIST.length);   //데이터 초기화
                list.forEach(element => {
                    this.BOOK_LIST.push(element); 
                });
                if(this.BOOK_LIST.length > 0){
                  if(select){
                    this.SELECT_BOOK = this.BOOK_LIST[0];
                    this.$session.set("SELECT_BOOK", this.SELECT_BOOK);
                  }
                  this.loadchapter(select);
                }

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },        
      

      //챕터 조회
      loadchapter(select){
          
        if(this.SELECT_SEMESTER == undefined || this.SELECT_SEMESTER == null){
          this.$alert("학기를 선택하세요.");
          return;
        }
        if(this.SELECT_BOOK == undefined || this.SELECT_BOOK == null){
          this.$alert("교재를 선택하세요.");
          return;
        }

          //교재, 챕터 초기화
          if(select){
            this.SELECT_CHAPTER = null;
          }
          this.CHAPTER_LIST.splice(0, this.CHAPTER_LIST.length);   //챕터 초기화
          this.$http.post(this.$host+'/ChapterList',{
              SEMESTER_NO: this.SELECT_SEMESTER.SEMESTER_NO
            , BOOK_NO: this.SELECT_BOOK.BOOK_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                this.CHAPTER_LIST.splice(0, this.CHAPTER_LIST.length);   
                list.forEach(element => {
                    this.CHAPTER_LIST.push(element); 
                });
                if(this.CHAPTER_LIST.length > 0){
                  if(select){
                    this.SELECT_CHAPTER = this.CHAPTER_LIST[0];
                    this.$session.set("SELECT_CHAPTER", this.SELECT_CHAPTER);
                  }
                  this.loadpage();
                }

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            //this.loading3 = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },              


      //학기 변경시
      changesemester(){
        this.$session.set("SELECT_SEMESTER", this.SELECT_SEMESTER);
        this.loadbook(true);
      },

      changebook(){
        this.$session.set("SELECT_BOOK", this.SELECT_BOOK);
        this.loadchapter(true);
      },

      changechapter(){
        this.$session.set("SELECT_CHAPTER", this.SELECT_CHAPTER);
        this.loadpage(true);
      },

      //검색조건 업체 설정
      noticesearchcb(val1){
        if(val1.TITLE.length > 0){
          this.editedItem.LINK_VALUE = val1.BOARD_NO;
        }else{
          this.editedItem.LINK_VALUE = "";
        }
      },

      //오리지널 리스트 순서를 변경
      changedrag(e){
        this.itemList.splice(e.moved.oldIndex, 1);  //원본 위치에서 제거
        this.itemList.splice(e.moved.newIndex, 0, e.moved.element);  //새로운 위치에 다시 추가
      },


      //배너 이미지 선택시
      changefile(){

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.crop_dialog_land = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
            //this.$refs.cropper_land.replace(event.target.result);
          }
          reader.readAsDataURL(this.uploadFile);  //비동기로 파일을 읽어서
        }

      },

      //이미지 크롭시
      cropimg(){
        //이미지 크롭 
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog_land = false;
          this.uploadFile = blob; //크롭된 이미지의 blob데이터
          this.$refs.cropper_land.getCropData(data=>{
            this.previewImg = data; //이미지뷰 미리보기용
          });
        });
      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage();
      },

      //조회
      loadpage(){
          //this.loading = true;
          this.$http.post(this.$host+'/QuestionALLList',{
              SEMESTER_NO: this.SELECT_SEMESTER.SEMESTER_NO
            , BOOK_NO: this.SELECT_BOOK.BOOK_NO
            , CHAPTER_NO: this.SELECT_CHAPTER.CHAPTER_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            //this.loading = false;
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    element.SELECT = false;
                    this.itemList.push(element); 
                });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              //this.loading = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              //this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            //this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },

      initpage(){
        //학기 리스트 조회
        this.loadsemester(false);
      },

      //다이얼로그 닫기
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.uploadFile = null;
          this.previewImg = "";
        }, 300)
      },

      //추가 및 수정
      save () {

        if(this.editedItem.QUESTION_NAME  == undefined || this.editedItem.QUESTION_NAME.length < 1){
          this.$alert("문제명을 입력하세요");
          return;
        } 

        if(this.editedItem.IMAGE == undefined || this.editedItem.IMAGE.length < 1){
          if(this.uploadFile == undefined || this.uploadFile.length < 1){
            this.$alert("이미지를 등록하세요");
            return;
          } 
        }

        if (this.editedIndex > -1) {

          if(this.uploadFile){

            //파일이 수정된 경우
            //console.log("edit file...");
            //this.loading = true;
            const citem  = this.editedItem;

            //기존 파일 삭제 (파일명이 동일하면 캐싱때문에 이미지가 안바뀜)
            const desertRef = ref(this.$firestorage, citem.REFIMAGE);
            deleteObject(desertRef).then(() => {

            // eslint-disable-next-line no-unused-vars
            }).catch((error) => {
                //this.loading = false;
                //this.$alert('수정 실패');
            });

            const uploadfile_ref = ref(this.$firestorage, "workbook/"+this.SELECT_SEMESTER.SEMESTER_PATH+"/"+this.SELECT_BOOK.BOOK_PATH+"/"+this.SELECT_CHAPTER.CHAPTER_PATH+"/"+Date.now().toString()+".jpg");
            const metadata = {
              contentType: 'image/jpeg',
            };      
            uploadBytes(uploadfile_ref, this.uploadFile, metadata)
            .then((snapshot) => {
              
              //console.log('full metadata ' + JSON.stringify(snapshot.metadata.name));
              this.$http.post(this.$host+'/QuestionUpdate',{
                  QUESTION_NAME: escape(citem.QUESTION_NAME)
                , QUESTION_IMG: snapshot.metadata.name
                , STATE:   citem.STATE
                , QUESTION_NO:   citem.QUESTION_NO
              },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
              .then((result)=>{
                //this.loading = false;
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                if(result.data.resultCode == 0){
                  this.$alert('수정이 완료되었습니다.').then(()=>{
                    const p = this.page;
                    this.page = 0;
                    this.loadpage(p);
                  });
                }else if(result.data.resultCode == 2){
                  this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
                  this.$EventBus.$emit('clearsession');
                }else{
                  this.$alert('수정 실패');
                }
              })
              // eslint-disable-next-line no-unused-vars
              .catch((error)=>{
                //this.loading = false;
                this.$alert('수정 실패');
              });
            })
            // eslint-disable-next-line no-unused-vars
            .catch((upload_error)=>{
                //this.loading = false;
                this.$alert('추가 실패');
            });

          }else{
            //console.log("edit normal...");

            //수정
            //this.loading = true;
            this.$http.post(this.$host+'/QuestionUpdate',{
                QUESTION_NAME: escape(this.editedItem.QUESTION_NAME)
              , STATE:   this.editedItem.STATE
              , QUESTION_NO: this.editedItem.QUESTION_NO
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
            .then((result)=>{
              //this.loading = false;
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              if(result.data.resultCode == 0){
                this.$alert('수정이 완료되었습니다.').then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
                this.$EventBus.$emit('clearsession');
              }else{
                this.$alert('수정이 실패했습니다.');
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              //this.loading = false;
              this.$alert('수정이 실패했습니다.');
            });

          }

        }else{
          
          //신규
          //this.loading = true;
          const citem = this.editedItem;

          //firebase object storage에 파일 업로드
          const uploadfile_ref = ref(this.$firestorage, "workbook/"+this.SELECT_SEMESTER.SEMESTER_PATH+"/"+this.SELECT_BOOK.BOOK_PATH+"/"+this.SELECT_CHAPTER.CHAPTER_PATH+"/"+Date.now().toString()+".jpg");
          const metadata = {
            contentType: 'image/jpeg',
          };      
          uploadBytes(uploadfile_ref, this.uploadFile, metadata)
          .then((snapshot) => {
            
            //console.log('full metadata ' + JSON.stringify(snapshot.metadata));
            this.$http.post(this.$host+'/QuestionInsert',{
                  QUESTION_NAME: escape(citem.QUESTION_NAME)
                , QUESTION_IMG: snapshot.metadata.name
                , STATE:   citem.STATE
                , SEMESTER_NO: this.SELECT_SEMESTER.SEMESTER_NO
                , BOOK_NO: this.SELECT_BOOK.BOOK_NO
                , CHAPTER_NO: this.SELECT_CHAPTER.CHAPTER_NO
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
            .then((result)=>{
              //this.loading = false;
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              //console.log("result : " + JSON.stringify(result.data));
              if(result.data.resultCode == 0){
                this.$alert('추가가 완료되었습니다.').then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
                this.$EventBus.$emit('clearsession');
              }else{
                this.$alert('추가 실패1');
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              //this.loading = false;
              this.$alert('추가 실패2 ' + error);
            });

          })
          // eslint-disable-next-line no-unused-vars
          .catch((upload_error)=>{
              //this.loading = false;
              this.$alert('추가 실패3 ' + upload_error);
          });

        }

        this.close()  //팝업닫기

      },      


      //검색버튼 클릭
      search_run(){
        this.loadpage(1);
      },

      //신규 추가
      newinsert(){

        if(this.SELECT_SEMESTER == null || this.SELECT_SEMESTER == undefined){
          this.$alert("학기를 선택하세요");
          return;
        }
        if(this.SELECT_BOOK == null || this.SELECT_BOOK == undefined){
          this.$alert("교재를 선택하세요");
          return;
        }
        if(this.SELECT_CHAPTER == null || this.SELECT_CHAPTER == undefined){
          this.$alert("챕터를 선택하세요");
          return;
        }

        this.dialogTitle  = "추가하기"
        this.editedIndex  = -1;
        this.editedItem   = Object.assign({}, this.defaultItem)
        this.editedItem.SORT = parseInt(this.itemList.length+1);
        this.dialog = true;
      },

      //신규 추가
      pdfinsert(){

        if(this.SELECT_SEMESTER == null || this.SELECT_SEMESTER == undefined){
          this.$alert("학기를 선택하세요");
          return;
        }
        if(this.SELECT_BOOK == null || this.SELECT_BOOK == undefined){
          this.$alert("교재를 선택하세요");
          return;
        }
        if(this.SELECT_CHAPTER == null || this.SELECT_CHAPTER == undefined){
          this.$alert("챕터를 선택하세요");
          return;
        }

        //PDF 편집 화면으로 이동
        this.$router.push({path:"PdfCanvas", query:{semester:this.SELECT_SEMESTER, book:this.SELECT_BOOK, chapter:this.SELECT_CHAPTER }});

      },      

      //아이템 수정
      edit_item(item){
        this.dialogTitle = "수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        //this.editedItem = item;
        this.editedItem = Object.assign({}, item);
        this.previewImg = this.editedItem.IMAGE;
        this.dialog = true;
      },

      //아이템 삭제
      delete_item(item){

        this.$confirm('정말 삭제하시겠습니까?')
        .then(() => {
        
        //this.loading = true;
        const desertRef = ref(this.$firestorage, item.REFIMAGE);
        deleteObject(desertRef).then(() => {
        });

        this.$http.post(this.$host+'/QuestionDelete',{
          QUESTION_NO:item.QUESTION_NO
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          //this.loading = false;
          if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            
            this.$alert('삭제가 완료되었습니다.').then(()=>{
              const p = this.page;
              this.page = 0;
              this.loadpage(p);
            });
            
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
            
          }else{
            this.$alert('삭제가 실패했습니다.');
          }
          
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
            //this.loading = false;
            this.$alert('삭제 실패');
        });                
        
        // eslint-disable-next-line no-unused-vars
        }).catch((error) => {
            //this.loading = false;
        });

      },      


      saveSort(){

        if(this.itemList.length <= 1){
          this.$alert('변경하실 내역이 없습니다.');
          return;
        }

        //드래그앤드랍 순서를 저장한다.
        this.$confirm('순서를 저장하시겠습니까?')
        .then(() => {

          var tempidx = 1;
          this.itemList.forEach(item=>{
            item.SORT = tempidx;
            tempidx++;
          });

          //this.loading = true;
          this.$http.post(this.$host+'/QuestionUpdateSort',
            {
              SORTDATA: escape(JSON.stringify(this.itemList))
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            //this.loading = false;
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            if(result.data.resultCode == 0){
              this.$alert('순서가 저장되었습니다.').then(()=>{
                this.loadpage(1);
              });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('순서 변경이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              //this.loading = false;
              this.$alert('순서 변경이 실패했습니다.');
          });

        });
      },               

    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>