<template>
  <v-container fluid >

    <v-row align="center" justify="start" class="mt-0 mt-md-5">

      <v-col cols="12" md="3" sm="12" xs="12">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="6" md="2" sm="6" xs="6"  align="center">
        <v-select id="mySelect"
          v-model="SELECT_YEAR" :items="SEARCHYEAR" label="년도" @change="search_run"
          dense hide-details outlined item-text="CODE_NAME" item-value="CODE_VALUE" class="basicselectstyle" 
          />
      </v-col>
      <v-col cols="6" md="2" sm="6" xs="6"  align="center">
        <v-select v-model="SELECT_SCHOOLYEAR" :items="SEARCHSCHOOLYEAR" label="학년" 
          class="basicselectstyle" @change="search_run"
          dense hide-details outlined item-text="CODE_NAME" item-value="CODE_VALUE"/>
      </v-col>
      <v-col cols="9"  md="3" sm="6" xs="6">
        <v-text-field 
          dense single-line clearable
          @keyup.enter="search_run" 
          v-model="search_keyword" 
          outlined height="43"
          color="#808080"
          hide-details
          placeholder="검색어를 입력하세요"
          class="basicsearchstyle"
          />
      </v-col>
      <v-col cols="2" md="1" sm="3" xs="3" class="pa-0" align="center">
        <v-btn class="search_btn_freesize" elevation="0" outlined large @click="search_run">검색</v-btn>
      </v-col>
      
  </v-row>


    
  <v-row align="start" dense>
    <v-col > 
      <!-- 테이블 -->
      <v-data-table
        class="datatablestyle"
        :headers="headers"
        :items="itemList"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        :items-per-page="pagerow"
        :search="search"  
        item-key="IDX"
        :loading="loading"
        >
        <template v-slot:item.print="{ item }">  
          <a style="color:blue"  @click="print(item)">출력</a>
        </template>     
      </v-data-table>
    </v-col>
  </v-row>

  <!-- 하단 페이징 영역 -->
  <v-row align="start">
    <v-col cols="12" md="1"/>
    <v-col cols="12" md="2" class="hidden-sm-and-down">
      <v-text-field
        v-model="search"
        append-icon="search"
        single-line
        hide-details
        outlined height="43"
        color="#808080"
        dense
        placeholder="페이지 내에서 검색"
        class="basicsearchstyle"
        />
    </v-col>
    <v-col cols="12" md="6" class="d-flex justify-center">
      <v-btn v-if="bottompage > 10" icon  small @click="prev()"><v-icon dark>mdi-chevron-left</v-icon></v-btn>
      <span v-for="n in subpage" :key="n.id">
          <span v-if="page == bottompage-1+n">
              <v-btn color="red" class="numberstyle" icon small @click="movepage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
          <span v-else>
              <v-btn icon small class="numberstyle2" @click="movepage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
      </span>
      <v-btn v-if="subpage == 10" icon small @click="next()" ><v-icon dark>mdi-chevron-right</v-icon></v-btn>
    </v-col>
    <v-col cols="12" md="2" class="hidden-sm-and-down">
      <v-select
        v-model="pagerow"
        :items="pagerow_combo"
        item-text="NAME"      
        item-value="VALUE"
        min-width="150px"
        outlined height="43"
        color="#808080"
        dense
        hide-details
        placeholder="목록개수"
        class="basicsearchstyle"
        ></v-select>
    </v-col>
    <v-col cols="12" md="1"/>
  </v-row>

  
  

  </v-container>
</template>

<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=2d34ab8a2ba110eed5c9ab2ba610bea6&libraries=services"></script>
<script>
import ScorePage from '../components/ScorePage.vue'
import Vue from 'vue'
export default {

    data: () => ({

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //테이블 정의
      headers: [
        {text: '지점', value: 'AREA_NAME', width:200, sortable: false, align:'center', class:"cheader_normal" },
        {text: '년도', value: 'EXAM_YEAR', width:80, sortable: false, align:'center', class:"cheader_normal"},
        {text: '학년', value: 'GRADENAME', width:80, sortable: false, align:'center', class:"cheader_normal" },
        {text: '시험명', value: 'EXAM_NAME', width:400, sortable: false, align:'center', class:"cheader_normal"},
        {text: '이름', value: 'NAME', width:120, sortable: false, align:'center', class:"cheader_normal" },
        {text: '점수', value: 'TOTALSCORE', width:100, sortable: false, align:'center', class:"cheader_normal" },
        {text: '응시일시', value: 'REG_DATE', width:200, sortable: false, align:'center', class:"cheader_normal" },
        {text: '출력', value: 'print', width:100, sortable: false, align:'center', class:"cheader_normal"}
      ],
      itemList: [],

      //검색 파라메터
      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    

      SELECT_YEAR:null,
      SELECT_SCHOOLYEAR:null,
      SEARCHYEAR: [],
      SEARCHSCHOOLYEAR: [],

      grade:0,

    }),

    mounted(){
      this.grade = this.$session.get("GRADE");
      if(this.grade > 0){
        this.$alert("사용할 수 없는 페이지입니다.").then(()=>{
          this.$router.push("Welcome");
        });
        return;
      }
      if(this.$route.query.page == undefined || this.$route.query.page == null){
        this.page = 1;
      }else{
        this.page = this.$route.query.page;
      }
      if(this.$route.query.search == undefined || this.$route.query.search == null){
        this.search_keyword = '';
      }else{
        this.search_keyword = this.$route.query.search;
      }
      if(this.sort_type == undefined || this.sort_type == null){
        this.sort_type = true;
      }else{
        if(this.$route.query.sorttype == "true"){
          this.sort_type = true;
        }else if(this.$route.query.sorttype == "false"){
          this.sort_type = false;
        }
      }
      this.sort_name = this.$route.query.sortname;
      if(this.sort_name == undefined || this.sort_name == null){
        this.sort_name = 'REG_DATE';
      }      
      if(this.$route.query.year == undefined || this.$route.query.year == null){
        this.SELECT_YEAR = null;
      }else{
        this.SELECT_YEAR = Number(this.$route.query.year);
      }
      if(this.$route.query.grade == undefined || this.$route.query.grade == null){
        this.SELECT_SCHOOLYEAR = null;
      }else{
        this.SELECT_SCHOOLYEAR = Number(this.$route.query.grade);
      }

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      prev(){
          this.bottompage = this.bottompage - 10 >= 1 ? this.bottompage - 10 : 1;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage + this.subpage - 1; //이전을 누르면 가장 끝 번호 선택
          this.loadpage(temp_page);
      },
      next(){
          this.bottompage = this.bottompage + 10 <= this.maxpage ? this.bottompage + 10 : this.maxpage;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage;
          this.loadpage(temp_page);
      },

      makepaging(){
        var div1 = this.totalcnt / this.pagerow; 
        var div2 = this.totalcnt % this.pagerow;
        if(div2 == 0){  
            this.maxpage = parseInt(div1);    
        }else{
            this.maxpage = parseInt(div1+1);
        }
        if(this.bottompage == 1){
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
        }
      },

      loadpage(p){

          this.loading = true;
          this.page = p;

          this.$http.post(this.$host+'/StudentExamList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , SELECT_YEAR: this.SELECT_YEAR
              , SELECT_SCHOOLYEAR: this.SELECT_SCHOOLYEAR
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            
            this.loading = false;

            if(result.data.resultCode == 0){
                this.totalcnt = result.data.totalcnt;
                this.makepaging();
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },

      async initpage(){

        //공통코드 조회
        await this.loadcomcode("YEAR", "ASC", this.SEARCHYEAR);
        if(this.SELECT_YEAR == null){
          this.SELECT_YEAR = this.SEARCHYEAR[0].CODE_VALUE;
        }

        await this.loadcomcode("SCHOOLYEAR", "DESC", this.SEARCHSCHOOLYEAR);
        if(this.SELECT_SCHOOLYEAR == null){
          this.SELECT_SCHOOLYEAR = this.SEARCHSCHOOLYEAR[0].CODE_VALUE;
        }

        //첫페이지 로드
        this.loadpage(this.page);

      },      


      movepage(p){
        this.$router.push({path:"StudentScore", query:{page:p, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name, year:this.SELECT_YEAR, grade:this.SELECT_SCHOOLYEAR}})
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
        });
      },      

      //검색
      search_run(){
        this.movepage(1);
      },

      //공통코드 로드 : codetype, DESC, 추가할 어레이 객체
      async loadcomcode(codetype, sorttype, arraylist){

        await this.$http.post(this.$host+'/getCodeList',{
              CODE_TYPE: codetype
            , sort_type: sorttype
        },{headers: { firetoken:this.$session.get("FIRETOKEN") }})
        .then((result)=>{
          
          if(result.data.resultCode == 0){

            const list = result.data.resultData;
            arraylist.splice(0, arraylist.length);   //데이터 초기화
            list.forEach(element => {
                arraylist.push(element); 
            });

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.loading = false;
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.loading = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });

      },              

      async print(item){

        //console.log("print:"+item);
        //실제 인쇄용
        this.loading_dialog = true;
        const ScorePageConstructor = Vue.extend(ScorePage);
        const vm = new ScorePageConstructor();
        await vm.initpage(item.TAKE_NO);
        this.loading_dialog = false;

      }


    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>