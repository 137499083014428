<template>

  <v-container fluid >

    <!-- 추가/수정 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#000000ff" v-model="dialog" max-width="550px">
        
        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">년도</label>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="editedItem.EXAM_YEAR" :items="SEARCHYEAR" outlined
                    item-text="CODE_NAME" item-value="CODE_VALUE" class="dialogselectstyle"
                    hide-details elevation="0" dense height="48px"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">학년</label>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="editedItem.EXAM_GRADE" :items="SEARCHSCHOOLYEAR" outlined
                    item-text="CODE_NAME" item-value="CODE_VALUE" class="dialogselectstyle"
                    hide-details elevation="0" dense height="48px"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">시험명</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.EXAM_NAME" dense single-line
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center" align-self="center">
                  <label class="mytextstyle">시작일</label>
                </v-col>
                <v-col cols="9" >
                  <v-menu
                    v-model="start_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                    >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="editedItem.START_DATE" class="popupiputstyle"
                        prepend-icon="event" dense single-line
                        readonly outlined height="48px" hide-details
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.START_DATE" @input="start_date = false" :first-day-of-week="0" locale="ko-kr"></v-date-picker>
                  </v-menu>                    
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center" align-self="center">
                  <label class="mytextstyle">종료일</label>
                </v-col>
                <v-col cols="9" >
                  <v-menu
                    v-model="end_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                    >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="editedItem.END_DATE" class="popupiputstyle"
                        prepend-icon="event"  dense single-line
                        readonly outlined height="48px" hide-details
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.END_DATE" @input="end_date = false" :first-day-of-week="0" locale="ko-kr"></v-date-picker>
                  </v-menu>                    
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">석차보정</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.EXAM_ADJUST" dense single-line type="number"
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">성적표 출력</label>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="editedItem.PRINT_STATE" :items="PRINTSTATELIST" outlined
                    item-text="name" item-value="value" class="dialogselectstyle"
                    hide-details elevation="0" dense height="48px"
                    /> 
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center" v-if="editedItem.EXAM_NO != null">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">상태</label>
                </v-col>
                <v-col cols="9" align="center">
                  <template v-if="editedItem.EXAM_STATE == 0">
                    <v-btn color="green" style="color:white" @click="openexam">시험 게시하기</v-btn>
                  </template>
                  <template v-else>
                    <v-btn color="red" style="color:white" @click="closeexam">시험 비활성화</v-btn>
                  </template>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>

    <v-row align="center" justify="start" class="mt-5">

      <v-col cols="12" md="3" sm="12" xs="12">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="6" md="2" sm="6" xs="6"  align="center">
        <v-select id="mySelect"
          v-model="SELECT_YEAR" :items="SEARCHYEAR" label="년도" @change="search_run"
          dense hide-details outlined item-text="CODE_NAME" item-value="CODE_VALUE" class="basicselectstyle" 
          />
      </v-col>
      <v-col cols="6" md="2" sm="6" xs="6"  align="center">
        <v-select v-model="SELECT_SCHOOLYEAR" :items="SEARCHSCHOOLYEAR" label="학년" 
          class="basicselectstyle" @change="search_run"
          dense hide-details outlined item-text="CODE_NAME" item-value="CODE_VALUE"/>
      </v-col>

      <v-col cols="9"  md="3" sm="6" xs="6">
        <v-text-field 
          dense single-line clearable
          @keyup.enter="search_run" 
          v-model="search_keyword" 
          outlined height="43"
          color="#808080"
          hide-details
          placeholder="검색어를 입력하세요"
          class="basicsearchstyle"
          />
      </v-col>
      <v-col cols="2" md="1" sm="3" xs="3" class="pa-0" align="center">
        <v-btn class="search_btn_freesize" elevation="0" outlined large @click="search_run">검색</v-btn>
      </v-col>
      <v-col cols="3" md="1" class="pa-0" align="center">
        <v-btn class="search_btn_freesize" outlined  @click="newinsert">추가</v-btn>
      </v-col>
  </v-row>

  <v-row>
    <v-col cols="12">
      <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
    </v-col>
  </v-row>

    
  <v-row align="start" dense>
    <v-col > 
      <!-- 테이블 -->
      <v-data-table
        class="datatablestyle"
        :headers="headers"
        :items="itemList"
        v-model="selected"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        :items-per-page="pagerow"
        :search="search"  
        item-key="IDX"
        :loading="loading"
        >
        <template v-slot:header.EXAM_YEAR>
          <a :class="sort_name=='EXAM_YEAR'?'cheader_sortselect':'cheader_sort'" @click="customsort('EXAM_YEAR')">년도</a>
        </template>
        <template v-slot:header.EXAM_GRADENAME>
          <a :class="sort_name=='EXAM_GRADENAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('EXAM_GRADENAME')">학년</a>
        </template>
        <template v-slot:header.EXAM_NAME>
          <a :class="sort_name=='EXAM_NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('EXAM_NAME')">시험명</a>
        </template>
        <template v-slot:header.PRINT_STATE>
          <a :class="sort_name=='PRINT_STATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRINT_STATE')">출력</a>
        </template>
        <template v-slot:header.EXAM_STATE>
          <a :class="sort_name=='EXAM_STATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('EXAM_STATE')">상태</a>
        </template>
        <template v-slot:header.START_DATE>
          <a :class="sort_name=='START_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('START_DATE')">시작일</a>
        </template>
        <template v-slot:header.END_DATE>
          <a :class="sort_name=='END_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('END_DATE')">종료일</a>
        </template>

        <template v-slot:item.EXAM_NAME="{ item }">  
          <a style="color:blue" @click="loadexam(item)">{{ item.EXAM_NAME }}</a>
        </template>     
        <template v-slot:item.PRINT_STATE="{ item }">  
          <label :style="item.PRINT_STATE == 1 ? 'text-align:center;color:green' : 'text-align:center;color:red'">{{ item.PRINT_STATE == 1 ? '출력가능' : '출력불가' }}</label>
        </template>     
        <template v-slot:item.EXAM_STATE="{ item }">  
          <label :style="item.EXAM_STATE == 1 ? 'text-align:center;color:green' : 'text-align:center;color:red'">{{ item.EXAM_STATE == 1 ? '게시' : '대기' }}</label>
        </template>     
        <template v-slot:item.subject="{ item }">  
            <v-icon class="mr-2" small @click="loadexam(item)">mdi-pencil</v-icon>
        </template>     
        <template v-slot:item.actions="{ item }">  
            <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
        </template>     
      </v-data-table>
    </v-col>
  </v-row>

  <!-- 하단 페이징 영역 -->
  <v-row align="start">
    <v-col cols="12" md="1"/>
    <v-col cols="12" md="2">
      <v-text-field
        v-model="search"
        append-icon="search"
        single-line
        hide-details
        outlined height="43"
        color="#808080"
        dense
        placeholder="페이지 내에서 검색"
        class="basicsearchstyle"
        />
    </v-col>
    <v-col cols="12" md="6" class="d-flex justify-center">
      <v-btn v-if="bottompage > 10" icon  small @click="prev()"><v-icon dark>mdi-chevron-left</v-icon></v-btn>
      <span v-for="n in subpage" :key="n.id">
          <span v-if="page == bottompage-1+n">
              <v-btn color="red" class="numberstyle" icon small @click="movepage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
          <span v-else>
              <v-btn icon small class="numberstyle2" @click="movepage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
      </span>
      <v-btn v-if="subpage == 10" icon small @click="next()" ><v-icon dark>mdi-chevron-right</v-icon></v-btn>
    </v-col>
    <v-col cols="12" md="2">
      <v-select
        v-model="pagerow"
        :items="pagerow_combo"
        item-text="NAME"      
        item-value="VALUE"
        min-width="150px"
        outlined height="43"
        color="#808080"
        dense
        hide-details
        placeholder="목록개수"
        class="basicsearchstyle"
        ></v-select>
    </v-col>
    <v-col cols="12" md="1"/>
  </v-row>

  
  

  </v-container>
</template>

<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=2d34ab8a2ba110eed5c9ab2ba610bea6&libraries=services"></script>
<script>
export default {

    data: () => ({

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          EXAM_NO:null,
          EXAM_YEAR:null,
          EXAM_GRADE:null,
          EXAM_NAME:'',
          START_DATE:'',
          END_DATE:'',
          EXAM_ADJUST:1,
          EXAM_STATE:0,
          PRINT_STATE:1
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {text: '년도', value: 'EXAM_YEAR', width:120, sortable: false, align:'center', class:"cheader_normal" },
        {text: '학년', value: 'EXAM_GRADENAME', width:120, sortable: false, align:'center', class:"cheader_normal"},
        {text: '시험명', value: 'EXAM_NAME', width:500, sortable: false, align:'center', class:"cheader_normal" },
        {text: '출력', value: 'PRINT_STATE', width:120, sortable: false, align:'center', class:"cheader_normal" },
        {text: '상태', value: 'EXAM_STATE', width:120, sortable: false, align:'center', class:"cheader_normal" },
        {text: '시작일', value: 'START_DATE', width:120, sortable: false, align:'center', class:"cheader_normal"},
        {text: '종료일', value: 'END_DATE', width:120, sortable: false, align:'center', class:"cheader_normal" },
        {text: '과목/문제', value: 'subject', width:100, sortable: false, align:'center', class:"cheader_normal" },
        {text: '수정', value: 'actions', width:80, sortable: false, align:'center', class:"cheader_normal"}
      ],
      itemList: [],

      //검색 파라메터
      sort_name: 'END_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
      
      
      SELECT_YEAR:null,
      SELECT_SCHOOLYEAR:null,

      SEARCHYEAR: [
      ],

      SEARCHSCHOOLYEAR: [
      ],

      STATELIST: [
          {name:'대기',       value:0}
        , {name:'게시',       value:1}
      ],

      PRINTSTATELIST: [
          {name:'출력불가',       value:0}
        , {name:'출력가능',       value:1}
      ],

      start_date : false,
      end_date : false,

      grade:0,

    }),

    mounted(){

      if(this.$session.get("GRADE") < 90){
        this.$alert("사용할 수 없는 등급의 페이지입니다.").then(()=>{
          this.$router.back();
        });
        return;
      }
      this.grade = this.$session.get("GRADE");

      if(this.$route.query.page == undefined || this.$route.query.page == null){
        this.page = 1;
      }else{
        this.page = this.$route.query.page;
      }
      if(this.$route.query.search == undefined || this.$route.query.search == null){
        this.search_keyword = '';
      }else{
        this.search_keyword = this.$route.query.search;
      }
      if(this.sort_type == undefined || this.sort_type == null){
        this.sort_type = true;
      }else{
        if(this.$route.query.sorttype == "true"){
          this.sort_type = true;
        }else if(this.$route.query.sorttype == "false"){
          this.sort_type = false;
        }
      }
      this.sort_name = this.$route.query.sortname;
      if(this.sort_name == undefined || this.sort_name == null){
        this.sort_name = 'END_DATE';
      }
      if(this.$route.query.year == undefined || this.$route.query.year == null){
        this.SELECT_YEAR = null;
      }else{
        this.SELECT_YEAR = Number(this.$route.query.year);
      }
      if(this.$route.query.grade == undefined || this.$route.query.grade == null){
        this.SELECT_SCHOOLYEAR = null;
      }else{
        this.SELECT_SCHOOLYEAR = Number(this.$route.query.grade);
      }
      console.log("SELECT_SCHOOLYEAR : " + this.SELECT_SCHOOLYEAR);

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {

      movepage(p){
        this.$router.push({path:"ExamList", query:{page:p, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name, year:this.SELECT_YEAR, grade:this.SELECT_SCHOOLYEAR}})
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
        });
      },

      openexam(){
        this.$http.post(this.$host+'/ExamEnableUpdate',{
          EXAM_NO:  this.editedItem.EXAM_NO
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          this.close();
          if(result.data.resultCode == 0){
              this.$alert("시험이 게시되었습니다.").then(()=>{
                const p = this.page;
                this.page = 0;
                this.loadpage(p);
              });
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.close();
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.close();
            this.$alert(result.data.resultMsg);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.close();
          this.$alert('비활성 처리에 실패했습니다.');
        });
      },

      closeexam(){
        
        this.$http.post(this.$host+'/ExamDisableUpdate',{
          EXAM_NO:  this.editedItem.EXAM_NO
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          this.close();
          if(result.data.resultCode == 0){
              this.$alert("비활성 처리가 완료되었습니다").then(()=>{
                const p = this.page;
                this.page = 0;
                this.loadpage(p);
              });
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.close();
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.close();
            this.$alert('비활성 처리에 실패했습니다.');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.close();
          this.$alert('비활성 처리에 실패했습니다.');
        });
      },



      //문제 상세보기
      loadexam(item){
        this.$router.push({path:"ExamSubjectList", query:{code:item.EXAM_NO, title:item.EXAM_YEAR+"년도 "+item.EXAM_GRADENAME+" "+item.EXAM_NAME}});
      },


      //이미지 크롭시
      cropimg(){
        //이미지 크롭 
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog_land = false;
          this.uploadFile = blob; //크롭된 이미지의 blob데이터
          this.$refs.cropper_land.getCropData(data=>{
            this.previewImg = data; //이미지뷰 미리보기용
          });
        });
      },

      //배너 이미지 선택시
      changefile(){

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.crop_dialog_land = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
            //this.$refs.cropper_land.replace(event.target.result);
          }
          reader.readAsDataURL(this.uploadFile);  //비동기로 파일을 읽어서
        }

      },

      //대표장소 설정
      comareacb(val1){
        //console.log(val1);
        if(val1.AREA_NAME.length > 0){
          this.editedItem.AREA_NAME = val1.AREA_NAME;
          this.editedItem.AREA_NO   = val1.AREA_NO;
        }else{
          this.editedItem.AREA_NAME = '';
          this.editedItem.AREA_NO   = null;
        }
      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      prev(){
          this.bottompage = this.bottompage - 10 >= 1 ? this.bottompage - 10 : 1;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage + this.subpage - 1; //이전을 누르면 가장 끝 번호 선택
          this.loadpage(temp_page);
      },
      next(){
          this.bottompage = this.bottompage + 10 <= this.maxpage ? this.bottompage + 10 : this.maxpage;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage;
          this.loadpage(temp_page);
      },

      makepaging(){
        var div1 = this.totalcnt / this.pagerow; 
        var div2 = this.totalcnt % this.pagerow;
        if(div2 == 0){  
            this.maxpage = parseInt(div1);    
        }else{
            this.maxpage = parseInt(div1+1);
        }
        if(this.bottompage == 1){
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
        }
      },

      loadpage(p){

          this.loading = true;
          this.page = p;

          this.$http.post(this.$host+'/ExamList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , SELECT_YEAR: this.SELECT_YEAR
              , SELECT_SCHOOLYEAR: this.SELECT_SCHOOLYEAR
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }

                this.totalcnt = result.data.totalcnt;
                this.makepaging();
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });

                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },

      async initpage(){

        //공통코드 조회
        await this.loadcomcode("YEAR", "ASC", this.SEARCHYEAR);
        if(this.SELECT_YEAR == null){
          this.SELECT_YEAR = this.SEARCHYEAR[0].CODE_VALUE;
        }

        await this.loadcomcode("SCHOOLYEAR", "DESC", this.SEARCHSCHOOLYEAR);
        if(this.SELECT_SCHOOLYEAR == null){
          this.SELECT_SCHOOLYEAR = this.SEARCHSCHOOLYEAR[0].CODE_VALUE;
        }

        //첫페이지 로드
        this.loadpage(this.page);
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      //추가 저장
      save () {

          if(this.editedItem.EXAM_NAME.length < 1 || this.editedItem.EXAM_NAME.trim().length < 1){
              this.$alert("시험명을 입력하세요");
              return;
          }
          if(this.editedItem.EXAM_NAME.length > 50){
              this.$alert("시험명은 50자 이하로 입력하세요");
              return;
          }
          if(this.editedItem.EXAM_YEAR == null || this.editedItem.EXAM_YEAR == undefined){
              this.$alert("년도를 선택하세요");
              return;
          }
          if(this.editedItem.EXAM_GRADE == null || this.editedItem.EXAM_GRADE == undefined){
              this.$alert("학년을 선택하세요");
              return;
          }
          if(this.editedItem.START_DATE.length < 1){
              this.$alert("시작일을 입력하세요");
              return;
          }
          if(this.editedItem.END_DATE.length < 1){
              this.$alert("종료일을 입력하세요");
              return;
          }
          if(new Date(this.editedItem.START_DATE) > new Date(this.editedItem.END_DATE)){
              this.$alert("시작일은 종료일보다 작아야합니다.");
              return;
          }
          if(this.editedItem.EXAM_ADJUST == null || this.editedItem.EXAM_ADJUST == undefined){
              this.$alert("석차보정값을 입력하세요");
              return;
          }
          if(this.editedItem.EXAM_ADJUST < 1){
              this.$alert("석차보정값은 1이상 입력하세요");
              return;
          }


          if (this.editedIndex > -1) {

            //수정
            this.loading = true;
            this.$http.post(this.$host+'/ExamUpdate',{
                EXAM_YEAR: this.editedItem.EXAM_YEAR
              , EXAM_GRADE: this.editedItem.EXAM_GRADE
              , EXAM_NAME: escape( this.editedItem.EXAM_NAME)
              , START_DATE: this.editedItem.START_DATE
              , END_DATE: this.editedItem.END_DATE
              , PRINT_STATE:   this.editedItem.PRINT_STATE
              , EXAM_STATE:   this.editedItem.EXAM_STATE
              , EXAM_ADJUST:  this.editedItem.EXAM_ADJUST
              , EXAM_NO:  this.editedItem.EXAM_NO
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                  //토큰 저장
                  if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                    this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                  }
                  if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                    this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                  }
                  this.$alert("수정이 완료되었습니다").then(()=>{
                    const p = this.page;
                    this.page = 0;
                    this.loadpage(p);
                  });

              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
                this.$EventBus.$emit('clearsession');

              }else{
                  this.$alert('수정이 실패했습니다.');
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.loading = false;
              this.$alert('수정이 실패했습니다.');
            });

          }else{

            //신규
            this.loading = true;
            this.$http.post(this.$host+'/ExamInsert',{
                  EXAM_YEAR: this.editedItem.EXAM_YEAR
                , EXAM_GRADE: this.editedItem.EXAM_GRADE
                , EXAM_NAME: escape( this.editedItem.EXAM_NAME)
                , START_DATE: this.editedItem.START_DATE
                , END_DATE: this.editedItem.END_DATE
                , PRINT_STATE:   this.editedItem.PRINT_STATE
                , EXAM_STATE:   this.editedItem.EXAM_STATE
                , EXAM_ADJUST:  this.editedItem.EXAM_ADJUST
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                  this.$alert('추가가 완료되었습니다.').then(()=>{
                    const p = this.page;
                    this.page = 0;
                    this.loadpage(p);
                  });

              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
                this.$EventBus.$emit('clearsession');
              }else{
                this.$alert('추가 실패');
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.loading = false;
              this.$alert('추가 실패');
            });

          }

        this.close();

      },


      //검색
      search_run(){
        this.movepage(1)
      },

      //아이템 수정
      edit_item(item){
        this.dialogTitle = "수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },
  

      //신규 추가
      newinsert(){
        this.dialogTitle = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem.EXAM_YEAR  = this.SELECT_YEAR;
        this.editedItem.EXAM_GRADE = this.SELECT_SCHOOLYEAR;
        this.dialog = true;
      },


      //아이템 삭제
      delete_item(item){

        this.$confirm('정말 삭제하시겠습니까?')
        .then(() => {
          this.loading = true;
          this.$http.post(this.$host+'/ExamDelete',{
              EXAM_NO:item.EXAM_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

              this.$alert('삭제가 완료되었습니다.').then(()=>{
                const p = this.page;
                this.page = 0;
                this.loadpage(p);
              });
              
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('삭제가 실패했습니다.');
            }
            
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              this.loading = false;
              this.$alert('삭제 실패');
          });      

        });

      },      


      //공통코드 로드 : codetype, DESC, 추가할 어레이 객체
      async loadcomcode(codetype, sorttype, arraylist){

        await this.$http.post(this.$host+'/getCodeList',{
              CODE_TYPE: codetype
            , sort_type: sorttype
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            const list = result.data.resultData;
            arraylist.splice(0, arraylist.length);   //데이터 초기화
            list.forEach(element => {
                //console.log("element : " + JSON.stringify(element));
                arraylist.push(element); 
            });

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.loading = false;
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.loading = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });

      },           


    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>