<template>

  <v-container fluid>

    <v-row align="center" justify="start" >
      <v-col cols="12" md="3" sm="6" xs="12">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row>

      <!-- 학기 -->
      <v-col cols="12" md="6" sm="12" xs="12" class="pa-5">
        <v-row>
          <v-col cols="2" class="pa-0 pl-3" align="center">
            <v-btn class="mini_btn" elevation="0" outlined rounded small @click="selectAll1">{{ allselect1 == false ? '전체선택' : '전체해제' }}</v-btn>
          </v-col>
          <v-spacer/>
          <v-col cols="2" class="pa-0 pr-3" align="center">
            <v-btn class="mini_btn" elevation="0" outlined rounded small @click="save1">저장</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col >
              <v-data-table
                :headers="headers1"
                :items="itemList1"
                no-data-text="데이터가 없습니다."
                hide-default-footer
                :items-per-page="99999"
                item-key="SEMESTER_NO"
                :loading="loading1"
                height="700"
                class="datatablestyle"
                >
                
                <template v-slot:body="props">
                  <tbody>
                    <tr v-for="(item, index) in props.items" :key="index" :style="item.SEMESTER_NO == SELECT_SEMESTER ? 'background:#C53D4580' : 'background:#ffffff'">
                      <td style="width:200px; text-align:center">
                         <a :style="item.STATE == 1 ? 'color:#6e3939' : 'color:#909090'" @click="loadbook(item)">{{ item.SEMESTER_NAME }}</a>
                      </td>
                      <td style="width:100px; text-align:center">
                        <!-- <v-simple-checkbox color="#da5058"  v-model="item.USECHECK" @change="edit_item1(item)"/> -->
                        <input type="checkbox"  @change="edit_item1(item)" :checked="item.USECHECK"><label @click="edit_item1(item)"></label>
                      </td>
                    </tr>
                  </tbody>
                </template>

              </v-data-table>         
          </v-col>
        </v-row>
      </v-col>
        
      <!-- 교재 -->
      <v-col cols="12" md="6" sm="12" xs="12" class="pa-5">
        <v-row align="center">
          <v-col cols="2" class="pa-0 pl-3" align="center">
            <v-btn class="mini_btn" elevation="0" outlined rounded small @click="selectAll2">전체선택</v-btn>
          </v-col>
          <v-col cols="8" class="pa-0" align="center">
            <label>{{ SEMESTER_NAME }}</label>
          </v-col>
          <v-col cols="2" class="pa-0 pr-3" align="center">
            <v-btn class="mini_btn" elevation="0" outlined rounded small @click="save2">저장</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
              <v-data-table
                :headers="headers2"
                :items="itemList2"
                no-data-text="데이터가 없습니다."
                hide-default-footer
                :items-per-page="99999"
                item-key="BOOK_NO"
                :loading="loading2"
                height="700"
                class="datatablestyle"
                >
                <template v-slot:body="props">
                  <tbody>
                    <tr v-for="(item, index) in props.items" :key="index" >
                      <td style="width:200px; text-align:center"><a :style="item.STATE == 1 ? 'color:#6e3939' : 'color:#909090'">{{ item.BOOK_NAME }}</a></td>
                      <td style="width:100px; text-align:center">
                        <!-- <v-simple-checkbox :disable="item.STATE == 0" color="#da5058"  v-model="item.USECHECK" @change="edit_item2(item)"/> -->
                        <!-- <input type="checkbox"  @change="edit_item2(item)" :disable="item.STATE == 0" :checked="item.USECHECK" style="width:20px;height:20px" /> -->
                        <input type="checkbox"  @change="edit_item2(item)" :disable="item.STATE == 0" :checked="item.USECHECK"><label @click="edit_item2(item)"></label>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>         
          </v-col>
        </v-row>
      </v-col>


    </v-row>

  </v-container>
</template>

<script>

export default {

    data: () => ({
      grade:0,

      STATELIST: [
          {name:'사용안함', value:0}
        , {name:'사용',   value:1}
      ],

      ACCNT_NO:"",
      ACCNT:"",
      SELECT_SEMESTER:null,

      allselect1: false,
      allselect2: false,

      loading1: false,
      loading2: false,

      itemList1: [],
      itemList2: [],

      editedItem1: {},
      editedItem2: {},
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트

      SEMESTER_NAME: "",
      BOOK_NAME:"",

      defaultItem1: {
        SEMESTER_NAME:'',
        SEMESTER_PATH:'',
        STATE:1,
        SORT:1
      },
      defaultItem2: {
        BOOK_NAME:'',
        BOOK_PATH:'',
        SEMESTER_NO:null,
        STATE:1,
        SORT:1
      },

      dialog1:false,
      dialog2:false,
      dialogTitle:'추가하기',

      //테이블 정의
      headers1: [
        {text: '학기', value: 'SEMESTER_NAME', sortable: false, align:'center', width:200, class:"cheader_normal"},
        {text: '사용', value: 'actions', sortable: false, align:'center', width:100, class:"cheader_normal"}
      ],

      headers2: [
        {text: '교재', value: 'BOOK_NAME', sortable: false, align:'center', width:200, class:"cheader_normal"},
        {text: '사용', value: 'actions', sortable: false, align:'center', width:100, class:"cheader_normal"}
      ],


    }),

    mounted(){
      if(this.$session.get("GRADE") < 10){
        this.$alert("사용할 수 없는 등급의 페이지입니다.").then(()=>{
          this.$router.back();
        });
        return;
      }
      this.ACCNT_NO = this.$route.query.accnt_no;
      this.ACCNT = this.$route.query.accnt;
      this.grade = this.$session.get("GRADE");

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){

    },

    methods: {

      back(){
        this.$router.back();
      },

      initpage(){
        this.loadsemester();
      },

      //아이템 수정
      edit_item1(item){
        if(item.USECHECK == 0){
          item.USECHECK = 1;
        }else{
          item.USECHECK = 0;
        }
      },

      selectAll1(){
        if(this.allselect1){
          //전체해제
          this.itemList1.forEach(item => {
            item.USECHECK = 0;
          });
          this.allselect1 = false;
        }else{
          //전체선택
          this.itemList1.forEach(item => {
            item.USECHECK = 1;
          });
          this.allselect1 = true;
        }
      },               
      
      save1 () {
        const jobList = [];
        
        //값이 변한 경우를 찾는다.
        this.itemList1.forEach(item=>{
          if(item.USECHECK != item.ORICHECK){
            jobList.push(item);
          }
        });

        if(jobList.length == 0){
          this.$alert("변경된 내용이 없습니다.");
          return;
        }

        //USECHECK와 ORICHECK를 비교해서 쿼리 태워야한다.
        //USECHECK가 0에서 1로 변한경우 인설트
        //USECHECK가 1에서 0로 변한경우 델리트
        this.$confirm('학기 권한을 저장하시겠습니까?')
        .then(() => {

          this.loading1 = true;
          this.$http.post(this.$host+'/UserPmSemesterUpdate',
            {
              SORTDATA: escape(JSON.stringify(jobList))
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading1 = false;
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            if(result.data.resultCode == 0){
              this.$alert('저장이 완료되었습니다.').then(()=>{
                this.loadsemester();
              });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('권한 저장에 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              this.loading1 = false;
              this.$alert('권한 저장에 실패했습니다.');
          });

        });

      },     

      edit_item2(item){
        if(item.USECHECK == 0){
          item.USECHECK = 1;
        }else{
          item.USECHECK = 0;
        }
      },

      selectAll2(){

        if(this.allselect2){
          //전체해제
          this.itemList2.forEach(item => {
            item.USECHECK = 0;
          });
          this.allselect2 = false;
        }else{
          //전체선택
          this.itemList2.forEach(item => {
            item.USECHECK = 1;
          });
          this.allselect2 = true;
        }

      },               
      
      //추가 및 수정
      save2() {

        const jobList = [];
        
        //값이 변한 경우를 찾는다.
        this.itemList2.forEach(item=>{
          if(item.USECHECK != item.ORICHECK){
            jobList.push(item);
          }
        });

        if(jobList.length == 0){
          this.$alert("변경된 내용이 없습니다.");
          return;
        }

        this.$confirm('교재 권한을 저장하시겠습니까?')
        .then(() => {

          this.loading2 = true;
          this.$http.post(this.$host+'/UserPmBookUpdate',
            {
              SORTDATA: escape(JSON.stringify(jobList))
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading2 = false;
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            if(result.data.resultCode == 0){
              this.$alert('저장이 완료되었습니다.').then(()=>{
                this.loadbook();
              });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('권한 저장에 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              this.loading2 = false;
              this.$alert('권한 저장에 실패했습니다.');
          });

        });

      },     



      //학기권한 조회
      loadsemester(){

          this.loading1 = true;
          this.$http.post(this.$host+'/UserPmSemesterList',{
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            if(result.data.resultCode == 0){
                this.loading1 = false;
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                const list = result.data.resultData;
                this.itemList1.splice(0, this.itemList1.length);   //데이터 초기화
                list.forEach(element => {
                  this.itemList1.push(element); 
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading1 = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.loading1 = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading1 = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },        

      //교재 조회
      loadbook(item){

        if(item.STATE == 0){
          this.$alert("비활성 상태의 학기에는 권한을 부여할 수 없습니다.");
          return;
        }

          //교재, 챕터 초기화
          this.itemList2.splice(0, this.itemList2.length);   //데이터 초기화

          if(item != null && item != undefined){
            this.SELECT_SEMESTER = item.SEMESTER_NO;
            this.SEMESTER_NAME   = item.SEMESTER_NAME;
          }

          this.loading2 = true;
          this.$http.post(this.$host+'/UserPmBookList',{
            SEMESTER_NO: this.SELECT_SEMESTER
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            if(result.data.resultCode == 0){
                this.loading2 = false;
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                //console.log("book : " + JSON.stringify(result.data.resultData));
                const list = result.data.resultData;
                this.itemList2.splice(0, this.itemList2.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList2.push(element); 
                });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading2 = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.loading2 = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading2 = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },        
      


    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

input[type="checkbox"]{
        display: none;
      }
      
input[type="checkbox"] + label{
        display: inline-block;
        width: 25px;
        height: 25px;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 5px;
        border:2px solid #da5058;
        position: relative;
        background:#ffffff;
      }
input[type="checkbox"]:checked + label::after{
        content:'✔';
        font-size: 16px;
        color: white;
        background: #da5058;
        width: 21px;
        height: 21px;        
        text-align: center;
        position: absolute;
        left: 0;
        top:0;      
      }


</style>