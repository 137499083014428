<template>
  <v-container fluid class="ma-0 pa-0" style="background:white;min-height:100%;overflow:hidden">

    <v-row class="ma-0 pa-0 hidden-sm-and-down" no-gutters dense>
      <v-col cols="12">
      <v-parallax
        height="226" 
        :src="require('@/assets/mathbg.jpg')"
        >
        <v-row align="center" justify="center">
          <v-col cols="12" class="text-center mt-15">
            <label style="color:white;font-size:25px">감성수학</label>
          </v-col>
        </v-row>
      </v-parallax>    
      </v-col>
    </v-row>

    <!-- 프로그레스 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading" max-width="300px">
        <v-container class="d-flex flex-column justify-center align-center" 
                     style="background-color:white;width:300px;height:300px">
          <v-row class="ma-8">
            <v-progress-circular
              :size="100"
              :width="15"
              color="#c53d45"
              indeterminate
              >
            </v-progress-circular>
          </v-row>
          <v-row class="ma-5">
            <label style="text-align:center">잠시만 기다려주세요</label>
          </v-row>
        </v-container >
      </v-dialog>
    </v-row>

    <v-row align="center" justify="center">
      
      <v-col class="mt-15" cols="12" md="4" sm="10" xs="10">
        <div class="roundbg text-center">  
            <p id="login_title" style="color:#9f0007;font-size:20px;" class="text-center">감성선생님 로그인</p>

            <v-row justify="center" dense class="mt-2">
              <v-col ccols="12">
                <v-row style="height:1px;background:#9f0007"/>
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center" class="mt-5">
              <v-col cols="12">
                <v-row dense align="center" justify="center">
                  <v-col cols="3" align="center">
                    <label class="mytextstyle">계정</label>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field 
                      outlined v-model="user_id" prepend-inner-icon="" 
                      name="login" placeholder="이메일 계정을 입력하세요" 
                      color="#9f0007" hide-details
                      type="text" dense></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center" class="mt-10" >
              <v-col cols="12">
                <v-row dense align="center" justify="center">
                  <v-col cols="3" align="center">
                    <label class="mytextstyle">비밀번호</label>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field 
                    outlined @keyup.enter="login" v-model="user_pw" prepend-inner-icon="" 
                    name="password" placeholder="비밀번호를 입력하세요" id="password" 
                    type="password" dense color="#9f0007" hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center" class="mt-5" >
              <v-col cols="10">
              <v-checkbox
                v-model="checkbox"
                label="아이디 저장" dense @change="checkchange"
                color="#9f0007"
                ></v-checkbox> 
              </v-col>           
            </v-row>

            <v-btn  
            class="mt-5"
            style="height:35px;width:200px;font-size:18px;color:white" color="#9f0007" rounded @click="login">로그인</v-btn>

        </div>
      </v-col>
    </v-row>


    <v-row align="center" justify="center">
      <v-col cols="3" md="1" sm="3" xs="3" align="center">
        <a style="color:#9f0007;font-size:16px" class="text-center" @click="runjoin">회원가입</a>
      </v-col>
      <v-col cols="3" md="1" sm="3" xs="3" align="center">
        <a style="color:#9f0007;font-size:16px" class="text-center" @click="runpass">PW찾기</a>
      </v-col>
    </v-row>   

    <!-- 
    <v-row style="height:350px" align="center" justify="center">
    </v-row>   
    -->

  </v-container>
</template>

<script>
import { signInWithEmailAndPassword } from "firebase/auth";
export default {

    mounted() {
      this.checkbox = localStorage.getItem("SAVE_ID");
      if(this.checkbox){
        this.user_id = localStorage.getItem("LOGIN_ID");
      }
    },

    data: () => ({

        user_id : ""
      , user_pw : ""
      , loading:false
      , checkbox:false

    }),

    methods: {
      
      checkchange(value){
        //console.log(value);
        if(value){
          localStorage.setItem("SAVE_ID", true);
        }else{
          localStorage.setItem("SAVE_ID", false);
        }
      },

      runjoin(){
        //회원가입 팝업 표시
        this.$router.push('/Join');
      },

      runpass(){


        if(this.user_id.length < 1){
          this.$alert('비밀번호를 찾기 위한 이메일 계정을 입력하세요');
          return;
        }

        //인증코드 재발송
        this.$confirm("비밀번호를 잊으셨나요?\n가입하신 이메일로 비밀번호를 찾기위한 인증메일을 발송 하시겠습니까?")
        .then(()=>{
          this.$http.post(this.$host+'/resetpassword',{
              ACCNT: this.user_id
          })
          // eslint-disable-next-line no-unused-vars
          .then((result)=>{

            if(result.data.resultCode == 0){
              this.$alert("비밀번호 찾기 인증메일이 발송되었습니다.\n인증메일을 확인하세요");
            }else{
              alert(result.data.resultMsg);      
            }
          });
        })        
      },

      //로그인되면 App.vue의 onAuthStateChanged 콜백됨 거기서 커스텀 토큰으로 최종 로그인함.
      async login(){

          if(this.user_id.length < 1){
            this.$alert('계정을 입력하세요');
            return;
          }
          if(this.user_pw.length < 1){
            this.$alert('비밀번호를 입력하세요');
            return;
          }
          if(this.user_pw.length < 6){
              this.$alert("비밀번호는 최소 6글자 이상입니다.");
              return;
          }

          localStorage.setItem("LOGIN_ID", this.user_id);

          //파이어베이스 로그인처리
          await signInWithEmailAndPassword(this.$fireauth, this.user_id, this.user_pw)
          .then(async (result) => {
            //console.log("signInWithEmailAndPassword result : " + JSON.stringify(result.user));
            if(!result.user.emailVerified){
              //인증코드 재발송
              this.$confirm("이메일 인증이 확인되지 않은 계정입니다.\n이메일 인증코드를 재발송 하시겠습니까?")
              .then(()=>{
                this.$http.post(this.$host+'/sendemail',{
                    USER_ID: this.user_id
                  , USER_PW: Buffer.from(this.user_pw).toString('base64')
                })
                // eslint-disable-next-line no-unused-vars
                .then((result2)=>{
                  //console.log("send email : " + JSON.stringify(result2));
                  if(result2.data.resultCode == 0){
                    this.$alert("인증메일이 발송되었습니다.\n인증메일을 확인하세요");
                  }else{
                    alert(result2.data.resultMsg);      
                  }
                });
              })
              return;
            }else{
              //여기서 커스텀 토큰생성 호출안해도 App.vue에 등록된 리스너에서 로그인을 감지해서 idtoken을 발급받음.
              //this.createCustomtoken(result.user.uid);
            }
          })
          .catch((error)=>{
            console.error('로그인 실패:' + error);
            if(error.code == 'auth/missing-email'){
              this.$alert('로그인 오류, 이메일주소를 확인하세요');
            }else if(error.code == 'auth/wrong-password'){
              this.$alert('로그인 오류, 패스워드를 확인하세요');
            }else if(error.code == 'auth/user-disabled'){
              this.$alert('로그인 오류, 관리자가 사용자 계정을 비활성화했습니다.');
            }else if(error.code == 'auth/user-not-found'){
              this.$alert('로그인 오류, 사용자를 찾을 수 없습니다.');
            }else{
              this.$alert('로그인 오류, 로그인 할 수 없습니다.');
            }
            
            //alert('로그인에 실패했습니다. 다시 시도해주세요.');
          });

      },

    },


    created(){

      if(this.$session.exists("LOCALID")){
        //키값이 있으면 로그인이 이미 된 경우
        this.$router.push('/AccntList');  
      }

    }


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.v-main__wrap{
  background: white;
}

.roundbg{
  border-radius: 25px;
  background: #ffffff;
  padding: 20px;
  padding-bottom: 30px;
}


</style>