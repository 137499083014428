<template>
  <v-container fluid style="background:white" class="ma-0 pa-0">
    
    <v-row v-if="itemList.length > 0" class="ma-0 pa-0" justify="center" style="background:#080808">
      <v-col class="ma-0 pa-0" xl="8" lg="8" md="10" cols="12" sm="12" xs="12">
        <v-carousel
          cycle height="100%" style="cursor:Pointer" hide-delimiters
          v-model="model"
          >
            <v-carousel-item
              v-for="(img, i) in itemList"
              :key="i" 
              style="width:100%;height:100%;background:#00000000"
              >
              <v-img :aspect-ratio="16/9" :src="img.IMAGE" @click="runevent(img)"/>
            </v-carousel-item>
          
        </v-carousel>
      </v-col>
    </v-row>

    <!-- 
    <v-row v-for="(img, i) in itemList" :key="i" class="ma-0 pa-0" justify="center">
      <v-col class="ma-0 pa-0" xl="8" lg="8" md="10" cols="12" sm="12" xs="12">
        <v-img :src="img.IMAGE">
        </v-img>
      </v-col>
    </v-row>
    -->
    <v-row class="ma-0 pa-0" justify="center" style="background:#000000">
      <v-col class="ma-0 pa-0" xl="8" lg="8" md="10" cols="12" sm="12" xs="12">
        <v-img :src="require('@/assets/land_01.jpg')">
        </v-img>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" justify="center" style="background:#000000">
      <v-col class="ma-0 pa-0" xl="8" lg="8" md="10" cols="12" sm="12" xs="12">
        <v-img :src="require('@/assets/land_02.jpg')">
          <a style="position:absolute; top: 78%; left: 10%; height:12%; width: 80%;" @click="gotoArea"></a>
        </v-img>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" justify="center" style="background:#000000">
      <v-col class="ma-0 pa-0" xl="8" lg="8" md="10" cols="12" sm="12" xs="12">
        <v-img :src="require('@/assets/land_03.jpg')">
        </v-img>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" justify="center" style="background:#000000">
      <v-col class="ma-0 pa-0" xl="8" lg="8" md="10" cols="12" sm="12" xs="12">
        <v-img :src="require('@/assets/land_04.jpg')">
        </v-img>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" justify="center" style="background:#000000">
      <v-col class="ma-0 pa-0" xl="8" lg="8" md="10" cols="12" sm="12" xs="12">
        <v-img :src="require('@/assets/land_05.jpg')">
        </v-img>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" justify="center" style="background:#000000">
      <v-col class="ma-0 pa-0" xl="8" lg="8" md="10" cols="12" sm="12" xs="12">
        <v-img :src="require('@/assets/land_06.jpg')">
        </v-img>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" justify="center" style="background:#000000">
      <v-col class="ma-0 pa-0" xl="8" lg="8" md="10" cols="12" sm="12" xs="12">
        <v-img :src="require('@/assets/land_07.jpg')">
        </v-img>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" justify="center" style="background:#000000">
      <v-col class="ma-0 pa-0" xl="8" lg="8" md="10" cols="12" sm="12" xs="12">
        <v-img :src="require('@/assets/land_08.jpg')">
          <a style="position:absolute; top: 30%; left: 26%; height:16%; width: 63%;" @click="showday"></a>
          <a style="position:absolute; top: 49%; left: 26%; height:16%; width: 63%;" @click="showweek"></a>
        </v-img>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" justify="center" style="background:#000000">
      <v-col class="ma-0 pa-0" xl="8" lg="8" md="10" cols="12" sm="12" xs="12">
        <v-img :src="require('@/assets/land_09.jpg')">
        </v-img>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" justify="center" style="background:#000000">
      <v-col class="ma-0 pa-0" xl="8" lg="8" md="10" cols="12" sm="12" xs="12">
        <v-img :src="require('@/assets/land_10.jpg')">
          <a style="position:absolute; top: 56%; left: 12%; height:8%; width: 76%;" href="https://storage.googleapis.com/gsmath-fdb22.appspot.com/common/msample.pdf" target="_blank"></a>
        </v-img>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" justify="center" style="background:#000000">
      <v-col class="ma-0 pa-0" xl="8" lg="8" md="10" cols="12" sm="12" xs="12">
        <v-img :src="require('@/assets/land_11.jpg')">
          <a style="position:absolute; top: 7%; left: 12%; height:9%; width: 76%;" @click="show1010"></a>
        </v-img>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" justify="center" style="background:#000000">
      <v-col class="ma-0 pa-0" xl="8" lg="8" md="10" cols="12" sm="12" xs="12">
        <v-img :src="require('@/assets/land_12.jpg')">
        </v-img>
      </v-col>
    </v-row>






    
  </v-container>
</template>
<script>
export default {
    
    data: () => ({

      itemList: [],
      model:0,

    }),

    mounted(){
      this.initpage();
    },

    methods: {

      //데일리 리포트 샘플보기
      show1010(){
        const qimg = document.createElement("img");
        qimg.src = require('@/assets/10to10review.png');
        qimg.style.width = "100%";
        qimg.style.padding = 0;
        qimg.style.margin = 0;
        var htmltext = qimg.outerHTML;

        var w = window.open("", "", "width=800,height=1000,left=100,top=50");
        w.document.write(`<!DOCTYPE html>
        <html>
          <head>
          </head>
          <body sytle="margin:0px !important;padding:0px">
            ${htmltext}
          </body>
        </html>`);        
        w.document.close(); // necessary for IE >= 10
        w.focus();          // necessary for IE >= 10
      },      

      //데일리 리포트 샘플보기
      showday(){
        const qimg = document.createElement("img");
        qimg.src = require('@/assets/dayimg.jpg');
        qimg.style.width = "100%";
        qimg.style.padding = 0;
        qimg.style.margin = 0;
        var htmltext = qimg.outerHTML;

        var w = window.open("", "", "width=800,height=1000,left=100,top=50");
        w.document.write(`<!DOCTYPE html>
        <html>
          <head>
          </head>
          <body sytle="margin:0px !important;padding:0px">
            ${htmltext}
          </body>
        </html>`);        
        w.document.close(); // necessary for IE >= 10
        w.focus();          // necessary for IE >= 10
      },

      //위클리 리포트 샘플보기
      showweek(){
        const qimg = document.createElement("img");
        qimg.src = require('@/assets/weekimg.png');
        qimg.style.width = "100%";
        qimg.style.padding = 0;
        qimg.style.margin = 0;
        var htmltext = qimg.outerHTML;

        var w = window.open("", "", "width=800,height=1000,left=100,top=50");
        w.document.write(`<!DOCTYPE html>
        <html>
          <head>
          </head>
          <body sytle="margin:0px !important;padding:0px">
            ${htmltext}
          </body>
        </html>`);        
        w.document.close(); // necessary for IE >= 10
        w.focus();          // necessary for IE >= 10
      },

      gotoArea(){
        //스크롤 0으로 옮겨줘야한다.
        window.scrollTo(0,0);
        this.$router.push('AreaInfoList');
      },


      scrollevent(){
        //window.scrollY      현재 스크롤된 top 좌표
        //window.innerHeight  브라우저에서 보여주는 웹사이트의 높이
        //엘리먼트.offsetTop   해당 엘리먼트가 배치된 y좌표
        //즉 window.scrollY+window.innserHeight가 offsetTop보다 크면 실제 화면에 나타난경우다.

        var test = document.getElementById("test");
        //console.log("scroll... : "+window.outerHeight + " / " + "/" + window.scrollY + " / test top " + test.offsetTop);
        if(window.scrollY+window.innerHeight-(window.innerHeight/3) >= test.offsetTop){
          //console.log("start....");
          //$('#test').addClass('slideup');
          this.upani.seek(1000);
        }
      },

      runevent(item){
        //console.log(JSON.stringify(item));
        if(item.LINK_TYPE == 0){

          //게시판 뉴탭으로 띄우는 방법 (조금 느림)
          /*
          let routeData = this.$router.resolve({path: 'BoardDetail', query: {code:item.LINK_VALUE}});
          //console.log("go link : " + routeData.href);
          window.open(routeData.href, '_blank');
          */

          //해당 페이지로 바로 이동
          if(item.LINK_VALUE != null && item.LINK_VALUE != undefined && item.LINK_VALUE.length > 0){
            this.$router.push({path: 'BoardDetail', query: {code:item.LINK_VALUE}});
          }

        }else if(item.LINK_TYPE == 1){
          //URL
          if(item.LINK_VALUE != null && item.LINK_VALUE != undefined && item.LINK_VALUE.length > 0){
            window.open(item.LINK_VALUE, "_blank");
          }
          
        }
        
      },

      initpage(){
        //첫페이지 로드
        this.loadpage();
      },      

      loadpage(){

          this.$http.post(this.$host+'/GuestBannerList',{
          }).then((result)=>{
            if(result.data.resultCode == 0){
              const list = result.data.resultData;
              this.itemList.splice(0, this.itemList.length);   //데이터 초기화
              list.forEach(element => {
                if(element.IMAGE != undefined && element.IMAGE != null && element.IMAGE.length > 0){
                  this.itemList.push(element);
                }
                
              });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },      

    },

    created(){

    }


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<!-- 
animation-name : 애니메이션의 중간 상태를 지정하기 위한 이름을 정의합니다. 중간 상태는 @keyframes 규칙을 이용하여 기술합니다.
animation-duration : 한 싸이클의 애니메이션이 얼마에 걸쳐 일어날지 지정합니다.
animation-delay :엘리먼트가 로드되고 나서 언제 애니메이션이 시작될지 지정합니다.
animation-direction : 애니메이션이 종료되고 다시 처음부터 시작할지 역방향으로 진행할지 지정합니다.
animation-iteration-count : 애니메이션이 몇 번 반복될지 지정합니다. infinite 로 지정하여 무한히 반복할 수 있습니다.
animation-play-state : 애니메이션을 멈추거나 다시 시작할 수 있습니다.
animation-timing-function : 중간 상태들의 전환을 어떤 시간간격으로 진행할지 지정합니다.
animation-fill-mode : 애니메이션이 시작되기 전이나 끝나고 난 후 어떤 값이 적용될지 지정합니다.
-->
<style scoped>

.slideup{
  visibility: hidden;
  animation-duration: 1s;
  animation-name: slideup;
  -webkit-animation-duration: 1s;
  -webkit-animation-name: slideup;
}
@keyframes slideup {
  0%{
    visibility: visible;
    top: 100px;
    opacity: 0%;
  }
  100%{
    visibility: visible;
    top: 0px;
    opacity: 100%;
  }
}



</style>