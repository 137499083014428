<template>

  <v-container fluid>

    <!-- 프로그레스 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading" max-width="300px">
        <v-container class="d-flex flex-column justify-center align-center" 
                     style="background-color:white;width:300px;height:300px">
          <v-row class="ma-8">
            <v-progress-circular
              :size="100"
              :width="15"
              color="purple"
              indeterminate
              >
            </v-progress-circular>
          </v-row>
          <v-row class="ma-5">
            <label style="text-align:center">잠시만 기다려주세요</label>
          </v-row>
        </v-container >
      </v-dialog>
    </v-row>

    <!-- 회원탈퇴 -->
    <v-row >
      <v-dialog scrollable overlay-color="#00000040" persistent v-model="dialog_exit" width="480px">
        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">회원탈퇴</span>
            </v-col>
          </v-row>

          <v-row dense align="center" justify="center" class="mt-5">
            <v-col cols="10">
              <p>회원탈퇴시 재가입할 수 없습니다. 정말 탈퇴하시겠습니까?</p>
            </v-col>
          </v-row>

          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="dialog_exit = false">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="runexit">탈퇴</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    


    <v-row align="center" justify="start">
      <v-col cols="12">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row dense align="center" justify="center">
      <v-col md="4" cols="12">
        <v-row dense align="center" justify="center">
          <v-col cols="3" align="center">
            <label class="mytextstyle">이름</label>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="editedItem.NAME" dense single-line
              outlined height="43" hide-details class="mypageiputstyle"
              />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col md="4" cols="12">
        <v-row dense align="center" justify="center">
          <v-col cols="3" align="center">
            <label class="mytextstyle">전화번호</label>
          </v-col>
          <v-col cols="9">
            <v-text-field disabled
              v-model="editedItem.TEL" dense single-line
              outlined height="43" hide-details class="mypageiputstyle"
              />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col md="4" cols="12">
        <v-row dense align="center" justify="center">
          <v-col cols="3" align="center">
            <label class="mytextstyle">지점</label>
          </v-col>
          <v-col cols="9">
            <areadialog :areaname="editedItem.AREA_NAME" @callback="comareacb"/>  
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col md="4" cols="12">
        <v-row dense align="center" justify="center">
          <v-col cols="3" align="center">
            <label class="mytextstyle">학부모</label>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="editedItem.PARENT_TEL" dense single-line
              outlined height="48px" hide-details class="popupiputstyle"
              />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col md="4" cols="12">
        <v-row dense align="center" justify="center">
          <v-col cols="3" align="center">
            <label class="mytextstyle">주소</label>
          </v-col>
          <v-col cols="9">
            <v-text-field disabled
              v-model="editedItem.ADDR1" dense single-line
              outlined height="48px" hide-details class="popupiputstyle"
              />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
    <v-row justify="center" align="center" dense>
      <v-col md="4" cols="12">
        <v-row dense align="center" justify="center">
          <v-col cols="12" align="right">
            <v-btn style="height:35px;width:150px;font-size:16px;color:white" color="#C53D45" @click="postcode">주소검색</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col md="4" cols="12">
        <v-row dense align="center" justify="center">
          <v-col cols="3" align="center">
            <label class="mytextstyle">상세주소</label>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="editedItem.ADDR2" dense single-line
              outlined height="48px" hide-details class="popupiputstyle"
              />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    
    <v-row dense align="center" justify="center">
      <v-col md="4" cols="12">
        <v-row dense align="center" justify="center">
          <v-col cols="3" align="center">
            <label class="mytextstyle">성별</label>
          </v-col>
          <v-col cols="9">
            <v-select v-model="editedItem.GENDER" :items="GENDERLIST" outlined
              item-text="name" item-value="value" class="dialogselectstyle"
              hide-details elevation="0" dense height="48px"
              />
          </v-col>
        </v-row>
      </v-col>
    </v-row>


    <v-row align="center" justify="center" class="mt-10">
      <v-col md="2" cols="6" align="center">
        <v-btn class="dialog_cancel_btn" width="100%" large @click="exit">회원탈퇴</v-btn>
      </v-col>
      <v-col md="2" cols="6" align="center">
        <v-btn class="dialog_btn" width="100%" large  @click="save">저장</v-btn>
      </v-col>
    </v-row>


  </v-container>
</template>

<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=2d34ab8a2ba110eed5c9ab2ba610bea6&libraries=services"></script>
<script>
export default {
    data: () => ({
      
      grade:0,

      show1:false,
      loading : false,    //로딩 표시
      dialog_exit: false,
      dialog:false,
      defaultItem: {
          STUDENT_NO:'',
          TEL:'',
          NAME:'',
          AREA_NO:null,
          STATE:1,
      },
      editedItem: {
      },

      STATELIST: [
          {name:'탈퇴',       value:0}
        , {name:'회원',       value:1}
      ],

      GENDERLIST: [
          {name:'남성',   value:0}
        , {name:'여성',   value:1}
      ],

    }),

    mounted(){
      this.grade = this.$session.get("GRADE");
      if(this.grade > 0){
        this.$alert("사용할 수 없는 페이지입니다.").then(()=>{
          this.$router.push("Welcome");
        });
        return;
      }

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("StudentLogin");
      }
    },

    create(){

    },

    methods: {

      initpage(){
        this.loadpage();
      },

      loadpage(){   
        this.loading = true;
        this.$http.post(this.$host+'/StudentDetail',{
        },{headers: { firetoken:this.$session.get("FIRETOKEN") }})
        .then((result)=>{
          if(result.data.resultCode == 0){
              this.loading = false;
              const list = result.data.resultData;
              if(list.length > 0){
                this.editedItem = list[0];
              }
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.loading = false;
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.loading = false;
            this.$alert(result.data.resultMsg).then(()=>{
              this.$router.back();
            });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });
        
      },

      exit(){
        this.dialog_exit = true;
      },

      runexit(){
        this.loading = true;
        this.$http.post(this.$host+'/StudentinfoDelete',{
        },{headers: { firetoken:this.$session.get("FIRETOKEN") }})
        .then((result)=>{
            this.loading = false;
            this.dialog_delete1 = false;
            if(result.data.resultCode == 0){
              this.$alert('회원탈퇴가 완료되었습니다.').then(()=>{
                //로그아웃
                this.$EventBus.$emit('clearsession');
              });
            }else{
              this.$alert('회원탈퇴에 실패했습니다.('+result.data.resultMsg+")");
            }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
            this.loading = false;
            this.$alert('회원탈퇴 실패');
        });      
      },


      //추가 저장
      save() {

          if(this.editedItem.NAME.length < 1){
              this.$alert("이름을 입력하세요");
              return;
          }
          if(this.editedItem.NAME.length > 10){
              this.$alert("이름은 10자 이하로 입력하세요");
              return;
          }

          if(this.editedItem.AREA_NO == null || this.editedItem.AREA_NO == undefined){
              this.$alert("지점을 선택하세요");
              return;
          }

          if(this.editedItem.ADDR1.length < 1){
              this.$alert("주소를 검색하세요");
              return;
          }
          if(this.editedItem.ADDR2.length < 1){
              this.$alert("상세주소를 입력하세요");
              return;
          }

          const mobileVal1 = /[^0-9]/g;
          var mobileVal = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
          if(this.editedItem.PARENT_TEL == undefined || this.editedItem.PARENT_TEL.length < 1){
            this.$alert("부모님 휴대폰 번호를 입력하세요");
            return;
          } 
          
          if(mobileVal1.test(this.editedItem.PARENT_TEL)){
            this.$alert("부모님 휴대폰 번호는 숫자만 입력이 가능합니다.");
            return;
          } 
          
          if(!mobileVal.test(this.editedItem.PARENT_TEL)){
            this.$alert("부모님 휴대폰 번호가 잘못되었습니다.");
            return;
          } 

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/StudentInfoUpdate',{
                NAME: escape(this.editedItem.NAME)
              , PARENT_TEL: this.editedItem.PARENT_TEL
              , AREA_NO: this.editedItem.AREA_NO
              , ADDR1: escape(this.editedItem.ADDR1)
              , ADDR2: escape(this.editedItem.ADDR2)
              , GENDER: this.editedItem.GENDER
              , STATE: this.editedItem.STATE
          },{headers: { firetoken:this.$session.get("FIRETOKEN") }})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){
                this.$alert("수정이 완료되었습니다").then(()=>{
                  this.loadpage();
                });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
                this.$alert('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert('수정이 실패했습니다.');
          });
      
      },      


      postcodetoAddr(addr){
        this.editedItem.ADDR1 = addr;
        this.$forceUpdate();
      },

      //주소검색
      postcode(){

        //콜백에서 못찾아서 콘스트로 미리 빼둔다.
        const callback_addrfunction     = this.postcodetoAddr;

        new daum.Postcode({
            oncomplete: function(data) {
                //console.log(JSON.stringify(data));
                var addr = data.address; // 최종 주소 변수
                if(data.userSelectedType == "J"){
                  //지번
                  addr = data.jibunAddress; 
                }
                //주소 정보를 해당 필드에 넣는다.
                callback_addrfunction(addr);
            }
        }).open();

      },    


      //대표장소 설정
      comareacb(val1){
        //console.log(val1);
        if(val1.AREA_NAME.length > 0){
          this.editedItem.AREA_NAME = val1.AREA_NAME;
          this.editedItem.AREA_NO   = val1.AREA_NO;
        }else{
          this.editedItem.AREA_NAME = '';
          this.editedItem.AREA_NO   = null;
        }
      },      

    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>