<!-- 성적 공유화면 -->
<template>

<!-- 
  <v-container fluid class="pa-0 ma-0" slot="width:100%;height:100%;">
      <div style="overflow-x:auto;overflow-y:auto;width:100%;height:100%;margin:auto">
        <div id="prewview" style="width:210mm;height:auto;" />
      </div>
  </v-container>
-->  

  <v-container fluid class="pa-0 ma-0">
    <v-row justify="center" style="background:gray">
      <div style="overflow-x:auto;overflow-y:auto;">
        <div id="prewview" style="width:210mm;height:auto;" />
      </div>
    </v-row>
  </v-container>

</template>

<script>
export default {
    directives: {
        print   
    },

    props:{
    },

    data: () => ({
        TOKEN:null
      , score_info:null   //기본시험정보
      , subject_rank:null //과목별석차
      , wrong_list:null   //오답률
      , grade_list:null   //등급표
      , com_result:null   //공통과목 채점결과
      , sel_result:null   //선택과목 채점결과

    }),

    mounted(){
      
      //console.log("showpage mounted....");
      if(this.$route.query.token == undefined || this.$route.query.token == null){
        this.$alert("시험정보가 없습니다.").then(()=>{
          this.$router.back();
        });
        return;
      }else{
        this.TOKEN = this.$route.query.token;
      }
      
      this.initpage(this.TOKEN);

    },

    unmounted() {
      //console.log("showpage unmounted....");  
    },


    methods: {

      close_page(){
        //다지우고
        var pdiv = document.getElementById("prewview"); 
        while (pdiv.hasChildNodes()) {
          pdiv.removeChild(pdiv.firstChild);
        }        

        //parent 함수 호출
        this.$router.back();
      },

      pxTomm(px){
          return (px * 0.2645833333).toFixed(5);
      },

      async initpage(TOKEN) {

        //console.log("score page initpage... " + TAKE_NO);
        await this.$http.post(this.$host+'/getScoreInfoToken',{
          TOKEN: TOKEN
        })
        .then((result)=>{
          if(result.data.resultCode == 0){

            this.score_info   = result.data.resultData1[0];
            this.subject_rank = result.data.resultData2;
            this.wrong_list = result.data.resultData3;
            this.grade_list = result.data.resultData4;
            this.com_result = result.data.resultData5;
            this.sel_result = result.data.resultData6;

            this.drawScore(this.score_info, this.subject_rank, this.wrong_list, this.grade_list, this.com_result, this.sel_result);



          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            if(result.data.resultMsg != null && result.data.resultMsg != undefined && result.data.resultMsg.length > 0){
              this.$alert(result.data.resultMsg);
            }else{
              this.$alert("성적조회에 실패했습니다.");
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });

        
        //루트 div
        /* 나중에 팝업 방식으로 교체시 사용해라.
        const prewview_div = document.createElement("div");
        prewview_div.id = "prewview_div";
        prewview_div.style.width = "100%";
        prewview_div.style.backgroundColor = "#808080ff";
        */
        


      },

      //성적표 그리기
      drawScore(score_info, subject_rank, wrong_list, grade_list, com_result, sel_result){
  
        //일단 여기서는 페이지 방식이라 이걸로 코딩
        const prewview_div = document.getElementById("prewview"); 

        //용지 사이즈 및 여백등
        /*
        const toptitle      = this.pxTomm(130); //첫페이지 타이틀 높이 104px + 하단 마진 26px = 130px
        const secondmargin  = this.pxTomm(36); //두번째 페이지부터 상단에 마진요청함.
        const a4width       = 210;
        const a4height      = 297;
        const sidemargin    = this.pxTomm(36);  //좌,우는  36px 공백
        const topmargin     = this.pxTomm(36);
        */

        //테이블 높이에 대한 스타일 정의
        const style = document.createElement('style');
          style.textContent = `
            .theight50 {
              height: 50px !important;
            }
            .theight23 {
              height: 23px !important;
            }
            .theight20 {
              height: 20px !important;
            }
            .twidth80 {
              width: 80px !important;
            }
            .twidth60 {
              width: 60px !important;
            }
          `;
        document.head.appendChild(style);       
        
        const a4width       = 210;

        //첫장 - 표지생성
        this.createTitle(prewview_div, score_info);


        //둘째장 - 성적표 생성

        //A4사이즈 페이지 생성
        var page_div = document.createElement("div");
        page_div.style.width   = "210mm";  
        page_div.style.height   = "297mm";  
        page_div.style.backgroundColor = "#ffffff";
        page_div.style.padding  = "0mm";
        page_div.style.marginTop = "-1px";
        page_div.style.marginLeft = "auto";
        page_div.style.marginRight = "auto";
        page_div.style.overflow = "hidden";
        page_div.style.pageBreakAfter = "always"; //페이지 넘김, 빈페이지가 생기는데 top 마진을 -1px을 함으로써 빈페이지를 지운다.
        //page_div.style.borderRadius = "10px 10px 10px 10px";
        
        //상단 회색 배경
        var bg_div1 = document.createElement("div"); 
        bg_div1.style.position = "absolute";
        bg_div1.style.marginLeft = "0px";
        bg_div1.style.marginTop  = "0px";
        bg_div1.style.width      = a4width+"mm";
        bg_div1.style.height     = this.pxTomm(105)+"mm";
        bg_div1.style.backgroundColor = "#e7e7e7";
        page_div.appendChild(bg_div1);

        //상단 빨강 경계선
        var bg_div2 = document.createElement("div"); 
        bg_div2.style.position = "absolute";
        bg_div2.style.marginLeft = "0px";
        bg_div2.style.marginTop  = this.pxTomm(105)+"mm";
        bg_div2.style.width      = a4width+"mm";
        bg_div2.style.height     = this.pxTomm(3)+"mm";
        bg_div2.style.backgroundColor = "#b40000";
        page_div.appendChild(bg_div2);


        //좌측 상단 시험이름
        var url_div = this.createDivPx(46, 63, 400, 30, score_info.EXAM_NAME);
        this.setTextStyle(url_div, 18, "bold", null, "#000000");
        page_div.appendChild(url_div);

        //우측 상단 날짜
        var date_div = this.createDivPx(656, 63, 110, 30, score_info.REG_DATE);
        this.setTextStyle(date_div, 18, "bold", null, "#000000");
        page_div.appendChild(date_div);        

        //1. 개별점수 및 통계 타이틀
        var number1_div = this.createDivPx(52, 156, 45, 45, "01");
        this.setTextStyle(number1_div, 40, "100", "center", "#000000");
        page_div.appendChild(number1_div);

        var title1_div = this.createDivPx(102, 171, 400, 45, "개별점수 및 통계");
        this.setTextStyle(title1_div, 23, "bold", null, "#000000");
        page_div.appendChild(title1_div);


        //개별점수 및 통계정보
        this.createInfoTable(page_div, score_info, subject_rank);

        //오답률 TOP5
        this.createWrongTable(page_div, wrong_list);

        //등급표
        this.createGradeTable(page_div, grade_list);

        //2. 채점결과 타이틀
        var number2_div = this.createDivPx(52, 640, 45, 45, "02");
        this.setTextStyle(number2_div, 40, "100", "center", "#000000");
        page_div.appendChild(number2_div);

        var title2_div = this.createDivPx(102, 655, 400, 45, "채점 결과");
        this.setTextStyle(title2_div, 23, "bold", null, "#000000");
        page_div.appendChild(title2_div);

        //채점결과 - 공통과목
        this.createScoreTable(page_div, 52, 700, "공통과목", com_result);

        //공통과목 채점결과가 몇줄인지 계산
        var numberOfRows = Math.floor(com_result.length/15);  
        if(com_result.length%15 == 0){
          numberOfRows -= 1;
        }
        numberOfRows += 1;

        //채점결과 - 선택과목
        this.createScoreTable(page_div, 52, 720+(numberOfRows*80), score_info.SELECT_NAME, sel_result);

        prewview_div.appendChild(page_div);

      },

      //표지생성
      createTitle(pdiv, score_info){

        const a4width       = 210;
        var page_div = document.createElement("div");
        page_div.style.width   = "210mm";  
        page_div.style.height   = "297mm";  
        page_div.style.backgroundColor = "#ffffff";
        page_div.style.padding  = "0mm";
        page_div.style.marginTop = "-1px";
        page_div.style.marginLeft = "auto";
        page_div.style.marginRight = "auto";
        page_div.style.overflow = "hidden";
        page_div.style.pageBreakAfter = "always"; //페이지 넘김, 빈페이지가 생기는데 top 마진을 -1px을 함으로써 빈페이지를 지운다.
        //page_div.style.borderRadius = "10px 10px 10px 10px";
        
        //상단 빨강배경 그리기
        var bg_div1 = document.createElement("div"); 
        bg_div1.style.position = "absolute";
        bg_div1.style.marginLeft = "0px";
        bg_div1.style.marginTop  = "0px";
        bg_div1.style.width      = a4width+"mm";
        bg_div1.style.height     = this.pxTomm(480)+"mm";
        bg_div1.style.backgroundColor = "#b40000";
        page_div.appendChild(bg_div1);

        //빨강/흰색 대각선 배경 색상 그리기
        var bg_div = document.createElement("div");
        bg_div.style.position = "absolute";
        bg_div.style.marginLeft = "0px";
        bg_div.style.marginTop  = this.pxTomm(480)+"mm";
        bg_div.style.width      = a4width+"mm";
        bg_div.style.height     = this.pxTomm(398)+"mm";
        bg_div.style.backgroundImage = "linear-gradient(to bottom right, #b40000, #b40000 50%, white 50%, white)";
        page_div.appendChild(bg_div);

        //좌측 상단 홈페이지 주소
        var url_div = this.createDivPx(46, 63, 190, 30, "https://gsmath.com");
        this.setTextStyle(url_div, 18, null, null, "#ffffff");
        page_div.appendChild(url_div);

        //우측 상단 날짜
        var date_div = this.createDivPx(656, 63, 110, 30, score_info.REG_DATE);
        this.setTextStyle(date_div, 18, "bold", null, "#ffffff");
        page_div.appendChild(date_div);

        //상단 감성수학 이름
        var title_div = this.createDivPx(90, 280, 614, 68, "대치 감성수학");
        this.setTextStyle(title_div, 60, "bold", null, "#ffffff");
        page_div.appendChild(title_div);

        //년도 학년 표시
        var year_div = this.createDivPx(90, 370, 614, 34, score_info.EXAM_YEAR+"학년도 "+score_info.GRADENAME);
        this.setTextStyle(year_div, 25, "bold", null, "#ffffff");
        page_div.appendChild(year_div);

        //시험이름 표시
        var ename_div = this.createDivPx(90, 400, 614, 34, score_info.EXAM_NAME);
        this.setTextStyle(ename_div, 30, "bold", null, "#ffffff");
        page_div.appendChild(ename_div);

        //학생 지점 학년 이름 표시
        var name_div = this.createDivmm((a4width-this.pxTomm(800))/2, a4width, this.pxTomm(800), this.pxTomm(34), score_info.AREA_NAME+" " +score_info.GRADENAME+" "+score_info.NAME);
        this.setTextStyle(name_div, 30, "bold", "center", "#000000");
        page_div.appendChild(name_div);

        //감성수학 로고
        var logo_div = document.createElement("div");
        logo_div.style.position   = "absolute";
        logo_div.style.marginLeft = ((a4width-this.pxTomm(280))/2)+"mm";
        logo_div.style.marginTop  = "900px";
        logo_div.style.width      = "280px";
        logo_div.style.height     = "145px";
        logo_div.style.backgroundImage  = `url(${require('../assets/gslogo.png')})`;
        logo_div.style.backgroundSize = "contain";
        page_div.appendChild(logo_div);

        //하단 사각형 장식
        var bar_div = document.createElement("div");
        bar_div.style.position   = "absolute";
        bar_div.style.marginLeft = ((a4width-this.pxTomm(710))/2)+"mm";
        bar_div.style.marginTop  = "1070px";
        bar_div.style.width      = "710px";
        bar_div.style.height     = "13px";
        bar_div.style.backgroundColor = "#b40000";
        page_div.appendChild(bar_div);

        pdiv.appendChild(page_div);

      },


      //개별점수 및 통계 테이블
      createInfoTable(pdiv, score_info, subject_rank){
        
        const a4width       = 210;

        const table = document.createElement("table");
        table.style.position   = "absolute";
        table.style.marginLeft = 13+"mm";
        table.style.marginTop  = "212px";
        table.style.width      = a4width-26+"mm";
        table.style.height     = "160px";
        this.nStylesToTable(table);

        const headerRow1 = table.insertRow();
        this.createTH(headerRow1, 4, 1, score_info.EXAM_YEAR+"학년도 "+score_info.GRADENAME+" " + score_info.EXAM_NAME, 20, false);
        this.createTH(headerRow1, 2, 1, "학원명", 20, true);
        this.createTH(headerRow1, 1, 1, "학년", 20, true);
        this.createTH(headerRow1, 1, 1, "성명", 20, true);
        this.createTH(headerRow1, 2, 1, "실시일", 20, true);

        const headerRow2 = table.insertRow();
        this.createTH(headerRow2, 4, 1, "성적통지표 (학생용)", 20, false);
        this.createTH(headerRow2, 2, 1, score_info.AREA_NAME, 20, false);
        this.createTH(headerRow2, 1, 1, score_info.GRADENAME, 20, false);
        this.createTH(headerRow2, 1, 1, score_info.NAME, 20, false);
        this.createTH(headerRow2, 2, 1, score_info.REG_DATE, 20, false);
        
        const headerRow3 = table.insertRow();
        this.createTH(headerRow3, 1, 3, "영역", 20, true);
        this.createTH(headerRow3, 2, 1, "원점수", 20, true);
        this.createTH(headerRow3, 6, 1, "원점수에 의한 석차/백분위 등급", 20, true);
        this.createTH(headerRow3, 1, 3, "응시자수", 20, true);

        const headerRow4 = table.insertRow();
        this.createTH(headerRow4, 1, 2, "배점", 20, true);
        this.createTH(headerRow4, 1, 2, "득점", 20, true);
        this.createTH(headerRow4, 1, 2, "지역석차", 20, true);
        this.createTH(headerRow4, 1, 2, "전체석차", 20, true);
        this.createTH(headerRow4, 2, 1, "백분위", 20, true);
        this.createTH(headerRow4, 2, 1, "등급", 20, true);

        const headerRow5 = table.insertRow();
        this.createTH(headerRow5, 1, 1, "지역", 20, true);
        this.createTH(headerRow5, 1, 1, "전국", 20, true);
        this.createTH(headerRow5, 1, 1, "지역", 20, true);
        this.createTH(headerRow5, 1, 1, "전국", 20, true);

        //공통과목 개별 석차        
        //console.log(JSON.stringify(subject_rank));
        
        if(subject_rank.length > 0){
          const subject1 = subject_rank[0];
          const headerRow6 = table.insertRow();
          this.createTH(headerRow6, 1, 1, score_info.COMMON_NAME, 20, false); //과목명
          this.createTH(headerRow6, 1, 1, subject1.TOTALSCORE, 20, false);  //배점 
          this.createTH(headerRow6, 1, 1, subject1.SCORE, 20, false); //득점
          this.createTH(headerRow6, 1, 1, subject1.AREA_CNT > 1 ? subject1.AREA_RANK+"("+subject1.AREA_CNT+")" : subject1.AREA_RANK, 20, false);      //전체석차
          this.createTH(headerRow6, 1, 1, subject1.CNT > 1 ? subject1.RANK+"("+subject1.CNT+")" : subject1.RANK, 20, false);      //전체석차
          this.createTH(headerRow6, 1, 1, subject1.AREA_RAT, 20, false);  //지역RAT
          this.createTH(headerRow6, 1, 1, subject1.RAT, 20, false);      //전국RAT
          this.createTH(headerRow6, 1, 1, subject1.AREA_GRADE, 20, false);  //지역등급
          this.createTH(headerRow6, 1, 1, subject1.GRADE, 20, false);  //전국등급
          this.createTH(headerRow6, 1, 1, subject1.TOT, 20, false); //응시인원
        }
        
        //개별과목 개별 석차
        if(subject_rank.length > 1){
          const subject1 = subject_rank[1];
          const headerRow7 = table.insertRow();
          this.createTH(headerRow7, 1, 1, score_info.SELECT_NAME, 20, false); //과목명
          this.createTH(headerRow7, 1, 1, subject1.TOTALSCORE, 20, false);  //배점 
          this.createTH(headerRow7, 1, 1, subject1.SCORE, 20, false); //득점
          this.createTH(headerRow7, 1, 1, subject1.AREA_CNT > 1 ? subject1.AREA_RANK+"("+subject1.AREA_CNT+")" : subject1.AREA_RANK, 20, false);      //전체석차
          this.createTH(headerRow7, 1, 1, subject1.CNT > 1 ? subject1.RANK+"("+subject1.CNT+")" : subject1.RANK, 20, false);      //전체석차
          this.createTH(headerRow7, 1, 1, subject1.AREA_RAT, 20, false);  //지역RAT
          this.createTH(headerRow7, 1, 1, subject1.RAT, 20, false);      //전국RAT
          this.createTH(headerRow7, 1, 1, subject1.AREA_GRADE, 20, false);  //지역등급
          this.createTH(headerRow7, 1, 1, subject1.GRADE, 20, false);  //전국등급
          this.createTH(headerRow7, 1, 1, subject1.TOT, 20, false); //응시인원
        }
        

        //전체 통합 석차
        const headerRow8 = table.insertRow();
        this.createTH(headerRow8, 1, 1, "종합", 20, false); //과목명
        this.createTH(headerRow8, 1, 1, "100", 20, false);  //배점 
        this.createTH(headerRow8, 1, 1, score_info.SCORE, 20, false);     //득점
        this.createTH(headerRow8, 1, 1, score_info.AREA_RANK, 20, false); //지역석차
        this.createTH(headerRow8, 1, 1, score_info.RANK, 20, false);      //전체석차
        this.createTH(headerRow8, 1, 1, score_info.AREA_RAT, 20, false);  //지역RAT
        this.createTH(headerRow8, 1, 1, score_info.RAT, 20, false);       //전국RAT
        this.createTH(headerRow8, 1, 1, score_info.AREA_GRADE, 20, false);//지역등급
        this.createTH(headerRow8, 1, 1, score_info.GRADE, 20, false);     //전국등급
        this.createTH(headerRow8, 1, 1, score_info.TOT, 20, false);       //응시인원
        

        pdiv.appendChild(table);

      },


      //오답률 테이블
      createWrongTable(pdiv, wrongArray){


        //오답률 정보가 1차원 배열로 들어와서 2차원 배열로 변경
        // SUBJECT_NO 별로 RANK 오름차순 정렬
        wrongArray.sort((a, b) => {
          if (a.SUBJECT_NO === b.SUBJECT_NO) {
            return a.RANK - b.RANK;
          }
          return a.SUBJECT_NO - b.SUBJECT_NO;
        });

        // 2차원 배열로 변환
        const groupedData = wrongArray.reduce((acc, item) => {
          if (!acc[item.SUBJECT_NO]) {
            acc[item.SUBJECT_NO] = [];
          }
          acc[item.SUBJECT_NO].push(item);
          return acc;
        }, {});

        // 배열 형태로 변환
        const waray = Object.values(groupedData);      

        const table = document.createElement("table");
        table.style.position   = "absolute";
        table.style.marginLeft = "52px";
        table.style.marginTop  = "413px";
        //table.style.width      = "270px";
        //table.style.height     = "180px";
        this.nStylesToTable(table);
        
        const headerRow1 = table.insertRow();
        this.createTH(headerRow1, 8, 1, "오답률 TOP 5", 23, true);
        
        const headerRow2 = table.insertRow();
        for(let i=0;i<waray.length;i++){
          if(waray[i][0].SUBJECT_TYPE == 0){
            this.createTH(headerRow2, 2, 1, "공통과목", 23, true);
          }else{
            this.createTH(headerRow2, 2, 1, "선택과목", 23, true);
          }
        }
        const headerRow3 = table.insertRow();
        for(let i=0;i<waray.length;i++){
          this.createTH(headerRow3, 2, 1, waray[i][0].SUBJECT_NAME, 23, false);
        }

        const headerRow4 = table.insertRow();
        const cells = [];
        for(let i=0;i<waray.length*2;i++){
          const tempcell = headerRow4.insertCell();
          if(i%2 == 0){
            tempcell.textContent = "번호";
          }else{
            tempcell.textContent = "비율";
          }
          cells.push(tempcell);
        }
        cells.forEach(cell => this.nStylesToTableCells(cell, 23));
        
        //과목별로 오답률 5개씩 넣어야한다.
        for (let i=0; i<5; i++) {
          const dataRow = table.insertRow();
          const dataCells = [];

          for(let j=0; j < waray.length ; j++){
            const tempcell1 = dataRow.insertCell();
            //문제번호
            tempcell1.textContent = waray[j][i].ANSWERS_NUMBER;
            dataCells.push(tempcell1);
            const tempcell2 = dataRow.insertCell();
            //오답비율
            tempcell2.textContent = waray[j][i].RATE;
            dataCells.push(tempcell2);
          }
          dataCells.forEach(cell => this.nStylesToTableCells(cell, 23));
        }
        
        pdiv.appendChild(table);

      },

      //등급표 테이블
      createGradeTable(pdiv, gradeArray){

        const table = document.createElement("table");
        table.style.position   = "absolute";
        table.style.marginLeft = "475px";
        table.style.marginTop  = "413px";
        table.style.width      = "270px";
        //table.style.height     = "180px";
        this.nStylesToTable(table);
        
        const headerRow1 = table.insertRow();
        this.createTH(headerRow1, 6, 1, "등급표", 23, true);

        const headerRow2 = table.insertRow();
        this.createTH(headerRow2, 2, 1, "응시지역", 23, true);
        this.createTH(headerRow2, 2, 1, "전국", 23, true);
        this.createTH(headerRow2, 2, 1, "교육특구", 23, true);

        const headerRow3 = table.insertRow();
        const cells = [
          headerRow3.insertCell(),
          headerRow3.insertCell(),
          headerRow3.insertCell(),
          headerRow3.insertCell(),
          headerRow3.insertCell(),
          headerRow3.insertCell()
        ];
        cells.forEach(cell => this.nStylesToTableCells(cell, 23));
        cells[0].textContent = "등급";
        cells[1].textContent = "점수";
        cells[2].textContent = "등급";
        cells[3].textContent = "점수";
        cells[4].textContent = "등급";
        cells[5].textContent = "점수";

        for (let i = 0; i < gradeArray.length; i++) {
          const grade = gradeArray[i];
          const dataRow = table.insertRow();
          const dataCells = [
            dataRow.insertCell(),
            dataRow.insertCell(),
            dataRow.insertCell(),
            dataRow.insertCell(),
            dataRow.insertCell(),
            dataRow.insertCell()
          ];
          dataCells.forEach(cell => this.nStylesToTableCells(cell, 23));
          dataCells[0].textContent = grade.GRADE;
          dataCells[1].textContent = grade.AREASCORE;
          dataCells[2].textContent = grade.GRADE;
          dataCells[3].textContent = grade.SCORE;
          dataCells[4].textContent = grade.GRADE;
          dataCells[5].textContent = grade.GRADEVALUE;
        }
        
        pdiv.appendChild(table);

      },


      //점수 테이블
      createScoreTable(pdiv, left, top, title, score_array){

        const table = document.createElement("table");
        table.style.position   = "absolute";
        table.style.marginLeft = left+"px";
        table.style.marginTop  = top+"px";
        //table.style.width      = width+"px";
        //table.style.height     = height+"px";
        this.nStylesToTable(table);

        //몇줄에 표현해야 하는지
        var numberOfRows = Math.floor(score_array.length/15);  //한줄에 15문제까지 표시가능
        if(score_array.length%15 == 0){
          //딱떨어지면 -1
          numberOfRows -= 1;
        }
        
        //한줄에 몇문제까지 표현하면 되는지 
        var cellsPerRow = 15; //기본 15줄
        //나눠서 딱떨어지면
        if(score_array.length%(numberOfRows+1) == 0){
          cellsPerRow = Math.floor(score_array.length/(numberOfRows+1));
        }

        for (let i = 0; i <= numberOfRows; i++) {

          const dataRow = [
            table.insertRow(),
            table.insertRow(),
            table.insertRow(),
            table.insertRow(),
          ];

          //타이틀 셀 넣기
          if(i == 0){
            this.createTHWidth(dataRow[0], 1, (numberOfRows+1)*4, title, 20, 60, true);
          }

          for (let j = 0; j < cellsPerRow; j++) {
            
            const index = (i * cellsPerRow) + j;

            if(j == 0){
              //첫 셀이면 타이틀 넣어라.
              this.createTHWidth(dataRow[0], 1, 1, "문항번호", 20, 80, true);
              this.createTHWidth(dataRow[1], 1, 1, "답안", 20, 80, true);
              this.createTHWidth(dataRow[2], 1, 1, "정답", 20, 80, true);
              this.createTHWidth(dataRow[3], 1, 1, "채점결과", 20, 80, true);
            }

            if (index < score_array.length) {

              for(let k=0; k < dataRow.length ; k++){
                const cell = dataRow[k].insertCell();
                this.nStylesToTableCells(cell, 20);
                cell.style.width = "36px";
                if (k === 0) {
                  this.nStylesToTableHeader(cell, 20);
                  this.nStyleBgColor(cell);
                  cell.textContent = score_array[index].ANSWERS_NUMBER; // 문제 번호
                } else if (k === 1) {
                  cell.textContent = score_array[index].ST_ANSWERS; // 답안
                } else if (k === 2) {
                  cell.textContent = score_array[index].ANSWERS; // 정답
                } else if (k === 3) {
                  cell.textContent = score_array[index].SCORE; // 채점 결과
                }
              } //end for k

            }
            
          } //end for j

        } //end for i



        pdiv.appendChild(table);

      },



      //일반 테이블 스타일 먹이기
      nStylesToTable(table){
        table.style.borderCollapse = "collapse";
      },

      //헤더 글씨는 굵게
      nStylesToTableHeader(header){
        header.style.fontSize   = "10px";
        header.style.fontWeight = "bold";
        header.style.height     = "23px";
      },

      //셀
      nStylesToTableCells(cell, height){
        cell.style.border = "1px solid black";
        cell.style.textAlign = "center";
        cell.style.fontSize = "10px";
        cell.style.width    = "45px";
        cell.style.textAlign = "center";
        if(height == 23){
          cell.classList.add("theight23");
        }else if(height == 20){
          cell.classList.add("theight20");
        }else if(height == 50){
          cell.classList.add("theight50");
        }
      },

      nStyleWidth(cell, width){
        if(width == 80){
          cell.classList.add("twidth80");
        }else if(width == 60){
          cell.classList.add("twidth60");
        }
      },

      /**
       * 테이블 셀 색칠
       * @param {Element} cell - 타겟 셀
       * @return {null} - 리턴값 없음
       */ 
      nStyleBgColor(cell){
        cell.style.backgroundColor = "#f2f2f2";
      },


      /**
       * div 객체를 생성한다.
       * @param {Number} left - left 마진
       * @param {Number} top - top 마진
       * @param {Number} width - 넓이
       * @param {Number} height - 높이
       * @param {String} title - 텍스트 내용
       * @return {Element} - 생성된 div 객체 리턴
       */      
      createDivPx(left, top, width, height, title){
        const tempDiv = document.createElement("div");
        tempDiv.style.position   = "absolute";
        tempDiv.style.marginLeft = this.pxTomm(left)+"mm";
        tempDiv.style.marginTop  = this.pxTomm(top)+"mm";
        tempDiv.style.width      = this.pxTomm(width)+"mm";
        tempDiv.style.height     = this.pxTomm(height)+"mm";
        tempDiv.style.display    = 'inline';
        tempDiv.textContent      = title;
        return tempDiv;
      }, 

      //이건 mm버전
      createDivmm(left, top, width, height, title){
        const tempDiv = document.createElement("div");
        tempDiv.style.position   = "absolute";
        tempDiv.style.marginLeft = left+"mm";
        tempDiv.style.marginTop  = top+"mm";
        tempDiv.style.width      = width+"mm";
        tempDiv.style.height     = height+"mm";
        tempDiv.style.display    = 'inline';
        tempDiv.textContent      = title;
        return tempDiv;
      }, 

      setTextStyle(tdiv, fontsize, bold, align, color){
        tdiv.style.color      = color;
        tdiv.style.fontSize   = fontsize+"px";
        if(bold){
          tdiv.style.fontWeight = bold;
        }
        if(align){
          tdiv.style.textAlign  = align;
        }
      },

      /**
       * 테이블 헤더용 셀을 생성하고 ROW에 추가한다.
       * @param {Element} parent - 생성한 셀을 추가할 부모 Element
       * @param {Number} cspan - 컬럼 합치기 숫자
       * @param {Number} rowspan - 로우 합치기 숫자
       * @param {String} title - 셀에 들어갈 타이틀
       * @param {Number} height - 셀의 높이
       * @param {Boolean} color - true면 컬러 색칠
       * @return {null} - 리턴값 없음
       */      
      createTH(parent, cspan, rowspan, title, height, color){
        const headerCell = document.createElement("th");
        headerCell.colSpan = cspan;
        headerCell.rowSpan = rowspan;
        headerCell.textContent = title;
        this.nStylesToTableHeader(headerCell);
        this.nStylesToTableCells(headerCell, height);
        if(color){
          this.nStyleBgColor(headerCell);
        }
        parent.appendChild(headerCell);
      }, 

      createTHWidth(parent, cspan, rowspan, title, height, width, color){
        const headerCell = document.createElement("th");
        headerCell.colSpan = cspan;
        headerCell.rowSpan = rowspan;
        headerCell.textContent = title;
        headerCell.style.width = width+"px";
        this.nStylesToTableHeader(headerCell);
        this.nStylesToTableCells(headerCell, height);
        this.nStyleWidth(headerCell, width);
        if(color){
          this.nStyleBgColor(headerCell);
        }
        parent.appendChild(headerCell);
      }, 

    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

  /* 프린트시 사이즈 (필수) */
  @page {
    size: 210mm 297mm;  /*A4 Size*/
    margin: 0;
    padding: 0;
  }

  @media print{
    .toplayout{
      display: none;
    }
  }


</style>