<template>
<div id="app">
  
  <v-app id="inspire">

    <template v-if="showflag">
      <!-- 로그인상태 -->
      <v-navigation-drawer
        clipped
        fixed
        v-model="drawer"
        app
        color="#ffffff"
        >
          <v-list>
          <template v-for="menu in menulist">
            <template v-if="menu.sublist.length > 0">
              <v-list-group
                :key="menu.IDX"
                no-action
                color="#000000"
                >
                <template v-slot:activator>
                    <v-icon color="#707070">{{menu.MENUICON}}</v-icon>
                    <v-list-item-content class="ml-2">
                      <v-list-item-title class="normaltext" style="font-size:18px" :key="menu.IDX" >{{ menu.MENUNAME }}</v-list-item-title>
                    </v-list-item-content>
                </template>
                  <!-- 서브 메뉴 -->
                  <v-list-item-content style="border-top:1px solid black;border-bottom:1px solid black;">
                  <v-row v-for="subitem in menu.sublist" :key="subitem.MENU_IDX" 
                         dense align="center" justify="start" class="ma-0">
                    <v-col cols="11" class="ma-0 pa-0">
                    <v-btn 
                        @click="callrouteName(subitem.ROUTE, subitem.GRADE, subitem.MENUNAME)" text elevation="0" width="100%" height="46"
                        class="d-flex align-center" :class="subitem.ROUTE === ROUTERNAME ? 'selectlist' : 'normallist'"
                        style="padding-left:10px;">
                      <p class="pa-0 ma-0" :class="subitem.ROUTE === ROUTERNAME ? 'selecttext' : 'normaltext'">{{subitem.MENUNAME}}</p>
                      <v-spacer/>
                      <v-icon style="margin-right:10px" :color="subitem.ROUTE === ROUTERNAME ? 'color:white':'color:black'">mdi-chevron-right</v-icon>
                    </v-btn>
                    </v-col>
                  </v-row>
                  </v-list-item-content>

                <!-- 
                <v-list-item v-for="subitem in menu.sublist" :key="subitem.IDX"
                            :class="subitem.ROUTE === ROUTERNAME ? 'selectlist' : 'normallist'"
                            @click="callrouteName(subitem.ROUTE, subitem.GRADE, subitem.MENUNAME)">
                  <v-list-item-content class="ml-2" :class="menu.ROUTE === ROUTERNAME ? 'selecttext' : 'normaltext'">
                    <v-list-item-title :class="subitem.ROUTE === ROUTERNAME ? 'selectlist' : 'normallist'" :key="subitem.IDX">{{subitem.MENUNAME}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                -->

              </v-list-group>

            </template>
            <template v-else>
              <v-list-item  :key="menu.IDX" 
                            :class="menu.ROUTE === ROUTERNAME ? 'selectlist' : 'normallist'"
                            @click="callrouteName(menu.ROUTE, menu.GRADE, menu.MENUNAME)">
                <v-icon :color="menu.ROUTE === ROUTERNAME ? '#ffffff' : '#707070'">{{menu.MENUICON}}</v-icon>
                <v-list-item-content class="ml-2" :class="menu.ROUTE === ROUTERNAME ? 'selecttext' : 'normaltext'">
                  <v-list-item-title :class="menu.ROUTE === ROUTERNAME ? 'selecttext' : 'normaltext'" :key="menu.IDX">{{menu.MENUNAME}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

          </template>

        </v-list>
        <v-divider></v-divider>
      </v-navigation-drawer>
      
      <v-app-bar app fixed clipped-left  
                style="background-color:#c53d45;color:#212121;">
        <v-app-bar-nav-icon style="color:#ffffff" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <label class="hidden-sm-and-down" style="color:white;font-size:25px;margin-left:20px;margin-top:5px;" @click="gohome">감성수학</label>

        <!-- 
        <v-avatar class="ml-5" :tile="true" width="70" height="38" @click="gohome">
          <img :src="require('@/assets/logo.png')" alt="logo">
        </v-avatar>
        -->
        <v-spacer/>

        <v-sheet
          class="hidden-sm-and-down rounded-pill"
          style="vertical-align:middle;cursor:pointer" rounded 
          elevation="10" 
          height="40"
          @click="gomypage"
          >
          <v-avatar color="#c8c8c8" size="26" class="ma-2">
            <v-icon dark size="20">
              mdi-account
            </v-icon>
          </v-avatar>
          <label class="mr-5" style="cursor:pointer">{{ this.$session.get("GRADE") > 0 ? this.$session.get("ACCNT") +'님' : this.$session.get("LOGIN_NAME") +'님'}}</label>
        </v-sheet>
        <!-- <v-toolbar-title>{{ COM_NAME }}</v-toolbar-title> -->
        <v-btn text color="white" @click="logout">로그아웃 ></v-btn>
      </v-app-bar>
    </template>
    <template v-else>
      <!-- 로그아웃상태 -->
      <v-navigation-drawer
        v-model="drawer"
        temporary
        color="#ffffff"
        fixed
        >
        <v-list
          nav
          dense
          >
          <v-list-item-group
            active-class="border"
            >
            <v-list-item :class="ROUTERNAME === 'Welcome' ? 'selectlist' : 'normallist'">
              <v-list-item-title :class="ROUTERNAME === 'Welcome' ? 'selecttext' : 'normaltext'" @click="gohome">감성수학</v-list-item-title>
            </v-list-item>
            <v-list-item :class="ROUTERNAME === 'Introboard' ? 'selectlist' : 'normallist'">
              <v-list-item-title :class="ROUTERNAME === 'Introboard' ? 'selecttext' : 'normaltext'" @click="goIntro">감성수학소개</v-list-item-title>
            </v-list-item>
            <v-list-item :class="ROUTERNAME === 'AreaInfoList' ? 'selectlist' : 'normallist'">
              <v-list-item-title :class="ROUTERNAME === 'AreaInfoList' ? 'selecttext' : 'normaltext'" @click="goArea">전국지점안내</v-list-item-title>
            </v-list-item>
            <v-list-item :class="ROUTERNAME === 'Freeboard' ? 'selectlist' : 'normallist'">
              <v-list-item-title :class="ROUTERNAME === 'Freeboard' ? 'selecttext' : 'normaltext'" @click="goBoard">자유게시판</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>    
      <v-app-bar color="#c53e45" elevation="0"
        app fixed
        >
        <v-app-bar-nav-icon class="hidden-md-and-up" style="color:white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-spacer class="hidden-md-and-up"/>
        <v-col md="2" lg="2" xl="2" class="hidden-sm-and-down" align="center">
          <v-btn text style="font-size:25px;color:#ffffff;'color:#fafafa'" @click="gohome">감성수학</v-btn>
        </v-col>
        <v-col md="7" cols="8" class="hidden-sm-and-down">
          <v-btn text :style="ROUTERNAME === 'Introboard' ? 'color:#ffffff;font-weight:bold;' : 'color:#fafafa'" @click="goIntro">감성수학소개</v-btn>
          <v-btn text :style="ROUTERNAME === 'AreaInfoList' ? 'color:#ffffff;font-weight:bold;' : 'color:#fafafa'" @click="goArea">전국지점안내</v-btn>
          <v-btn text :style="ROUTERNAME === 'Freeboard' ? 'color:#ffffff;font-weight:bold;' : 'color:#fafafa'" @click="goBoard">자유게시판</v-btn>
        </v-col>
        <v-col md="3" cols="8" class="pa-0" align-self="center" align="end">
          <v-btn @click="gologin" small rounded color="#ffffff" style="color:#c53e45" class="mr-2">선생님 로그인</v-btn>
          <v-btn @click="gostlogin" small rounded color="#ffffff" style="color:#c53e45" >학생 로그인</v-btn>
        </v-col>
        
      </v-app-bar>
    </template>

    <v-main style="background:#f8f8f8">
      <router-view :key="$route.fullPath"/> <!-- 같은 vue에 파라메터만 다른 경우에도 새로 읽기하려면 fullpath 처리, 페이징시 필수 -->
    </v-main>

    <v-footer app height="auto" style="background-color:#c53d45;color:#ffffff;">
      <v-spacer></v-spacer>
      <div style="text-align:center; font-size:12px;">
				(주)감성교육 | 주소: 서울특별시 강남구 대치동 912-7 PEACE 빌딩(역삼로 456) | 대표 : 신진원 | 사업자등록번호: 420-81-01880 | Copyright 2022. all rights reserved.
			</div>      
      <v-spacer></v-spacer>
    </v-footer>

  </v-app>
  
</div>
</template>

<script>
//import { EventBus } from './util/event-bus'
import { getFirestore, doc, onSnapshot, setDoc } from "firebase/firestore";
import { signInWithCustomToken } from "firebase/auth";
import moment from 'moment';
export default {

  data: () => ({
      drawer: false
    
    , menulist : []
    , login_flag : false
    , COM_NAME : ''
    , ROUTERNAME: ''
    , showflag: false //사이드 메뉴와 탑바의 보여주기 유무
    , db : null
    , unsub: null
    , unauth: null
    , untoken:null
    , group: null,
  }),

  created(){
    
    this.db = getFirestore();

    //로그인 전역 이벤트 수신
    this.$EventBus.$on('loginresult', this.selectmenu);

    //풀사이즈 이벤트 수신
    this.$EventBus.$on('setfullsize', this.setfullsize);

    //화면이동 이벤트 수신
    this.$EventBus.$on('movepageresult', this.movepage);

    //세션 초기화 이벤트 수신
    this.$EventBus.$on('clearsession', this.clearsession);

    
    //FIREBASE 세션복구 - 새로고침시, 파베코튼 만료시, 이메일 로그인시 이 부분을 탄다.
    //signInWithCustomToken로 로그인하면 세션이 기본적으로 30일정도 간다. 
    //실제 토큰은 1시간인데 알아서 리플레쉬 한다고 함.
    //파베 토큰은 알아서 리플레쉬 하라고 하고 서버 토큰을 복구해야한다.
    this.unauth = this.$fireauth.onAuthStateChanged(async (user) => {
      //console.log("onAuthStateChanged time : " + new Date());

      if (user) {
        // 로그인된 사용자가 있음
        //console.log("onAuthStateChanged user : " + JSON.stringify(user));
        this.user = user;
        if(user.email){
          //선생님 로그인
          this.createCustomtoken(user.uid);
        }else{

          if(window.location.pathname == "/StudentJoin"){
            //회원가입 단계에서 토큰이 생성되면 호출되는데 여기서 로그인 시키지 말 것.
            return;
          }

          //학생 로그인
          this.firerestore(user.uid);
        }
        
      } else {
        // 로그인된 사용자가 없음
        this.user = null;
      }
    });    

    //id토큰이 갱신되면 호출됨. 저장해야함.
    this.untoken = this.$fireauth.onIdTokenChanged(async (user)=>{
      //console.log("onIdTokenChanged time : "+ new Date());

      if(window.location.pathname == "/StudentJoin"){
        //회원가입 단계에서 토큰이 생성되면 호출되는데 여기서 처리하지 말것.
        return;
      }

      if(user){
        user.getIdToken().then((idtoken)=>{
          //console.log("onIdTokenChanged user : " + JSON.stringify(idtoken));
          this.$session.set("FIRETOKEN",  idtoken);
        // eslint-disable-next-line no-unused-vars
        }).catch((error)=>{
          //console.log("onIdTokenChanged error : " + JSON.stringify(error));
        });
      }else{
        this.user = null;
      }
    })

  },

  watch: {
  },


  methods: {

    //선생님 로그인 정보 복원
    createCustomtoken(localid){

        this.$http.post(this.$host+'/createCustomToken',{
          LOCALID:localid,
        })
        .then((result)=>{
          
          //console.log("create token : " + JSON.stringify(result.data));

          
          if(result.data.resultCode == 0){

            const user = result.data.resultData[0];
            const uuid = result.data.uuid;

            //custom token으로 클라이언트 로그인하기
            signInWithCustomToken(this.$fireauth, result.data.customToken)
            .then(async (userCredential)=>{
              
              //로그인 성공
              const userinfo = userCredential.user;

              //id토큰 받기
              const idToken = await userinfo.getIdToken();
              this.$session.set("ACCNT_NO",   user.ACCNT_NO);
              this.$session.set("LOCALID",    userinfo.uid);
              this.$session.set("ACCNT",      user.ACCNT);
              this.$session.set("NAME",       user.NAME);
              this.$session.set("GRADE",      user.GRADE);
              this.$session.set("AREA_NO",    user.AREA_NO);
              this.$session.set("DATAGRADE",  user.DATAGRADE);
              this.$session.set("LOGIN_DATE", user.LOGIN_DATE);
              this.$session.set("FOOTER_IMG", user.FOOTER_IMG);
              this.$session.set("FIRETOKEN",  idToken);
              localStorage.setItem("UUID", uuid);

              console.log(" userinfo.uid : " + userinfo.uid);

              //나의 로그인 시간을 저장한다.
              const db = getFirestore();
              const docref = doc(db, "users", userinfo.uid);
              await setDoc(docref, {
                LOCALID:userinfo.uid,
                UUID:uuid,
                LoginDate: moment(user.LOGIN_DATE).format("YYYY-MM-DD HH:mm:ss")
              });

              //이동
              if(user.GRADE > 90){
                //로그인 성공 전역 이벤트 발송
                if(window.location.pathname == "/Login"){
                  this.$router.push('/AccntList');
                }
                this.$EventBus.$emit('loginresult');
              }else if(user.GRADE > 5){
                if(window.location.pathname == "/Login"){
                  this.$router.push('/Mypage');
                }
                //로그인 성공 전역 이벤트 발송
                this.$EventBus.$emit('loginresult');
              }else{
                //console.log("curent : " + this.$router.path);
                this.$alert('현재 승인대기중입니다.');
              }

            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              //console.log("custom token error : " + error);
              this.$alert("로그인에 실패했습니다. 다시 시도하세요.");
              this.logout2();
            });

          }else if(result.data.resultCode == 3){
            //커스텀 토큰이 만료된 경우 갱신 필요
            
          

          }else{
            this.$alert(result.data.resultMsg);
            this.logout2();
          }

        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          
          //console.log("createToken error : " + error);
          this.$alert("로그인에 실패했습니다. 다시 시도하세요.");
          this.logout2();
        });
    },          

    //학생 로그인 정보 복원
    firerestore:function(localid){

      //console.log("firestore login : " + localid);

      this.$http.post(this.$host+'/phoneLogin',{
        LOCALID:localid,
      })
      .then((result)=>{
        
          if(result.data.resultCode == 0){
            
            const userinfo = result.data.resultData[0];
            
            //custom token으로 클라이언트 로그인하기
            signInWithCustomToken(this.$fireauth, result.data.customToken)
            .then(async (userCredential)=>{
              
              //로그인 성공
              const user = userCredential.user;

              //id토큰 받기
              const idToken = await user.getIdToken();

              this.$session.set("LOCALID",    user.uid);
              this.$session.set("GRADE",      0);
              this.$session.set("LOGIN_NAME", userinfo.NAME);
              this.$session.set("FIRETOKEN",  idToken);
              
              this.$EventBus.$emit('loginresult');

            })
          }else{
            this.$alert(result.data.resultMsg);
            //오류난 경우 명시적으로 로그아웃 시켜라.
            this.logout2();
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        //복구에 실패하면 그냥 로그아웃 시켜버려라.
        this.logout2();
      });

    },



    gomypage(){
      this.$router.push('Mypage').then(()=>{
        this.ROUTERNAME = 'Mypage';
        this.$session.set("MENUNAME",   '마이페이지');
      })
      .catch(()=>{
      });
    },
    
    goIntro(){
      this.$router.push('Introboard').then(()=>{
        this.ROUTERNAME = 'Introboard';
      })
      .catch(()=>{
      });
    },

    goArea(){
      this.$router.push('AreaInfoList').then(()=>{
        this.ROUTERNAME = 'AreaInfoList';
      })
      .catch(()=>{
      });
    },

    goBoard(){
      this.$router.push('Freeboard').then(()=>{
        this.ROUTERNAME = 'Freeboard';
      })
      .catch(()=>{
      });
    },

    setfullsize(flag){
      //console.log("set full size : " + flag);
      this.showflag = flag;
      this.$forceUpdate();
    },

    gohome(){
      this.$router.push("Welcome").then(()=>{
        this.ROUTERNAME = "Welcome";
      })
      .catch(()=>{
      });
    },

    gostlogin(){
      this.$router.push("StudentLogin").then(()=>{
        this.ROUTERNAME = "StudentLogin";
      })
      .catch(()=>{
      });
    },

    gologin(){
      this.$router.push("Login").then(()=>{
        this.ROUTERNAME = "Login";
      })
      .catch(()=>{
      });
    },

    //중복 로그인 강제 로그아웃
    async logout2(){
      this.$session.clear("ACCNT_NO");
      this.$session.clear("LOCALID");
      this.$session.clear("LOGIN_DATE");
      this.$session.clear("ACCNT");
      this.$session.clear("NAME");
      this.$session.clear("GRADE");
      this.$session.clear("AREA_NO");
      this.$session.clear("DATAGRADE");
      this.$session.clear("FOOTER_IMG");
      this.$session.clear("ACCESSTOKEN");
      this.$session.clear("REFLASHTOKEN");
      this.$session.clear("FIRETOKEN");

      localStorage.removeItem("UUID");

      //학생도 로그아웃
      this.$session.clear("FIRETOKEN");
      try{
        await this.$fireauth.signOut();
      }catch(error){
        //console.log("firelogout : " + error);
      }

      this.selectmenu();
    },

    logout:function(){

      if(this.login_flag){

        this.$confirm("로그아웃 하시겠습니까?")
        .then(async () => {
          this.$session.clear("ACCNT_NO");
          this.$session.clear("LOCALID");
          this.$session.clear("LOGIN_DATE");
          this.$session.clear("ACCNT");
          this.$session.clear("NAME");
          this.$session.clear("GRADE");
          this.$session.clear("AREA_NO");
          this.$session.clear("DATAGRADE");
          this.$session.clear("FOOTER_IMG");
          this.$session.clear("ACCESSTOKEN");
          this.$session.clear("REFLASHTOKEN");
          localStorage.removeItem("UUID");
          
          //학생도 로그아웃
          this.$session.clear("FIRETOKEN");
          try{
            await this.$fireauth.signOut();
          }catch(error){
            //console.log("firelogout : " + error);
          }

          this.selectmenu();
        });

      }

    },

      //화면이동 전역 이벤트 수신
      clearsession(){
        this.logout2();
        /*
        this.$session.clear("ACCNT_NO");
        this.$session.clear("LOCALID");
        this.$session.clear("LOGIN_DATE");
        this.$session.clear("ACCNT");
        this.$session.clear("NAME");
        this.$session.clear("GRADE");
        this.$session.clear("AREA_NO");
        this.$session.clear("DATAGRADE");
        this.$session.clear("FOOTER_IMG");
        this.$session.clear("ACCESSTOKEN");
        this.$session.clear("REFLASHTOKEN");
        this.$session.clear("FIRETOKEN");
        this.$router.push('/');
        */
      },


      //로그인 전역 이벤트 수신
      selectmenu:function(){

        //로그인이 되어있는 경우
        if(this.$session.exists("LOCALID")){

          //중복로그인 감지용 리스너 처리
          if(this.$session.get("GRADE") > 0){
            if(this.unsub == null) {
              //console.log("duple login add : " + this.$session.get("LOCALID"));
              this.unsub = onSnapshot(doc(this.db, "users", this.$session.get("LOCALID")), (doc) => {

                //로그인한 시간
                var date1 = new Date(this.$session.get("LOGIN_DATE"));
                if(date1 == null || date1 == undefined) return;

                //서버에 로그인된 시간 - 다른사람이 로그인을 하면 다르겠지.
                //새로고침시에는 LOGIN_DATE와 동일하기 때문에 여기 안탈듯.
                var date2   = new Date(doc.data().LoginDate);

                //UUID는 동일 브라우저의 여러 탭에서 공유되야 하기 때문에 로컬 스토리지를 사용.
                var uuid1 = localStorage.getItem("UUID");
                if(uuid1 == null || uuid1 == undefined){
                  //구버전 브라우저
                  this.$alert("새로고침 후 다시 로그인해 주세요.")
                  .then(async ()=>{
                    this.logout2();
                  })                 
                  return;
                }

                var uuid2 = doc.data().UUID;
                if(date2 > date1 && uuid1 !== uuid2){

                  //중복 로그인
                  this.$alert("동시접속이 감지되어 자동으로 로그아웃되었습니다. 다른 기기에서 접속할 시에는 반드시 기존 기기에서 <로그아웃>후 새로 로그인해주시기 바랍니다. 동시접속 3회 발생시 계정이 정지됩니다. 문의사항은 감성교육 컨텐츠 개발실로 문의해주시기 바랍니다.")
                  .then(async ()=>{

                    //관리자에게 중복로그인 알림 이메일 발송
                    await this.$http.post(this.$host+'/sendDuplicationLogin'
                    ,{
                        ACCNT:this.$session.get("ACCNT")
                      , ACCNT_NO:this.$session.get("ACCNT_NO")
                    },{headers: { firetoken:this.$session.get("FIRETOKEN")}});

                    this.logout2();

                  })                 
                }
              });
            }
            
          }

          this.showflag   = true;
          this.drawer     = true;
          this.login_flag = true;
          
          if(this.$session.get("GRADE") > 0){
            this.getmenulist();
          }else{
            this.getStudentmenulist();
          }
          
          this.COM_NAME = this.$session.get("LOGIN_NAME");

          this.$forceUpdate();

        } else {

          //로그아웃
          if(this.unsub != null){
            console.log("duple login unsub");
            this.unsub(); //firestore 리스너 중지
            this.unsub = null;
          }
          
          this.showflag   = false;
          this.drawer     = false;
          this.login_flag = false;
          
          this.removemenulist();
          this.$router.push('Welcome');   //로그인 페이지로 이동
          this.COM_NAME = "";

          this.$forceUpdate();

        }

      },

      //화면이동 전역 이벤트 수신
      movepage(router_name, menuname){
        //console.log("movepage : " + router_name + " / " + menuname);
        this.$router.push(router_name).then(()=>{
          this.ROUTERNAME = router_name;
          this.$session.set("MENUNAME",   menuname);
          this.$forceUpdate();
        })
        .catch(()=>{
        });  //해당 이름으로 라우트됨 ex) link면 /link 로 라우트
      },

      callrouteName:function(router_name, grade, menuname){

          //console.log("callroutename : " + router_name + " / " +menuname );

          //권한등급 체크
          if(this.$session.get("GRADE") >= grade){
            this.$session.set("MENUNAME",   menuname);
            this.$router.push(router_name).then(()=>{
              this.ROUTERNAME = router_name;
            })
            .catch(()=>{
            });  //해당 이름으로 라우트됨 ex) link면 /link 로 라우트
            
          }else{
            this.$alert("사용할 수 없는 권한의 페이지입니다.").then(()=>{
              this.$router.back();
            });
          }

      },

      getmenulist:function(){

        //console.log("getmenulist....");

        this.$http.post(this.$host+'/getMenuList'
        ,{
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{

          if(result.data.resultCode == 0){

            var glist = result.data.groupData;
            var slist = result.data.subData;

            glist.forEach(group => {
                group.sublist = [];
                slist.forEach(sub=>{
                  if(group.MENUNAME == sub.GROUPNAME){
                    group.sublist.push(sub);
                  }
                });
                /*
                if(group.sublist.length == 0){
                  group.sublist.push({IDX:group.IDX, COM_IDX:group.COM_IDX, MENUNAME:group.MENUNAME, ROUTE:group.ROUTE});
                }
                */
            });
            this.menulist.splice(0, this.menulist.length);
            glist.forEach(element => {
              this.menulist.push(element); 
            });

          }else if(result.data.resultCode == 2){

            //로그인 필요
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.").then(()=>{
              this.$session.clear("ACCNT_NO");
              this.$session.clear("LOCALID");
              this.$session.clear("ACCNT");
              this.$session.clear("NAME");
              this.$session.clear("GRADE");
              this.$session.clear("AREA_NO");
              this.$session.clear("DATAGRADE");
              this.$session.clear("FOOTER_IMG");
              this.$session.clear("ACCESSTOKEN");
              this.$session.clear("REFLASHTOKEN");
              this.$session.clear("FIRETOKEN");
              this.login_flag = false;
              this.removemenulist();
              this.$router.push('Login');   //로그인 페이지로 이동
            });

          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
        });

      },

      getStudentmenulist:function(){

        this.$http.post(this.$host+'/getStudentMenuList'
        ,{
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{

          if(result.data.resultCode == 0){

            var glist = result.data.groupData;
            var slist = result.data.subData;

            glist.forEach(group => {
                group.sublist = [];
                slist.forEach(sub=>{
                  if(group.MENUNAME == sub.GROUPNAME){
                    group.sublist.push(sub);
                  }
                });
                /*
                if(group.sublist.length == 0){
                  group.sublist.push({IDX:group.IDX, COM_IDX:group.COM_IDX, MENUNAME:group.MENUNAME, ROUTE:group.ROUTE});
                }
                */
            });
            this.menulist.splice(0, this.menulist.length);
            glist.forEach(element => {
              this.menulist.push(element); 
            });

          }else if(result.data.resultCode == 2){

            //로그인 필요
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.").then(()=>{
              this.$session.clear("ACCNT_NO");
              this.$session.clear("LOCALID");
              this.$session.clear("ACCNT");
              this.$session.clear("NAME");
              this.$session.clear("GRADE");
              this.$session.clear("AREA_NO");
              this.$session.clear("DATAGRADE");
              this.$session.clear("FOOTER_IMG");
              this.$session.clear("ACCESSTOKEN");
              this.$session.clear("REFLASHTOKEN");
              this.$session.clear("FIRETOKEN");
              this.login_flag = false;
              this.removemenulist();
              this.$router.push('StudentLogin');   //로그인 페이지로 이동
            });

          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
        });

      },      

      removemenulist:function(){
        //모두 제거
        this.menulist.splice(0, this.menulist.length);
      },

      //페이지 새로고침 또는 주소창에 다른주소 입력해서 빠져나갈 때 경고
      //path에 /가 들어가면 경고가 뜨네..
      unloadevent:function(event){
        //console.log("unloadevent" + JSON.stringify(event));
        event.preventDefault();
        event.returnValue = "";
      }


  },


  beforeDestroy(){
    
    console.log("beforeDestroy...");

    //로그인 전역 이벤트 수신 해제
    this.$EventBus.$off('loginresult');

    this.$EventBus.$off('setfullsize');

    //페이지 이동 이벤트 수신 해제
    this.$EventBus.$off('movepageresult');
    
    //세션 초기화 이벤트 해제
    this.$EventBus.$off('clearsession');

    //파이어베이스 리스너 해제
    if(this.unauth){
      this.unauth();
    }
    if(this.untoken){
      this.untoken();
    }
    if(this.unsub){
      this.unsub();
    }

  },

  beforeUnmount() {
    //console.log("beforeMount..." + this.ROUTERNAME);
    window.removeEventListener('beforeunload', this.unloadevent);
  },

  mounted(){
    //console.log("mount..." + this.ROUTERNAME);

    window.addEventListener('beforeunload', this.unloadevent);
  
    if(this.$session.exists("LOCALID")){
      //로그인이 된 경우 (새로고침 케이스)
      this.showflag = true;
      this.selectmenu();
    }else{
      //로그인이 안된 경우 무조건 로그인으로 넘기지 말 것.
      //로그인이 안된 상태면
      //this.$router.push('Login');   //로그인 페이지로 이동
    }
    
    //로딩바 숨기기
    document.getElementById('loading').style.display = 'none';


  }

}
</script>

<style>
  /* 여기는 기본 태그들에 스타일을 먹이는 곳인듯 */

  /* 페이지 제목 */
  .menunamestyle{
    font-size:16px;
    color: #2D2D2D;
    font-weight: bold;
  }
  .total_label{
    font-size:15px;
    color: #777777;
    font-weight: bold;
  }
  .mytextstyle{
    font-size:16px;
    color: #777777;
    font-weight: bold;
  }

  /* 페이징 숫자 */
  .numberstyle{
    font-size:16px !important;
    color: red !important;
    font-weight: bold !important;
  }  
  .numberstyle2{
    font-size:16px !important;
    color: #5a5a5a !important;
    font-weight: bold !important;
  }  


  /* 상단 메뉴의 기본 텍스트 스타일 */
  .basictextstyle{
    font-size:16px;
    color: #5a5a5a;
    font-weight: bold;
  }

  /* 셀렉트 콤보의 기본 텍스트 스타일 */
  .basicselectstyle{
    font-size:16px;
    color: #2b2b2b;
    font-weight: bold;
    background: white;
    box-shadow: 2px 2px 5px #00000030;
  }
  
  /* 요청으로 v-select의 팝업 높이 수정 */
  .menuable__content__active{
    max-height:600px !important;
    /* min-width:580px !important; */
  }
  

  /* 검색창 스타일 */
  .basicsearchstyle{
    font-size:16px;
    color: #2b2b2b;
    font-weight: bold;
    background: white;
    box-shadow: 2px 2px 5px #00000030;
    margin-right: 5px !important;
  }
  .basicsearchstyle input{
    padding-top: 12px !important;
  }


  /* 검색창 스타일 */
  .mypageiputstyle{
    font-size:16px;
    color: #2b2b2b;
    font-weight: bold;
    background: white;
    box-shadow: 2px 2px 5px #00000030;
    margin-right: 5px !important;
  }


  /* 미니 라운드 버튼 */
  .mini_btn {
    color: #ffffff !important;
    background-color: #C53D45 !important;
  }



  /* 검색버튼 */
  .search_btn_freesize {
    color: #ffffff !important;
    background-color: #C53D45 !important;
    border: thin solid #C53D45 !important;
    border-radius: 10px 10px 10px 10px !important;
    font-size: 16px !important;
    height: 43px !important;
    width: 98% !important;
    text-align: center !important;
    box-shadow: 2px 2px 5px #00000030 !important;
  }

  /* 팝업 인풋창 스타일 */
  .popupiputstyle{
    font-size:16px;
    color: #2b2b2b;
    font-weight: bold;
    border-radius: 10px 10px 10px 10px !important;
    background: white;
  }

  /* 팝업 검색버튼 */
  .dialogsearch_btn_freesize {
    color: #ffffff !important;
    background-color: #C53D45 !important;
    border: thin solid #C53D45 !important;
    border-radius: 10px 10px 10px 10px !important;
    font-size: 16px !important;
    width: 98% !important;
    text-align: center !important;
    box-shadow: 2px 2px 5px #00000030 !important;
  }

  /* 전역 데이터 테이블 라운드 스타일 */
  .datatablestyle{
    border-radius: 10px; 
    border-color:#ebebeb; 
    border-style:solid; 
    border-width:1px 2px 3px 2px;
    box-shadow: 1px 1px 5px #00000030 !important;
  }

  /* 팝업  클로즈 버튼 */
  .dialog_close_btn {
    color: #e5e5e5 !important;
    background-color: #ffffff !important;
    border-color:#e5e5e5 !important; 
    border-style:solid !important; 
    border-width:3px 3px 3px 3px !important;
  }

  /* 팝업 타이틀 */
  .dialog_title {
    color: #0A0A0A !important;
    font-size: 20px !important;
    font-weight: bold !important;
  }

  /* 팝업 라운드 처리 */
  .dialog_round{
    border-radius: 0px 0px 0px 0px !important;
  }


  /* 팝업 하단 버튼 */
  .dialog_btn {
    color: #ffffff !important;
    background-color: #690505 !important;
    font-size: 16px !important;
    border-radius: 0px !important;
  }

  /* 팝업 하단 버튼 */
  .dialog_cancel_btn {
    font-weight: bold !important;
    color: #6b0105 !important;
    background-color: #e5e5e5 !important;
    font-size: 16px !important;
    border-radius: 0px !important;
  }  

  /* 팝업 셀렉트 콤보 스타일 */
  .dialogselectstyle{
    font-size:16px;
    color: #2b2b2b;
    font-weight: bold;
    background: white;
    border-radius: 10px 10px 10px 10px !important;
  }





  /* 랜딩페이지 검색창 스타일 */
  .landsearchstyle{
    border-radius: 30px 30px 30px 30px !important;
    font-size:16px;
    color: #cbcbcb;
    font-weight: bold;
    background: white;
    box-shadow: 2px 2px 5px #00000030;
    margin-right: 5px !important;
    
  }
  .landsearchstyle input{
    padding-left: 10px !important;
    padding-top: 12px !important;
    padding-right: 10px;
    font-size: 20px;
    
  }

  /* 랜딩페이지 데이터 테이블 스타일 */
  .landdatatablestyle{
    border-radius: 0px; 
    border-color:#ebebeb; 
    border-style: none; 
    border-width:1px 2px 3px 2px;
    box-shadow: 1px 1px 5px #00000030 !important;
  }

  /* 전국지점정보 컬럼명 텍스트 */
  .areacolumntitletext{
    font-size: 19px !important;
    color:#9f0007 !important;
  }
  .areacolumnvaluetext{
    font-size: 19px !important;
    color:#2b2b2b !important;
  }

  /* 전국지점정보 컬럼헤드 스타일 */
  .areacolumnheaderstyle{
    font-size: 19px !important;
    color:#9f0007 !important;
    border-radius: 0px 0px 0px 0px !important;
    box-shadow: none !important;
  }

  .homepage_btn_freesize {
    color: #9f0007 !important;
    background-color: #ffffff !important;
    border: thin solid #C53D45 !important;
    font-size: 20px !important;
    height: 60px !important;
    width: 98% !important;
    text-align: center !important;
    box-shadow: 2px 2px 5px #00000030 !important;
  }

  .areaunderlinestyle{
    color:black;
    font-size:30px;
    z-index: 5;
    position:relative;
    left:0px;
    top:0px;
  }


  /* 데이터 테이블 정렬용 컬럼 헤더 스타일 */
  .cheader_sortselect{
    font-size: 16px !important;
    color:#ca252c !important;
  }
  
  .cheader_sort{
    font-size: 16px !important;
    color:#594ef3 !important;
  }

  .cheader_normal{
    font-size: 16px !important;
    color:#5a5a5a !important;
    text-underline-position: auto;
    border-bottom: 2px red;
  }

  .broadheader_normal{
    font-size: 15px !important;
    color:#3d3d3d !important;
    font-weight: bold;
  }
  .broadrow_line{
    border-bottom-color: #ececec;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  .repple_line{
    border-bottom-color: #ececec;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

</style>

<style scoped>

  #logouttitle{
    font-size: 18px;
  }

  .normallist{
    font-size: 18px;
    color: #b7b7b7;
    background-color: #ffffff;
    border-radius: 10px 10px 10px 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 48px;
  }

  .selectlist{
    font-size: 18px;
    color: #ffffff;
    background: linear-gradient(to right, #da5058, #ca252c)!important;
    box-shadow: 3px 3px 5px #00000030;
    border-radius: 10px 10px 10px 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 48px;
  }

  .normaltext{
    font-size: 18px;
    color: #707070;
  }

  .selecttext{
    font-size: 18px;
    color: #ffffff;
  }

  a {
    color:#ffffff !important;
    text-decoration: none;
  }

</style>

<!-- 전체 폰트 -->
<style lang="scss">

  .redtesttable {
    tbody {
      tr:hover {
          background-color: transparent !important;
      }
    }
  }

  //로컬폰트 로드
  @font-face {
    font-family: 'AppleSDGothicNeoM';
    src: local('AppleSDGothicNeoM'), url("./assets/fonts/AppleSDGothicNeoM.ttf") format("truetype");
  }
  
  //구글폰트
  @import url('https://fonts.googleapis.com/css2?family=Nanum+Brush+Script&display=swap');
  $font-family: 'NaumBrush', cursive;

  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;900&display=swap');
  $font-family: 'NotoSansKR', cursive;

  //전체 폰트 반영
  
  $font-family: 'AppleSDGothicNeoM';
  .v-application {
    .headline,
    [class*='display-'],
    [class*='text-'] {
      color: #36405a;
      font-family: $font-family, sans-serif !important;
    }
    font-family: $font-family, sans-serif !important;
  }
  

  /*
  $font-family: 'NotoSansKR';
  .v-application {
    .headline,
    [class*='display-'],
    [class*='text-'] {
      color: #36405a;
      font-family: $font-family, sans-serif !important;
    }
    font-family: $font-family, sans-serif !important;
  }
  */
  

</style>

