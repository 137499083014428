<template>

  <v-container fluid >

    <!-- 추가/수정 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#000000ff" v-model="dialog" max-width="550px">
        
        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">이름</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.NAME" dense single-line
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">전화번호</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field disabled
                    v-model="editedItem.TEL" dense single-line
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">지점</label>
                </v-col>
                <v-col cols="9">
                  <areadialog :areaname="editedItem.AREA_NAME" @callback="comareacb"/>  
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">학부모</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.PARENT_TEL" dense single-line
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">주소</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field disabled
                    v-model="editedItem.ADDR1" dense single-line
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>
              <v-row justify="center" align="center" dense>
                <v-col cols="12" align="right">
                  <v-btn style="height:35px;width:150px;font-size:16px;color:white" color="#C53D45" @click="postcode">주소검색</v-btn>
                </v-col>
              </v-row>
              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">상세주소</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.ADDR2" dense single-line
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              
              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">성별</label>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="editedItem.GENDER" :items="GENDERLIST" outlined
                    item-text="name" item-value="value" class="dialogselectstyle"
                    hide-details elevation="0" dense height="48px"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">회원상태</label>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="editedItem.STATE" :items="STATELIST" outlined
                    item-text="name" item-value="value" class="dialogselectstyle"
                    hide-details elevation="0" dense height="48px"
                    />
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>

    <v-row align="center" justify="start" class="mt-5">

      <v-col cols="12" md="3" sm="12" xs="12">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="6" md="2" sm="6" xs="6"  align="center">
        <v-select id="mySelect"
          v-model="SELECT_AREA_NO" :items="AREA_LIST" label="지점" no-data-text="지점이 없습니다."
          dense hide-details outlined item-text="AREA_NAME" item-value="AREA_NO" class="basicselectstyle" 
          :disabled="grade < 90"
          />
      </v-col>
      <v-col cols="6" md="2" sm="6" xs="6"  align="center">
        <v-select v-model="SELECT_STATE" :items="SEARCHSTATELIST" label="상태" 
          class="basicselectstyle"
          dense hide-details outlined item-text="name" item-value="value"/>
      </v-col>

      <v-col cols="9"  md="3" sm="6" xs="6">
        <v-text-field 
          dense single-line clearable
          @keyup.enter="search_run" 
          v-model="search_keyword" 
          outlined height="43"
          color="#808080"
          hide-details
          placeholder="검색어를 입력하세요"
          class="basicsearchstyle"
          />
      </v-col>
      <v-col cols="2" md="1" sm="3" xs="3" class="pa-0" align="center">
        <v-btn class="search_btn_freesize" elevation="0" outlined large @click="search_run">검색</v-btn>
      </v-col>
      
  </v-row>

  <v-row>
    <v-col cols="12">
      <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
    </v-col>
  </v-row>

    
  <v-row align="start" dense>
    <v-col > 
      <!-- 테이블 -->
      <v-data-table
        class="datatablestyle"
        :headers="headers"
        :items="itemList"
        v-model="selected"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        :items-per-page="pagerow"
        :search="search"  
        item-key="IDX"
        :loading="loading"
        >
        <template v-slot:header.TEL>
          <a :class="sort_name=='TEL'?'cheader_sortselect':'cheader_sort'" @click="customsort('TEL')">전화번호</a>
        </template>
        <template v-slot:header.NAME>
          <a :class="sort_name=='NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('NAME')">이름</a>
        </template>
        <template v-slot:header.STATE>
          <a :class="sort_name=='STATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('STATE')">상태</a>
        </template>
        <template v-slot:header.AREA_NAME>
          <a :class="sort_name=='AREA_NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('AREA_NAME')">지점</a>
        </template>
        <template v-slot:header.LOGIN_DATE>
          <a :class="sort_name=='LOGIN_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('LOGIN_DATE')">최근로그인</a>
        </template>
        <template v-slot:item.GENDER="{ item }">  
          <label style="text-align:center">{{ item.GENDER == 0 ? '남자' : '여자' }}</label>
        </template>     
        <template v-slot:item.STATE="{ item }">  
          <label style="text-align:center">{{ item.STATE == 1 ? '활성' : '탈퇴' }}</label>
        </template>     
        <template v-slot:item.actions="{ item }">  
            <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
        </template>     
      </v-data-table>
    </v-col>
  </v-row>

  <!-- 하단 페이징 영역 -->
  <v-row align="start">
    <v-col cols="12" md="1"/>
    <v-col cols="12" md="2">
      <v-text-field
        v-model="search"
        append-icon="search"
        single-line
        hide-details
        outlined height="43"
        color="#808080"
        dense
        placeholder="페이지 내에서 검색"
        class="basicsearchstyle"
        />
    </v-col>
    <v-col cols="12" md="6" class="d-flex justify-center">
      <v-btn v-if="bottompage > 10" icon  small @click="prev()"><v-icon dark>mdi-chevron-left</v-icon></v-btn>
      <span v-for="n in subpage" :key="n.id">
          <span v-if="page == bottompage-1+n">
              <v-btn color="red" class="numberstyle" icon small @click="loadpage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
          <span v-else>
              <v-btn icon small class="numberstyle2" @click="loadpage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
      </span>
      <v-btn v-if="subpage == 10" icon small @click="next()" ><v-icon dark>mdi-chevron-right</v-icon></v-btn>
    </v-col>
    <v-col cols="12" md="2">
      <v-select
        v-model="pagerow"
        :items="pagerow_combo"
        item-text="NAME"      
        item-value="VALUE"
        min-width="150px"
        outlined height="43"
        color="#808080"
        dense
        hide-details
        placeholder="목록개수"
        class="basicsearchstyle"
        ></v-select>
    </v-col>
    <v-col cols="12" md="1"/>
  </v-row>

  
  

  </v-container>
</template>

<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=2d34ab8a2ba110eed5c9ab2ba610bea6&libraries=services"></script>
<script>
export default {

    data: () => ({

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          STUDENT_NO:'',
          TEL:'',
          NAME:'',
          AREA_NO:null,
          STATE:1,
          
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {text: '지점', value: 'AREA_NAME', sortable: false, align:'center', class:"cheader_normal" },
        {text: '이름', value: 'NAME', sortable: false, align:'center', class:"cheader_normal"},
        {text: '성별', value: 'GENDER', sortable: false, align:'center', class:"cheader_normal" },
        {text: '전화번호', value: 'TEL', sortable: false, align:'center', class:"cheader_normal"},
        {text: '상태', value: 'STATE', sortable: false, align:'center', class:"cheader_normal" },
        {text: '최근로그인', value: 'LOGIN_DATE', sortable: false, align:'center', class:"cheader_normal" },
        {text: '수정', value: 'actions', sortable: false, align:'center', class:"cheader_normal"}
      ],
      itemList: [],

      //검색 파라메터
      sort_name: 'LOGIN_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
      
      SELECT_AREA_NO: -1,
      SELECT_STATE:-1,
      AREA_LIST:[],

      //추가 변수들
      SEARCHSTATELIST: [
          {name:'전체',       value:-1}
        , {name:'탈퇴',       value:0}
        , {name:'활성',       value:1}
      ],

      STATELIST: [
          {name:'탈퇴',       value:0}
        , {name:'활성',       value:1}
      ],
      GENDERLIST: [
          {name:'남성',   value:0}
        , {name:'여성',   value:1}
      ],

      grade:0,

    }),

    mounted(){

      if(this.$session.get("GRADE") < 10){
        this.$alert("사용할 수 없는 등급의 페이지입니다.").then(()=>{
          this.$router.back();
        });
        return;
      }
      this.grade = this.$session.get("GRADE");
      this.SELECT_AREA_NO  = this.$session.get("AREA_NO");

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {

      //이미지 크롭시
      cropimg(){
        //이미지 크롭 
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog_land = false;
          this.uploadFile = blob; //크롭된 이미지의 blob데이터
          this.$refs.cropper_land.getCropData(data=>{
            this.previewImg = data; //이미지뷰 미리보기용
          });
        });
      },

      //배너 이미지 선택시
      changefile(){

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.crop_dialog_land = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
            //this.$refs.cropper_land.replace(event.target.result);
          }
          reader.readAsDataURL(this.uploadFile);  //비동기로 파일을 읽어서
        }

      },

      //대표장소 설정
      comareacb(val1){
        //console.log(val1);
        if(val1.AREA_NAME.length > 0){
          this.editedItem.AREA_NAME = val1.AREA_NAME;
          this.editedItem.AREA_NO   = val1.AREA_NO;
        }else{
          this.editedItem.AREA_NAME = '';
          this.editedItem.AREA_NO   = null;
        }
      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      prev(){
          this.bottompage = this.bottompage - 10 >= 1 ? this.bottompage - 10 : 1;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage + this.subpage - 1; //이전을 누르면 가장 끝 번호 선택
          this.loadpage(temp_page);
      },
      next(){
          this.bottompage = this.bottompage + 10 <= this.maxpage ? this.bottompage + 10 : this.maxpage;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage;
          this.loadpage(temp_page);
      },

      makepaging(){
        var div1 = this.totalcnt / this.pagerow; 
        var div2 = this.totalcnt % this.pagerow;
        if(div2 == 0){  
            this.maxpage = parseInt(div1);    
        }else{
            this.maxpage = parseInt(div1+1);
        }
        if(this.bottompage == 1){
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
        }
      },

      loadpage(p){

          this.loading = true;
          this.page = p;

          this.$http.post(this.$host+'/StudentList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , AREA_NO: this.SELECT_AREA_NO
              , STATE: this.SELECT_STATE
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }

                this.totalcnt = result.data.totalcnt;
                this.makepaging();
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });

                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },

      initpage(){
        //지점 리스트 조회
        this.loadarea();

        //첫페이지 로드
        this.loadpage(1);
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      //추가 저장
      save () {

          if(this.editedItem.NAME.length < 1){
              this.$alert("이름을 입력하세요");
              return;
          }
          if(this.editedItem.NAME.length > 10){
              this.$alert("이름은 10자 이하로 입력하세요");
              return;
          }

          if(this.editedItem.AREA_NO == null || this.editedItem.AREA_NO == undefined){
              this.$alert("지점을 선택하세요");
              return;
          }

          if(this.editedItem.ADDR1.length < 1){
              this.$alert("주소를 검색하세요");
              return;
          }
          if(this.editedItem.ADDR2.length < 1){
              this.$alert("상세주소를 입력하세요");
              return;
          }

          const mobileVal1 = /[^0-9]/g;
          var mobileVal = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
          if(this.editedItem.PARENT_TEL == undefined || this.editedItem.PARENT_TEL.length < 1){
            this.$alert("부모님 휴대폰 번호를 입력하세요");
            return;
          } 
          
          if(mobileVal1.test(this.editedItem.PARENT_TEL)){
            this.$alert("부모님 휴대폰 번호는 숫자만 입력이 가능합니다.");
            return;
          } 
          
          if(!mobileVal.test(this.editedItem.PARENT_TEL)){
            this.$alert("부모님 휴대폰 번호가 잘못되었습니다.");
            return;
          } 

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/StudentUpdate',{
                NAME: escape(this.editedItem.NAME)
              , PARENT_TEL: this.editedItem.PARENT_TEL
              , AREA_NO: this.editedItem.AREA_NO
              , ADDR1: escape(this.editedItem.ADDR1)
              , ADDR2: escape(this.editedItem.ADDR2)
              , GENDER: this.editedItem.GENDER
              , STATE: this.editedItem.STATE
              , STUDENT_NO: this.editedItem.STUDENT_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$alert("수정이 완료되었습니다").then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
                this.$alert('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert('수정이 실패했습니다.');
          });

        this.close();

      },


      //검색
      search_run(){
        this.loadpage(1);
      },

      //아이템 수정
      edit_item(item){
        this.dialogTitle = "수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },



      loadarea(){

        this.$http.post(this.$host+'/AreaList',{
              search_keyword: ''
            , sort_name: "AREA_NAME"
            , sort_type: "DESC"
            , page: 1
            , pagerow: 99999
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            const list = result.data.resultData;
            this.AREA_LIST.splice(0, this.AREA_LIST.length);   //데이터 초기화
            this.AREA_LIST.push({"AREA_NAME":"전체", "AREA_VALUE":"-1"});
            list.forEach(element => {
                this.AREA_LIST.push(element); 
            });

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.loading = false;
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.loading = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });

      },      


      postcodetoAddr(addr){
        this.editedItem.ADDR1 = addr;
        this.$forceUpdate();
      },

      //주소검색
      postcode(){

        //콜백에서 못찾아서 콘스트로 미리 빼둔다.
        const callback_addrfunction     = this.postcodetoAddr;

        new daum.Postcode({
            oncomplete: function(data) {
                //console.log(JSON.stringify(data));
                var addr = data.address; // 최종 주소 변수
                if(data.userSelectedType == "J"){
                  //지번
                  addr = data.jibunAddress; 
                }
                //주소 정보를 해당 필드에 넣는다.
                callback_addrfunction(addr);
            }
        }).open();

      },      

    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>