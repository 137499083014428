import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#da5058',
            secondary: '#da5058',
            accent: '#da5058',
            error: '#da5058',
          },
        },
      },    
});
