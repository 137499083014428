<template>

  <v-container fluid>

    <!-- 이미지 크롭 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" 
        v-model="crop_dialog_land" max-width="500px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">이미지 편집</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <div style="width:100%;height:500px;">
            <vueCropper
                ref="cropper_land"
                :img="imgSrc"
                :outputType="option.outputType"
                :fixed="option.fixed"
                :fixedNumber="option.fixedNumber"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :fixedBox="option.fixedBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :centerBox="option.centerBox"
                :high="option.high"
                :infoTrue="option.infoTrue"
                :maxImgSize="option.maxImgSize"
                :enlarge="option.enlarge"
                :mode="option.mode"
                :limitMinSize="option.limitMinSize"
                />
              </div>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="crop_dialog_land = false">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="cropimg">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- 추가버튼 + 다이얼로그 -->
    <v-row>
    <v-dialog persistent scrollable overlay-color="#000000ff" v-model="dialog" max-width="500px">
      
      <v-card class="pa-1 ma-0">

        <v-row dense class="mt-5">
          <v-col cols="12" align="center">
            <span class="dialog_title">{{ dialogTitle }}</span>
          </v-col>
        </v-row>

        <v-card-text class="ma-0 pl-5 pr-5 pt-3">
          <v-container>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">제목</label>
              </v-col>
              <v-col cols="9">
                <v-text-field 
                  v-model="editedItem.TITLE" dense single-line
                  outlined height="48px" hide-details class="popupiputstyle"
                  />
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">상태</label>
              </v-col>
              <v-col cols="9">
                <v-select v-model="editedItem.STATE" :items="STATELIST" outlined
                  item-text="name" item-value="value" class="dialogselectstyle"
                  hide-details elevation="0" dense height="48px"
                  /> 
              </v-col>
            </v-row>

            <v-row align="center" justify="center" style="background:#eaeaea;height:140px">
              <v-col align="center" align-self="center" style="height:120px">
                <viewer
                  class="viewer"
                  ref="viewer" 
                  :options="options"
                  >
                  <img 
                    height="100"
                    :src="previewImg"
                    class="image"
                    >
                </viewer>
              </v-col>
            </v-row>                

            <v-row  align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">배너 이미지</label>
              </v-col>
              <v-col cols="9">
                <v-file-input 
                  outlined dense height="48" class="popupiputstyle"
                  show-size hide-details v-model="uploadFile" 
                  accept="image/png, image/jpeg"
                  @change="changefile"
                  >
                  </v-file-input>
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">연결타입</label>
              </v-col>
              <v-col cols="9">
                <v-select v-model="editedItem.LINK_TYPE" :items="TYPELIST" outlined
                  item-text="name" item-value="value" class="dialogselectstyle"
                  hide-details elevation="0" dense height="48px" @change="linktypechange"
                  />
              </v-col>
            </v-row>

            <template v-if="editedItem.LINK_TYPE == 0">
              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">연결 게시번호</label>
                </v-col>
                <v-col cols="9">
                  <boarddialog :linkvalue="editedItem.LINK_VALUE" @callback="noticesearchcb"></boarddialog>
                </v-col>
              </v-row>
            </template>
            <template v-else-if="editedItem.LINK_TYPE == 1">
              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">연결 URL</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.LINK_VALUE" dense single-line 
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>
            </template>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center" align-self="center">
                <label class="mytextstyle">시작일</label>
              </v-col>
              <v-col cols="9" >
                <v-menu
                  v-model="start_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="editedItem.STARTDATE" class="popupiputstyle"
                      prepend-icon="event" dense single-line
                      readonly outlined height="48px" hide-details
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="editedItem.STARTDATE" @input="start_date = false" :first-day-of-week="0" locale="ko-kr"></v-date-picker>
                </v-menu>                    
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center" align-self="center">
                <label class="mytextstyle">종료일</label>
              </v-col>
              <v-col cols="9" >
                <v-menu
                  v-model="end_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="editedItem.ENDDATE" class="popupiputstyle"
                      prepend-icon="event"  dense single-line
                      readonly outlined height="48px" hide-details
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="editedItem.ENDDATE" @input="end_date = false" :first-day-of-week="0" locale="ko-kr"></v-date-picker>
                </v-menu>                    
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">{{ editedIndex == -1 ? '추가' : '수정' }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>  
    </v-row>


    <v-row dense align="center" justify="start" class="mt-5" >
      <v-col cols="6" md="3" sm="6" xs="6">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>

      <v-col cols="6" md="2" sm="6" xs="6" class="pr-5">
        <v-row justify="end">
            <v-checkbox class="basictextstyle" label="노출배너만 보기" v-model="dateprint" dense hide-details/>
        </v-row>
      </v-col>

      <!-- 검색어 -->
      <v-col cols="12" md="3" sm="12" xs="12" class="pa-0">
        <v-text-field 
          dense single-line clearable
          @keyup.enter="search_run" 
          v-model="search_keyword" 
          placeholder="검색어를 입력하세요"
          outlined height="43"
          color="#808080"
          hide-details   
          class="basicsearchstyle"       
          />
      </v-col>

      <v-col cols="6" md="1" sm="6" xs="6" class="pa-0" align="center">
        <v-btn width="94%" class="search_btn_freesize"  elevation="0" outlined large @click="search_run">검색</v-btn>
      </v-col>

      <v-col cols="6" md="1" sm="6" xs="6" class="pa-0" align="center">
        <v-btn width="94%" class="search_btn_freesize" elevation="0" outlined large @click="newinsert">추가</v-btn>
      </v-col>

      <v-col cols="6" md="1" sm="6" xs="6" class="pa-0" align="center">
        <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="saveSort">순서저장</v-btn>        
      </v-col>

    </v-row>


  <v-row dense no-gutters>
    <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
  </v-row>
  <v-row align="start" dense>
    <v-col>
      <!-- 테이블 -->
      <draggable v-model="itemList">
        <v-data-table
          :headers="headers"
          :items="itemList"
          no-data-text="데이터가 없습니다."
          hide-default-footer
          class="datatablestyle"
          :items-per-page="pagerow"
          :search="search"  
          item-key="BANNER_NO"
          :loading="loading"
          >
          <!-- 
          <template v-slot:header.TITLE>
            <a :class="sort_name=='TITLE'?'cheader_sortselect':'cheader_sort'" @click="customsort('TITLE')">제목</a>
          </template>
          <template v-slot:header.STATE_NAME>
            <a :class="sort_name=='STATE_NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('STATE_NAME')">상태</a>
          </template>
          <template v-slot:header.TYPE_NAME>
            <a :class="sort_name=='TYPE_NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('TYPE_NAME')">연결</a>
          </template>
          <template v-slot:header.STARTDATE>
            <a :class="sort_name=='STARTDATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('STARTDATE')">시작일</a>
          </template>
          <template v-slot:header.ENDDATE>
            <a :class="sort_name=='ENDDATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('ENDDATE')">종료일</a>
          </template>
          <template v-slot:header.REG_DATE>
            <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">등록일</a>
          </template>
          -->
          <template v-slot:body="props">
            <draggable :list="props.items" ghost-class="ghost" @change="changedrag" tag="tbody">
              <tr v-for="(item, index) in props.items" :key="index">
                <td style="width:50px; text-align:center"><v-icon small>mdi-drag</v-icon></td>
                <td style="width:200px; text-align:center"><v-col class="pa-0" align="center"><v-img :src="item.IMAGE" height="80" width="200" contain/></v-col></td>
                <td style="width:200px; text-align:center">{{ item.TITLE.substring(0, 30) + "..." }}</td>
                <td style="width:100px; text-align:center">{{ item.STATE_NAME }}</td>
                <td style="width:80px; text-align:center">{{ item.TYPE_NAME }}</td>
                <td style="width:100px; text-align:center">{{ item.STARTDATE }}</td>
                <td style="width:100px; text-align:center">{{ item.ENDDATE }}</td>
                <td style="width:80px; text-align:center"><v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon><v-icon small @click="delete_item(item)">mdi-delete</v-icon></td>
              </tr>
            </draggable>
          </template>

        </v-data-table>
      </draggable>
    </v-col>
  </v-row>

  <!-- 하단 페이징 영역 -->
  <v-row align="start">
    <v-col cols="12" md="1"/>
    <v-col cols="12" md="2">
      <v-text-field
        v-model="search"
        append-icon="search"
        single-line
        hide-details
        outlined height="43"
        color="#808080"
        dense
        placeholder="페이지 내에서 검색"
        class="basicsearchstyle"
        />
    </v-col>
    <v-col cols="12" md="6" class="d-flex justify-center">
      <v-btn v-if="bottompage > 10" icon  small @click="prev()"><v-icon dark>mdi-chevron-left</v-icon></v-btn>
      <span v-for="n in subpage" :key="n.id">
          <span v-if="page == bottompage-1+n">
              <v-btn color="red" class="numberstyle" icon small @click="loadpage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
          <span v-else>
              <v-btn  icon small class="numberstyle2" @click="loadpage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
      </span>
      <v-btn v-if="subpage == 10" icon small @click="next()" ><v-icon dark>mdi-chevron-right</v-icon></v-btn>
    </v-col>
    <v-col cols="12" md="2" v-if="false">
      <v-select
        v-model="pagerow"
        :items="pagerow_combo"
        item-text="NAME"      
        item-value="VALUE"
        label="목록개수"
        min-width="150px"
        outlined height="43"
        color="#808080"
        @change="changepagerow"
        dense
        ></v-select>
    </v-col>
    <v-col cols="12" md="1"/>
  </v-row>


  </v-container>
</template>

<script>
//import VueCropper from 'vue-cropperjs'; //이미지 크롭
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import { ref, uploadBytes, deleteObject } from "firebase/storage";
import draggable from 'vuedraggable'
export default {
    components:{
      VueCropper,
      draggable,
    },

    data: () => ({

      //파일 업로드
      uploadFile : null,
      
      crop_dialog_land: false,
      imgSrc:'',
      ratio: 16/9,
      
      previewImg:"",
      options: {
        focus:false,
      },      

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시
      dateprint: true,
      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          TITLE:'',
          IMAGE:'',
          REFIMAGE:'',
          STATE:1, 
          LINK_TYPE:0, 
          LINK_VALUE:'',
          SORT:1, 
          STARTDATE: new Date().toISOString().substr(0, 10),
          ENDDATE: new Date().toISOString().substr(0, 10),
      },
      editedItem: {
      },

      //테이블 정의
      headers: [
        {text: '',  value: 'IMAGE', sortable: false, align:'center'},
        {text: '배너 이미지',  value: 'IMAGE', sortable: false, align:'center', width:200, class:"cheader_normal"},
        {text: '제목',    value: 'TITLE', sortable: false, align:'center', width:200, class:"cheader_normal"},
        {text: '상태',    value: 'STATE_NAME', sortable: false, align:'center', width:80, class:"cheader_normal"},
        {text: '연결',    value: 'TYPE_NAME', sortable: false, align:'center', width:80, class:"cheader_normal"},
        {text: '시작일',    value: 'STARTDATE', sortable: false, align:'center', width:120, class:"cheader_normal" },
        {text: '종료일',    value: 'ENDDATE', sortable: false, align:'center', width:120, class:"cheader_normal" },
        {text: '수정/삭제', value: 'actions', sortable: false, align:'center', width:100, class:"cheader_normal"}
      ],
      itemList: [],

      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어


      //한 페이지 ROW 개수 설정
      pagerow : 9999,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'10', VALUE:10}
        , {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
         
      //추가 변수들
      STATELIST: [
          {name:'비활성', value:0}
        , {name:'활성',   value:1}
      ],
        
      TYPELIST: [
          {name:'게시판',   value:0}
        , {name:'URL',   value:1}
      ],

      start_date : false,
      end_date : false,

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: true,          //크롭박스 비율 고정
        fixedNumber: [16,9],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:400,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:400,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 5000,
        limitMinSize: [300, 300]
      },            

    }),

    mounted(){
      if(this.$session.get("GRADE") < 90){
        this.$alert("사용할 수 없는 등급의 페이지입니다.");
        this.$router.back();
        return;
      }

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){

    },

    methods: {


      //검색조건 업체 설정
      noticesearchcb(val1){
        if(val1.TITLE.length > 0){
          this.editedItem.LINK_VALUE = val1.BOARD_NO;
        }else{
          this.editedItem.LINK_VALUE = "";
        }
      },

      changepagerow(){
        this.loadpage(1);
      },

      //오리지널 리스트 순서를 변경
      changedrag(e){
        this.itemList.splice(e.moved.oldIndex, 1);  //원본 위치에서 제거
        this.itemList.splice(e.moved.newIndex, 0, e.moved.element);  //새로운 위치에 다시 추가
      },

      linktypechange(){
        this.editedItem.LINK_VALUE = "";
      },

      //배너 이미지 선택시
      changefile(){

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.crop_dialog_land = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
            //this.$refs.cropper_land.replace(event.target.result);
          }
          reader.readAsDataURL(this.uploadFile);  //비동기로 파일을 읽어서
        }

      },

      //이미지 크롭시
      cropimg(){
        //이미지 크롭 
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog_land = false;
          this.uploadFile = blob; //크롭된 이미지의 blob데이터
          this.$refs.cropper_land.getCropData(data=>{
            this.previewImg = data; //이미지뷰 미리보기용
          });
        });
      },


      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      prev(){
          this.bottompage = this.bottompage - 10 >= 1 ? this.bottompage - 10 : 1;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage + this.subpage - 1; //이전을 누르면 가장 끝 번호 선택
          this.loadpage(temp_page);
      },
      next(){
          this.bottompage = this.bottompage + 10 <= this.maxpage ? this.bottompage + 10 : this.maxpage;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage;
          this.loadpage(temp_page);
      },

      makepaging(){
        var div1 = this.totalcnt / this.pagerow; 
        var div2 = this.totalcnt % this.pagerow;
        if(div2 == 0){  
            this.maxpage = parseInt(div1);    
        }else{
            this.maxpage = parseInt(div1+1);
        }
        if(this.bottompage == 1){
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
        }
      },

      //조회
      loadpage(p){
          this.loading = true;
          this.page = p;
          this.$http.post(this.$host+'/BannerList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , dateprint: this.dateprint
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.totalcnt = result.data.totalcnt;
                this.makepaging();
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadpage(1);
      },

      //다이얼로그 닫기
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.uploadFile = null;
          this.previewImg = "";
        }, 300)
      },

      //추가 및 수정
      save () {

        if(this.editedItem.TITLE  == undefined || this.editedItem.TITLE.length < 1){
          //alert("제목을 입력하세요");
          this.$alert("제목을 입력하세요");
          return;
        } 

        if(this.editedItem.TITLE.length > 50){
          this.$alert("제목은 50자까지 입력이 가능합니다.");
          return;
        } 

        if(this.editedItem.IMAGE == undefined || this.editedItem.IMAGE.length < 1){
          if(this.uploadFile == undefined || this.uploadFile.length < 1){
            this.$alert("이미지를 등록하세요");
            return;
          } 
        }

        const temp_start = new Date(this.editedItem.STARTDATE)
        const temp_end   = new Date(this.editedItem.ENDDATE)
        if(temp_start > temp_end){
          this.$alert("시작일이 종료일보다 큽니다.");
          return;
        }

        if(this.editedItem.LINK_TYPE == undefined || this.editedItem.LINK_TYPE == null){
          this.$alert("연결타입을 선택하세요.");
          return;
        }

        /* 일부러 주석처리, 값이 없으면 연결 없음
        if(this.editedItem.LINK_VALUE == undefined || this.editedItem.LINK_VALUE == null || this.editedItem.LINK_VALUE.length < 1){
          this.$alert("연결타입에 따른 연결값을 입력하세요.");
          return;
        }
        */

        if(this.editedItem.LINK_VALUE.length > 100){
          this.$alert("연결값은 100자까지 입력이 가능합니다.");
          return;
        } 

        if (this.editedIndex > -1) {

          if(this.uploadFile){

            //파일이 수정된 경우
            //console.log("edit file...");
            this.loading = true;
            const citem  = this.editedItem;

            //기존 파일 삭제 (파일명이 동일하면 캐싱때문에 이미지가 안바뀜)
            const desertRef = ref(this.$firestorage, citem.REFIMAGE);
            deleteObject(desertRef).then(() => {
            // eslint-disable-next-line no-unused-vars
            }).catch((error) => {
                this.loading = false;
                //this.$alert('수정 실패');
            });

            const uploadfile_ref = ref(this.$firestorage, "banner/"+Date.now().toString()+".jpg");
            const metadata = {
              contentType: 'image/jpeg',
            };      
            uploadBytes(uploadfile_ref, this.uploadFile, metadata)
            .then((snapshot) => {
              
              //console.log('full path ' + snapshot.metadata.fullPath);
              this.$http.post(this.$host+'/BannerUpdate',{
                    TITLE: escape(citem.TITLE)
                  , LINK_VALUE: escape( citem.LINK_VALUE)
                  , IMAGE: snapshot.metadata.fullPath
                  , STATE:   citem.STATE
                  , SORT:   citem.SORT
                  , LINK_TYPE:   citem.LINK_TYPE
                  , STARTDATE:   citem.STARTDATE
                  , ENDDATE:  citem.ENDDATE
                  , BANNER_NO:   citem.BANNER_NO
              },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
              .then((result)=>{
                this.loading = false;
                if(result.data.resultCode == 0){
                  
                  this.loading = false;
                  
                  //토큰 저장
                  if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                    this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                  }
                  if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                    this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                  }
                    this.$alert('수정이 완료되었습니다.').then(()=>{
                      const p = this.page;
                      this.page = 0;
                      this.loadpage(p);
                    });

                }else if(result.data.resultCode == 2){

                  //로그인 필요
                  this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
                  this.$EventBus.$emit('clearsession');
                }else{
                  this.$alert('수정 실패');
                }
              })
              // eslint-disable-next-line no-unused-vars
              .catch((error)=>{
                this.loading = false;
                this.$alert('수정 실패');
              });
            })
            // eslint-disable-next-line no-unused-vars
            .catch((upload_error)=>{
                this.loading = false;
                this.$alert('추가 실패');
            });

          }else{
            //console.log("edit normal...");

            //수정
            this.loading = true;
            this.$http.post(this.$host+'/BannerUpdate',{
                  TITLE: escape(this.editedItem.TITLE)
                , LINK_VALUE: escape( this.editedItem.LINK_VALUE)
                , STATE:    this.editedItem.STATE
                , SORT:     this.editedItem.SORT
                , LINK_TYPE:   this.editedItem.LINK_TYPE
                , STARTDATE:   this.editedItem.STARTDATE
                , ENDDATE:  this.editedItem.ENDDATE
                , BANNER_NO:   this.editedItem.BANNER_NO
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                  this.$alert('수정이 완료되었습니다.').then(()=>{
                    const p = this.page;
                    this.page = 0;
                    this.loadpage(p);
                  });

              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
                this.$EventBus.$emit('clearsession');
              }else{
                this.$alert('수정이 실패했습니다.');
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.loading = false;
              this.$alert('수정이 실패했습니다.');
            });

          }

        }else{
          
          //신규
          this.loading = true;
          const citem = this.editedItem;

          //firebase object storage에 파일 업로드
          const uploadfile_ref = ref(this.$firestorage, "banner/"+Date.now().toString()+".jpg");
          const metadata = {
            contentType: 'image/jpeg',
          };      
          uploadBytes(uploadfile_ref, this.uploadFile, metadata)
          .then((snapshot) => {
            
            //console.log('full path ' + snapshot.metadata.fullPath);
            this.$http.post(this.$host+'/BannerInsert',{
                  TITLE: escape(citem.TITLE)
                , LINK_VALUE: escape( citem.LINK_VALUE)
                , IMAGE: snapshot.metadata.fullPath
                , STATE:   citem.STATE
                , SORT:   citem.SORT
                , LINK_TYPE:   citem.LINK_TYPE
                , STARTDATE:   citem.STARTDATE
                , ENDDATE:  citem.ENDDATE
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){
                
                this.loading = false;
                
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                  this.$alert('추가가 완료되었습니다.').then(()=>{
                    const p = this.page;
                    this.page = 0;
                    this.loadpage(p);
                  });

              }else if(result.data.resultCode == 2){

                //로그인 필요
                this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
                this.$EventBus.$emit('clearsession');
              }else{
                this.$alert('추가 실패');
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.loading = false;
              this.$alert('추가 실패');
            });
            

          })
          // eslint-disable-next-line no-unused-vars
          .catch((upload_error)=>{
              this.loading = false;
              this.$alert('추가 실패');
          });

        }

        this.close()  //팝업닫기

      },      


      //검색버튼 클릭
      search_run(){
        this.loadpage(1);
      },

      //신규 추가
      newinsert(){
        this.dialogTitle  = "추가하기"
        this.editedIndex  = -1;
        this.editedItem   = Object.assign({}, this.defaultItem)
        this.editedItem.SORT = parseInt(this.itemList.length+1);
        this.dialog = true;
      },

      //아이템 수정
      edit_item(item){
        this.dialogTitle = "수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        //this.editedItem = item;
        this.editedItem = Object.assign({}, item);
        this.previewImg = this.editedItem.IMAGE;
        this.dialog = true;
      },

      //아이템 삭제
      delete_item(item){

        this.$confirm('정말 삭제하시겠습니까?')
        .then(() => {
        
        this.loading = true;
        const desertRef = ref(this.$firestorage, item.REFIMAGE);
        deleteObject(desertRef).then(() => {

          this.$http.post(this.$host+'/BannerDelete',{
                BANNER_NO:item.BANNER_NO
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                
                this.$alert('삭제가 완료되었습니다.').then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
                this.$EventBus.$emit('clearsession');
                
              }else{
                this.$alert('삭제가 실패했습니다.');
              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                //console.log("banner delete 1 " + error);
                this.loading = false;
                this.$alert('삭제 실패');
            });      
          });
        // eslint-disable-next-line no-unused-vars
        }).catch((error) => {
          this.loading = false;
        });

      },      


      saveSort(){

        if(this.itemList.length <= 1){
          this.$alert('변경하실 내역이 없습니다.');
          return;
        }

        //드래그앤드랍 순서를 저장한다.
        this.$confirm('순서를 저장하시겠습니까?')
        .then(() => {

          var tempidx = 1;
          this.itemList.forEach(item=>{
            item.SORT = tempidx;
            tempidx++;
          });

          this.loading = true;
          this.$http.post(this.$host+'/BannerUpdateSort',
            {
              SORTDATA: escape(JSON.stringify(this.itemList))
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            
            this.loading = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
                this.$alert('순서가 저장되었습니다.').then(()=>{
                  this.loadpage(1);
                });
  

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.$alert('순서 변경이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              this.loading = false;
              this.$alert('순서 변경이 실패했습니다.');
          });

        });
      },               

    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>