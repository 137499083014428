<template>

  <v-container fluid>

    <!-- 프로그레스 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading_dialog" max-width="300px">
        <v-container class="d-flex flex-column justify-center align-center" 
                     style="background-color:white;width:300px;height:300px">
          <v-row class="ma-8">
            <v-progress-circular
              :size="100"
              :width="15"
              color="#cc3c48"
              indeterminate
              >
            </v-progress-circular>
          </v-row>
          <v-row class="ma-5">
            <label style="text-align:center">잠시만 기다려주세요</label>
          </v-row>
        </v-container >
      </v-dialog>
    </v-row>

  <v-row align="center" justify="start" >
    <v-col cols="12" md="1" sm="12" xs="12">
      <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
    </v-col>
    <v-spacer/>
    <v-col cols="12" md="2" sm="6" xs="6"  align="center">
      <v-select v-model="SELECT_AREA_NO" :items="AREA_LIST" label="지점" no-data-text="지점이 없습니다."
        dense hide-details outlined item-text="AREA_NAME" item-value="AREA_NO" class="basicselectstyle" @change="loadpage"
        />
    </v-col>
    <v-col cols="12" md="3" sm="6" xs="6"  align="center">
      <v-select ref="vsemester" v-model="SELECT_SEMESTER" :items="SEMESTER_LIST" label="학기" no-data-text="학기가 없습니다." class="basicselectstyle"
        dense hide-details outlined item-text="SEMESTER_NAME" item-value="SEMESTER_NO" return-object @change="changesemester" />
    </v-col>
    <v-col cols="12" md="3" sm="6" xs="6"  align="center">
      <v-row class="pa-0" align="center">
        <v-col cols="9" class="ma-0">
          <v-select v-model="SELECT_BOOK" :items="BOOK_LIST" label="교재" no-data-text="교재가 없습니다." class="basicselectstyle"
            dense hide-details outlined  item-text="BOOK_NAME" item-value="BOOK_NO" return-object @change="changebook" />
        </v-col>
        <v-col cols="3" class="pa-0 ma-0" align="center" >
          <bookdialog @callback="bookcomareacb"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="3" sm="6" xs="6" align="center">
      <v-select v-model="SELECT_CHAPTER" :items="CHAPTER_LIST" label="챕터" no-data-text="챕터가 없습니다." class="basicselectstyle"
        dense hide-details outlined item-text="CHAPTER_NAME" item-value="CHAPTER_NO" return-object @change="changechapter" />
    </v-col>
  </v-row>

  <v-row align="center" justify="end" dense >
    <v-col cols="12" md="2" sm="6" xs="6"  align="center">
      <v-select v-model="SELECT_CNT" :items="CNTLIST" label="문항수" class="basicselectstyle"
        dense hide-details outlined item-text="name" item-value="value" />
    </v-col>
    <v-col cols="12" md="2" sm="6" xs="6"  align="center">
      <v-select v-model="SELECT_TYPE" :items="TYPELIST" label="문제형식"  class="basicselectstyle"
        dense hide-details outlined  item-text="name" item-value="value" />
    </v-col>

    <v-col cols="6" md="2" sm="6" xs="6" align="center">
      <v-checkbox class="basictextstyle" :disabled="useAnswer" label="정답및해설 출력" v-model="answerprint"/>
    </v-col>
    <v-col cols="6" md="2" sm="6" xs="6" align="center">
      <v-checkbox class="basictextstyle" :disabled="useCheck" label="오답히스토리 테이블 출력" v-model="noteprint"/>
    </v-col>
    <v-col cols="6" md="1" sm="6" xs="6" align="center">
      <v-btn width="100%" class="search_btn_freesize" elevation="0" outlined large @click="showview">보기</v-btn>
    </v-col>

  </v-row>

  <v-row align="center" justify="end" dense >
    <v-col cols="6" md="1" align="center">
      <v-btn width="100%" class="search_btn_freesize" elevation="0" outlined large @click="selectall">{{ allselect == false ? '전체선택' : '전체해제' }}</v-btn>
    </v-col>
    <v-col cols="6" md="2" align="center">
      <v-row justify="center">
        <v-checkbox class="basictextstyle" label="문제이미지 보기" v-model="showimage" @change="changeshowimage"/>
      </v-row>
    </v-col>
    <v-col cols="6" md="1" align="center">
      <v-row justify="center">
        <v-checkbox class="basictextstyle" label="크게보기" v-model="scaleup" />
      </v-row>
    </v-col>
    <v-col cols="6" md="1"  align="center">
      <v-row justify="center">
        <v-checkbox class="basictextstyle" label="오답순정렬" v-model="use_orderby" @change="loadpage" />
      </v-row>
    </v-col>
    <v-col cols="6" md="1"  align="center">
      <v-row justify="center">
        <v-checkbox class="basictextstyle" label="양면인쇄" v-model="twoside" />
      </v-row>
    </v-col>
    <v-col cols="6" md="1"  align="center">
      <v-row justify="center">
        <v-checkbox class="basictextstyle" label="점선출력" v-model="printline" />
      </v-row>
    </v-col>

    <v-spacer/>
    <v-col cols="6" md="2" sm="6" xs="6" align="center">
      <v-text-field 
        dense single-line clearable
        v-model="INPUT_TITLE" 
        placeholder="제목을 입력하세요"
        outlined height="43"
        color="#808080"
        hide-details          
        class="basicsearchstyle" 
        />
    </v-col>
    <v-col cols="6" md="2" sm="6" xs="6" align="center">
      <v-text-field 
        dense single-line clearable
        v-model="INPUT_WATER" 
        placeholder="워터마크를 입력하세요"
        outlined height="43"
        color="#808080"
        hide-details          
        class="basicsearchstyle" 
        />
    </v-col>
    <v-col cols="6" md="1" sm="6" xs="6" align="center">
      <v-btn width="100%" class="search_btn_freesize" elevation="0" outlined large @click="savenote">오답노트 저장</v-btn>
    </v-col>
  </v-row>

  <v-row align="start" dense>
    <v-col>
      <!-- 테이블 -->
        <v-data-table
          :headers="headers"
          :items="itemList"
          no-data-text="데이터가 없습니다."
          hide-default-footer
          class="datatablestyle redtesttable"
          :items-per-page="99999"
          item-key="QUESTION_NO"
          :loading="loading"
          mobile-breakpoint="0"
          >
          <template v-slot:item.IMAGE="{ item }"> 
          <td :style="scaleup ?'width:20vw; text-align:center':'width:200px; text-align:center'">
            <v-col class="pa-0" align="center">
              <v-img :src="item.IMAGE" :style="scaleup ? 'width:20vw;':'height:100px;width:200px'" contain/>
            </v-col>
          </td>
          </template>
          <template v-slot:item.QUESTION_NAME="{ item }"> 
            <v-row justify="start">
              <v-checkbox v-model="item.SELECT" :label="item.QUESTION_NAME" />
            </v-row>
          </template>
        
          <template v-slot:item.QA="{ item }"> 
            <v-row justify="start">
              <v-checkbox v-model="item.QA.SELECT" v-if="item != undefined && item.QA != undefined" :label="item != undefined && item.QA != undefined ? item.QA.QUESTION_NAME + ' ('+item.QA.CNT+')' : ''" />
            </v-row>
          </template>
          <template v-slot:item.QB="{ item }">  
            <v-row justify="start">
              <v-checkbox v-model="item.QB.SELECT" v-if="item != undefined && item.QB != undefined" :label="item != undefined && item.QB != undefined ? item.QB.QUESTION_NAME + ' ('+item.QB.CNT+')' : ''" />
            </v-row>
          </template>
          <template v-slot:item.QC="{ item }">  
            <v-row justify="start">
              <v-checkbox v-model="item.QC.SELECT" v-if="item != undefined && item.QC != undefined" :label="item != undefined && item.QC != undefined ? item.QC.QUESTION_NAME + ' ('+item.QC.CNT+')' : ''" />
            </v-row>
          </template>
          <template v-slot:item.QD="{ item }">  
            <v-row justify="start">
              <v-checkbox v-model="item.QD.SELECT" v-if="item != undefined && item.QD != undefined" :label="item != undefined && item.QD != undefined ? item.QD.QUESTION_NAME + ' ('+item.QD.CNT+')' : ''" />
            </v-row>
          </template>
          <template v-slot:item.QE="{ item }">  
            <v-row justify="start">
              <v-checkbox v-model="item.QE.SELECT" v-if="item != undefined && item.QE != undefined" :label="item != undefined && item.QE != undefined ? item.QE.QUESTION_NAME + ' ('+item.QE.CNT+')' : ''" />
            </v-row>
          </template>
        

      </v-data-table>
    </v-col>
  </v-row>

  </v-container>
</template>

<script>
//import dateFormat from 'dateformat'
import ShowPage from '../components/ShowPage.vue'
import Vue from 'vue'
export default {

    data: () => ({

      //상단 검색 콤보
      SEMESTER_LIST:[],
      BOOK_LIST:[],
      CHAPTER_LIST:[],
      AREA_LIST:[],

      SELECT_SEMESTER: null,
      SELECT_BOOK: null,
      SELECT_CHAPTER: null,
      SELECT_AREA_NO: null,
      use_orderby: false, //오답순 정렬
      loading : false,    //로딩 표시
      dialog_print : false,
      loading_dialog: false,
      
      //테이블 정의
      headers: [
          {text: '',  value: 'QA', sortable: false, align:'center', width:'20%', class:"cheader_normal"}
        , {text: '',  value: 'QB', sortable: false, align:'center', width:'20%', class:"cheader_normal"}
        , {text: '',  value: 'QC', sortable: false, align:'center', width:'20%', class:"cheader_normal"}
        , {text: '',  value: 'QD', sortable: false, align:'center', width:'20%', class:"cheader_normal"}
        , {text: '',  value: 'QE', sortable: false, align:'center', width:'20%', class:"cheader_normal"}
      ],
      itemList: [],
      backList: [],
      checkList:[],
      useCheck:true,
      useAnswer:true,

      //추가 변수들
      CNTLIST: [
          {name:'2문항',   value:2}
        , {name:'3문항',   value:3}
        , {name:'4문항',   value:4}
        , {name:'5문항',   value:5}
        , {name:'6문항',   value:6}
        , {name:'7문항',   value:7}
        , {name:'8문항',   value:8}
      ],
      TYPELIST: [
          {name:'가로',   value:0}
        , {name:'세로',   value:1}
        , {name:'가로장형',  value:2}
      ],

      SELECT_CNT: 4,  //문항수
      SELECT_TYPE: 0, //문제형식
      INPUT_TITLE:'', //제목
      INPUT_WATER:'', //워터마크
      answerprint: false, //정답및해설출력
      noteprint: false,   //오답히스토리 테이블 출력
      allselect: false,
      showimage: false,
      scaleup:false,
      printline:true,
      twoside:false,
      grade:0,
    }),


    computed:{
      semesternames: function(){
        //console.log("semesternames : " + this.SELECT_SEMESTER.SEMESTER_NAME + " /  " + this.SELECT_BOOK.BOOK_NAME + " / " + this.SELECT_CHAPTER.CHAPTER_NAME);
        return this.SELECT_SEMESTER.SEMESTER_NAME + " /  " + this.SELECT_BOOK.BOOK_NAME + " / " + this.SELECT_CHAPTER.CHAPTER_NAME;
      },
    },


    mounted(){
      if(this.$session.get("GRADE") < 10){
        this.$alert("사용할 수 없는 등급의 페이지입니다.");
        this.$router.back();
        return;
      }
      this.grade = this.$session.get("GRADE");
      this.SELECT_AREA_NO  = this.$session.get("AREA_NO");
      this.SELECT_SEMESTER = this.$session.get("SELECT_SEMESTER");
      this.SELECT_BOOK     = this.$session.get("SELECT_BOOK");
      this.SELECT_CHAPTER  = this.$session.get("SELECT_CHAPTER");


      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){

    },

    methods: {

      initpage(){
        //지점 리스트 조회
        this.loadarea();

        //학기 리스트 조회
        this.loadsemester(false);
      },


      //교재검색 콜백
      async bookcomareacb(val1){
        console.log(JSON.stringify(val1));
        if(val1.BOOK_NAME.length > 0){
          this.SELECT_SEMESTER = {"SEMESTER_NAME":val1.SEMESTER_NAME, "SEMESTER_NO":val1.SEMESTER_NO};
          this.$session.set("SELECT_SEMESTER", this.SELECT_SEMESTER);
          this.SELECT_CHAPTER = null;
          await this.loadbook(true);
          this.SELECT_BOOK = {"BOOK_NAME":val1.BOOK_NAME,"BOOK_NO":val1.BOOK_NO};
          this.$session.set("SELECT_BOOK", this.SELECT_BOOK);
          this.loadchapter(true);
        }else{
          //this.editedItem.BOOK_NAME = '';
          //this.editedItem.AREA_NO   = null;
        }
      },      

      //문제이미지 보기 토글
      changeshowimage(){

        if(this.showimage){
          //이미지 보기
          this.headers.splice(0, this.headers.length);
          this.itemList.splice(0, this.itemList.length);
          this.headers.push({text: '이미지',   value: 'IMAGE', sortable: false, align:'center', width:300});
          this.headers.push({text: '문제명',   value: 'QUESTION_NAME', sortable: false, align:'center', width:200});
          this.headers.push({text: '오답횟수', value: 'CNT', sortable: false, align:'center', width:100});

          this.backList.forEach(item=>{
            this.itemList.push(item);
          });

        }else{
          //이미지 안보기
          this.headers.splice(0, this.headers.length);
          this.itemList.splice(0, this.itemList.length);
          this.headers.push({text: '',  value: 'QA', sortable: false, align:'center', width:'20%'});
          this.headers.push({text: '',  value: 'QB', sortable: false, align:'center', width:'20%'});
          this.headers.push({text: '',  value: 'QC', sortable: false, align:'center', width:'20%'});
          this.headers.push({text: '',  value: 'QD', sortable: false, align:'center', width:'20%'});
          this.headers.push({text: '',  value: 'QE', sortable: false, align:'center', width:'20%'});

          if(this.backList.length < 1){
            return;
          }

          //5개가 1줄로 들어가도록 처리해야함.
          var rows = parseInt(this.backList.length/5);
          if(this.backList.length % 5 != 0){
            rows++;
          }

          for(var i=0;i<rows;i++){
            var rowitem = {};
            for(var j=0;j<5;j++){
              var pos = (i*5)+j;
              if(pos < this.backList.length){
                this.backList[pos].SELECT = false;
                if(j == 0){
                  rowitem.QA = this.backList[pos];
                }
                else if(j == 1){
                  rowitem.QB = this.backList[pos];
                }
                else if(j == 2){
                  rowitem.QC = this.backList[pos];
                }
                else if(j == 3){
                  rowitem.QD = this.backList[pos];
                }
                else if(j == 4){
                  rowitem.QE = this.backList[pos];
                }
              }
            }
            //console.log("rowitem : " + JSON.stringify(rowitem));
            this.itemList.push(rowitem);
          }

        }

      },



      close_showpage(){
        //console.log("close.....");
        this.dialog_print = false;

      },

      //미리보기
      async showview(){

        //this.$selectlist.splice(0, this.$selectlist.length);
        this.loading_dialog = true;
        this.checkList.splice(0, this.checkList.length);
        this.backList.forEach(item=>{
          if(item.SELECT){
            //this.$selectlist.push(item);
            this.checkList.push(item);
          }
        })
        if(this.checkList.length < 1){
          this.loading_dialog = false;
          this.$alert("선택된 내용이 없습니다.");
          return;
        }
        
        const name = this.$session.get("NAME");
        const ShowPageConstructor = Vue.extend(ShowPage);
        const vm = new ShowPageConstructor();
        await vm.initpage(this.checkList, this.SELECT_CNT, this.SELECT_TYPE, this.INPUT_TITLE, this.semesternames, this.INPUT_WATER, this.answerprint, this.noteprint, this.SELECT_CHAPTER, this.printline, name, this.twoside);
        this.loading_dialog = false;
      },


      //전체선택
      selectall(){

        if(this.allselect){
          //전체해제
          this.backList.forEach(item => {
            item.SELECT = false;
          });
          this.allselect = false;
        }else{
          //전체선택
          this.backList.forEach(item => {
            item.SELECT = true;
          });
          this.allselect = true;
        }

      },

      //오답노트 저장
      savenote(){

        if(this.$session.get("AREA_NO") == undefined || this.$session.get("AREA_NO") == null){
          this.$alert("로그인 계정의 지점 정보가 없습니다. 지점을 배정받고 이용하세요");
          return;
        }

        if(this.INPUT_TITLE.length < 1){
          this.$alert("제목을 입력하세요");
          return;
        } 
        if(this.INPUT_TITLE.length > 50){
          this.$alert("제목은 50자 이하로 입력하세요");
          return;
        } 
        if(this.INPUT_WATER.length > 50){
          this.$alert("워터마크는 50자 이하로 입력하세요");
          return;
        } 

        //선택값
        if(this.backList.length < 1){
          this.$alert('선택된 문제가 없습니다.');
          return;
        }
        const selectList = [];
        this.backList.forEach(item =>{
          if(item.SELECT){
            selectList.push(item);
          }
        });
        if(selectList.length < 1){
          this.$alert('선택된 문제가 없습니다.');
          return;
        }

        this.loading = true;
        this.$http.post(this.$host+'/NoteInsert',{
            NOTE_TITLE: escape(this.INPUT_TITLE)
          , NOTE_WATER: escape(this.INPUT_WATER)
          , SEMESTER_NO: this.SELECT_SEMESTER.SEMESTER_NO
          , BOOK_NO: this.SELECT_BOOK.BOOK_NO
          , CHAPTER_NO: this.SELECT_CHAPTER.CHAPTER_NO
          , AREA_NO: this.SELECT_AREA_NO
          , NOTEDATA: escape(JSON.stringify(selectList))
        },{headers:{firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$alert("오답노트 추가가 완료되었습니다.");

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');

          }else{
            if(result.data.resultMsg == undefined || result.data.resultMsg == null){
              this.$alert('추가 실패');
            }else{
              this.$alert(result.data.resultMsg);
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          this.$alert('추가 실패');
        });


      },

      //학기 변경시
      changesemester(){
        this.$session.set("SELECT_SEMESTER", this.SELECT_SEMESTER);
        this.loadbook(true);
      },

      changebook(){
        this.$session.set("SELECT_BOOK", this.SELECT_BOOK);
        this.loadchapter(true);
      },

      changechapter(){
        this.$session.set("SELECT_CHAPTER", this.SELECT_CHAPTER);
        this.loadpage();
      },


      loadarea(){

        this.$http.post(this.$host+'/DataAreaList',{
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            const list = result.data.resultData;
            this.AREA_LIST.splice(0, this.AREA_LIST.length);   //데이터 초기화
            if(list.length > 1){
              this.AREA_LIST.push({AREA_NAME:"전지점", AREA_NO: null});
            }
            list.forEach(element => {
                this.AREA_LIST.push(element); 
            });

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.loading = false;
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.loading = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });

      },



      //학기 조회 - select가 true면 손으로 셀렉트 박스를 변경한 경우고 false면 최초에 기존 콤보박스 선택값 복원
      loadsemester(select){

        //전부 초기화
        if(select){
          this.SELECT_SEMESTER = null;
          this.SELECT_BOOK     = null;
          this.SELECT_CHAPTER  = null;
        }
        this.SEMESTER_LIST.splice(0, this.SEMESTER_LIST.length);  //데이터 초기화
        this.BOOK_LIST.splice(0, this.BOOK_LIST.length);          //데이터 초기화
        this.CHAPTER_LIST.splice(0, this.CHAPTER_LIST.length);    //데이터 초기화

        this.$http.post(this.$host+'/UserSearchSemesterList',{
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          if(result.data.resultCode == 0){
          
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              const list = result.data.resultData;
              this.SEMESTER_LIST.splice(0, this.SEMESTER_LIST.length);   //데이터 초기화
              list.forEach(element => {
                  this.SEMESTER_LIST.push(element); 
              });
              
              if(this.SEMESTER_LIST.length > 0){
                //기본값을 선택해야 하는 경우
                if(select){
                  this.SELECT_SEMESTER = this.SEMESTER_LIST[0];
                  this.$session.set("SELECT_SEMESTER", this.SELECT_SEMESTER);
                }
                this.loadbook(select);
              }

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            //오류시
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });
      },        


      //교재 조회
      async loadbook(select){

        if(this.SELECT_SEMESTER == undefined || this.SELECT_SEMESTER == null){
          this.$alert("학기를 선택하세요.");
          return;
        }

          //교재, 챕터 초기화
          if(select){
            this.SELECT_BOOK    = null;
            this.SELECT_CHAPTER = null;
          }
          this.BOOK_LIST.splice(0, this.BOOK_LIST.length);          //데이터 초기화
          this.CHAPTER_LIST.splice(0, this.CHAPTER_LIST.length);    //데이터 초기화

          await this.$http.post(this.$host+'/UserSearchBookList',{
            SEMESTER_NO: this.SELECT_SEMESTER.SEMESTER_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                //console.log(JSON.stringify(result.data.resultData));
                this.BOOK_LIST.splice(0, this.BOOK_LIST.length);   //데이터 초기화
                list.forEach(element => {
                    this.BOOK_LIST.push(element); 
                });
                if(this.BOOK_LIST.length > 0){
                  if(select){
                    this.SELECT_BOOK = this.BOOK_LIST[0];
                    this.$session.set("SELECT_BOOK", this.SELECT_BOOK);
                  }
                  this.loadchapter(select);
                }

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },        
      

      //챕터 조회
      loadchapter(select){
          
        if(this.SELECT_SEMESTER == undefined || this.SELECT_SEMESTER == null){
          this.$alert("학기를 선택하세요.");
          return;
        }
        if(this.SELECT_BOOK == undefined || this.SELECT_BOOK == null){
          this.$alert("교재를 선택하세요.");
          return;
        }

          //교재, 챕터 초기화
          if(select){
            this.SELECT_CHAPTER = null;
          }
          this.CHAPTER_LIST.splice(0, this.CHAPTER_LIST.length);   //챕터 초기화
          this.$http.post(this.$host+'/ChapterList',{
              SEMESTER_NO: this.SELECT_SEMESTER.SEMESTER_NO
            , BOOK_NO: this.SELECT_BOOK.BOOK_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                this.CHAPTER_LIST.splice(0, this.CHAPTER_LIST.length);   
                list.forEach(element => {
                    this.CHAPTER_LIST.push(element); 
                });
                if(this.CHAPTER_LIST.length > 0){
                  if(select){
                    this.SELECT_CHAPTER = this.CHAPTER_LIST[0];
                    this.$session.set("SELECT_CHAPTER", this.SELECT_CHAPTER);
                  }
                  this.loadpage();
                }

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },              



      //조회
      loadpage(){

        if(this.SELECT_SEMESTER == null){
          return;
        }
        if(this.SELECT_BOOK == null){
          return;
        }
        if(this.SELECT_CHAPTER == null){
          return;
        }

          this.loading = true;
          this.$http.post(this.$host+'/DataList',{
              SEMESTER_NO: this.SELECT_SEMESTER.SEMESTER_NO
            , BOOK_NO: this.SELECT_BOOK.BOOK_NO
            , CHAPTER_NO: this.SELECT_CHAPTER.CHAPTER_NO
            , AREA_NO: this.SELECT_AREA_NO
            , USEORDERBY: this.use_orderby
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                this.backList.splice(0, this.backList.length);   //데이터 초기화
                
                this.useCheck     = true;
                this.useAnswer    = true;
                this.answerprint  = false;
                this.noteprint    = false;

                if(list.length < 1){
                  this.$alert("데이터가 없습니다.");
                  return;
                }

                if(result.data.checkCNT > 0){
                  this.useCheck = false;
                }
                if(result.data.answerCNT > 0){
                  this.useAnswer = false;
                }

                //5개가 1줄로 들어가도록 처리해야함.
                var rows = parseInt(list.length/5);
                if(list.length % 5 != 0){
                  rows++;
                }

                for(var i=0;i<rows;i++){
                  var rowitem = {};
                  for(var j=0;j<5;j++){
                    var pos = (i*5)+j;
                    if(pos < list.length){
                      list[pos].SELECT = false;
                      if(j == 0){
                        rowitem.QA = list[pos];
                      }
                      else if(j == 1){
                        rowitem.QB = list[pos];
                      }
                      else if(j == 2){
                        rowitem.QC = list[pos];
                      }
                      else if(j == 3){
                        rowitem.QD = list[pos];
                      }
                      else if(j == 4){
                        rowitem.QE = list[pos];
                      }
                    }
                  }
                  //console.log("rowitem : " + JSON.stringify(rowitem));
                  this.itemList.push(rowitem);
                }

                list.forEach(item=>{
                  this.backList.push(item);
                });

                if(this.showimage){
                  this.changeshowimage();
                }

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },


    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>
