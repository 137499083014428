//배경 div - 컴포넌트의 선택 및 이동을 처리
import DRModel from '../cropeditor/DRModel.js'
import DRComponent from '../cropeditor/DRComponent.js'

class DRBackground {

    constructor(model){

        //배경 div
        this.back_div = document.createElement("div");
        this.back_div.tabIndex = 0;                         //이걸 해줘야 키다운 이벤트가 먹는다.
        this.back_div.model = model;
        this.back_div.FIRST_POS     = {x:0, y:0};
        this.back_div.style.height  = "100%";
        this.back_div.style.width   = "100%";

        //컬러 배경
        this.back_div.style.backgroundColor = "#"+model.BGCOLOR;
        this.back_div.style.backgroundImage = "";
        this.back_div.style.backgroundRepeat = "no-repeat";

        //컴포넌트 div
        var component_div = document.createElement("div");
        component_div.style.height    = this.back_div.model.LAYOUT_WIDTH;
        component_div.style.width     = this.back_div.model.LAYOUT_HEIGHT;
        component_div.style.pointerEvents = "none";    //마우스 이벤트 무시 그래야 이벤트 안탐.
        component_div.style.position  = "absolute";
        this.back_div.appendChild(component_div);
        this.back_div.component_div = component_div;
        this.back_div.component_list = [];

        //사이즈 및 이동 핸들링 div
        var edit_div = document.createElement("div");
        edit_div.style.pointerEvents = "none";    
        edit_div.style.position  = "absolute";

        //사이즈 변경용 6점 표인트 표기
        //왼쪽 위
        var point1 = this.createPointElement();
        point1.style.left      = "0px";
        point1.style.top       = "0px";
        edit_div.appendChild(point1);
        edit_div.point1 = point1;

        //윈쪽 아래
        var point2 = this.createPointElement();
        point2.style.left      = "0px";
        point2.style.top       = "0px";
        edit_div.appendChild(point2);
        edit_div.point2 = point2;

        //가운데 위
        var point3 = this.createPointElement();
        point3.style.left      = "0px";
        point3.style.top       = "0px";
        edit_div.appendChild(point3);
        edit_div.point3 = point3;

        //가운데 아래
        var point4 = this.createPointElement();
        point4.style.left      = "0px";
        point4.style.top       = "0px";
        edit_div.appendChild(point4);
        edit_div.point4 = point4;

        //우측 위
        var point5 = this.createPointElement();
        point5.style.left      = "0px";
        point5.style.top       = "0px";
        edit_div.appendChild(point5);
        edit_div.point5 = point5;

        //우측 아래
        var point6 = this.createPointElement();
        point6.style.left      = "0px";
        point6.style.top       = "0px";
        edit_div.appendChild(point6);
        edit_div.point6 = point6;

        //우측
        var point7 = this.createPointElement();
        point7.style.left      = "0px";
        point7.style.top       = "0px";
        edit_div.appendChild(point7);
        edit_div.point7 = point7;

        //좌측
        var point8 = this.createPointElement();
        point8.style.left      = "0px";
        point8.style.top       = "0px";
        edit_div.appendChild(point8);
        edit_div.point8 = point8;

        //포인트 감추기
        //edit_div.rotate_point.style.visibility = "hidden";
        edit_div.point1.style.visibility = "hidden";
        edit_div.point2.style.visibility = "hidden";
        edit_div.point3.style.visibility = "hidden";
        edit_div.point4.style.visibility = "hidden";
        edit_div.point5.style.visibility = "hidden";
        edit_div.point6.style.visibility = "hidden";
        edit_div.point7.style.visibility = "hidden";
        edit_div.point8.style.visibility = "hidden";

        this.back_div.appendChild(edit_div);
        this.back_div.edit_div = edit_div;


        //드래그 영역을 표시하는 div
        var area_div = document.createElement("div");
        area_div.style.pointerEvents = "none";          //마우스 이벤트 무시 그래야 이벤트 안탐.
        area_div.style.position  = "absolute";
        this.back_div.appendChild(area_div);
        this.back_div.area_div = area_div;

        //이벤트
        this.back_div.onmousedown  = this.onmousedown;
        this.back_div.onmousemove  = this.onmousemove;
        this.back_div.onmouseup    = this.onmouseup;
        //this.back_div.onmouseleave = this.onmouseleave;
        this.back_div.onmouseout   = this.onmouseout;
        //this.back_div.onkeydown    = this.onkeydown;
        

        //함수
        //포인트 span의 위치값 갱신
        this.back_div.updatePointSpanPosition = function(target){

            //target.rotate_point.style.top  = "-50px";
            //target.rotate_point.style.left = (target.LAYOUT_RIGHT-target.LAYOUT_LEFT)/2-5+"px";

            //좌상
            target.point1.style.left      = "-6px";
            target.point1.style.top       = "-6px";
            
            //좌측중간
            target.point2.style.left      = "-6px";
            target.point2.style.top       = target.LAYOUT_BOTTOM-target.LAYOUT_TOP-9+"px";
    
            //상단 중앙
            target.point3.style.left      = ((target.LAYOUT_RIGHT-target.LAYOUT_LEFT)/2)-6+"px";
            target.point3.style.top       = "-6px";
            
            //하단 중앙
            target.point4.style.left      = ((target.LAYOUT_RIGHT-target.LAYOUT_LEFT)/2)-6+"px";
            target.point4.style.top       = target.LAYOUT_BOTTOM-target.LAYOUT_TOP-9+"px";
    
            //우측상단
            target.point5.style.left      = (target.LAYOUT_RIGHT-target.LAYOUT_LEFT-9)+"px";
            target.point5.style.top       = "-6px";
            
            //우측하단
            target.point6.style.left      = target.LAYOUT_RIGHT-target.LAYOUT_LEFT-9+"px";
            target.point6.style.top       = target.LAYOUT_BOTTOM-target.LAYOUT_TOP-9+"px";

            //우측 중앙
            target.point7.style.left      = target.LAYOUT_RIGHT-target.LAYOUT_LEFT-9+"px";
            target.point7.style.top       = (target.LAYOUT_BOTTOM-target.LAYOUT_TOP)/2-9+"px";

            //좌측 하단
            target.point8.style.left      = "-6px";
            target.point8.style.top       = (target.LAYOUT_BOTTOM-target.LAYOUT_TOP)/2-6+"px";

        }

        //point span의 보이기 숨기기
        this.back_div.setVisiblePointSpan = function(targetdiv, flag){
            if(flag){
                targetdiv.style.border = "2px #ff0000 solid"
                //targetdiv.rotate_point.style.visibility = "visible";
                targetdiv.point1.style.visibility = "visible";
                targetdiv.point2.style.visibility = "visible";
                targetdiv.point3.style.visibility = "visible";
                targetdiv.point4.style.visibility = "visible";
                targetdiv.point5.style.visibility = "visible";
                targetdiv.point6.style.visibility = "visible";
                targetdiv.point7.style.visibility = "visible";
                targetdiv.point8.style.visibility = "visible";
              }else{
                targetdiv.style.border = ""
                //targetdiv.rotate_point.style.visibility = "hidden";
                targetdiv.point1.style.visibility = "hidden";
                targetdiv.point2.style.visibility = "hidden";
                targetdiv.point3.style.visibility = "hidden";
                targetdiv.point4.style.visibility = "hidden";
                targetdiv.point5.style.visibility = "hidden";
                targetdiv.point6.style.visibility = "hidden";
                targetdiv.point7.style.visibility = "hidden";
                targetdiv.point8.style.visibility = "hidden";
            }
        }

        //에디트 영역 갱신하기
        this.back_div.updateEditArea = function(minx, miny, maxr, maxb, targetdiv){
            targetdiv.component_list.forEach(el=>{
                if(el.temp_div.selectflag){
                    minx = Math.min(minx, el.temp_div.model.TEMP_POSX);
                    miny = Math.min(miny, el.temp_div.model.TEMP_POSY);
                    maxr = Math.max(maxr, parseInt(el.temp_div.model.TEMP_POSX)+parseInt(el.temp_div.model.TEMP_WIDTH));
                    maxb = Math.max(maxb, parseInt(el.temp_div.model.TEMP_POSY)+parseInt(el.temp_div.model.TEMP_HEIGHT));
                }
            });

            //console.log(minx+"/"+miny+"/"+maxr + " / "+ maxb);

            targetdiv.edit_div.LAYOUT_RIGHT     = maxr;
            targetdiv.edit_div.LAYOUT_BOTTOM    = maxb;
            targetdiv.edit_div.LAYOUT_LEFT      = minx;
            targetdiv.edit_div.LAYOUT_TOP       = miny;
            targetdiv.edit_div.LAYOUT_WIDTH     = maxr - minx;
            targetdiv.edit_div.LAYOUT_HEIGHT    = maxb - miny;
            targetdiv.edit_div.LAYOUT_SCALE     = 1;
            targetdiv.edit_div.LAYOUT_ROTATE    = 0;
            targetdiv.edit_div.style.width     = parseInt(targetdiv.edit_div.LAYOUT_WIDTH)  + "px";
            targetdiv.edit_div.style.height    = parseInt(targetdiv.edit_div.LAYOUT_HEIGHT) + "px";
            targetdiv.edit_div.style.transform = "translate("+targetdiv.edit_div.LAYOUT_LEFT+"px, "+targetdiv.edit_div.LAYOUT_TOP+"px) scale("+targetdiv.edit_div.LAYOUT_SCALE+") rotateZ("+targetdiv.edit_div.LAYOUT_ROTATE+"deg)";
            targetdiv.updatePointSpanPosition(targetdiv.edit_div);                
        }

    }

    //DR컴포넌트 객체 추가
    addComponent(cm){
        this.back_div.component_list.push(cm);
        this.back_div.component_div.appendChild(cm.temp_div);
    }

    getSelectComponent(){
        if(this.back_div.component_list.length > 0){
            return this.back_div.component_list[0];
        }else{
            return null;
        }
    }


    //컴포넌트 맨앞으로 가져오기
    getfront(){
        var slist = [];
        for(var i=0; i < this.back_div.component_list.length; i++){
        
            const cm = this.back_div.component_list[i];
            if(cm.temp_div.selectflag){
                var idx = this.back_div.component_list.indexOf(cm);
                if(idx > -1){
                    slist.push(cm);
                    cm.temp_div.selectflag = false;                     //중복 실행을 방지
                    this.back_div.component_list.splice(idx, 1);        //리스트에서 제거
                    this.back_div.component_list.push(cm);              //맨뒤에 다시 추가
                    //현재값이 리스트의 맨뒤에 추가되면 다음 i값을 뛰어넘게 되어서 -해준다.
                    i--;
                    this.back_div.component_div.removeChild(cm.temp_div);
                    this.back_div.component_div.appendChild(cm.temp_div);                    
                }
            }
        }
        
        //셀렉트값 복구
        slist.forEach(element => {
            element.temp_div.selectflag = true;
        });

        //this.redrawComponent();
    }

    //컴포넌트 맨뒤로 밀기
    pushback(){
        
        var slist = [];
        for(var i = this.back_div.component_list.length-1 ; i >=0 ; i--){
            const cm = this.back_div.component_list[i];
            if(cm.temp_div.selectflag){
                var idx = this.back_div.component_list.indexOf(cm);
                if(idx > -1){
                    slist.push(cm);
                    cm.temp_div.selectflag = false;                     //중복 실행을 방지
                    this.back_div.component_list.splice(idx, 1);          //리스트에서 제거
                    this.back_div.component_list.unshift(cm);             //맨앞에 다시 추가
                    //현재값이 리스트의 맨앞에 추가되면 다음 i값을 뛰어넘게 되어서 +해준다.
                    i++;
                    this.back_div.component_div.removeChild(cm.temp_div);
                    this.back_div.component_div.insertBefore(cm.temp_div, this.back_div.component_div.firstChild);                    
                }
            }
        }

        //셀렉트값 복구
        slist.forEach(element => {
            element.temp_div.selectflag = true;
        });
    }

    //선택된 컴포넌트 삭제
    removeComponent(){
        for(var i = this.back_div.component_list.length-1 ; i >=0 ; i--){
            const cm = this.back_div.component_list[i];
            if(cm.temp_div.selectflag){
                var idx = this.back_div.component_list.indexOf(cm);
                if(idx > -1){
                    this.back_div.setVisiblePointSpan(this.back_div.edit_div, false);
                    this.back_div.component_list.splice(idx, 1);
                    this.back_div.component_div.removeChild(cm.temp_div);
                }
            }
        }

        this.back_div.style.cursor = "default";
        this.back_div.mode = "";
        this.back_div.selectflag = false;
        
    }

    //초기화
    clearBackground(){

        for(var i = this.back_div.component_list.length-1 ; i >=0 ; i--){
            const cm = this.back_div.component_list[i];
            var idx = this.back_div.component_list.indexOf(cm);
            //console.log("indexof : " + idx);
            if(idx > -1){
                this.back_div.setVisiblePointSpan(this.back_div.edit_div, false);
                this.back_div.component_list.splice(idx, 1);
                this.back_div.component_div.removeChild(cm.temp_div);
            }
        }
        this.back_div.style.cursor = "default";
        this.back_div.mode = "";
        this.back_div.selectflag = false;

        //이걸 건들면 문제됨.. 참조변수가 변조됨..
        //this.back_div.model.ISBG           = 1;
        //this.back_div.model.COMPONENT_TYPE = 0;
        this.back_div.model.BGTYPE  = 0;
        this.back_div.model.BGCOLOR = "808080";
        this.back_div.model.IMG = null;
        this.back_div.model.LOCALIMG = null;

        //컬러 배경
        this.back_div.style.backgroundColor = "#808080";
        this.back_div.style.backgroundImage = "";
        this.back_div.style.backgroundRepeat = "no-repeat";
        
        
        //갱신
        /*
        if(this.back_div.model.BGTYPE == 0){
            //컬러 배경
            this.back_div.style.backgroundColor = "#"+this.back_div.model.BGCOLOR;
            this.back_div.style.backgroundImage = "";
            this.back_div.style.backgroundRepeat = "no-repeat";
        }else{
            //이미지 배경
            this.back_div.style.backgroundSize = this.back_div.model.LAYOUT_WIDTH + "px " + this.back_div.model.LAYOUT_HEIGHT + "px";
            this.back_div.style.backgroundImage = "url('"+this.back_div.model.IMG+"')"
            this.back_div.style.backgroundRepeat = "no-repeat";
        }
        */

        
    }

    //배경 업데이트
    updateBackground(newmodel){
        this.back_div.model = newmodel;
        //배경 처리
        if(this.back_div.model.BGTYPE == 0){
            //컬러 배경
            this.back_div.style.backgroundColor = "#"+this.back_div.model.BGCOLOR;
            this.back_div.style.backgroundImage = "";
            this.back_div.style.backgroundRepeat = "no-repeat";
        }else{
            //이미지 배경
            this.back_div.style.backgroundSize = this.back_div.model.LAYOUT_WIDTH + "px " + this.back_div.model.LAYOUT_HEIGHT + "px";
            this.back_div.style.backgroundImage = "url('"+this.back_div.model.IMG+"')"
            this.back_div.style.backgroundRepeat = "no-repeat";
        }
    }
    
    //모든 선택 취소하기(출력전에 에디트 라인 안보이게 해야함)
    selectCancel(){
        this.back_div.component_list.forEach(cm=>{
            cm.setSelected(false);
        });
        //선택된것이 없으면 숨기기
        this.back_div.selectflag = false;
        this.back_div.setVisiblePointSpan(this.back_div.edit_div, false);
        
    }





    //선택된 객체의 model값 변경에 따른 갱신
    updateComponent(){
        this.back_div.component_list.forEach(cm=>{
            if(cm.temp_div.selectflag){
                cm.updateComponent();
                this.back_div.updateEditArea(99999, 99999, 0, 0, this.back_div)    //에디트 영역 갱신
            }
        });
    }


    //포인트 span 생성해서 리턴
    createPointElement(){
        var point_circle = document.createElement("span");
        point_circle.style.position  = "absolute";
        point_circle.style.height    = "12px";
        point_circle.style.width     = "12px";
        point_circle.style.backgroundColor = "#f00000";
        point_circle.style.border = "2px #ff0000 solid"
        point_circle.style.borderRadius = "50%";
        point_circle.style.display = "inline-block";
        point_circle.style.pointerEvents = "none";    //마우스 이벤트 무시 그래야 이벤트 안탐.
        return point_circle;
    }

    onkeydown(ev){

        if(ev.target.selectflag){

            if(ev.keyCode == 38){   //up

                //선택된 컴포넌트가 있다면 이동
                ev.target.component_list.forEach(cm=>{
                    if(cm.temp_div.selectflag){
                        cm.setMove(cm.temp_div, 0, -1);
                    }
                });
                
                //에디트 영역도 이동
                ev.target.edit_div.LAYOUT_LEFT = ev.target.edit_div.LAYOUT_LEFT + 0;
                ev.target.edit_div.LAYOUT_TOP  = ev.target.edit_div.LAYOUT_TOP  + -1;
                ev.target.edit_div.style.transform = "translate("+ev.target.edit_div.LAYOUT_LEFT+"px, "+ev.target.edit_div.LAYOUT_TOP+"px) scale("+ev.target.edit_div.LAYOUT_SCALE+") rotateZ("+ev.target.edit_div.LAYOUT_ROTATE+"deg)";
                
                //이동후 마우스 업하면 인포창 갱신 (부하땜에)
                ev.target.component_list.forEach(cm=>{
                    if(cm.temp_div.selectflag){
                        cm.setMoveEnd(cm.temp_div);
                    }
                });
                return false;   //이걸 해줘야 스크롤이 키이벤트에 의해서 위아래로 안움직임.

            }else if(ev.keyCode == 37){ //left
                //선택된 컴포넌트가 있다면 이동
                ev.target.component_list.forEach(cm=>{
                    if(cm.temp_div.selectflag){
                        cm.setMove(cm.temp_div, -1, 0);
                    }
                });
                //에디트 영역도 이동
                ev.target.edit_div.LAYOUT_LEFT = ev.target.edit_div.LAYOUT_LEFT + -1;
                ev.target.edit_div.LAYOUT_TOP  = ev.target.edit_div.LAYOUT_TOP  + 0;
                ev.target.edit_div.style.transform = "translate("+ev.target.edit_div.LAYOUT_LEFT+"px, "+ev.target.edit_div.LAYOUT_TOP+"px) scale("+ev.target.edit_div.LAYOUT_SCALE+") rotateZ("+ev.target.edit_div.LAYOUT_ROTATE+"deg)";
                
                //이동후 마우스 업하면 인포창 갱신 (부하땜에)
                ev.target.component_list.forEach(cm=>{
                    if(cm.temp_div.selectflag){
                        cm.setMoveEnd(cm.temp_div);
                    }
                });
            }else if(ev.keyCode == 39){ //right
                //선택된 컴포넌트가 있다면 이동
                ev.target.component_list.forEach(cm=>{
                    if(cm.temp_div.selectflag){
                        cm.setMove(cm.temp_div, 1, 0);
                    }
                });
                //에디트 영역도 이동
                ev.target.edit_div.LAYOUT_LEFT = ev.target.edit_div.LAYOUT_LEFT + 1;
                ev.target.edit_div.LAYOUT_TOP  = ev.target.edit_div.LAYOUT_TOP  + 0;
                ev.target.edit_div.style.transform = "translate("+ev.target.edit_div.LAYOUT_LEFT+"px, "+ev.target.edit_div.LAYOUT_TOP+"px) scale("+ev.target.edit_div.LAYOUT_SCALE+") rotateZ("+ev.target.edit_div.LAYOUT_ROTATE+"deg)";
                
                //이동후 마우스 업하면 인포창 갱신 (부하땜에)
                ev.target.component_list.forEach(cm=>{
                    if(cm.temp_div.selectflag){
                        cm.setMoveEnd(cm.temp_div);
                    }
                });
            }else if(ev.keyCode == 40){ //down
                //선택된 컴포넌트가 있다면 이동
                ev.target.component_list.forEach(cm=>{
                    if(cm.temp_div.selectflag){
                        cm.setMove(cm.temp_div, 0, 1);
                    }
                });
                //에디트 영역도 이동
                ev.target.edit_div.LAYOUT_LEFT = ev.target.edit_div.LAYOUT_LEFT + 0;
                ev.target.edit_div.LAYOUT_TOP  = ev.target.edit_div.LAYOUT_TOP  + 1;
                ev.target.edit_div.style.transform = "translate("+ev.target.edit_div.LAYOUT_LEFT+"px, "+ev.target.edit_div.LAYOUT_TOP+"px) scale("+ev.target.edit_div.LAYOUT_SCALE+") rotateZ("+ev.target.edit_div.LAYOUT_ROTATE+"deg)";
                
                //이동후 마우스 업하면 인포창 갱신 (부하땜에)
                ev.target.component_list.forEach(cm=>{
                    if(cm.temp_div.selectflag){
                        cm.setMoveEnd(cm.temp_div);
                    }
                });
                return false;   //이걸 해줘야 스크롤이 키이벤트에 의해서 위아래로 안움직임.
            }

            

        }else{
            //선택된 컴포넌트 없음.
        }
    }
    

    //드래그앤드랍 관련 이벤트
    onmousedown(ev){

        ev.target.downflag = true;
        ev.target.FIRST_POS.x = ev.offsetX;
        ev.target.FIRST_POS.y = ev.offsetY;

        if(ev.target.selectflag){
            //컴포넌트가 선택된 상태라면 에디트 영역의 위치에 따라서 모드 처리
            if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP-10 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+10){
                //좌측 상단
                //console.log("nw");
                ev.target.mode = "nw";
            }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP+ev.target.edit_div.LAYOUT_HEIGHT-10 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+ev.target.edit_div.LAYOUT_HEIGHT+10){
                //좌측 하단
                //console.log("sw");
                ev.target.mode = "sw";
            }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT+(ev.target.edit_div.LAYOUT_WIDTH/2)-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+(ev.target.edit_div.LAYOUT_WIDTH/2)+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP-10 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+10){
                //상단 중앙
                //console.log("n");
                ev.target.mode = "n";
            }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT+(ev.target.edit_div.LAYOUT_WIDTH/2)-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+(ev.target.edit_div.LAYOUT_WIDTH/2)+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP+ev.target.edit_div.LAYOUT_HEIGHT-10 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+ev.target.edit_div.LAYOUT_HEIGHT+10){
                //하단 중앙
                //console.log("s");
                ev.target.mode = "s";
            }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT+ev.target.edit_div.LAYOUT_WIDTH-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+ev.target.edit_div.LAYOUT_WIDTH+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP-10 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+10){
                //우측 상단
                //console.log("ne");
                ev.target.mode = "ne";
            }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT+ev.target.edit_div.LAYOUT_WIDTH-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+ev.target.edit_div.LAYOUT_WIDTH+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP+ev.target.edit_div.LAYOUT_HEIGHT-10 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+ev.target.edit_div.LAYOUT_HEIGHT+10){
                //우측 하단
                //console.log("se");
                ev.target.mode = "se";
            }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT+ev.target.edit_div.LAYOUT_WIDTH-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+ev.target.edit_div.LAYOUT_WIDTH+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP+(ev.target.edit_div.LAYOUT_HEIGHT/2)-10 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+(ev.target.edit_div.LAYOUT_HEIGHT/2)+10){
                //우측 중앙
                //console.log("e");
                ev.target.mode = "e";
            }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+ev.target.edit_div.LAYOUT_WIDTH+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP+(ev.target.edit_div.LAYOUT_HEIGHT/2)-10 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+(ev.target.edit_div.LAYOUT_HEIGHT/2)+10){
                //좌측 중앙
                //console.log("w");
                ev.target.mode = "w";
            /*
            }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT+(ev.target.edit_div.LAYOUT_WIDTH/2)-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+(ev.target.edit_div.LAYOUT_WIDTH/2)+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP-60 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP-40){                    
                //회전
                //console.log("rt");
                ev.target.mode = "rt";
            */
            }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+ev.target.edit_div.LAYOUT_WIDTH && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+ev.target.edit_div.LAYOUT_HEIGHT){
                //console.log("move");
                ev.target.mode = "move";
            }else{
                //console.log("none");
                ev.target.mode = "";
                ev.target.selectflag = false;
                ev.target.area_div.style.visibility  = "visible";
                ev.target.area_div.style.backgroundColor = "#80000080";
            }
        }else{
            ev.target.area_div.style.visibility  = "visible";
            ev.target.area_div.style.backgroundColor = "#80000080";
        }
        //console.log("onmousedown" + ev.offsetX+"/"+ev.offsetY);
    }
    
    onmousemove(ev){
        var origin_size = 0;
        var cur_size = 0;
        var size = 0;
        if(ev.target.downflag){

            if(ev.target.selectflag){

                if(ev.target.mode == "nw"){
                    //좌측상단 좌표방식
                    ev.target.component_list.forEach(el=>{
                        if(el.temp_div.selectflag){
                            el.setSizePos(
                                  el.temp_div
                                , ev.offsetX
                                , ev.offsetY
                                , (ev.target.edit_div.LAYOUT_LEFT- ev.offsetX)+ev.target.edit_div.LAYOUT_WIDTH
                                , (ev.target.edit_div.LAYOUT_TOP - ev.offsetY)+ev.target.edit_div.LAYOUT_HEIGHT
                                , false);
                        }
                    });
                    ev.target.updateEditArea(99999, 99999, 0, 0, ev.target)    //에디트 영역 갱신

                }else if(ev.target.mode == "sw"){
                    //좌측하단 좌표방식
                    ev.target.component_list.forEach(el=>{
                        if(el.temp_div.selectflag){
                            el.setSizePos(
                                  el.temp_div
                                , ev.offsetX
                                , ev.target.edit_div.LAYOUT_TOP
                                , (ev.target.edit_div.LAYOUT_LEFT- ev.offsetX)+ev.target.edit_div.LAYOUT_WIDTH
                                , ev.offsetY-ev.target.edit_div.LAYOUT_TOP
                                , false);
                        }
                    });
                    ev.target.updateEditArea(99999, 99999, 0, 0, ev.target)

                }else if(ev.target.mode == "n"){
                    //상단중앙
                    origin_size = ev.target.edit_div.LAYOUT_BOTTOM-ev.target.FIRST_POS.y;
                    cur_size    = ev.target.edit_div.LAYOUT_BOTTOM-ev.offsetY;
                    size = cur_size/origin_size;
                    ev.target.component_list.forEach(el=>{
                        if(el.temp_div.selectflag){
                            el.setSize(el.temp_div, ev.target.edit_div, size, "n", false);
                        }
                    });
                    ev.target.updateEditArea(99999, 99999, 0, 0, ev.target)

                }else if(ev.target.mode == "s"){
                    //하단 중앙
                    origin_size = ev.target.FIRST_POS.y - ev.target.edit_div.LAYOUT_TOP;
                    cur_size    = ev.offsetY - ev.target.edit_div.LAYOUT_TOP;
                    size = cur_size/origin_size;
                    ev.target.component_list.forEach(el=>{
                        if(el.temp_div.selectflag){
                            el.setSize(el.temp_div, ev.target.edit_div, size, "s", false);
                        }
                    });
                    ev.target.updateEditArea(99999, 999999, 0, 0, ev.target)

                }else if(ev.target.mode == "ne"){
                    //우측상단 좌표방식
                    ev.target.component_list.forEach(el=>{
                    if(el.temp_div.selectflag){
                        el.setSizePos(
                              el.temp_div
                            , ev.target.edit_div.LAYOUT_LEFT 
                            , ev.offsetY
                            , ev.offsetX-ev.target.edit_div.LAYOUT_LEFT
                            , (ev.target.edit_div.LAYOUT_TOP - ev.offsetY)+ev.target.edit_div.LAYOUT_HEIGHT
                            , false);
                        }
                    });
                    ev.target.updateEditArea(99999, 99999, 0, 0, ev.target)    //에디트 영역 갱신

                }else if(ev.target.mode == "se"){
                    //우측하단 좌표방식
                   ev.target.component_list.forEach(el=>{
                    if(el.temp_div.selectflag){
                        el.setSizePos(
                              el.temp_div
                            , ev.target.edit_div.LAYOUT_LEFT 
                            , ev.target.edit_div.LAYOUT_TOP 
                            , ev.offsetX-ev.target.edit_div.LAYOUT_LEFT
                            , ev.offsetY-ev.target.edit_div.LAYOUT_TOP
                            , false);
                    }
                   });
                   ev.target.updateEditArea(99999, 99999, 0, 0, ev.target)    //에디트 영역 갱신
                   

                }else if(ev.target.mode == "e"){
                    //우측 중앙
                    origin_size = ev.target.FIRST_POS.x - ev.target.edit_div.LAYOUT_LEFT;
                    cur_size    = ev.offsetX - ev.target.edit_div.LAYOUT_LEFT;
                    size = cur_size/origin_size;
                    ev.target.component_list.forEach(el=>{
                        if(el.temp_div.selectflag){
                            el.setSize(el.temp_div, ev.target.edit_div, size, "e", false);
                        }
                    });
                    ev.target.updateEditArea(99999, 99999, 0, 0, ev.target)

                }else if(ev.target.mode == "w"){
                    //좌측 중앙
                    origin_size = ev.target.edit_div.LAYOUT_RIGHT - ev.target.FIRST_POS.x;
                    cur_size    = ev.target.edit_div.LAYOUT_RIGHT - ev.offsetX;
                    size = cur_size/origin_size;
                    ev.target.component_list.forEach(el=>{
                        if(el.temp_div.selectflag){
                            el.setSize(el.temp_div, ev.target.edit_div, size, "w", false);
                        }
                    });
                    ev.target.updateEditArea(99999, 999999, 0, 0, ev.target)
                
                /*
                }else if(ev.target.mode == "rt"){
                    var rotate = ev.target.FIRST_POS.x - ev.offsetX;
                    //회전    
                    ev.target.component_list.forEach(el=>{
                        if(el.temp_div.selectflag){
                            el.setRotation(el.temp_div, ev.target.edit_div, rotate, false);
                        }
                    });
                    ev.target.updateEditArea(99999, 999999, 0, 0, ev.target)
                */
                }else if(ev.target.mode == "move"){

                    //선택된 컴포넌트가 있다면 이동
                    ev.target.component_list.forEach(cm=>{
                        if(cm.temp_div.selectflag){
                            cm.setMove(cm.temp_div, ev.movementX, ev.movementY);
                        }
                    });

                    //에디트 영역도 이동
                    ev.target.edit_div.LAYOUT_LEFT = ev.target.edit_div.LAYOUT_LEFT + ev.movementX;
                    ev.target.edit_div.LAYOUT_TOP  = ev.target.edit_div.LAYOUT_TOP  + ev.movementY;
                    ev.target.edit_div.style.transform = "translate("+ev.target.edit_div.LAYOUT_LEFT+"px, "+ev.target.edit_div.LAYOUT_TOP+"px) scale("+ev.target.edit_div.LAYOUT_SCALE+") rotateZ("+ev.target.edit_div.LAYOUT_ROTATE+"deg)";
                }

               
            }else{

                //마우스 드래그시 드래그 영역 표시
                var x = Math.min(ev.target.FIRST_POS.x, ev.offsetX);
                var y = Math.min(ev.target.FIRST_POS.y, ev.offsetY);
                var w = Math.abs(ev.target.FIRST_POS.x - ev.offsetX);
                var h = Math.abs(ev.target.FIRST_POS.y - ev.offsetY);
                ev.target.area_div.style.width     = w + "px";
                ev.target.area_div.style.height    = h  + "px";
                ev.target.area_div.style.left      = x   + "px";
                ev.target.area_div.style.top       = y   + "px";
            }

        }else{

            //특정 컴포넌트가 선택되어 에디트 영역이 활성화 된 상태 - 마우스 커서 변경 필요
            if(ev.target.selectflag){
                if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP-10 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+10){
                    //좌측 상단
                    ev.target.style.cursor = "nw-resize";
                  }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP+ev.target.edit_div.LAYOUT_HEIGHT-10 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+ev.target.edit_div.LAYOUT_HEIGHT+10){
                    //좌측 하단
                    ev.target.style.cursor = "sw-resize";
                  }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT+(ev.target.edit_div.LAYOUT_WIDTH/2)-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+(ev.target.edit_div.LAYOUT_WIDTH/2)+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP-10 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+10){
                    //상단 중앙
                    ev.target.style.cursor = "n-resize";
                  }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT+(ev.target.edit_div.LAYOUT_WIDTH/2)-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+(ev.target.edit_div.LAYOUT_WIDTH/2)+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP+ev.target.edit_div.LAYOUT_HEIGHT-10 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+ev.target.edit_div.LAYOUT_HEIGHT+10){
                    //하단 중앙
                    ev.target.style.cursor = "s-resize";
                  }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT+ev.target.edit_div.LAYOUT_WIDTH-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+ev.target.edit_div.LAYOUT_WIDTH+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP-10 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+10){
                    //우측 상단
                    ev.target.style.cursor = "ne-resize";
                  }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT+ev.target.edit_div.LAYOUT_WIDTH-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+ev.target.edit_div.LAYOUT_WIDTH+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP+ev.target.edit_div.LAYOUT_HEIGHT-10 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+ev.target.edit_div.LAYOUT_HEIGHT+10){
                    //우측 하단
                    ev.target.style.cursor = "se-resize";
                  }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT+ev.target.edit_div.LAYOUT_WIDTH-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+ev.target.edit_div.LAYOUT_WIDTH+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP+(ev.target.edit_div.LAYOUT_HEIGHT/2)-10 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+(ev.target.edit_div.LAYOUT_HEIGHT/2)+10){
                    //우측 중앙
                    ev.target.style.cursor = "e-resize";
                  }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+ev.target.edit_div.LAYOUT_WIDTH+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP+(ev.target.edit_div.LAYOUT_HEIGHT/2)-10 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+(ev.target.edit_div.LAYOUT_HEIGHT/2)+10){
                    //좌측 중앙
                    ev.target.style.cursor = "w-resize";
                  //}else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT+(ev.target.edit_div.LAYOUT_WIDTH/2)-10 && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+(ev.target.edit_div.LAYOUT_WIDTH/2)+10 && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP-60 && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP-40){                    
                    //회전
                    //ev.target.style.cursor = "col-resize";
                  }else if(ev.offsetX >= ev.target.edit_div.LAYOUT_LEFT && ev.offsetX <= ev.target.edit_div.LAYOUT_LEFT+ev.target.edit_div.LAYOUT_WIDTH && ev.offsetY >= ev.target.edit_div.LAYOUT_TOP && ev.offsetY <= ev.target.edit_div.LAYOUT_TOP+ev.target.edit_div.LAYOUT_HEIGHT){
                    //이동
                    ev.target.style.cursor = "all-scroll";
                  }else{
                    ev.target.style.cursor = "default";
                  }
          
            }

        }

    }
    
    onmouseup(ev){
        //console.log("onmouseup ");

        ev.target.downflag = false;
        var minx = 99999;
        var miny = 99999;
        var maxr = 0;
        var maxb = 0;
        var origin_size = 0;
        var cur_size = 0;
        var size = 0;

        if(ev.target.selectflag){   //이미 선택된 컴포넌트가 있는 경우 - 모드에 따른 스케일 변경 또는 이동

            if(ev.target.mode == "nw"){
                ev.target.component_list.forEach(el=>{
                    if(el.temp_div.selectflag){
                        el.setSizePos(
                              el.temp_div
                            , ev.offsetX
                            , ev.offsetY
                            , (ev.target.edit_div.LAYOUT_LEFT- ev.offsetX)+ev.target.edit_div.LAYOUT_WIDTH
                            , (ev.target.edit_div.LAYOUT_TOP - ev.offsetY)+ev.target.edit_div.LAYOUT_HEIGHT
                            , true);
                    }
                });
                ev.target.updateEditArea(99999, 99999, 0, 0, ev.target)    //에디트 영역 사이즈 갱신
                ev.target.style.cursor = "default";
                ev.target.mode = "";

            }else if(ev.target.mode == "sw"){
                ev.target.component_list.forEach(el=>{
                    if(el.temp_div.selectflag){
                        el.setSizePos(
                              el.temp_div
                            , ev.offsetX
                            , ev.target.edit_div.LAYOUT_TOP
                            , (ev.target.edit_div.LAYOUT_LEFT- ev.offsetX)+ev.target.edit_div.LAYOUT_WIDTH
                            , ev.offsetY-ev.target.edit_div.LAYOUT_TOP
                            , true);
                    }
                });
                ev.target.updateEditArea(99999, 99999, 0, 0, ev.target)    //에디트 영역 사이즈 갱신
                ev.target.style.cursor = "default";
                ev.target.mode = "";

            }else if(ev.target.mode == "n"){
                origin_size = ev.target.edit_div.LAYOUT_BOTTOM-ev.target.FIRST_POS.y;
                cur_size    = ev.target.edit_div.LAYOUT_BOTTOM-ev.offsetY;
                size = cur_size/origin_size;
                ev.target.component_list.forEach(el=>{
                    if(el.temp_div.selectflag){
                        el.setSize(el.temp_div, ev.target.edit_div, size, "n", true);
                    }
                });
                ev.target.updateEditArea(99999, 999999, 0, 0, ev.target)
                ev.target.style.cursor = "default";
                ev.target.mode = "";

            }else if(ev.target.mode == "s"){
                origin_size = ev.target.FIRST_POS.y - ev.target.edit_div.LAYOUT_TOP;
                cur_size    = ev.offsetY - ev.target.edit_div.LAYOUT_TOP;
                size = cur_size/origin_size;
                ev.target.component_list.forEach(el=>{
                    if(el.temp_div.selectflag){
                        el.setSize(el.temp_div, ev.target.edit_div, size, "s", true);
                    }
                });
                ev.target.updateEditArea(99999, 999999, 0, 0, ev.target)
                ev.target.style.cursor = "default";
                ev.target.mode = "";

            }else if(ev.target.mode == "e"){
                origin_size = ev.target.FIRST_POS.x - ev.target.edit_div.LAYOUT_LEFT;
                cur_size    = ev.offsetX - ev.target.edit_div.LAYOUT_LEFT;
                size = cur_size/origin_size;
                ev.target.component_list.forEach(el=>{
                    if(el.temp_div.selectflag){
                        el.setSize(el.temp_div, ev.target.edit_div, size, "e", true);
                    }
                });
                ev.target.updateEditArea(99999, 99999, 0, 0, ev.target)
                ev.target.style.cursor = "default";
                ev.target.mode = "";

            }else if(ev.target.mode == "w"){
                origin_size = ev.target.edit_div.LAYOUT_RIGHT - ev.target.FIRST_POS.x;
                cur_size    = ev.target.edit_div.LAYOUT_RIGHT - ev.offsetX;
                size = cur_size/origin_size;
                ev.target.component_list.forEach(el=>{
                    if(el.temp_div.selectflag){
                        el.setSize(el.temp_div, ev.target.edit_div, size, "w", true);
                    }
                });
                ev.target.updateEditArea(99999, 999999, 0, 0, ev.target)
                ev.target.style.cursor = "default";
                ev.target.mode = "";

            }else if(ev.target.mode == "ne"){
                ev.target.component_list.forEach(el=>{
                    if(el.temp_div.selectflag){
                        el.setSizePos(
                              el.temp_div
                            , ev.target.edit_div.LAYOUT_LEFT 
                            , ev.offsetY
                            , ev.offsetX-ev.target.edit_div.LAYOUT_LEFT
                            , (ev.target.edit_div.LAYOUT_TOP - ev.offsetY)+ev.target.edit_div.LAYOUT_HEIGHT
                            , true);
                    }
                });
                ev.target.updateEditArea(99999, 99999, ev.offsetX, 0, ev.target)    //에디트 영역 갱신
                ev.target.style.cursor = "default";
                ev.target.mode = "";

            }else if(ev.target.mode == "se"){
                //좌표방식
                ev.target.component_list.forEach(el=>{
                    if(el.temp_div.selectflag){
                        el.setSizePos(
                            el.temp_div
                        , ev.target.edit_div.LAYOUT_LEFT 
                        , ev.target.edit_div.LAYOUT_TOP 
                        , ev.offsetX-ev.target.edit_div.LAYOUT_LEFT
                        , ev.offsetY-ev.target.edit_div.LAYOUT_TOP
                        , true);
                    }
                });
                ev.target.updateEditArea(99999, 99999, 0, 0, ev.target)    //에디트 영역 갱신
                ev.target.style.cursor = "default";
                ev.target.mode = "";
            }else if(ev.target.mode == "move"){


                //이동후 마우스 업하면 인포창 갱신 (부하땜에)
                ev.target.component_list.forEach(cm=>{
                    if(cm.temp_div.selectflag){
                        //픽스
                        cm.setMoveEnd(cm.temp_div);
                    }
                });
                

            /*
            }else if(ev.target.mode == "rt"){                
                //회전
                var rotate = ev.target.FIRST_POS.x - ev.offsetX;
                ev.target.component_list.forEach(el=>{
                    if(el.temp_div.selectflag){
                        el.setRotation(el.temp_div, ev.target.edit_div, rotate, true);
                    }
                });
            */
            }else{
                //빈곳을 클릭한 경우
                ev.target.component_list.forEach(cm=>{
                    if(cm.temp_div.selectflag){
                        cm.setSelected(false);
                    }
                });
                ev.target.style.cursor = "default";
                ev.target.mode = "";
                ev.target.selectflag = false;
                ev.target.setVisiblePointSpan(ev.target.edit_div, false);

            }
            
        }else{
            
            

            //드래그를 해서 마우스를 놓으면

            //1. 드래그 표시 숨기기
            ev.target.area_div.style.visibility  = "hidden";
            ev.target.area_div.style.width     = "0px";
            ev.target.area_div.style.height    = "0px";
            ev.target.area_div.style.left      = "0px";
            ev.target.area_div.style.top       = "0px";

            var flag = false;
            //에디트용 edit_div의 사이즈 계산
            var tempsub = Math.abs(ev.target.FIRST_POS.x - ev.offsetX);
            if(tempsub > 10){

                //체킹용 렉트 좌표
                var rectx = Math.min(ev.target.FIRST_POS.x, ev.offsetX);
                var recty = Math.min(ev.target.FIRST_POS.y, ev.offsetY);
                var rectw = Math.abs(ev.target.FIRST_POS.x - ev.offsetX);
                var recth = Math.abs(ev.target.FIRST_POS.y - ev.offsetY);


                //기존에 생성된 컴포넌트가 있으면 제거 - gsmath
                if(ev.target.component_list.length > 0){

                    for(var k = ev.target.component_list.length-1 ; k >=0 ; k--){
                        const cm = ev.target.component_list[k];
                        var idx = ev.target.component_list.indexOf(cm);
                        //console.log("delete indexof : " + idx);
                        if(idx > -1){
                            ev.target.setVisiblePointSpan(ev.target.edit_div, false);
                            ev.target.component_list.splice(idx, 1);
                            ev.target.component_div.removeChild(cm.temp_div);
                        }
                    }
                    ev.target.style.cursor = "default";
                    ev.target.mode = "";
                    ev.target.selectflag = false;

                    ev.target.model.BGTYPE  = 0;
                    ev.target.model.BGCOLOR = "00000000";
                    ev.target.model.IMG = null;
                    ev.target.model.LOCALIMG = null;
            
                    //컬러 배경
                    ev.target.style.backgroundColor = "#00000000";
                    ev.target.style.backgroundImage = "";
                    ev.target.style.backgroundRepeat = "no-repeat";                   
                }

                //2. 드래그한 사이즈대로 컴포넌트 추가
                //console.log("create component...");
                const cm = new DRModel();
                cm.BGCOLOR = "00000000";
                cm.LAYOUT_WIDTH = rectw;
                cm.LAYOUT_HEIGHT = recth;
                cm.LAYOUT_POSX = rectx;
                cm.LAYOUT_POSY = recty;
                const cp = new DRComponent(cm);
                ev.target.component_list.push(cp);
                ev.target.component_div.appendChild(cp.temp_div);
                

                for(var i=ev.target.component_list.length-1;i>=0;i--){
                    var el = ev.target.component_list[i];
                    if(rectx <= el.temp_div.model.LAYOUT_POSX && rectx + rectw >= el.temp_div.model.LAYOUT_POSX + el.temp_div.model.LAYOUT_WIDTH 
                    && recty <= el.temp_div.model.LAYOUT_POSY && recty + recth >= el.temp_div.model.LAYOUT_POSY + el.temp_div.model.LAYOUT_HEIGHT)
                    {
                        //선택 마킹
                        flag = true;
                        minx = Math.min(minx, el.temp_div.model.LAYOUT_POSX);
                        miny = Math.min(miny, el.temp_div.model.LAYOUT_POSY);
                        maxr = Math.max(maxr, el.temp_div.model.LAYOUT_POSX+el.temp_div.model.LAYOUT_WIDTH);
                        maxb = Math.max(maxb, el.temp_div.model.LAYOUT_POSY+el.temp_div.model.LAYOUT_HEIGHT);
                        el.setSelected(true);
                    }else{
                        el.setSelected(false);
                    }
                }

                if(flag){   
                    //선택된 컴포넌트가 있으면 edit_div를 동적으로 생성. - 선택 영역 표시
                    ev.target.selectflag = true;
                    ev.target.edit_div.LAYOUT_RIGHT     = maxr;
                    ev.target.edit_div.LAYOUT_BOTTOM    = maxb;
                    ev.target.edit_div.LAYOUT_LEFT      = minx;
                    ev.target.edit_div.LAYOUT_TOP       = miny;
                    ev.target.edit_div.LAYOUT_WIDTH     = maxr - minx;
                    ev.target.edit_div.LAYOUT_HEIGHT    = maxb - miny;
                    ev.target.edit_div.LAYOUT_SCALE     = 1;
                    ev.target.edit_div.LAYOUT_ROTATE    = 0;
                    ev.target.edit_div.style.width     = ev.target.edit_div.LAYOUT_WIDTH + "px";
                    ev.target.edit_div.style.height    = ev.target.edit_div.LAYOUT_HEIGHT + "px";
                    ev.target.edit_div.style.transform = "translate("+ev.target.edit_div.LAYOUT_LEFT+"px, "+ev.target.edit_div.LAYOUT_TOP+"px) scale("+ev.target.edit_div.LAYOUT_SCALE+") rotateZ("+ev.target.edit_div.LAYOUT_ROTATE+"deg)";
                    ev.target.updatePointSpanPosition(ev.target.edit_div);
                    ev.target.setVisiblePointSpan(ev.target.edit_div, true);
                }else{
                    ev.target.selectflag = false;
                    ev.target.setVisiblePointSpan(ev.target.edit_div, false);
                }

            }else{

                //싱글 선택 - 해당 좌표에 해당하는 컴포넌트 1개를 찾아서 선택
                //console.log("select single");
                
                //일단 모두 선택 취소
                ev.target.component_list.forEach(el=>{
                    el.setSelected(false);
                });


                for(var j=ev.target.component_list.length-1;j>=0;j--){
                    var el2 = ev.target.component_list[j];
                    if(ev.offsetX >= el2.temp_div.model.LAYOUT_POSX && ev.offsetX <= el2.temp_div.model.LAYOUT_POSX + el2.temp_div.model.LAYOUT_WIDTH 
                    && ev.offsetY >= el2.temp_div.model.LAYOUT_POSY && ev.offsetY <= el2.temp_div.model.LAYOUT_POSY + el2.temp_div.model.LAYOUT_HEIGHT)
                    {
                        //선택 마킹
                        flag = true;
                        minx = Math.min(minx, el2.temp_div.model.LAYOUT_POSX);
                        miny = Math.min(miny, el2.temp_div.model.LAYOUT_POSY);
                        maxr = Math.max(maxr, el2.temp_div.model.LAYOUT_POSX+el2.temp_div.model.LAYOUT_WIDTH);
                        maxb = Math.max(maxb, el2.temp_div.model.LAYOUT_POSY+el2.temp_div.model.LAYOUT_HEIGHT);
                        el2.setSelected(true);

                        //위치 백업 - 인포창 갱신 부하 줄이려고
                        el2.setMoveStart(el2.temp_div);

                        //에디트 div 사이즈 동기화
                        ev.target.selectflag = true;
                        ev.target.edit_div.LAYOUT_RIGHT     = maxr;
                        ev.target.edit_div.LAYOUT_BOTTOM    = maxb;
                        ev.target.edit_div.LAYOUT_LEFT      = minx;
                        ev.target.edit_div.LAYOUT_TOP       = miny;
                        ev.target.edit_div.LAYOUT_WIDTH     = maxr - minx;
                        ev.target.edit_div.LAYOUT_HEIGHT    = maxb - miny;
                        ev.target.edit_div.LAYOUT_SCALE     = 1;
                        ev.target.edit_div.LAYOUT_ROTATE    = el2.temp_div.model.ROTATE;
                        ev.target.edit_div.ORIGIN_X         = el2.temp_div.model.ORIGIN_X;
                        ev.target.edit_div.ORIGIN_Y         = el2.temp_div.model.ORIGIN_Y;
                        ev.target.edit_div.style.width     = ev.target.edit_div.LAYOUT_WIDTH  + "px";
                        ev.target.edit_div.style.height    = ev.target.edit_div.LAYOUT_HEIGHT + "px";
                        ev.target.edit_div.style.transform = "translate("+ev.target.edit_div.LAYOUT_LEFT+"px, "+ev.target.edit_div.LAYOUT_TOP+"px) scale("+ev.target.edit_div.LAYOUT_SCALE+") rotateZ("+ev.target.edit_div.LAYOUT_ROTATE+"deg)";
                        ev.target.edit_div.style.transformOrigin = ev.target.edit_div.ORIGIN_X+"px " + ev.target.edit_div.ORIGIN_Y + "px";
                        ev.target.updatePointSpanPosition(ev.target.edit_div);
                        ev.target.setVisiblePointSpan(ev.target.edit_div, true);
                        
                        //하나 선택되면 브레이크 걸어라 안그럼 같은좌표 다 선택되서 분리안됨.
                        break;
                    }
                }



                if(!flag){

                    //선택된것이 없으면 숨기기
                    ev.target.selectflag = false;
                    ev.target.setVisiblePointSpan(ev.target.edit_div, false);

                }
                
            }

        }


    }

    onmouseleave(ev){
        //console.log("onmouseleave");
        ev.target.downflag = false;
        ev.target.area_div.style.visibility  = "hidden";
        ev.target.area_div.style.width     = "0px";
        ev.target.area_div.style.height    = "0px";
        ev.target.area_div.style.left      = "0px";
        ev.target.area_div.style.top       = "0px";
    }

    onmouseout(ev){
        ////console.log("onmouseout");
        ////console.log("down flag : " + ev.target.downflag);
        ////console.log("selectflag  : " + ev.target.selectflag);
        ////console.log("mode  : " + ev.target.mode);
        if(ev.target.downflag){
            //마우스를 누르고 있는 상태
            this.onmouseup(ev); //영역을 벗어나도 이동 및 스케일이 자연스럽게 연결되도록 처리
        }else{
            //컴포넌트 선택 후 우측의 인포창으로 가는 경우
            ev.target.downflag = false;
            ev.target.area_div.style.visibility  = "hidden";
            ev.target.area_div.style.width     = "0px";
            ev.target.area_div.style.height    = "0px";
            ev.target.area_div.style.left      = "0px";
            ev.target.area_div.style.top       = "0px";
        }
    }
  

}

export default DRBackground;