<!-- 이미지 영역 충돌 테스트, 사이즈 테스트 -->
<!-- 이미지 업로드 테스트 -->
<template>
  <v-container>
    <v-row align="center">
      <v-col align="center">
        <v-file-input label="파일선택" v-model="selectFile" @change="loadfile"></v-file-input>
        <v-btn @click="prev">충돌</v-btn>
        <v-btn @click="next">업로드</v-btn>
        <v-btn @click="zoomout">다운로드</v-btn>
        <v-btn @click="zoomin">확대</v-btn>
        <v-btn @click="savecrop">크롭</v-btn>
      </v-col>
    </v-row>

    <!-- pdf 스크롤이 v-app-bar 내부에선 문제되어 position을 absolute로 놔야함. -->
    <v-row align="center" dense>
      <v-col cols="12" class="pa-0 ma-0" align="center">
        
        <div id="draw_div" style="background:#ff8080;position:absolute;overflow:auto;height:800px;width:800px"></div>
        
      </v-col>
    </v-row>
    

  </v-container>
</template>

<script>
import fetch from 'node-fetch';
import sizeOf from 'image-size';
//import probe from 'probe-image-size';
import intersectRect from 'intersect-rect';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
export default {
  
  data(){
    return{
        pdf_div:null
      , selectFile: null  //select file
    }
  },

  mounted() {

    //PDF CROP EDITOR 설정
    this.pdf_div    = document.getElementById("draw_div");

  },

  methods: {
    
    loadfile(){
      
    },

    prev(){
      
      //충돌 테스트
      var temp_div = document.createElement("div");
      temp_div.style.width  = 200+"px";
      temp_div.style.height = 100+"px";
      temp_div.style.position = "absolute";
      temp_div.style.transform = "translate(0px, 0px)";
      temp_div.style.backgroundColor = "#80000080";
      
      this.pdf_div.appendChild(temp_div);


      var temp_div2 = document.createElement("div");
      temp_div2.style.width = 100+"px";
      temp_div2.style.height = 100+"px";
      temp_div2.style.position = "absolute";
      temp_div2.style.transform = "translate(150px, 0px)";
      temp_div2.style.backgroundColor = "#00800080";
      this.pdf_div.appendChild(temp_div2);

      var rect1 = temp_div.getBoundingClientRect();
      var rect2 = temp_div2.getBoundingClientRect();
      //console.log("rect1 : " + JSON.stringify(rect1));
      if(intersectRect(rect1, rect2)){
        //console.log("1111");
      }else{
        //console.log("2222");
      }



    },

    next(){
      
      //firebase 업로드 - firebase storage rule 변경해야함. auth없이 읽기 쓰기 가능하게
      //문제점 : all uers public으로 올릴수 없네.. 공용 URL을 만들 수 없다는 말임. => gcp에서 퍼블릭 설정으로 해결
      const testref2 = ref(this.$firestorage, "test2/test1112.jpg");
      const metadata = {
        contentType: 'image/jpeg',
      };      
      uploadBytes(testref2, this.selectFile, metadata)
      // eslint-disable-next-line no-unused-vars
      .then((snapshot) => {
        //console.log('Uploaded a blob or file! ' + JSON.stringify(snapshot));
        //console.log('full path ' + snapshot.metadata.fullPath);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        //console.log("upload error : " + error);
      });      

    },

    zoomout(){
      //다운로드
      const testref2 = ref(this.$firestorage, "test2/test1111.jpg");
      getDownloadURL(testref2)
      // eslint-disable-next-line no-unused-vars
      .then(async (imageurl) => {
        //console.log("download imageurl : " + imageurl);

        fetch('https://storage.googleapis.com/crows-d7512.appspot.com/test2/test1111.jpg').then((response)=>{
          // eslint-disable-next-line no-unused-vars
          const info = sizeOf(response.blob());
          //console.log(info);
        });

        //cors 오류남.
        /*
        requestImageSize(url)
        .then(size=>//console.log("size:"+size))
        .catch(err => console.error(err));
        */

        /* 파일 다운로드
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
        };
        xhr.open('GET', url);
        xhr.send();

        // Or inserted into an <img> element
        const img = document.getElementById('myimg');
        img.setAttribute('src', url);
        */
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        //console.log(error);
      });      
    },

    zoomin() {
        fetch('https://storage.googleapis.com/crows-d7512.appspot.com/test2/test1111.jpg').then((response)=>{
          // eslint-disable-next-line no-unused-vars
          const info = sizeOf(response.blob());
          //console.log(info);
        });

    },

    savecrop(){
      //사이즈 체크
      var temp_div = document.createElement("div");
      temp_div.style.width  = 200+"px";
      //temp_div.style.height = 100+"px";
      temp_div.style.position = "absolute";
      temp_div.style.transform = "translate(100px, 100px)";
      temp_div.style.backgroundColor = "#80000080";

      var temp_img = document.createElement("img");
      temp_img.src = 'https://storage.googleapis.com/crows-d7512.appspot.com/test2/test1111.jpg';
      temp_img.style.width = "100%";
      //console.log("1 : "+temp_img.width + " / " + temp_img.height);
      
      temp_div.appendChild(temp_img);
      this.pdf_div.appendChild(temp_div);

      //console.log("2 : "+temp_img.width + " / " + temp_img.height);

    },

    
  }
};
</script>

<style>
#the-canvas {
  border: 1px solid black;
  direction: ltr;
}
</style>