<template>

  <v-container fluid >

    <v-row align="center" justify="start" class="mt-5">
        <v-col cols="12" align="center">
          <label class="menunamestyle">응시하실 시험을 선택하세요</label>
        </v-col>
    </v-row>

    <v-row align="center" justify="center">
        <v-col cols="12" md="4" align="center">
          <v-select id="mySelect"
            v-model="SELECT_YEAR" :items="SEARCHYEAR" label="년도" @change="loadexam" no-data-text="년도정보가 없습니다."
            dense hide-details outlined item-text="CODE_NAME" item-value="CODE_VALUE" class="basicselectstyle" 
            />
        </v-col>
    </v-row>

    <v-row align="center" justify="center">
        <v-col cols="12" md="4" align="center">
          <v-select v-model="SELECT_SCHOOLYEAR" :items="SEARCHSCHOOLYEAR" label="학년" 
            class="basicselectstyle"  @change="loadexam" no-data-text="학년정보가 없습니다."
            dense hide-details outlined item-text="CODE_NAME" item-value="CODE_VALUE"/>
        </v-col>
    </v-row>

    <v-row align="center" justify="center">
        <v-col cols="12" md="4" align="center">
          <v-select v-model="SELECT_EXAM" :items="SEARCHEXAM" label="시험" 
            class="basicselectstyle" @change="loadsubject" no-data-text="시험정보가 없습니다."
            dense hide-details outlined item-text="EXAM_NAME" item-value="EXAM_NO"/>
        </v-col>
    </v-row>

    <v-row align="center" justify="center" v-if="SELECT_EXAMNAME.length > 0">
      <v-col cols="12" md="4" align="center">
        <v-text-field
          label="공통과목"
          v-model="SELECT_EXAMNAME" disabled outlined dense hide-details class="popupiputstyle"
          />
      </v-col>
    </v-row>

    <v-row align="center" justify="center" v-if="SEARCHSUBJECT.length > 0">
      <v-col cols="12" md="4" align="center">
        <v-select v-model="SELECT_SUBJECT" :items="SEARCHSUBJECT" label="선택과목" 
          class="basicselectstyle" no-data-text="과목정보가 없습니다."
          dense hide-details outlined item-text="SUBJECT_NAME" item-value="SUBJECT_NO"/>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12" md="4" align="center">
        <v-btn width="94%" class="search_btn_freesize" @click="next">정답 입력하기</v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {

    data: () => ({
      
      SELECT_YEAR:null,
      SELECT_SCHOOLYEAR:null,
      SELECT_EXAM:null,
      SELECT_EXAMNAME:"",
      SELECT_SUBJECT:null,

      SEARCHYEAR: [],
      SEARCHSCHOOLYEAR: [],
      SEARCHEXAM: [],
      SEARCHSUBJECT: [],
      
      grade:0,

    }),

    mounted(){

      this.grade = this.$session.get("GRADE");
      if(this.grade > 0){
        this.$alert("사용할 수 없는 페이지입니다.").then(()=>{
          this.$router.push("Welcome");
        });
        return;
      }

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("StudentLogin");
      }

    },

    create(){
      
    },

    methods: {

      async initpage(){

        //공통코드 조회
        await this.loadcomcode("YEAR", "ASC", this.SEARCHYEAR);
        this.SELECT_YEAR = this.SEARCHYEAR[0].CODE_VALUE;

        await this.loadcomcode("SCHOOLYEAR", "DESC", this.SEARCHSCHOOLYEAR);
        this.SELECT_SCHOOLYEAR = this.SEARCHSCHOOLYEAR[0].CODE_VALUE;

        await this.loadexam();
        this.SELECT_EXAM = this.SEARCHEXAM[0].EXAM_NO;

        if(this.SEARCHEXAM[0].CNT2 > 0){
          await this.loadsubject();
          this.SELECT_SUBJECT = this.SEARCHSUBJECT[0].SUBJECT_NO;
        }

      },      

      //정답입력하러가기
      async next(){

        if(this.SELECT_EXAM == null || this.SELECT_EXAM == undefined){
          this.$alert("시험을 선택하세요.");
          return;
        }

        //선택된 시험값 가져오기
        const exam_object = await this.getSelectObject();
        if(exam_object == null || exam_object == undefined){
          this.$alert("시험을 선택하세요.");
          return;
        }

        //이미 응시한 시험인지 체크 필요


        if(exam_object.CNT2 < 1){
          this.$router.push({path:"StudentInput", query:{exam:this.SELECT_EXAM}});
        }else{
          if(this.SELECT_SUBJECT == null || this.SELECT_SUBJECT == undefined){
            this.$alert("선택과목을 선택하세요.");
            return;
          }
          this.$router.push({path:"StudentInput", query:{exam:this.SELECT_EXAM, subject:this.SELECT_SUBJECT}});
        }

      },

      //공통코드 로드 : codetype, DESC, 추가할 어레이 객체
      async loadcomcode(codetype, sorttype, arraylist){

        await this.$http.post(this.$host+'/getCodeList',{
              CODE_TYPE: codetype
            , sort_type: sorttype
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
            const list = result.data.resultData;
            arraylist.splice(0, arraylist.length);   //데이터 초기화
            list.forEach(element => {
                //console.log("element : " + JSON.stringify(element));
                arraylist.push(element); 
            });

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.loading = false;
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.loading = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });

      },           



      async loadexam(){

        //초기화
        this.SELECT_EXAM = null;
        this.SELECT_EXAMNAME = "";
        this.SELECT_SUBJECT = null;
        this.SEARCHSUBJECT.splice(0, this.SEARCHSUBJECT.length);   //데이터 초기화

          await this.$http.post(this.$host+'/getEnableExamList',{
                SELECT_YEAR: this.SELECT_YEAR
              , SELECT_SCHOOLYEAR: this.SELECT_SCHOOLYEAR
          },{headers: { firetoken:this.$session.get("FIRETOKEN") }})
          .then((result)=>{

            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                this.SEARCHEXAM.splice(0, this.SEARCHEXAM.length);   //데이터 초기화
                list.forEach(element => {
                    this.SEARCHEXAM.push(element); 
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },      


      getSelectObject(){
        for(var i=0; i < this.SEARCHEXAM.length ; i++){
          const temp = this.SEARCHEXAM[i];
          if(temp.EXAM_NO == this.SELECT_EXAM){
            return temp;
          }
        }
      },


      async loadsubject(){
          
          //선택된 시험값 가져오기
          const exam_object = await this.getSelectObject();
          if(exam_object == null || exam_object == undefined){
            return;
          }

          //선택된 시험의 공통과목 이름 넣기
          this.SELECT_EXAMNAME = exam_object.EXAM_NAME;

          //선택된 시험은 선택과목이 없음.
          this.SEARCHSUBJECT.splice(0, this.SEARCHSUBJECT.length);   //데이터 초기화
          if(Number(exam_object.CNT2) < 1){
            return;
          }

          await this.$http.post(this.$host+'/getSelectSubject',{
                EXAM_NO: this.SELECT_EXAM
          },{headers: { firetoken:this.$session.get("FIRETOKEN") }})
          .then((result)=>{

            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                list.forEach(element => {
                    this.SEARCHSUBJECT.push(element); 
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },      

    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>