import Vue from 'vue'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify'

import App from './App.vue'
import PdfCanvas from './components/PdfCanvas.vue'
import Pdflist from './components/Pdflist.vue'
import Pdfview from './components/Pdfview.vue'

import Welcome from './components/Welcome.vue'
import Login from './components/Login.vue'
import Join from './components/Join.vue'
import AccntList from './components/AccntList.vue'
import BannerList from './components/BannerList.vue'
import AreaList from './components/AreaList.vue'
import AreaInfoList from './components/AreaInfoList.vue'

import IntroBoardList from './components/IntroBoardList.vue'
import IntroBoardDetail from './components/IntroBoardDetail.vue'
import Introboard from './components/Introboard.vue'

import BoardList from './components/BoardList.vue'
import BoardDetail from './components/BoardDetail.vue'
import BookList from './components/BookList.vue'
import QueList from './components/QueList.vue'
import DescList from './components/DescList.vue'
import CheckList from './components/CheckList.vue'
import Freeboard from './components/Freeboard.vue'
import Mypage from './components/Mypage.vue'
import Permission from './components/Permission.vue'
import UserPermission from './components/UserPermission.vue'
import Redtest from './components/Redtest.vue'
import NoteList from './components/NoteList.vue'
import NoteDetail from './components/NoteDetail.vue'
import DataList from './components/DataList.vue'
import ShowPage from './components/ShowPage.vue'
import ShowPage_Backup2 from './components/ShowPage_Backup2.vue'
import NotFound from './components/NotFound.vue'

//추가개발
import StudentJoin from './components/StudentJoin.vue'
import StudentLogin from './components/StudentLogin.vue'
import StudentList from './components/StudentList.vue'
import ExamList from './components/ExamList.vue'
import ExamSubjectList from './components/ExamSubjectList.vue'
import ExamGrade from './components/ExamGrade.vue'
import StudentExam from './components/StudentExam.vue'
import StudentInput from './components/StudentInput.vue'
import StudentInfo from './components/StudentInfo.vue'
import StudentScore from './components/StudentScore.vue'
import AdminScore from './components/AdminScore.vue'
import AdminScoreInput from './components/AdminScoreInput.vue'
import AdminScoreUpdate from './components/AdminScoreUpdate.vue'
import AdminSelectExam from './components/AdminSelectExam.vue'
import ScorePage from './components/ScorePage.vue'
import ScorePage_Preview from './components/ScorePage_Preview.vue'
import ScoreInfo from './components/ScoreInfo.vue'


//커스텀 컴포넌트 (다이얼로그)
import DialogArea from './components/DialogArea.vue'
import DialogBook from './components/DialogBook.vue'
import DialogBoard from './components/DialogBoard.vue'
import DialogShowPage from './components/DialogShowPage.vue'

//커스텀 컴포넌트 등록
Vue.component("areadialog",      DialogArea)            
Vue.component("boarddialog",     DialogBoard)            
Vue.component("bookdialog",      DialogBook)            
Vue.component("showdialog",      DialogShowPage)            

//파이어베이스
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth"; //전화번호 인증을 위해
import { getStorage } from "firebase/storage";

//라이브러리
import axios from 'axios'               //http library
import querystring from 'query-string'   //axios랑 함께 써야하는 라이브러리 (post 파라메터 전송)
import VueSessionStorage from 'vue-sessionstorage'  //저장소

//이미지 뷰어
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'


import VueSimpleAlert from "vue-simple-alert";
//https://sweetalert2.github.io/#configuration 옵션은 sweetalert2 참고
Vue.use(VueSimpleAlert,{reverseButtons: true, confirmButtonColor:'#690505', allowEscapeKey:true, allowEnterKey:true, confirmButtonText:"확인", cancelButtonText:"취소"});  //엔터를 처도 팝업창 클로즈 안함.(sweetalert2 라이브러리 옵션 참고)

const firebaseConfig = {
  apiKey: "AIzaSyBhbBXHNjbap8unk2ojMou_sTfNSgVNCgA",
  authDomain: "gsmath-fdb22.firebaseapp.com",
  projectId: "gsmath-fdb22",
  storageBucket: "gsmath-fdb22.appspot.com",
  messagingSenderId: "948792018069",
  appId: "1:948792018069:web:c4ce44bbc289118c06c79c",
  measurementId: "G-5SW106CF61"
};

const app     = initializeApp(firebaseConfig);
const auth    = getAuth(app);
auth.languageCode = "kr";
const storage = getStorage(app, "gs://gsmath-fdb22.appspot.com");

Vue.prototype.$firebase   = app;
Vue.prototype.$fireauth   = auth;
Vue.prototype.$firestorage = storage;

//전역변수
Vue.prototype.$http = axios
Vue.prototype.$qstr = querystring
//Vue.prototype.$host = "http://192.168.0.39:8080";   //테스트 서버
Vue.prototype.$host = "https://gsmath-sddavc3qla-du.a.run.app";   //실서버

//선택된 문제값들 전역으로 담기
Vue.prototype.$selectlist = [];

Vue.config.productionTip = false



//플러그인 사용
Vue.use(VueRouter)

// 라우터 푸시 메서드 래핑
/* 중복에러 안나게 하는 코드
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => {
		if (err.name !== 'NavigationDuplicated') throw err;
	});
};
export default router;
*/

Vue.use(VueSessionStorage)
Vue.use(VueViewer)

//라우터 인스턴스 생성
const router = new VueRouter({
  mode:'history', //history모드 중요함. 이걸로 해야함.
  routes : [
      {path : "/",              component:Welcome}
    , {path : "",               component:Welcome}
    , {path : "/Welcome",       component:Welcome}
    , {path : "/PdfCanvas",     component:PdfCanvas}
    , {path : "/PdfCanvas/:semester_no/:book_no/:chapter_no",     component:PdfCanvas}
    , {path : "/Pdfview",       component:Pdfview}
    , {path : "/Pdflist",       component:Pdflist}
    , {path : "/Login",         component:Login}
    , {path : "/Join",          component:Join}
    
    , {path : "/AccntList",     component:AccntList}
    , {path : "/BannerList",    component:BannerList}
    , {path : "/AreaList",      component:AreaList}
    , {path : "/AreaInfoList",  component:AreaInfoList}

    , {path : "/BoardList",           component:BoardList}
    , {path : "/BoardList/:page",     component:BoardList}
    
    , {path : "/IntroBoardList",          component:IntroBoardList}

    , {path : "/IntroBoardDetail",        component:IntroBoardDetail}
    , {path : "/IntroBoardDetail/:code",  component:IntroBoardDetail}
    , {path : "/Introboard",              component:Introboard}

    , {path : "/BoardDetail",       component:BoardDetail}
    , {path : "/BoardDetail/:code", component:BoardDetail}

    , {path : "/BookList",      component:BookList}
    , {path : "/QueList",       component:QueList}
    , {path : "/DescList",      component:DescList}
    , {path : "/CheckList",     component:CheckList}

    , {path : "/Freeboard",     component:Freeboard}
    , {path : "/Mypage",        component:Mypage}
    , {path : "/Permission",    component:Permission}
    , {path : "/Permission/:accnt_no/:accnt",     component:Permission}
    , {path : "/UserPermission",component:UserPermission}
    , {path : "/Redtest",       component:Redtest}
    , {path : "/NoteList",      component:NoteList}

    , {path : "/NoteDetail",          component:NoteDetail}
    , {path : "/NoteDetail/:note_no/:semester_no/:book_no/:chapter_no/:area_no/:title/:water",    component:NoteDetail}

    , {path : "/DataList",      component:DataList}
    
    , {path : "/ShowPage",      component:ShowPage, name:'ShowPage'}
    , {path : "/ShowPage_Backup2", component:ShowPage_Backup2, name:'ShowPage_Backup2'}
    
    , {path : "/StudentJoin",      component:StudentJoin, name:'StudentJoin'}
    , {path : "/StudentLogin",     component:StudentLogin, name:'StudentLogin'}
    , {path : "/StudentList",      component:StudentList, name:'StudentList'}
    , {path : "/ExamList",         component:ExamList, name:'ExamList'}
    , {path : "/ExamSubjectList",  component:ExamSubjectList, name:'ExamSubjectList'}
    , {path : "/ExamSubjectList/:code",  component:ExamSubjectList, name:'ExamSubjectList'}
    , {path : "/ExamGrade",        component:ExamGrade, name:'ExamGrade'}
    , {path : "/StudentExam",        component:StudentExam, name:'StudentExam'}
    , {path : "/StudentInput",        component:StudentInput, name:'StudentInput'}
    , {path : "/StudentInput/:exam/:subject",  component:StudentInput, name:'StudentInput'}
    , {path : "/StudentInfo",        component:StudentInfo, name:'StudentInfo'}
    , {path : "/StudentScore",        component:StudentScore, name:'StudentScore'}
    
    , {path : "/AdminScore",          component:AdminScore, name:'AdminScore'}
    , {path : "/AdminScore/:area_no/:year/:grade/:exam",          component:AdminScore, name:'AdminScore'}
    , {path : "/AdminScoreUpdate",     component:AdminScoreUpdate, name:'AdminScoreUpdate'}
    , {path : "/AdminScoreUpdate/:take/:exam",     component:AdminScoreUpdate, name:'AdminScoreUpdate'}
    , {path : "/AdminScoreInput",     component:AdminScoreInput, name:'AdminScoreInput'}
    , {path : "/AdminScoreInput/:area_no/:area_name/:exam/:subject",     component:AdminScoreInput, name:'AdminScoreInput'}
    , {path : "/AdminSelectExam",     component:AdminSelectExam, name:'AdminSelectExam'}
    , {path : "/AdminSelectExam/:area_no/:year/:grade/:exam",     component:AdminSelectExam, name:'AdminSelectExam'}

    
    , {path : "/ScorePage",                     component:ScorePage, name:'ScorePage'}
    , {path : "/ScoreInfo",                     component:ScoreInfo, name:'ScoreInfo'}
    , {path : "/ScorePage_Preview",             component:ScorePage_Preview, name:'ScorePage_Preview'}
    , {path : "/ScorePage_Preview/:takeno",     component:ScorePage_Preview, name:'ScorePage_Preview'}
    

    , {path : "*",              redirect:"/404"}
    , {path : "/404",           component:NotFound}

  ]
})


Vue.prototype.$EventBus = new Vue();


new Vue({
    vuetify
  , render: h => h(App)
  , router,
  mounted(){
    //마운트 되면 로딩바 제거
    //document.getElementById('loading').style.display = 'none';
  }
}).$mount('#app')


