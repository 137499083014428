<!-- 업체 선택용 공통 팝업 -->
<template>

  <v-container fluid class="ma-0 pa-0">
    
    <!-- 공지확인 팝업 -->
    <v-row no-gutters dense align="center" justify="start">

      <v-dialog persistent scrollable overlay-color="#00000040" v-model="dialog_notice" width="800px">
        <v-card class="pa-10">
                <v-row dense>
                  <v-col md="12" sm="12" xs="12">
                    <span class="dialog_title">{{ editedItem.TITLE }}</span>
                  </v-col>
                </v-row>

                <v-card-text class="ma-0 pl-5 pr-5 pt-3">
                    <v-container>
                        <v-row>
                        <v-col md="12" sm="12" xs="12">
                            <div v-html="editedItem.DESC">
                            </div>
                        </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
          <v-card-actions>
            <v-row justify="center">
            <v-btn color="primary" @click="dialog_notice = false">닫기</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>

    <v-row no-gutters dense align="center" justify="start">
        <v-col cols="9">
            <v-text-field 
                v-model="linkvalue" placeholder="게시글선택" dense disabled
                outlined height="48"
                color="#808080" hide-details class="popupiputstyle pr-1"
                />
        </v-col>
        <v-col cols="3">
            <v-btn class="dialogsearch_btn_freesize" height="48" elevation="0" outlined large @click="showpopup_com" >선택</v-btn>
        </v-col>
    </v-row>   

    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="dialog_com" >
        <v-card class="pa-1 ma-0">
          <v-card-title>
            <v-row justify="space-between">
              <v-col align="center" cols="12" md="6" sm="6" xs="6">
                <span class="dialog_title">게시글 선택</span>
              </v-col>
              <v-col align="center" cols="12" md="6" sm="6" xs="6">
                <v-btn class="dialog_btn mr-5" @click="close_com">닫기</v-btn>
                <v-btn class="dialog_btn mr-5" @click="clear_com">선택취소</v-btn>
                <v-btn class="dialog_btn"  @click="save_com">선택</v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row justify="end">
                <v-col cols="4">
                  <v-text-field 
                    dense single-line clearable
                    @keyup.enter="search_run_com" 
                    v-model="search_keyword_com" 
                    placeholder="검색어를 입력하세요"
                    outlined height="43"
                    color="#808080"
                    class="ml-10"
                    hide-details
                    />
                </v-col>
                <v-col cols="1">
                  <v-btn class="search_btn_freesize" elevation="0" outlined large @click="search_run_com">검색</v-btn>
                </v-col>
                <v-col cols="1">
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" sm="12" xs="12">
                  <v-data-table
                    :headers="headers_com"
                    :items="itemList_com"
                    v-model="select_com"
                    no-data-text="데이터가 없습니다."
                    hide-default-footer
                    class="datatablestyle ml-10 mr-10"
                    :items-per-page="pagerow_com"
                    item-key="BOARD_NO"
                    show-select single-select
                    height="500px"
                    >
                    <template v-slot:header.BOARD_NO>
                    <a :class="sort_name_com=='BOARD_NO'?'cheader_sortselect':'cheader_sort'" @click="customsort('BOARD_NO')">번호</a>
                    </template>
                    <template v-slot:header.TITLE>
                    <a :class="sort_name_com=='TITLE'?'cheader_sortselect':'cheader_sort'" @click="customsort('TITLE')">제목</a>
                    </template>
                    <template v-slot:header.UP_DATE>
                    <a :class="sort_name_com=='UP_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('UP_DATE')">수정일시</a>
                    </template>
                    <template v-slot:header.REG_DATE>
                    <a :class="sort_name_com=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">등록일시</a>
                    </template>
                    <template v-slot:item.TITLE="{ item }">  
                    <a @click="edit_item(item)" >{{item.TITLE}}</a>
                    </template>

                    <template v-slot:item.REPPLE="{ item }">  
                    <label style="text-align:center">{{ item.REPPLE == 1 ? '사용' : '미사용' }}</label>
                    </template>     
                    <template v-slot:item.FIX="{ item }">  
                    <label style="text-align:center">{{ item.FIX == 1 ? '사용' : '미사용' }}</label>
                    </template>     
                    <template v-slot:item.READPM="{ item }">  
                    <label style="text-align:center">{{ item.READPM == 1 ? '전체' : '사용자' }}</label>
                    </template>     
                    
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" class="d-flex justify-center">
                  <v-btn v-if="bottompage_com > 10" icon  small @click="prev()"><v-icon dark>mdi-chevron-left</v-icon></v-btn>
                  <span v-for="n in subpage_com" :key="n.id">
                      <span v-if="page_com == bottompage_com-1+n">
                          <v-btn color="red" icon small @click="loadpage_com(bottompage_com-1+n)">{{bottompage_com-1+n}}</v-btn>
                      </span>
                      <span v-else>
                          <v-btn icon small @click="loadpage_com(bottompage_com-1+n)">{{bottompage_com-1+n}}</v-btn>
                      </span>
                  </span>
                  <v-btn v-if="subpage_com == 10" icon small @click="next()" ><v-icon dark>mdi-chevron-right</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center">
              <v-col align="center" cols="12" md="6" sm="6" xs="6">
                <v-btn class="dialog_btn mr-5" @click="close_com">닫기</v-btn>
                <v-btn class="dialog_btn mr-5" @click="clear_com">선택취소</v-btn>
                <v-btn class="dialog_btn"  @click="save_com">선택</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>     
    </v-row>
    
  </v-container>

</template>

<script>

export default {
        
    //전달받을 프로퍼티 정의, 타입을 정의해도 되고 안해도 되고
    props:{
      linkvalue: String,
    },

    data: ()=> ({
        editorText: {},
        editorOptions : {
                        minHeight: '200px',
                        language: 'en-US',
                        useCommandShortcut: true,
                        useDefaultHTMLSanitizer: true,
                        usageStatistics: false,
                        hideModeSwitch: false,
                        toolbarItems: [
                            'heading',
                            'bold',
                            'italic',
                            'strike',
                            'divider',
                            //'hr',
                            'quote',
                            'divider',
                            'ul',
                            'ol',
                            'task',
                            //'indent',
                            //'outdent',
                            'divider',
                            //'table',
                            'image',
                            'link',
                            'divider',
                            //'code',
                            //'codeblock'
                        ]
                        },
                
        dialog_notice : false,
        editedItem: {
        },
        dialog_com : false,
        headers_com: [
            {text: '번호', value: 'BOARD_NO', sortable: false, align:'center', width:'80px'},
            {text: '제목', value: 'TITLE', sortable: false, align:'center', width:'320px'},
            {text: '댓글사용', value: 'REPPLE', sortable: false, align:'center', width:'100px'},
            {text: '읽기권한', value: 'READPM', sortable: false, align:'center', width:'100px'},
            {text: '상단고정', value: 'FIX', sortable: false, align:'center', width:'100px'},
            {text: '등록일시', value: 'REG_DATE', sortable: false, align:'center' , width:'200px'},
        ],
        itemList_com: [],
        select_com:[],
        search_keyword_com : '',
        totalcnt_com : 0,      //전체 아이템 개수
        page_com : 0,
        maxpage_com : 0,       //전체 페이지 개수
        startpage_com: 1,      //시작 인덱스
        bottompage_com : 1,    //하단 페이징 번호의 시작점을 계산하기 위한 변수
        subpage_com : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
        pagerow_com : 10,      //한 페이지에 보여줄 row수
        
        //추가 변수들
        TYPELIST: [
            {name:'전체',     value:0}
            , {name:'관리자',   value:1}
            , {name:'관람객',   value:2}
        ],
    
        sort_name_com: 'REG_DATE',
        sort_type_com: true,    //true : DESC, false: ASC


    }),

    updated(){
        if(this.dialog_com){
        if(!this.load_flag && this.itemList_com.length == 0 && this.search_keyword_com.length == 0){
            this.load_flag = true;
            this.loadpage_com(1);
        }
        }else{
        this.load_flag = false;
        }
    },

    methods: {

        customsort_com(sortname){
            this.sort_name_com = sortname;
            this.sort_type_com = !this.sort_type_com;
            this.loadpage_com(this.page);
        },

        prev_com(){
            this.bottompage_com = this.bottompage_com - 10 >= 1 ? this.bottompage_com - 10 : 1;
            this.subpage_com = this.maxpage_com - this.bottompage_com >= 10 ? 10 : this.maxpage_com - this.bottompage_com+1;
            var temp_page = this.bottompage_com + this.subpage_com - 1; //이전을 누르면 가장 끝 번호 선택
            this.loadpage_com(temp_page);
        },
        next_com(){
            this.bottompage_com = this.bottompage_com + 10 <= this.maxpage_com ? this.bottompage_com + 10 : this.maxpage_com;
            this.subpage_com = this.maxpage_com - this.bottompage_com >= 10 ? 10 : this.maxpage_com - this.bottompage_com+1;
            var temp_page = this.bottompage_com;
            this.loadpage_com(temp_page);
        },

        makepaging_com(){
            var div1 = this.totalcnt_com / this.pagerow_com; 
            var div2 = this.totalcnt_com % this.pagerow_com;
            if(div2 == 0){  
                this.maxpage_com = parseInt(div1);    
            }else{
                this.maxpage_com = parseInt(div1+1);
            }
            if(this.bottompage_com == 1){
            this.subpage_com = this.maxpage_com - this.bottompage_com >= 10 ? 10 : this.maxpage_com - this.bottompage_com+1;
            }
        },

        search_run_com(){
            this.loadpage_com(1);
        },

        loadpage_com(p){

            this.page_com = p;
            this.$http.post(this.$host+'/BoardList',{
                  search_keyword: this.search_keyword_com == null || this.search_keyword_com == undefined ?  '' : escape(this.search_keyword_com)
                , sort_name:this.sort_name_com
                , sort_type: this.sort_type_com==true ? "DESC" : "ASC" 
                , page: this.page_com
                , pagerow: this.pagerow_com
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
            .then((result)=>{
                if(result.data.resultCode == 0){

                    //토큰 저장
                    if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                        this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                    }
                    if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                        this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                    }

                    this.totalcnt_com = result.data.totalcnt;
                    this.makepaging_com();
                    const list = result.data.resultData;
                    this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
                    list.forEach(element => {
                        this.itemList_com.push(element); 
                    });

                }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
                this.$EventBus.$emit('clearsession');

                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                
            });

        },


      //아이템 수정
      edit_item(item){

        //내용 가져오기
        this.loading = true;
        this.$http.post(this.$host+'/BoardDetail',{
              BOARD_NO: item.BOARD_NO
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{

          this.dialog_notice = false;
          
          if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            //this.editedItem = item;
            this.editedItem = Object.assign({}, item);

            //이미 선택된 아이템에 상세만 교체
            this.editedItem.DESC  = result.data.resultData[0].DESC;
           
            this.editorText = this.editedItem.DESC;
            this.dialog_notice = true;
            this.$refs.toastuiEditor.invoke('setHtml', this.editedItem.DESC); //팝업이 떠서 에디터가 생성된 후 읽을 수 있다.

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');

          }

        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
        });

      },            

            //다이얼로그 닫기
            close_com () {
                this.dialog_com = false
                this.select_com = [];
            },

            //선택 초기화
            clear_com () {
                this.$emit('callback', {TITLE:'', LINK_VALUE:''})
                this.dialog_com = false
                this.select_com = [];
            },

            //추가 저장
            save_com () {

                if(this.select_com.length > 0){
                    this.$emit('callback', this.select_com[0])
                    //콜백 호출 후 닫아야함.
                    this.dialog_com = false
                    this.select_com = [];
                }else{
                    this.$alert('행사를 선택하세요')
                    return;
                }
                
            },      

            showpopup_com(){
                this.dialog_com = true;
            },


        }

    }
</script>

<style scoped>


</style>