
<template>

  <v-container fluid >
    

    <!-- 이미지 크롭 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040"
      v-model="crop_dialog_land" max-width="500px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">이미지 편집</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <div style="width:100%;height:500px;">
            <vueCropper
                ref="cropper_land"
                :img="imgSrc"
                :outputType="option.outputType"
                :fixed="option.fixed"
                :fixedNumber="option.fixedNumber"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :fixedBox="option.fixedBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :centerBox="option.centerBox"
                :high="option.high"
                :infoTrue="option.infoTrue"
                :maxImgSize="option.maxImgSize"
                :enlarge="option.enlarge"
                :mode="option.mode"
                :limitMinSize="option.limitMinSize"
                />
              </div>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="crop_dialog_land = false">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="cropimg">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>


    <!-- 추가/수정 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#000000ff" v-model="dialog" max-width="550px">
        
        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">계정</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field disabled
                    v-model="editedItem.ACCNT" dense single-line
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">이름</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.NAME" dense single-line
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">전화번호</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.TEL" dense single-line 
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">지점</label>
                </v-col>
                <v-col cols="9">
                  <areadialog :areaname="editedItem.AREA_NAME" @callback="comareacb"/>  
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">회원등급</label>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="editedItem.GRADE" :items="GRADELIST" outlined
                    item-text="name" item-value="value" class="dialogselectstyle"
                    hide-details elevation="0" dense height="48px"
                    /> 
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">빅데이터 권한</label>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="editedItem.DATAGRADE" :items="DATAGRADELIST" outlined
                    item-text="name" item-value="value" class="dialogselectstyle"
                    hide-details elevation="0" dense height="48px"
                    />
                </v-col>
              </v-row>

            <v-row>
              <v-col align="center">
                <label class="total_label">권장 워터마크 사이즈 (100x100)</label>
              </v-col>
            </v-row>

            <v-row align="center" justify="center" style="background:#eaeaea;height:140px">
              <v-col align="center" align-self="center" style="height:120px">
                <viewer
                  class="viewer"
                  ref="viewer" 
                  :options="options"
                  >
                  <img 
                    height="100"
                    :src="previewImg"
                    class="image"
                    >
                </viewer>
              </v-col>
            </v-row>                

            <v-row>
              <v-col md="12" sm="12" xs="12">
              </v-col>
            </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">워터마크</label>
                </v-col>
                <v-col cols="9">
                  <v-file-input 
                    outlined dense height="48" class="popupiputstyle"
                    show-size hide-details v-model="uploadFile" 
                    accept="image/png, image/jpeg"
                    @change="changefile"
                    >
                    </v-file-input>
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">중복로그인 횟수</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.DUPLECNT" dense single-line type="number"
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">회원상태</label>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="editedItem.STATE" :items="STATELIST" outlined
                    item-text="name" item-value="value" class="dialogselectstyle"
                    hide-details elevation="0" dense height="48px"
                    />
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>

    <v-row align="center" justify="start" class="mt-5">

      <v-col cols="12" md="3" sm="12" xs="12">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>

      <v-col cols="6"  md="3" sm="6" xs="6" class="pa-0">
        <v-text-field 
          dense single-line clearable
          @keyup.enter="search_run" 
          v-model="search_keyword" 
          outlined height="43"
          color="#808080"
          hide-details
          placeholder="검색어를 입력하세요"
          class="basicsearchstyle"
          />
      </v-col>
      <v-col cols="3" md="1" sm="3" xs="3" class="pa-0" align="center">
        <v-btn class="search_btn_freesize" elevation="0" outlined large @click="search_run">검색</v-btn>
      </v-col>
      
  </v-row>

  <v-row>
    <v-col cols="12">
      <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
    </v-col>
  </v-row>

    
  <v-row align="start" dense>
    <v-col > 
      <!-- 테이블 -->
      <v-data-table
        class="datatablestyle"
        :headers="headers"
        :items="itemList"
        v-model="selected"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        :items-per-page="pagerow"
        :search="search"  
        item-key="IDX"
        :loading="loading"
        >
        <template v-slot:header.ACCNT>
          <a :class="sort_name=='ACCNT'?'cheader_sortselect':'cheader_sort'" @click="customsort('ACCNT')">계정</a>
        </template>
        <template v-slot:header.NAME>
          <a :class="sort_name=='NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('NAME')">이름</a>
        </template>

        <template v-slot:header.GRADE>
          <a :class="sort_name=='GRADE'?'cheader_sortselect':'cheader_sort'" @click="customsort('GRADE')">회원등급</a>
        </template>
        <template v-slot:header.STATE>
          <a :class="sort_name=='STATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('STATE')">상태</a>
        </template>
        <template v-slot:header.AREA_NAME>
          <a :class="sort_name=='AREA_NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('AREA_NAME')">지점</a>
        </template>
        <template v-slot:header.DUPLECNT>
          <a :class="sort_name=='DUPLECNT'?'cheader_sortselect':'cheader_sort'" @click="customsort('DUPLECNT')">중복</a>
        </template>
        <template v-slot:header.LOGIN_DATE>
          <a :class="sort_name=='LOGIN_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('LOGIN_DATE')">최근로그인</a>
        </template>

        <template v-slot:item.GRADE="{ item }">  
          <label style="text-align:center">{{ item.GRADE == 99 ? '관리자' : item.GRADE == 10 ? '정회원' : '준회원' }}</label>
        </template>     
        <template v-slot:item.DATAGRADE="{ item }">  
          <label style="text-align:center">{{ item.DATAGRADE == 10 ? '전체' : '지점' }}</label>
        </template>     
        <template v-slot:item.STATE="{ item }">  
          <label style="text-align:center">{{ item.STATE == 1 ? '활성' : '탈퇴' }}</label>
        </template>     
        <template v-slot:item.permission="{ item }">  
            <v-icon class="mr-2" small @click="permission_item(item)">mdi-pencil</v-icon>
        </template>     
        <template v-slot:item.actions="{ item }">  
            <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
        </template>     
      </v-data-table>
    </v-col>
  </v-row>

  <!-- 하단 페이징 영역 -->
  <v-row align="start">
    <v-col cols="12" md="1"/>
    <v-col cols="12" md="2">
      <v-text-field
        v-model="search"
        append-icon="search"
        single-line
        hide-details
        outlined height="43"
        color="#808080"
        dense
        placeholder="페이지 내에서 검색"
        class="basicsearchstyle"
        />
    </v-col>
    <v-col cols="12" md="6" class="d-flex justify-center">
      <v-btn v-if="bottompage > 10" icon  small @click="prev()"><v-icon dark>mdi-chevron-left</v-icon></v-btn>
      <span v-for="n in subpage" :key="n.id">
          <span v-if="page == bottompage-1+n">
              <v-btn color="red" class="numberstyle" icon small @click="loadpage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
          <span v-else>
              <v-btn icon small class="numberstyle2" @click="loadpage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
      </span>
      <v-btn v-if="subpage == 10" icon small @click="next()" ><v-icon dark>mdi-chevron-right</v-icon></v-btn>
    </v-col>
    <v-col cols="12" md="2">
      <v-select
        v-model="pagerow"
        :items="pagerow_combo"
        item-text="NAME"      
        item-value="VALUE"
        min-width="150px"
        outlined height="43"
        color="#808080"
        dense
        hide-details
        placeholder="목록개수"
        class="basicsearchstyle"
        ></v-select>
    </v-col>
    <v-col cols="12" md="1"/>
  </v-row>

  
  

  </v-container>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import { ref, uploadBytes, deleteObject } from "firebase/storage";

export default {
    components:{
      VueCropper
    },

    data: () => ({

      //파일 업로드
      uploadFile : null,
      crop_dialog_land: false,
      imgSrc:'',
      ratio: 16/9,
      previewImg:null,
      options: {
        focus:false,
      },      

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: false,          //크롭박스 비율 고정
        fixedNumber: [1,1],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:100,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:100,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 5000,
        limitMinSize: [100, 100]
      },            

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          ACCNT_NO:'',
          ACCNT:'',
          REFIMAGE:'',
          GRADE:1,
          STATE:1,
          DATAGRADE:1
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {text: '계정', value: 'ACCNT', sortable: false, align:'center', class:"cheader_normal"},
        {text: '이름', value: 'NAME', sortable: false, align:'center', class:"cheader_normal"},
        {text: '전화번호', value: 'TEL', sortable: false, align:'center', class:"cheader_normal" },
        {text: '지점', value: 'AREA_NAME', sortable: false, align:'center', class:"cheader_normal" },
        {text: '등급', value: 'GRADE', sortable: false, align:'center', class:"cheader_normal" },
        {text: '빅데이터', value: 'DATAGRADE', sortable: false, align:'center', class:"cheader_normal" },
        {text: '상태', value: 'STATE', sortable: false, align:'center', class:"cheader_normal" },
        {text: '중복', value: 'DUPLECNT', sortable: false, align:'center', class:"cheader_normal" },        
        {text: '최근로그인', value: 'LOGIN_DATE', sortable: false, align:'center', class:"cheader_normal" },
        {text: '권한', value: 'permission', sortable: false, align:'center', class:"cheader_normal"},
        {text: '수정', value: 'actions', sortable: false, align:'center', class:"cheader_normal"}
      ],
      itemList: [],

      //검색 파라메터
      sort_name: 'LOGIN_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
         
      //추가 변수들
      dialog_pw : false,
      GRADELIST: [
          {name:'준회원',       value:1}
        , {name:'정회원',       value:10}
        , {name:'관리자',       value:99}
      ],
      DATAGRADELIST: [
          {name:'지점',       value:1}
        , {name:'전체',       value:10}
      ],
      STATELIST: [
          {name:'탈퇴',       value:0}
        , {name:'회원',       value:1}
      ],

    }),

    mounted(){

      if(this.$session.get("GRADE") < 90){
        this.$alert("사용할 수 없는 등급의 페이지입니다.").then(()=>{
          this.$router.push("Welcome");
        });
        return;
      }

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {

      //이미지 크롭시
      cropimg(){
        //이미지 크롭 
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog_land = false;
          this.uploadFile = blob; //크롭된 이미지의 blob데이터
          this.$refs.cropper_land.getCropData(data=>{
            this.previewImg = data; //이미지뷰 미리보기용
          });
        });
      },

      //배너 이미지 선택시
      changefile(){

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.crop_dialog_land = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
            //this.$refs.cropper_land.replace(event.target.result);
          }
          reader.readAsDataURL(this.uploadFile);  //비동기로 파일을 읽어서
        }

      },

      //대표장소 설정
      comareacb(val1){
        //console.log(val1);
        if(val1.AREA_NAME.length > 0){
          this.editedItem.AREA_NAME = val1.AREA_NAME;
          this.editedItem.AREA_NO   = val1.AREA_NO;
        }else{
          this.editedItem.AREA_NAME = '';
          this.editedItem.AREA_NO   = null;
        }
      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      prev(){
          this.bottompage = this.bottompage - 10 >= 1 ? this.bottompage - 10 : 1;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage + this.subpage - 1; //이전을 누르면 가장 끝 번호 선택
          this.loadpage(temp_page);
      },
      next(){
          this.bottompage = this.bottompage + 10 <= this.maxpage ? this.bottompage + 10 : this.maxpage;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage;
          this.loadpage(temp_page);
      },

      makepaging(){
        var div1 = this.totalcnt / this.pagerow; 
        var div2 = this.totalcnt % this.pagerow;
        if(div2 == 0){  
            this.maxpage = parseInt(div1);    
        }else{
            this.maxpage = parseInt(div1+1);
        }
        if(this.bottompage == 1){
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
        }
      },

      async loadpage(p){

          this.loading = true;
          this.page = p;

          //const idtoken = await this.$fireauth.currentUser.getIdToken(true);
          //console.log("accnt idtoken : " + idtoken);

          this.$http.post(this.$host+'/AcntList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
          },{headers: { firetoken: this.$session.get("FIRETOKEN") }})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }

                this.totalcnt = result.data.totalcnt;
                this.makepaging();
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });

                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadpage(1);
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        this.dialog_pw  = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      //추가 저장
      save () {

          if(this.editedItem.GRADE == undefined || this.editedItem.GRADE.length < 1){
            this.$alert("등급을 선택하세요");
            return;
          } 

          if(this.editedItem.NAME.length < 1){
              this.$alert("이름을 입력하세요");
              return;
          }
          if(this.editedItem.NAME.length > 10){
              this.$alert("이름은 10자 이하로 입력하세요");
              return;
          }

          if(this.editedItem.DUPLECNT == null || this.editedItem.DUPLECNT == undefined || this.editedItem.DUPLECNT.length < 1){
              this.$alert("중복로그인 횟수를 입력하세요");
              return;
          }

          if(this.editedItem.TEL  == undefined || this.editedItem.TEL.length < 1){
            this.$alert("휴대폰 번호를 입력하세요(최대 20자)");
            return;
          } 
          const mobileVal1 = /[^0-9-]/g;
          if(mobileVal1.test(this.editedItem.TEL)){
            this.$alert("휴대폰 번호는 숫자와 -만 입력이 가능합니다.");
            return;
          } 
          var mobileVal = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
          if(!mobileVal.test(this.editedItem.TEL)){
            this.$alert("휴대폰 번호가 잘못되었습니다.");
            return;
          } 

          if(this.uploadFile){
            //파일이 수정된 경우
            //console.log("edit file...");
            this.loading = true;
            const citem  = this.editedItem;

            //기존 파일 삭제 (파일명이 동일하면 캐싱때문에 이미지가 안바뀜)
            if(citem.REFIMAGE){
              const desertRef = ref(this.$firestorage, citem.REFIMAGE);
              deleteObject(desertRef).then(() => {

              // eslint-disable-next-line no-unused-vars
              }).catch((error) => {
                  this.loading = false;
                  //this.$alert('수정 실패');
              });
            }

            const uploadfile_ref = ref(this.$firestorage, "watermark/"+Date.now().toString()+".png");
            const metadata = {
              contentType: 'image/png',
            };      
            uploadBytes(uploadfile_ref, this.uploadFile, metadata)
            .then((snapshot) => {
              
              //console.log('full path ' + snapshot.metadata.fullPath);
              this.$http.post(this.$host+'/AcntUpdateAdmin',{
                    GRADE: citem.GRADE
                  , NAME: citem.NAME == null || citem.NAME == undefined ? '' : escape(citem.NAME)
                  , TEL: citem.TEL == null || citem.TEL == undefined ? '' : escape(citem.TEL)
                  , AREA_NO: citem.AREA_NO
                  , DATAGRADE: citem.DATAGRADE
                  , DUPLECNT: citem.DUPLECNT
                  , FOOTER_IMG: snapshot.metadata.fullPath
                  , STATE: citem.STATE
                  , ACCNT_NO: citem.ACCNT_NO
              },{headers: { firetoken: this.$session.get("FIRETOKEN") }})
              .then((result)=>{
                this.loading = false;
                if(result.data.resultCode == 0){
                    //토큰 저장
                    if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                      this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                    }
                    if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                      this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                    }
                    this.$alert("수정이 완료되었습니다").then(()=>{
                      const p = this.page;
                      this.page = 0;
                      this.loadpage(p);
                    });

                }else if(result.data.resultCode == 2){
                  //로그인 필요
                  this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
                  this.$EventBus.$emit('clearsession');

                }else{
                    this.$alert('수정이 실패했습니다.');
                }
              })
              // eslint-disable-next-line no-unused-vars
              .catch((error)=>{
                this.loading = false;
                this.$alert('수정이 실패했습니다.');
              });

            })
            // eslint-disable-next-line no-unused-vars
            .catch((upload_error)=>{
                this.loading = false;
                this.$alert('수정 실패');
            });

          }else{

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/AcntUpdateAdmin',{
                GRADE: this.editedItem.GRADE
              , NAME: this.editedItem.NAME == null || this.editedItem.NAME == undefined ? '' : escape(this.editedItem.NAME)
              , TEL: this.editedItem.TEL == null || this.editedItem.TEL == undefined ? '' : escape(this.editedItem.TEL)
              , AREA_NO: this.editedItem.AREA_NO
              , DUPLECNT: this.editedItem.DUPLECNT
              , DATAGRADE: this.editedItem.DATAGRADE
              , STATE: this.editedItem.STATE
              , ACCNT_NO: this.editedItem.ACCNT_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$alert("수정이 완료되었습니다").then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
                this.$alert('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert('수정이 실패했습니다.');
          });

        }

        this.close()
      },      

      //검색
      search_run(){
        this.loadpage(1);
      },
      

      //신규 추가
      newinsert(){
        this.dialogTitle = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialog = true;
      },

      //아이템 수정
      edit_item(item){
        this.dialogTitle = "수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        //this.editedItem = item;
        this.editedItem = Object.assign({}, item);
        this.previewImg = this.editedItem.FOOTER_IMG;
        this.dialog = true;
      },

      //권한 설정
      permission_item(item){
        this.$router.push({path:"Permission", query:{accnt_no:item.ACCNT_NO, accnt:item.ACCNT}});
      },

      save_password(){
        //비밀번호 리셋 메일 발송
        this.$confirm('비밀번호를 재설정하기 위한 메일을 발송하시겠습니까?')
        .then(() => {

          this.loading = true;
          this.$http.post(this.$host+'/resetpassword',{
                ACCNT: this.editedItem.ACCNT
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$alert("메일 발송이 완료되었습니다").then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
                this.$alert(result.data.resultMsg);
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert('메일 발송에 실패했습니다.');
          });

        });
      },
      

    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>