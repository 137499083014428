<template>

  <v-container fluid>



    <!-- 학기 -->
    <v-row>
    <v-dialog persistent scrollable overlay-color="#000000FF" v-model="dialog1" max-width="500px">

      <v-card class="pa-1 ma-0">

        <v-row dense class="mt-5">
          <v-col cols="12" align="center">
            <span class="dialog_title">{{ dialogTitle }}</span>
          </v-col>
        </v-row>

        <v-card-text class="ma-0 pl-5 pr-5 pt-3">
          <v-container>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">학기명</label>
              </v-col>
              <v-col cols="9">
                <v-text-field 
                  v-model="editedItem1.SEMESTER_NAME" dense single-line
                  outlined height="48px" hide-details class="popupiputstyle"
                  />
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">상태</label>
              </v-col>
              <v-col cols="9">
                <v-select v-model="editedItem1.STATE" :items="STATELIST" outlined
                  item-text="name" item-value="value" class="dialogselectstyle"
                  hide-details elevation="0" dense height="48px"
                  /> 
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close1">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save1">{{ editedIndex == -1 ? '추가' : '수정' }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>  
    </v-row>


    <!-- 교재 -->
    <v-row>
    <v-dialog persistent scrollable overlay-color="#00000080" v-model="dialog2" max-width="500px">
      
      <v-card class="pa-1 ma-0">

        <v-row dense class="mt-5">
          <v-col cols="12" align="center">
            <span class="dialog_title">{{ dialogTitle }}</span>
          </v-col>
        </v-row>

        <v-card-text class="ma-0 pl-5 pr-5 pt-3">
          <v-container>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">교재명</label>
              </v-col>
              <v-col cols="9">
                <v-text-field 
                  v-model="editedItem2.BOOK_NAME" dense single-line
                  outlined height="48px" hide-details class="popupiputstyle"
                  />
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">상태</label>
              </v-col>
              <v-col cols="9">
                <v-select v-model="editedItem2.STATE" :items="STATELIST" outlined
                  item-text="name" item-value="value" class="dialogselectstyle"
                  hide-details elevation="0" dense height="48px"
                  /> 
              </v-col>
            </v-row>
            
          </v-container>
        </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close2">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save2">{{ editedIndex == -1 ? '추가' : '수정' }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>  
    </v-row>


    <!-- 챕터 -->
    <v-row>
    <v-dialog persistent scrollable overlay-color="#00000040" v-model="dialog3" max-width="500px">
      <v-card class="pa-1 ma-0">

        <v-row dense class="mt-5">
          <v-col cols="12" align="center">
            <span class="dialog_title">{{ dialogTitle }}</span>
          </v-col>
        </v-row>

        <v-card-text class="ma-0 pl-5 pr-5 pt-3">
          <v-container>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">챕터명</label>
              </v-col>
              <v-col cols="9">
                <v-text-field 
                  v-model="editedItem3.CHAPTER_NAME" dense single-line
                  outlined height="48px" hide-details class="popupiputstyle"
                  />
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">상태</label>
              </v-col>
              <v-col cols="9">
                <v-select v-model="editedItem3.STATE" :items="STATELIST" outlined
                  item-text="name" item-value="value" class="dialogselectstyle"
                  hide-details elevation="0" dense height="48px"
                  /> 
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close3">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save3">{{ editedIndex == -1 ? '추가' : '수정' }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>  
    </v-row>    

    <v-row align="center" justify="start" >
      <v-col cols="12" md="3">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row>

      <!-- 학기 -->
      <v-col cols="12" md="4" sm="12" xs="12" class="pa-5">
        <v-row>
          <v-col cols="2" class="pa-0 pl-3" align="center">
            <v-btn  elevation="0" class="mini_btn" outlined rounded small @click="saveSort1">순서저장</v-btn>
          </v-col>
          <v-spacer/>
          <v-col cols="2" class="pa-0 pr-3" align="center">
            <v-btn  elevation="0" class="mini_btn" outlined rounded small @click="newinsert1">추가</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col >
            <draggable v-model="itemList1">
              <v-data-table
                :headers="headers1"
                :items="itemList1"
                no-data-text="데이터가 없습니다."
                hide-default-footer
                :items-per-page="99999"
                item-key="SEMESTER_NO"
                :loading="loading1"
                height="700"
                class="datatablestyle"
                >
                <template v-slot:body="props">
                  <draggable :list="props.items" ghost-class="ghost" @change="changedrag1" tag="tbody">
                    <tr v-for="(item, index) in props.items" :key="index" :style="item.SEMESTER_NO == SELECT_SEMESTER ? 'background:#C53D4580' : 'background:#ffffff'">
                      <td style="width:50px; text-align:center"><v-icon small>mdi-drag</v-icon></td>
                      <td style="width:200px; text-align:center"><a :style="item.STATE == 1 ? 'color:#6e3939' : 'color:#909090'" @click="loadbook(item)">{{ item.SEMESTER_NAME }}</a></td>
                      <td style="width:80px; text-align:center"><v-icon class="mr-2" small @click="edit_item1(item)">mdi-pencil</v-icon></td>
                    </tr>
                  </draggable>
                </template>
              </v-data-table>         
            </draggable>   
          </v-col>
        </v-row>
      </v-col>
        
      <!-- 교재 -->
      <v-col cols="12" md="4" sm="12" xs="12" class="pa-5">
        <v-row align="center">
          <v-col cols="2" class="pa-0 pl-3" align="center">
            <v-btn  elevation="0" class="mini_btn" outlined rounded small @click="saveSort2">순서저장</v-btn>
          </v-col>
          <v-col cols="8" class="pa-0" align="center">
            <label>{{ SEMESTER_NAME }}</label>
          </v-col>
          <v-col cols="2" class="pa-0 pr-3" align="center">
            <v-btn elevation="0" class="mini_btn" outlined rounded small @click="newinsert2">추가</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <draggable v-model="itemList2">
              <v-data-table
                :headers="headers2"
                :items="itemList2"
                no-data-text="데이터가 없습니다."
                hide-default-footer
                :items-per-page="99999"
                item-key="BOOK_NO"
                :loading="loading2"
                height="700"
                class="datatablestyle"
                >
                <template v-slot:body="props">
                  <draggable :list="props.items" ghost-class="ghost" @change="changedrag2" tag="tbody">
                    <tr v-for="(item, index) in props.items" :key="index" :style="item.BOOK_NO == SELECT_BOOK ? 'background:#C53D4580' : 'background:#ffffff'">
                      <td style="width:50px; text-align:center"><v-icon small>mdi-drag</v-icon></td>
                      <td style="width:200px; text-align:center"><a :style="item.STATE == 1 ? 'color:#6e3939' : 'color:#909090'" @click="loadchapter(item)">{{ item.BOOK_NAME }}</a></td>
                      <td style="width:80px; text-align:center"><v-icon class="mr-2" small @click="edit_item2(item)">mdi-pencil</v-icon></td>
                    </tr>
                  </draggable>
                </template>
              </v-data-table>         
            </draggable>   

          </v-col>
        </v-row>
      </v-col>

      <!-- 챕터 -->      
      <v-col cols="12" md="4" sm="12" xs="12" class="pa-5">
        <v-row align="center">
          <v-col cols="2" class="pa-0 pl-3" align="center">
            <v-btn  elevation="0" class="mini_btn" outlined rounded small @click="saveSort3">순서저장</v-btn>
          </v-col>
          <v-col cols="8" class="pa-0" align="center">
            <label>{{ BOOK_NAME }}</label>
          </v-col>
          <v-col cols="2" class="pa-0 pr-3" align="center">
            <v-btn  elevation="0" class="mini_btn" outlined rounded small @click="newinsert3">추가</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <draggable v-model="itemList3">
              <v-data-table
                :headers="headers3"
                :items="itemList3"
                no-data-text="데이터가 없습니다."
                hide-default-footer
                :items-per-page="99999"
                item-key="CHAPTER_NO"
                :loading="loading3"
                height="700"
                class="datatablestyle"
                >
                <template v-slot:body="props">
                  <draggable :list="props.items" ghost-class="ghost" @change="changedrag3" tag="tbody">
                    <tr v-for="(item, index) in props.items" :key="index">
                      <td style="width:50px; text-align:center"><v-icon small>mdi-drag</v-icon></td>
                      <td style="width:200px; text-align:center" :style="item.STATE == 1 ? 'color:#6e3939' : 'color:#909090'">{{ item.CHAPTER_NAME }}</td>
                      <td style="width:80px; text-align:center"><v-icon class="mr-2" small @click="edit_item3(item)">mdi-pencil</v-icon></td>
                    </tr>
                  </draggable>
                </template>
              </v-data-table>         
            </draggable>   

          </v-col>
        </v-row>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
export default {

    components:{
      draggable,
    },

    data: () => ({
      grade:0,

      STATELIST: [
          {name:'사용안함', value:0}
        , {name:'사용',   value:1}
      ],

      SELECT_SEMESTER:null,
      SELECT_BOOK:null,

      loading1: false,
      loading2: false,
      loading3: false,

      itemList1: [],
      itemList2: [],
      itemList3: [],

      editedItem1: {},
      editedItem2: {},
      editedItem3: {},
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트

      SEMESTER_NAME: "",
      BOOK_NAME:"",

      defaultItem1: {
        SEMESTER_NAME:'',
        SEMESTER_PATH:'',
        STATE:1,
        SORT:1
      },
      defaultItem2: {
        BOOK_NAME:'',
        BOOK_PATH:'',
        SEMESTER_NO:null,
        STATE:1,
        SORT:1
      },
      defaultItem3: {
        SEMESTER_NO:null,
        BOOK_NO:null,
        CHAPTER_NAME:'',
        CHAPTER_PATH:'',
        STATE:1,
        SORT:1
      },

      dialog1:false,
      dialog2:false,
      dialog3:false,
      dialogTitle:'추가하기',

      //테이블 정의
      headers1: [
        {text: '',  value: 'IMAGE', sortable: false, align:'center', class:"cheader_normal"},
        {text: '학기', value: 'SEMESTER_NAME', sortable: false, align:'center', width:200, class:"cheader_normal"},
        {text: '수정', value: 'actions', sortable: false, align:'center', width:100, class:"cheader_normal"}
      ],

      headers2: [
        {text: '',  value: 'IMAGE', sortable: false, align:'center', class:"cheader_normal"},
        {text: '교재', value: 'BOOK_NAME', sortable: false, align:'center', width:200, class:"cheader_normal"},
        {text: '수정', value: 'actions', sortable: false, align:'center', width:100, class:"cheader_normal"}
      ],
      
      headers3: [
        {text: '',  value: 'IMAGE', sortable: false, align:'center', class:"cheader_normal"},
        {text: '챕터', value: 'CHAPTER_NAME', sortable: false, align:'center', width:200, class:"cheader_normal"},
        {text: '수정', value: 'actions', sortable: false, align:'center', width:100, class:"cheader_normal"}
      ],


    }),

    mounted(){
      if(this.$session.get("GRADE") < 10){
        this.$alert("사용할 수 없는 등급의 페이지입니다.");
        this.$router.back();
        return;
      }
      this.grade = this.$session.get("GRADE");

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){

    },

    methods: {

      initpage(){
        this.loadsemester();
      },

      //신규 추가
      newinsert1(){
        this.dialogTitle  = "학기 추가"
        this.editedIndex  = -1;
        this.editedItem1  = Object.assign({}, this.defaultItem1);
        this.dialog1      = true;
      },

      //아이템 수정
      edit_item1(item){
        this.dialogTitle = "학기 수정";
        this.editedIndex = this.itemList1.indexOf(item);
        this.editedItem1 = Object.assign({}, item);
        this.dialog1 = true;
      },

      saveSort1(){

        if(this.itemList1.length <= 1){
          this.$alert('저장하실 내역이 없습니다.');
          return;
        }
        
        this.$confirm('현재의 학기 순서를 저장하시겠습니까?')
        .then(() => {

          var tempidx = 1;
          this.itemList1.forEach(item=>{
            item.NEW_SORT = tempidx;
            tempidx++;
          });

          var sortList = [];
          this.itemList1.forEach(item=>{
            if(item.SORT != item.NEW_SORT){
              sortList.push(item);
            }
          });
          if(sortList.length <= 1){
            this.$alert('변경된 순서가 없습니다.');
            return;
          }

          this.loading1 = true;
          this.$http.post(this.$host+'/SemesterUpdateSort',
            {
              SORTDATA: escape(JSON.stringify(sortList))
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            
            this.loading1 = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$alert('순서가 저장되었습니다.').then(()=>{
                this.loadsemester();
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.$alert('순서 변경이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              this.loading1 = false;
              this.$alert('순서 변경이 실패했습니다.');
          });

        });
      },               
      
      //추가 및 수정
      save1 () {

        if(this.editedItem1.SEMESTER_NAME  == undefined || this.editedItem1.SEMESTER_NAME.length < 1){
          this.$alert("학기명을 입력하세요");
          return;
        } 
        if(this.editedItem1.SEMESTER_NAME.length > 50){
          this.$alert("학기명은 50자까지 입력이 가능합니다.");
          return;
        } 

        if (this.editedIndex > -1) {

          //수정
          this.loading1 = true;
          this.$http.post(this.$host+'/SemesterUpdate',{
                SEMESTER_NAME: escape(this.editedItem1.SEMESTER_NAME)
              , STATE:    this.editedItem1.STATE
              , SEMESTER_NO: this.editedItem1.SEMESTER_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading1 = false;
            if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$alert('수정이 완료되었습니다.').then(()=>{
                this.loadsemester(this.editedItem1.SEMESTER_NO);
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading1 = false;
            this.$alert('수정이 실패했습니다.');
          });

        }else{
          //신규
          this.loading1 = true;
          this.$http.post(this.$host+'/SemesterInsert',{
                SEMESTER_NAME: escape(this.editedItem1.SEMESTER_NAME)
              , STATE: this.editedItem1.STATE
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading1 = false;
            if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$alert('추가가 완료되었습니다.').then(()=>{
                this.loadsemester();
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading1 = false;
            this.$alert('추가 실패');
          });

        }

        this.close1();  //팝업닫기

      },     

      close1() {
        this.dialog1 = false
        setTimeout(() => {
          this.editedItem1 = Object.assign({}, this.defaultItem1);
          this.editedIndex = -1
        }, 300)
      },


      //교재
      newinsert2(){

        if(this.SELECT_SEMESTER == null || this.SELECT_SEMESTER == undefined){
          this.$alert("학기를 선택하세요");
          return;
        }

        this.dialogTitle  = "교재 추가"
        this.editedIndex  = -1;
        this.editedItem2  = Object.assign({}, this.defaultItem2);
        this.dialog2      = true;
      },

      edit_item2(item){

        if(this.SELECT_SEMESTER == null || this.SELECT_SEMESTER == undefined){
          this.$alert("학기를 선택하세요");
          return;
        }

        this.dialogTitle = " 수정";
        this.editedIndex = this.itemList2.indexOf(item);
        this.editedItem2 = Object.assign({}, item);
        this.dialog2 = true;
      },

      saveSort2(){

        if(this.itemList2.length <= 1){
          this.$alert('저장하실 내역이 없습니다.');
          return;
        }
        
        this.$confirm('현재의 교재 순서를 저장하시겠습니까?')
        .then(() => {

          var tempidx = 1;
          this.itemList2.forEach(item=>{
            item.NEW_SORT = tempidx;
            tempidx++;
          });

          var sortList = [];
          this.itemList2.forEach(item=>{
            if(item.SORT != item.NEW_SORT){
              sortList.push(item);
            }
          });
          if(sortList.length <= 1){
            this.$alert('변경된 순서가 없습니다.');
            return;
          }

          this.loading2 = true;
          this.$http.post(this.$host+'/BookUpdateSort',
            {
              SORTDATA: escape(JSON.stringify(sortList))
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            
            this.loading2 = false;
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            if(result.data.resultCode == 0){
              this.$alert('순서가 저장되었습니다.').then(()=>{
                this.loadbook(null);
              });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.$alert('순서 변경이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              this.loading2 = false;
              this.$alert('순서 변경이 실패했습니다.');
          });

        });
      },               
      
      //추가 및 수정
      save2() {

        if(this.editedItem2.BOOK_NAME  == undefined || this.editedItem2.BOOK_NAME.length < 1){
          this.$alert("교재명을 입력하세요");
          return;
        } 
        if(this.editedItem2.BOOK_NAME.length > 50){
          this.$alert("교재명은 50자까지 입력이 가능합니다.");
          return;
        } 

        if (this.editedIndex > -1) {

          //수정
          this.loading2 = true;
          this.$http.post(this.$host+'/BookUpdate',{
                BOOK_NAME: escape(this.editedItem2.BOOK_NAME)
              , STATE:    this.editedItem2.STATE
              , BOOK_NO: this.editedItem2.BOOK_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading2 = false;
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            if(result.data.resultCode == 0){
              this.$alert('수정이 완료되었습니다.').then(()=>{
                this.loadbook(null);
              });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading2 = false;
            this.$alert('수정이 실패했습니다.');
          });

        }else{
          //신규
          this.loading2 = true;
          this.$http.post(this.$host+'/BookInsert',{
                BOOK_NAME: escape(this.editedItem2.BOOK_NAME)
              , STATE: this.editedItem2.STATE
              , SEMESTER_NO: this.SELECT_SEMESTER
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading2 = false;
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }

            //console.log("result : " + JSON.stringify(result));
            if(result.data.resultCode == 0){
              this.$alert('추가가 완료되었습니다.').then(()=>{
                this.loadbook(null);
              });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('추가 실패2');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading2 = false;
            this.$alert('추가 실패1' +  error);
          });

        }

        this.close2();  //팝업닫기

      },     

      close2() {
        this.dialog2 = false
        setTimeout(() => {
          this.editedItem2 = Object.assign({}, this.defaultItem2);
          this.editedIndex = -1
        }, 300)
      },


      newinsert3(){

        if(this.SELECT_BOOK == null || this.SELECT_BOOK == undefined){
          this.$alert("교재를 선택하세요");
          return;
        }

        this.dialogTitle  = "챕터 추가"
        this.editedIndex  = -1;
        this.editedItem3  = Object.assign({}, this.defaultItem3);
        this.dialog3      = true;
      },

      edit_item3(item){

        if(this.SELECT_BOOK == null || this.SELECT_BOOK == undefined){
          this.$alert("교재를 선택하세요");
          return;
        }

        this.dialogTitle = "챕터 수정";
        this.editedIndex = this.itemList3.indexOf(item);
        this.editedItem3 = Object.assign({}, item);
        this.dialog3 = true;
      },

      saveSort3(){

        if(this.itemList3.length <= 1){
          this.$alert('저장하실 내역이 없습니다.');
          return;
        }
        
        this.$confirm('현재의 챕터 순서를 저장하시겠습니까?')
        .then(() => {

          var tempidx = 1;
          this.itemList3.forEach(item=>{
            item.NEW_SORT = tempidx;
            tempidx++;
          });

          var sortList = [];
          this.itemList3.forEach(item=>{
            if(item.SORT != item.NEW_SORT){
              sortList.push(item);
            }
          });
          if(sortList.length <= 1){
            this.$alert('변경된 순서가 없습니다.');
            return;
          }

          this.loading3 = true;
          this.$http.post(this.$host+'/ChapterUpdateSort',
            {
              SORTDATA: escape(JSON.stringify(sortList))
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            
            this.loading3 = false;
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
            if(result.data.resultCode == 0){
              this.$alert('순서가 저장되었습니다.').then(()=>{
                this.loadchapter(null);
              });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.$alert('순서 변경이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              this.loading3 = false;
              this.$alert('순서 변경이 실패했습니다.');
          });

        });
      },               
      
      //추가 및 수정
      save3 () {

        if(this.editedItem3.CHAPTER_NAME  == undefined || this.editedItem3.CHAPTER_NAME.length < 1){
          this.$alert("챕터명을 입력하세요");
          return;
        } 
        if(this.editedItem3.CHAPTER_NAME.length > 50){
          this.$alert("챕터명은 50자까지 입력이 가능합니다.");
          return;
        } 

        if (this.editedIndex > -1) {

          //수정
          this.loading3 = true;
          this.$http.post(this.$host+'/ChapterUpdate',{
                CHAPTER_NAME: escape(this.editedItem3.CHAPTER_NAME)
              , STATE:    this.editedItem3.STATE
              , CHAPTER_NO: this.editedItem3.CHAPTER_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading3 = false;
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            if(result.data.resultCode == 0){
              this.$alert('수정이 완료되었습니다.').then(()=>{
                this.loadchapter(null);
              });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading3 = false;
            this.$alert('수정이 실패했습니다.');
          });

        }else{
          //신규
          this.loading3 = true;
          this.$http.post(this.$host+'/ChapterInsert',{
                CHAPTER_NAME: escape(this.editedItem3.CHAPTER_NAME)
              , STATE: this.editedItem3.STATE
              , SEMESTER_NO: this.SELECT_SEMESTER
              , BOOK_NO: this.SELECT_BOOK
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading3 = false;
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            if(result.data.resultCode == 0){
              this.$alert('추가가 완료되었습니다.').then(()=>{
                this.loadchapter(null);
              });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading3 = false;
            this.$alert('추가 실패');
          });

        }
        this.close3();  //팝업닫기
      },     

      close3() {
        this.dialog3 = false
        setTimeout(() => {
          this.editedItem3 = Object.assign({}, this.defaultItem3);
          this.editedIndex = -1
        }, 300)
      },

      //오리지널 리스트 순서를 변경
      changedrag1(e){
        this.itemList1.splice(e.moved.oldIndex, 1);  //원본 위치에서 제거
        this.itemList1.splice(e.moved.newIndex, 0, e.moved.element);  //새로운 위치에 다시 추가
      },
      changedrag2(e){
        this.itemList2.splice(e.moved.oldIndex, 1);  //원본 위치에서 제거
        this.itemList2.splice(e.moved.newIndex, 0, e.moved.element);  //새로운 위치에 다시 추가
      },
      changedrag3(e){
        this.itemList3.splice(e.moved.oldIndex, 1);  //원본 위치에서 제거
        this.itemList3.splice(e.moved.newIndex, 0, e.moved.element);  //새로운 위치에 다시 추가
      },

      //학기 조회
      loadsemester(){

          this.loading1 = true;
          this.$http.post(this.$host+'/SemesterListALL',{
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            if(result.data.resultCode == 0){
                this.loading1 = false;
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                const list = result.data.resultData;
                this.itemList1.splice(0, this.itemList1.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList1.push(element); 
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading1 = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.loading1 = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading1 = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },        

      //교재 조회
      loadbook(item){

          //교재, 챕터 초기화
          this.itemList2.splice(0, this.itemList2.length);   //데이터 초기화
          this.itemList3.splice(0, this.itemList3.length);   //데이터 초기화

          if(item != null && item != undefined){
            this.SELECT_SEMESTER = item.SEMESTER_NO;
            this.SEMESTER_NAME   = item.SEMESTER_NAME;
            this.SELECT_BOOK     = null;
            this.BOOK_NAME       = "";
          }

          this.loading2 = true;
          this.$http.post(this.$host+'/BookListALL',{
            SEMESTER_NO: this.SELECT_SEMESTER
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            if(result.data.resultCode == 0){
                this.loading2 = false;
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                //console.log("book : " + JSON.stringify(result.data.resultData));
                const list = result.data.resultData;
                this.itemList2.splice(0, this.itemList2.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList2.push(element); 
                });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading2 = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.loading2 = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading2 = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },        
      

      //챕터 조회
      loadchapter(item){
          
          this.itemList3.splice(0, this.itemList3.length);   //챕터 초기화

          if(item != null && item != undefined){
            this.SELECT_BOOK = item.BOOK_NO;
            this.BOOK_NAME = item.BOOK_NAME;
          }
          this.loading3 = true;
          this.$http.post(this.$host+'/ChapterALLList',{
              SEMESTER_NO: this.SELECT_SEMESTER
            , BOOK_NO: this.SELECT_BOOK
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            if(result.data.resultCode == 0){
                this.loading3 = false;
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                const list = result.data.resultData;
                this.itemList3.splice(0, this.itemList3.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList3.push(element); 
                });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading3 = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.loading3 = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading3 = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },              


    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>