import { render, staticRenderFns } from "./ShowPage_Backup2.vue?vue&type=template&id=4d48a8ec&scoped=true&"
import script from "./ShowPage_Backup2.vue?vue&type=script&lang=js&"
export * from "./ShowPage_Backup2.vue?vue&type=script&lang=js&"
import style0 from "./ShowPage_Backup2.vue?vue&type=style&index=0&id=4d48a8ec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d48a8ec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VContainer,VDialog,VProgressCircular,VRow})
