<!-- 업체 선택용 공통 팝업 -->
<template>

  <v-container fluid class="ma-0 pa-0" style="min-height:100%">

    <v-row no-gutters dense align="center" justify="start">
      <v-btn class="dialogsearch_btn_freesize" 
        :height="35" 
        elevation="0" outlined large @click="showpopup_com" >검색</v-btn>
    </v-row>   

    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" 
        v-model="dialog_com" max-width="940px"
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        >
        <v-card class="pa-0 ma-0">

          <v-row dense justify="center" align="center" class="pa-0 ma-0 mt-md-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">교재 검색</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pa-0" style="overflow:hidden">
            
              <v-row class="mt-1" align="center">
                <v-col offset-md="6" md="4" cols="9">
                  <v-text-field 
                    dense single-line clearable
                    @keyup.enter="search_run_com" 
                    v-model="search_keyword_com" 
                    placeholder="검색어를 입력하세요"
                    outlined height="35"
                    color="#808080"
                    class="ml-1"
                    hide-details
                    />
                </v-col>
                <v-col md="1" cols="3">
                  <v-btn class="dialogsearch_btn_freesize" :height="35" elevation="0" outlined large @click="search_run_com">검색</v-btn>
                </v-col>
              </v-row>

              <v-row dense class="pa-0">
                <v-col cols="12">
                  <v-data-table
                    ref="table"
                    :headers="headers_com"
                    :items="itemList_com"
                    v-model="select_com"
                    no-data-text="데이터가 없습니다."
                    hide-default-footer
                    class="datatablestyle"
                    :items-per-page="pagerow_com"
                    item-key="IDX"
                    height="500px"
                    >
                    <template v-slot:item.BOOK_NAME="{ item }">
                      <a @click="select_func(item)">{{item.BOOK_NAME}}</a>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" class="d-flex justify-center">
                  <v-btn v-if="bottompage_com > 10" icon  small @click="prev()"><v-icon dark>mdi-chevron-left</v-icon></v-btn>
                  <span v-for="n in subpage_com" :key="n.id">
                      <span v-if="page_com == bottompage_com-1+n">
                          <v-btn color="red" class="numberstyle" icon small @click="loadpage_com(bottompage_com-1+n)">{{bottompage_com-1+n}}</v-btn>
                      </span>
                      <span v-else>
                          <v-btn icon small class="numberstyle2" @click="loadpage_com(bottompage_com-1+n)">{{bottompage_com-1+n}}</v-btn>
                      </span>
                  </span>
                  <v-btn v-if="subpage_com == 10" icon small @click="next()" ><v-icon dark>mdi-chevron-right</v-icon></v-btn>
                </v-col>
              </v-row>
            
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close_com">닫기</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>     
    </v-row>
    
  </v-container>

</template>

<script>
    export default {
        
        //전달받을 프로퍼티 정의, 타입을 정의해도 되고 안해도 되고
        props:{
            areaname: String,
            areaidx: Number,
        },

        data: ()=> ({
            dialog_com : false,
            load_flag : false,
            headers_com: [
              {text: '학기', value: 'SEMESTER_NAME', width:200, sortable: false, align:'center'},
              {text: '교재', value: 'BOOK_NAME', sortable: false, align:'left'}
            ],

            sort_name_com: 'AREA_NAME',
            sort_type_com: true,    //true : DESC, false: ASC

            itemList_com: [],
            select_com:[],
            search_keyword_com : '',
            totalcnt_com : 0,      //전체 아이템 개수
            page_com : 0,
            maxpage_com : 0,       //전체 페이지 개수
            startpage_com: 1,      //시작 인덱스
            bottompage_com : 1,    //하단 페이징 번호의 시작점을 계산하기 위한 변수
            subpage_com : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
            pagerow_com : 15,      //한 페이지에 보여줄 row수
            
        }),

        updated(){
          
          if(this.dialog_com){
            if(!this.load_flag && this.itemList_com.length == 0 && this.search_keyword_com.length == 0){
              this.load_flag = false;
              this.loadpage_com(1);
            }
          }else{
            this.load_flag = false;
          }

        },
        

        methods: {
            customsort_com(sortname){
              this.sort_name_com = sortname;
              this.sort_type_com = !this.sort_type_com;
              this.loadpage_com(this.page_com);
            },

            // eslint-disable-next-line no-unused-vars
            visibilityChanged (isVisible, entry) {
              
            },

            prev_com(){
                this.bottompage_com = this.bottompage_com - 10 >= 1 ? this.bottompage_com - 10 : 1;
                this.subpage_com = this.maxpage_com - this.bottompage_com >= 10 ? 10 : this.maxpage_com - this.bottompage_com+1;
                var temp_page = this.bottompage_com + this.subpage_com - 1; //이전을 누르면 가장 끝 번호 선택
                this.loadpage_com(temp_page);
            },
            next_com(){
                this.bottompage_com = this.bottompage_com + 10 <= this.maxpage_com ? this.bottompage_com + 10 : this.maxpage_com;
                this.subpage_com = this.maxpage_com - this.bottompage_com >= 10 ? 10 : this.maxpage_com - this.bottompage_com+1;
                var temp_page = this.bottompage_com;
                this.loadpage_com(temp_page);
            },

            makepaging_com(){
                var div1 = this.totalcnt_com / this.pagerow_com; 
                var div2 = this.totalcnt_com % this.pagerow_com;
                if(div2 == 0){  
                    this.maxpage_com = parseInt(div1);    
                }else{
                    this.maxpage_com = parseInt(div1+1);
                }
                if(this.bottompage_com == 1){
                this.subpage_com = this.maxpage_com - this.bottompage_com >= 10 ? 10 : this.maxpage_com - this.bottompage_com+1;
                }
            },

            search_run_com(){
                this.loadpage_com(1);
            },

            loadpage_com(p){

                this.page_com = p;
                this.$http.post(this.$host+'/SearchBookList',{
                      search_keyword: this.search_keyword_com == null || this.search_keyword_com == undefined ?  '' : escape(this.search_keyword_com)
                    , sort_name:this.sort_name_com
                    , sort_type: this.sort_type_com==true ? "DESC" : "ASC" 
                    , page: this.page_com
                    , pagerow: this.pagerow_com
                },{headers:{firetoken:this.$session.get("FIRETOKEN")}})
                .then((result)=>{
                    if(result.data.resultCode == 0){
                        this.totalcnt_com = result.data.totalcnt;
                        this.makepaging_com();
                        const list = result.data.resultData;
                        this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
                        list.forEach(element => {
                            this.itemList_com.push(element); 
                        });
                  }else if(result.data.resultCode == 2){
                    //로그인 필요
                    this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
                    this.$EventBus.$emit('clearsession');
                    }
                })
                // eslint-disable-next-line no-unused-vars
                .catch((error)=>{
                    
                });

            },

            //다이얼로그 닫기
            close_com () {
                this.dialog_com = false
                this.select_com = [];
                this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
                this.search_keyword_com = '';
            },

            //선택 초기화
            clear_com () {
                this.$emit('callback', {AREA_NAME:'', IDX:''})
                this.dialog_com = false
                this.select_com = [];
                this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
                this.search_keyword_com = '';
            },
            
            //선택
            select_func(select_item){
              this.$emit('callback', select_item)
              //콜백 호출 후 닫아야함.
              this.dialog_com = false
              this.select_com = [];
              this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
              this.search_keyword_com = '';
            },

            showpopup_com(){
                this.dialog_com = true;
            },


        }

    }
</script>

<style scoped>


</style>