<template>
  <v-container fluid style="background:#ffffff">

  <v-row style="background:#ffffff;" class="ma-md-10 ma-sm-5" justify="center">
    <v-col cols="11" sm="11" xs="11" md=8 lg="8" xl="8">
      <v-stepper
        v-model="e6"
        vertical
        >
    <v-stepper-step color="#9f0007"
      :complete="e6 > 1"
      step="1"
      >
      서비스약관
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-card
        color="#f0f0f0"
        class="mb-12 overflow-y-auto"
        max-height="500px"
        >
        <v-card-text>
            <div class="col-sm-12">
                <h3>1.목적</h3>
            </div>
            <div class="col-sm-12">
                <p>본 약관은 (주)감성교육 제공하는 감성수학에 회원으로 가입하고 이를 이용함에 있어 회사와 회원(약관에 동의한 이용자)의 권리와 의무 및 책임사항을 규정함을 목적으로 합니다.</p>
            </div>
            <br>
            <div class="col-sm-12">
                <h3>2.약관의 명시, 효력 및 개정</h3>
            </div>
            <div class="col-sm-12">
                <p>(1) 회사는 이 약관의 내용을 회원이 알 수 있도록 회원가입시 화면에 게시합니다.</p>
                <p>(2) 회사는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 소비자기본법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</p>
                <p>(3) 회사가 약관이 변경되는 경우 귀하는 최소 30일 이전에 알림을 받게 되며, 새로운 약관은 이 기간을 거친 후에 효력을 발휘합니다. 귀하가 이 기간이 지난 후에도 감성수학을 계속 사용하면 새 약관에 동의한 것으로 간주됩니다. 새 약관은 감성수학을 이용하는 모든 부분에 적용됩니다.</p>
            </div>
            <br>
            <div class="col-sm-12">
                <h3>3.회원의 가입 및 관리</h3>
            </div>
            <div class="col-sm-12">
                <p>(1) 서비스 이용자가 회원가입 인증 메일의 링크를 눌러 이메일을 인증하면 본 약관에 동의한 것으로 간주합니다.</p>
                <p>(2) 회사는 아래 각 호의 1에 해당하는 경우에는 회원등록의 승낙을 거부할 수 있습니다.</p>
                <p>&ensp;&ensp;1. 서비스를 제공하는데 기술적인 문제가 있다고 판단되는 경우</p>
                <p>&ensp;&ensp;2. 기타 회사가 재정적 기술적으로 필요하다고 인정하는 경우</p>
                <p>&ensp;&ensp;3. 법인고객의 경우 회사가 요구한 증명서를 제출하지 않은 경우</p>
                <p>&ensp;&ensp;4. 회사가 관련법령 등을 기준으로 하여 명백하게 사회질서 및 미풍양속에 반할 우려가 있음을 인정하는 경우</p>
                <p>(3) 회원은 서비스 이용을 위한 회원 ID, 비밀번호의 관리에 대한 책임, 본인 ID의 제3자에 의한 부정사용 등 회원의 고의∙과실로 인해 발생하는 모든 불이익에 대한 책임을 부담합니다. 단, 이것이 회사의 고의∙과실로 인하여 야기된 경우는 회사가 책임을 부담합니다.</p>
                <p>(4) 회사는 서비스를 제공하기 위하여 관련 법령의 규정에 따라 회원으로부터 필요한 개인정보를 수집합니다.</p>
            </div>
            <br>
            <div class="col-sm-12">
                <h3>4.서비스의 이용</h3>
            </div>
            <div class="col-sm-12">
                <p>(1) 서비스 이용은 회사의 서비스 사용승낙 직후부터 가능합니다. 다만, 유료 서비스의 경우 회사가 요금납입을 확인한 직후부터 가능하게 할 수 있습니다.</p>
                <p>(2) 서비스 이용시간은 회사의 업무상 또는 기술상 불가능한 경우를 제외하고는 연중무휴 1일 24시간(00:00-24:00)으로 함을 원칙으로 합니다. 다만, 서비스설비의 정기점검 등의 사유로 회사가 서비스를 특정범위로 분할하여 별도로 날짜와 시간을 정할 수 있습니다.</p>
                <p>(3) 회사의 서비스가 종료되는 경우 회사는 회원의 등록된 전자우편 주소로 이메일을 통하여 서비스 내용의 변경 사항 또는 종료를 통지할 수 있습니다.</p>
                <p>(4) 회사는 아래 각 호의 1에 해당하는 사유가 발생한 경우에는 회원의 서비스 이용을 제한하거나 중지시킬 수 있습니다.</p>
                <p>&ensp;&ensp;1. 회원이 회사 서비스의 운영을 고의∙과실로 방해하는 경우</p>
                <p>&ensp;&ensp;2. 서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우</p>
                <p>&ensp;&ensp;3. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</p>
                <p>&ensp;&ensp;4. 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때</p>
                <p>&ensp;&ensp;5. 국기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우</p>
                <p>(5) 서비스 이용자가 약관을 위반하는 경우 이용자의 권리는 효력을 상실하며 (주)감성교육은 해당 금액을 귀하에게 환불하지 않고 귀하의 계정에 대한 사용을 종료할 수 있습니다. </p>
            </div>
            <br>
            <div class="col-sm-12">
                <h3>5.회사의 의무</h3>
            </div>
            <div class="col-sm-12">
                <p>(1) 회사는 회사의 서비스를 지속적이고 안정적으로 제공할 수 있도록 유지, 점검 또는 복구 등의 조치를 성실히 이행하여야 합니다.</p>
                <p>(2) 회사는 회원이 수신 동의를 하지 않은 영리 목적의 광고성 전자우편, SMS 문자메시지 등을 발송하지 아니합니다.</p>
                <p>(3) 회사는 서비스의 제공과 관련하여 알게 된 회원의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않고, 이를 보호하기 위하여 노력합니다. 회원의 개인정보보호에 관한 기타의 사항은 정보통신망법 및 회사가 별도로 정한 “개인정보관리지침”에 따릅니다.</p>
            </div>
            <br>
            <div class="col-sm-12">
                <h3>6.회원의 의무</h3>
            </div>
            <div class="col-sm-12">
                <p>(1) 회원은 아래 각 호의 1에 해당하는 행위를 하여서는 아니 됩니다.</p>
                <p>&ensp;&ensp;1.회원가입신청 또는 변경 시 허위내용을 등록하는 행위</p>
                <p>&ensp;&ensp;2.회사나 제3자의 권리를 침해하는 행위</p>
                <p>&ensp;&ensp;3.다른 회원의 ID 및 비밀번호를 도용하여 부당하게 서비스를 이용하는 행위</p>
                <p>(2) 회사는 회원이 제1항의 행위를 하는 경우 서비스의 이용을 제한하거나 일방적으로 본 계약을 해지할 수 있습니다.</p>
                <p>(3) 회원의 서비스 받을 권리는 이를 양도 내지 증여하거나 질권의 목적으로 사용할 수 없습니다.</p>
            </div>
            <br>
            <div class="col-sm-12">
                <h3>7.기타</h3>
            </div>
            <div class="col-sm-12">
                <p>(1) 회사는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 회원에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.</p>
                <p>&ensp;&ensp;1.천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</p>
                <p>&ensp;&ensp;2.서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우</p>
                <p>&ensp;&ensp;3.회원의 귀책사유로 서비스 이용에 장애가 있는 경우</p>
                <p>(2)본 약관은 대한민국법령에 의하여 규정되고 이행되며, 서비스 이용과 관련하여 회사와 회원간에 발생한 분쟁에 대해서는 민사소송법상의 주소지를 관할하는 법원을 합의관할로 합니다.</p>
                <p>(3) 본 약관에 명시되지 않은 사항에 대해서는 관련법령에 의하고, 법에 명시되지 않은 부분에 대하여는 관습에 의합니다.</p>
            </div>
    
        </v-card-text>
      </v-card>
      <v-btn style="color:white" color="#9f0007" @click="e6 = 2">동의합니다.</v-btn>
      <v-btn text @click="$router.back()">취소</v-btn>
    </v-stepper-content>

    <v-stepper-step color="#c53d45"
      :complete="e6 > 2"
      step="2"
    >
      개인정보처리방침
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-card
        color="#f0f0f0"
        class="mb-12 overflow-y-auto"
        max-height="500px"
        >
        <v-card-text>
            <div class="col-sm-12">
                <h3>1. 개인정보 처리방침</h3>
            </div>
            <div class="col-sm-12">
                <p>(주)감성교육은 ‘이용자의 권리 (개인정보 자기결정권)를 적극적으로 보장’ 합니다. 정보통신서비스제공자가 준수하여야 하는 대한민국의 관계 법령 및 개인정보보호 규정, 가이드라인을 
                준수하고 있습니다. “개인정보처리방침”이란 이용자의 소중한 개인정보를 보호함으로써 이용자가 안심하고 서비스를 이용할 수 있도록 회사가 준수해야 할 지침을 의미합니다. 본 개인정보 처리방침은 감성수학의 모든 서비스에 적용됩니다.</p>
            </div>
            <div class="col-sm-12">
                <h3>2. 개인정보 수집</h3>
            </div>
            <div class="col-sm-12">
                <p>(주)감성교육은 회원으로 부터 감성수학의 원활한 서비스를 위해 일부 정보를 수집합니다.</p>
                <p>과도한 개인정보가 아닌 서비스 제공에 필요한 최소한의 정보만을 수집하며 이용자의 인권을 침배할 우려가 있는 민감한 정보는 절대 수집하지 않습니다.</p>
            </div>
            <div class="col-sm-12">
                <h3>3.개인정보 이용</h3>
            </div>
            <div class="col-sm-12">
                <p>이용자의 개인정보를 다음과 같은 목적으로만 이용하며, 목적이 변경될 경우에는 반드시 사전에 이용자에게 동의를 구하도록 하겠습니다.</p>
                <p>&ensp;&ensp;1.이용자 식별, 불량회원 부정이용 방지</p>
                <p>&ensp;&ensp;2.다양한 서비스 제공, 문의사항 또는 불만 처리, 공지사항 전달</p>
                <p>&ensp;&ensp;3.이용자와 약속한 서비스 제공, 유료 서비스 구매 및 이용 시 요금 정산</p>
                <p>&ensp;&ensp;4.신규 서비스 개발, 이벤트 행사 시 정보 전달, 마케팅 및 광고 등에 활용</p>
                <p>&ensp;&ensp;5.서비스 이용 기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 맞춤형 서비스 제공, 서비스 개선에 활용</p>
            </div>
            <div class="col-sm-12">
                <h3>4.개인정보의 위탁</h3>
            </div>
            <div class="col-sm-12">
                <p>개인정보를 제3자에게 제공해야 하는 경우 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 구하도록 하겠습니다. 다만 다음의 경우는 예외로 하고 있습니다.</p>
                <p>&ensp;&ensp;1.서비스 제공에 따른 요금정산을 위해 필요한 경우</p>
                <p>&ensp;&ensp;2.법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</p>
            </div>
            <div class="col-sm-12">
                <h3>5.개인정보의 파기</h3>
            </div>
            <div class="col-sm-12">
                <p>이용자의 개인정보에 대해 개인정보의 수집·이용 목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 다만 내부 방침에 의해 서비스 부정이용기록은 부정 가입 및 이용 방지를 위하여 회원 탈퇴 시점으로부터 최대 1년간 보관 후 파기하며, 관계법령에 의해 보관해야 하는 정보는 법령이 정한 기간 동안 보관한 후 파기합니다.</p>
                <p>개인정보 파기 시에는 복구 및 재생되지 않도록 완전하게 삭제합니다.</p>
            </div>          
        </v-card-text>
      </v-card>
      <v-btn style="color:white" color="#9f0007" @click="e6=3">
        동의합니다.
      </v-btn>
      <v-btn text @click="e6=1">취소</v-btn>
    </v-stepper-content>

    <v-stepper-step color="#c53d45"
      :complete="e6 > 3"
      step="3"
      >
      마케팅정보동의
    </v-stepper-step>
    <v-stepper-content step="3">
      <v-card
        color="#f0f0f0" class="mb-12" height="50px"
      >
      <v-card-text>
        <p>감성수학에서 보내드리는 메일정보 수신에 동의하시겠습니까?</p>
      </v-card-text>
      </v-card>
      <v-btn style="color:white" color="#9f0007"  @click="e6 = 4">동의</v-btn>
      <v-btn text @click="e6=2">취소</v-btn>
    </v-stepper-content>

    <template v-if="select_type == 1">
      <v-stepper-step color="#c53d45" step="4">
        이메일 계정과 비밀번호를 입력하세요
      </v-stepper-step>
      <v-stepper-content step="4">
        <v-card color="#f0f0f0" class="mb-12" >
          <v-container>
          <v-row dense>
              <div class="col-sm-12">
                  <br>
                  <p>가입방법</p>
                  <p>1. 이름, 연락처, 이메일 계정과 비밀번호를 입력하시고 가입하기를 누르시면 입력하신 이메일 계정으로 인증메일을 보내드립니다.</p>
                  <p>2. 인증메일에 첨부된 링크를 누르시면 가입이 완료되어 계정사용이 가능합니다.</p>
              </div>
          </v-row>
          <v-row justify="center" class="mt-5" dense>
            <v-col cols="12" md="4" sm="12" xs="12">
              <v-text-field v-model="user_name" outlined
                 name="name" label="이름을 입력하세요" type="text" dense solo></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" dense>
            <v-col cols="12" md="4" sm="12" xs="12">
              <v-text-field v-model="user_tel" outlined maxlength="20"
                 name="tel" label="휴대폰 번호를 입력하세요" type="text" dense solo></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" dense>
            <v-col cols="12" md="4" sm="12" xs="12">
              <v-text-field v-model="user_id" outlined
                prepend-inner-icon="mdi-map-marker" name="login" label="이메일을 입력하세요" type="text" dense solo></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" dense>
            <v-col cols="12" md="4" sm="12" xs="12">
              <v-text-field v-model="user_pw" outlined 
                prepend-inner-icon="mdi-lock" name="password" label="비밀번호" hint="비밀번호를 입력하세요"
                id="password" type="password" dense solo></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" dense>
            <v-col cols="12" md="4" sm="12" xs="12">
              <v-text-field v-model="user_pw2" outlined 
                prepend-inner-icon="mdi-lock" name="password2" label="비밀번호 확인" hint="비밀번호를 다시 입력하세요"
                id="password2" type="password" dense solo></v-text-field>
            </v-col>
          </v-row>
          </v-container>
        </v-card>
        <v-btn style="color:white" color="#9f0007" @click="join">가입하기</v-btn>
        <v-btn text @click="e6=3">취소</v-btn>
      </v-stepper-content>
    </template>


      </v-stepper>
    </v-col>
  </v-row>

  </v-container>
</template>

<script>

export default {

  data: () => ({
        dialog:true,
        select_type:1,
        select_login:false,
        e6: 1,
        user_id : ""
      , user_pw : ""
      , user_pw2 : ""
      , user_name : ""
      , user_tel : ""
      , rules: {
          mobile: value => !value || !/[^0-9-]/.test(value) || '숫자와 -외에는 입력할 수 없습니다.',
          eng: value => !value || !/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value) || '한글을 사용할 수 없습니다.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          notemail: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return !pattern.test(value) || '이메일주소는 사용할 수 없습니다.'
          },
      },      
  }),

  mounted(){  
    this.select_login = false;
    
  },

  methods: {
      
    join(){

        if(this.user_name.length < 1){
            this.$alert("이름을 입력하세요");
            return;
        }
        if(this.user_name.length > 10){
            this.$alert("이름은 10자 이하로 입력하세요");
            return;
        }

        if(this.user_tel  == undefined || this.user_tel.length < 1){
          this.$alert("휴대폰 번호를 입력하세요(최대 20자)");
          return;
        } 
        const mobileVal1 = /[^0-9-]/g;
        if(mobileVal1.test(this.user_tel)){
          this.$alert("휴대폰 번호는 숫자와 -만 입력이 가능합니다.");
          return;
        } 
        var mobileVal = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
        if(!mobileVal.test(this.user_tel)){
          this.$alert("휴대폰 번호가 잘못되었습니다.");
          return;
        } 

        var emailVal = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        if(this.user_id  == undefined || this.user_id.length < 1 || this.user_id.length > 30){
          this.$alert("이메일을 입력하세요(최대 30자)");
          return;
        } 
        if(!emailVal.test(this.user_id)){
          this.$alert("입력하신 이메일은 이메일 주소 양식이 아닙니다.");
          return;
        } 

        if(this.user_pw.length < 1){
            this.$alert("비밀번호를 입력하세요");
            return;
        }
        if(this.user_pw.length < 6){
            this.$alert("비밀번호는 최소 6글자 이상입니다.");
            return;
        }
        if(this.user_pw2.length < 1){
            this.$alert("비밀번호 확인을 입력하세요");
            return;
        }
        if(this.user_pw2.length < 6){
            this.$alert("비밀번호는 최소 6글자 이상입니다.");
            return;
        }
        if(this.user_pw != this.user_pw2){
            this.$alert("비밀번호화 비밀번호 확인이 다릅니다.");
            return;
        }

        this.$http.post(this.$host+'/fireJoin',{
            USER_ID: this.user_id
          , USER_PW: Buffer.from(this.user_pw).toString('base64')
          , NAME: escape(this.user_name)
          , TEL: escape(this.user_tel)
        })
        .then((result)=>{
            //console.log(JSON.stringify(result));
            if(result.data.resultCode == 0){
                //신규추가 성공
                this.$alert("인증메일이 발송되었습니다.\n인증메일 확인 후 로그인해주세요.");
                this.$router.push('/Login');
            }else{
              this.$alert(result.data.resultMsg);
            }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          //console.log(error);
          this.$alert('오류로 인해 회원가입이 실패했습니다.');
        });

      }

  },


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>