import { render, staticRenderFns } from "./Freeboard.vue?vue&type=template&id=3f8091da&scoped=true&"
import script from "./Freeboard.vue?vue&type=script&lang=js&"
export * from "./Freeboard.vue?vue&type=script&lang=js&"
import style0 from "./Freeboard.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Freeboard.vue?vue&type=style&index=1&id=3f8091da&lang=scss&scoped=true&"
import style2 from "./Freeboard.vue?vue&type=style&index=2&id=3f8091da&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f8091da",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VParallax } from 'vuetify/lib/components/VParallax';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VContainer,VDialog,VIcon,VParallax,VProgressCircular,VRow,VTextField})
