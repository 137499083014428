<template>

  <v-container fluid>

    <!-- 추가/수정 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#000000ff" v-model="dialog" max-width="600px">
        
        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
          </v-row>
          
          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">지점명</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field 
                    v-model="editedItem.AREA_NAME" dense single-line
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">전화번호</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field 
                    v-model="editedItem.AREA_TEL" dense single-line
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">홈페이지</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field 
                    v-model="editedItem.AREA_URL" dense single-line
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">주소</label>
                </v-col>
                <v-col cols="6">
                  <v-text-field 
                    disabled
                    v-model="editedItem.AREA_ADDR1" dense single-line
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
                <v-col cols="3">
                  <v-btn class="search_btn_freesize ml-2" elevation="0" outlined large @click="postcode">주소검색</v-btn>
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">상세주소</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field outlined counter="50" ref="addr2" 
                    v-model="editedItem.AREA_ADDR2" class="popupiputstyle"
                    >
                  </v-text-field>
                </v-col>
              </v-row>
              
              <v-row>
                <div id="map" style="width:300px;height:300px;margin-top:10px;display:none"></div>
              </v-row>
              <v-row dense class="mt-5">
                <v-col>
                  <v-row justify="center">
                    <div id="gmap" />
                  </v-row>
                </v-col>
              </v-row>              
            </v-container>
          </v-card-text>

          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <v-row align="center" justify="start" class="mt-5">
      <v-col cols="12" md="3" sm="12" xs="12">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="6" md="3" align="center" class="pa-0">
        <v-text-field
          dense single-line clearable
          @keyup.enter="search_run" 
          v-model="search_keyword"
          hide-details
          outlined height="43"
          color="#808080"
          placeholder="검색어를 입력하세요"
          class="basicsearchstyle"
          />
      </v-col>
      <v-col cols="3" md="1" class="pa-0" align="center">
        <v-btn class="search_btn_freesize" outlined  @click="search_run">조회</v-btn>
      </v-col>
      <v-col cols="3" md="1" class="pa-0" align="center">
        <v-btn class="search_btn_freesize" outlined  @click="newinsert">추가</v-btn>
      </v-col>
    </v-row>

    <v-row dense no-gutters>
      <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
    </v-row>

    <v-row align="start" dense>
      <v-col>
        <!-- 테이블 -->
        <v-data-table
          :headers="headers"
          :items="itemList"
          no-data-text="데이터가 없습니다."
          hide-default-footer
          class="datatablestyle"
          item-key="ACCNT_NO"
          :loading="loading"
          :items-per-page="pagerow"
          :search="search"  
          >
          <template v-slot:header.AREA_NAME>
            <a :class="sort_name=='AREA_NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('AREA_NAME')">지점명</a>
          </template>
          <template v-slot:item.actions="{ item }">  
              <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
              <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
          </template>     
        </v-data-table>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=2d34ab8a2ba110eed5c9ab2ba610bea6&libraries=services"></script>
<script>
//import dateFormat from 'dateformat'
export default {
    data: () => ({
      
      //필수 페이징 파라메터
      loading : false,    //로딩 표시
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수

      gmap:null,
      gmarker:null,

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          AREA_NAME:'',
          AREA_TEL:'',
          AREA_URL:'',
          LATITUTE:'37.51205332712675',
          LONGITUTE:'127.05914127516714',
          AREA_ADDR1:'',
          AREA_ADDR2:'',
      },
      editedItem: {
      },


      
      search: '',         //필터링용 검색어

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
      },
      editedItem: {
      },

      //테이블 정의
      headers: [
        {text: '지점명',    value: 'AREA_NAME', sortable: false, align:'left', class:"cheader_normal" },
        {text: '전화번호',  value: 'AREA_TEL', sortable: false, align:'left', class:"cheader_normal" },
        {text: '주소',      value: 'AREA_ADDR1', sortable: false, align:'left', class:"cheader_normal"},
        {text: '홈페이지',   value: 'AREA_URL', sortable: false, align:'left', class:"cheader_normal"},
        {text: '수정/삭제',  value: 'actions', sortable: false, align:'center', class:"cheader_normal"}
      ],
      itemList: [],

      //검색 파라메터
      sort_name: 'AREA_NAME',
      sort_type: false,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 9999,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    

      marker_position:{lat: 37.512268, lng: 127.058940},
      map_position: {lat: 37.512268, lng: 127.058940},

      grade:0,

    }),

    mounted(){
      if(this.$session.get("GRADE") < 10){
        this.$alert("사용할 수 없는 등급의 페이지입니다.");
        this.$router.back();
        return;
      }
      this.grade = this.$session.get("GRADE");

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){

    },

    methods: {

      //검색
      search_run(){
        this.loadpage(1);
      },

      initpage(){
        //첫페이지 로드
        this.loadpage(1);
      },

      //정렬
      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      //페이징
      prev(){
          this.bottompage = this.bottompage - 10 >= 1 ? this.bottompage - 10 : 1;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage + this.subpage - 1; //이전을 누르면 가장 끝 번호 선택
          this.loadpage(temp_page);
      },
      next(){
          this.bottompage = this.bottompage + 10 <= this.maxpage ? this.bottompage + 10 : this.maxpage;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage;
          this.loadpage(temp_page);
      },
      makepaging(){
        var div1 = this.totalcnt / this.pagerow; 
        var div2 = this.totalcnt % this.pagerow;
        if(div2 == 0){  
            this.maxpage = parseInt(div1);    
        }else{
            this.maxpage = parseInt(div1+1);
        }
        if(this.bottompage == 1){
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
        }
      },

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      loadpage(p){
        
          if(this.loading){
            return;
          }
          this.itemList.splice(0, this.itemList.length);   //데이터 초기화
          this.loading = true;
          this.page = p;

          this.$http.post(this.$host+'/AreaList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
          },{headers: { firetoken: this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

                this.totalcnt = result.data.totalcnt;
                this.makepaging();
                const list = result.data.resultData;
                
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },


      //추가 저장
      save () {
        
        if(this.editedItem.AREA_NAME  == undefined || this.editedItem.AREA_NAME.length < 1){
          this.$alert("대표장소명을 입력하세요");
          return;
        } 
        if(this.editedItem.AREA_ADDR1  == undefined || this.editedItem.AREA_ADDR1.length < 1){
          this.$alert("주소를 검색하세요");
          return;
        } 
        if(this.editedItem.AREA_ADDR2  == undefined || this.editedItem.AREA_ADDR2.length < 1){
          this.$alert("상세주소를 입력하세요 (최대 50자)");
          return;
        } 

        if(this.editedItem.LATITUTE  == undefined || this.editedItem.LATITUTE.length < 1){
          this.$alert("주소에 대한 맵정보가 잘못되었습니다. 주소를 다시 입력해주세요");
          return;
        } 
        if(this.editedItem.LONGITUTE  == undefined || this.editedItem.LONGITUTE.length < 1){
          this.$alert("주소에 대한 맵정보가 잘못되었습니다. 주소를 다시 입력해주세요");
          return;
        } 


      //필수정보 체크
      const telVal = /[^0-9+-]/g;
      if(this.editedItem.AREA_TEL.length > 0){
        if(this.editedItem.AREA_TEL.length > 20){
          alert("전화번호는 20자 이내로 입력하세요");
          return;
        }
        if(telVal.test(this.editedItem.AREA_TEL)){
          this.$alert("전화번호는 숫자와 +-만 입력이 가능합니다.");
          return;
        }
      }

      
      if(this.editedItem.AREA_URL != null && this.editedItem.AREA_URL.length > 0){
        var areaurl = String(this.editedItem.AREA_URL);
        if(areaurl.toLowerCase().startsWith("http://") || areaurl.toLowerCase().startsWith("https://")){
        }else{
          this.$alert("홈페이지 주소는 http:// 또는 https://로 시작해야합니다.");
          return;
        }
      }


        if (this.editedIndex > -1) {

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/AreaUpdate',{
                AREA_NAME: escape(this.editedItem.AREA_NAME)
              , AREA_TEL: this.editedItem.AREA_TEL == null || this.editedItem.AREA_TEL == undefined ? '' : escape(this.editedItem.AREA_TEL)
              , AREA_URL: this.editedItem.AREA_URL == null || this.editedItem.AREA_URL == undefined ? '' : escape(this.editedItem.AREA_URL)
              , AREA_ADDR1: escape( this.editedItem.AREA_ADDR1)
              , AREA_ADDR2: escape(this.editedItem.AREA_ADDR2)
              , LATITUTE:   this.editedItem.LATITUTE
              , LONGITUTE:  this.editedItem.LONGITUTE
              , AREA_NO:   this.editedItem.AREA_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
                this.$alert('수정이 완료되었습니다.').then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert('수정이 실패했습니다.');
          });


        }else{
          
          //신규
          this.loading = true;
          this.$http.post(this.$host+'/AreaInsert',{
                AREA_NAME: escape(this.editedItem.AREA_NAME)
              , AREA_TEL: this.editedItem.AREA_TEL == null || this.editedItem.AREA_TEL == undefined ? '' : escape(this.editedItem.AREA_TEL)
              , AREA_URL: this.editedItem.AREA_URL == null || this.editedItem.AREA_URL == undefined ? '' : escape(this.editedItem.AREA_URL)
              , AREA_ADDR1: escape( this.editedItem.AREA_ADDR1)
              , AREA_ADDR2: escape(this.editedItem.AREA_ADDR2)
              , LATITUTE:   this.editedItem.LATITUTE
              , LONGITUTE:  this.editedItem.LONGITUTE
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
                this.$alert('추가가 완료되었습니다.').then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert('추가 실패');
          });


        }

        this.close()
      },     

      //신규 추가
      newinsert(){

        this.dialogTitle = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem)

        this.dialog = true;

        //맵은 화면에 div가 노출된 후에 그려야한다. 안보이면 오류남.
        if(this.gmap == null){
          setTimeout(() => {
            this.gmap = new google.maps.Map(document.getElementById('gmap'), {
              zoom: 16, center:this.map_position
            });
            this.gmarker = new google.maps.Marker({position:this.marker_position});
            this.gmarker.setMap(this.gmap);
          }, 300)
        }else{
          this.gmap.panTo(this.map_position);
          this.gmarker.setPosition(this.marker_position);
        }

      },

      //아이템 수정
      edit_item(item){
        this.dialogTitle = "수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        this.editedItem = Object.assign({}, item);

        //console.log("test : " + JSON.stringify(item));
        if(this.editedItem.LATITUTE != null && this.editedItem.LONGITUTE != null && this.editedItem.LATITUTE.length > 0 && this.editedItem.LONGITUTE.length > 0 ){
        this.map_position    = JSON.parse('{"lat":'+parseFloat(this.editedItem.LATITUTE)+', "lng":'+parseFloat(this.editedItem.LONGITUTE)+'}');
        this.marker_position = JSON.parse('{"lat":'+parseFloat(this.editedItem.LATITUTE)+', "lng":'+parseFloat(this.editedItem.LONGITUTE)+'}');
        }
        this.dialog = true;

        //맵은 화면에 div가 노출된 후에 그려야한다. 안보이면 오류남.
        if(this.gmap == null){
          setTimeout(() => {
            this.gmap = new google.maps.Map(document.getElementById('gmap'), {
              zoom: 16, center:this.map_position
            });
            this.gmarker = new google.maps.Marker({position:this.marker_position});
            this.gmarker.setMap(this.gmap);
          }, 300)
        }else{
          this.gmap.panTo(this.map_position);
          this.gmarker.setPosition(this.marker_position);
        }

      },

      //아이템 삭제
      delete_item(item){

        this.$confirm('정말 삭제하시겠습니까?')
        .then(() => {
          this.loading = true;
          this.$http.post(this.$host+'/AreaDelete',{
              AREA_NO:item.AREA_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

              this.$alert('삭제가 완료되었습니다.').then(()=>{
                const p = this.page;
                this.page = 0;
                this.loadpage(p);
              });
              
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('삭제가 실패했습니다.');
            }
            
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              this.loading = false;
              this.$alert('삭제 실패');
          });      

        });

      },      



      //지도
      postcodetoMap(mpos){
        this.map_position    = mpos;
        this.marker_position = mpos;

        this.gmap.panTo(this.map_position);
        this.gmarker.setPosition(this.marker_position);

        //지도위치 저장
        var temp = JSON.stringify(this.marker_position);
        const pos = JSON.parse(temp);
        this.editedItem.LATITUTE  =  String(pos.lat);
        this.editedItem.LONGITUTE =  String(pos.lng);
      },

      postcodetoAddr(addr){
        this.editedItem.AREA_ADDR1 = addr;
        this.$forceUpdate();
      },

      postcode(){

        //콜백에서 못찾아서 콘스트로 미리 빼둔다.
        const callback_mapfunction      = this.postcodetoMap;
        const callback_addrfunction     = this.postcodetoAddr;

        new daum.Postcode({
            oncomplete: function(data) {

                //console.log(JSON.stringify(data));
                var addr = data.address; // 최종 주소 변수
                if(data.userSelectedType == "J"){
                  //지번
                  addr = data.jibunAddress; 
                }

                //주소 정보를 해당 필드에 넣는다.
                callback_addrfunction(addr);
                
                var geocoder = new daum.maps.services.Geocoder();
                
                //주소로 상세 정보를 검색
                geocoder.addressSearch(data.address, function(results, status) {

                    // 정상적으로 검색이 완료됐으면
                    if (status === window.daum.maps.services.Status.OK) {

                        var result = results[0]; //첫번째 결과의 값을 활용

                        // 해당 주소에 대한 좌표를 받아서
                        //var coords = new daum.maps.LatLng(result.y, result.x);
                        var markerpos = JSON.parse('{"lat":'+result.y+', "lng":'+result.x+'}');
                        callback_mapfunction(markerpos);

                        //지도를 보여준다.
                        /*
                        mapContainer.style.display = "block";
                        map.relayout();
                        // 지도 중심을 변경한다.
                        map.setCenter(coords);
                        // 마커를 결과값으로 받은 위치로 옮긴다.
                        marker.setPosition(coords)
                        */

                    }
                });
            }
        }).open();

      },

    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>
  #gmap{
    width:500px;
    height: 300px;
    background-color: gray;
  }
</style>