<template>
  <v-container fluid >

    <!-- 프로그레스 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading_dialog" max-width="300px">
        <v-container class="d-flex flex-column justify-center align-center" 
                     style="background-color:white;width:300px;height:300px">
          <v-row class="ma-8">
            <v-progress-circular
              :size="100"
              :width="15"
              color="#cc3c48"
              indeterminate
              >
            </v-progress-circular>
          </v-row>
          <v-row class="ma-5">
            <label style="text-align:center">잠시만 기다려주세요</label>
          </v-row>
        </v-container >
      </v-dialog>
    </v-row>

    <!-- 엑셀등록 팝업 -->
    <v-row  align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="excel_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
            <v-row dense align="center" justify="center">
              <v-col cols="12"  align="center" style="height:70px;background:#690505;font-size:16px;line-height:18px" class="ma-0 pa-0 d-flex flex-wrap align-center justify-center">
                <div style="color:white">엑셀 양식 다운로드 및 엑셀 업로드</div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

              <v-row align="center" justify="start" class="mt-5">
                  <v-col cols="12" align="center">
                    <label class="menunamestyle">시험정보를 선택하세요</label>
                  </v-col>
              </v-row>

              <v-row align="center" justify="center">
                  <v-col cols="12" md="8" align="center">
                    <v-select 
                      v-model="SELECT_AREA_NO" :items="AREA_LIST" disabled label="지점" no-data-text="지점정보가 없습니다."
                      dense hide-details outlined item-text="AREA_NAME" item-value="AREA_NO" class="basicselectstyle" 
                      />
                  </v-col>
              </v-row>

              <v-row align="center" justify="center">
                  <v-col cols="12" md="8" align="center">
                    <v-select id="mySelect"
                      v-model="SELECT_YEAR" :items="SEARCHYEAR" disabled label="년도" @change="loadexam" no-data-text="년도정보가 없습니다."
                      dense hide-details outlined item-text="CODE_NAME" item-value="CODE_VALUE" class="basicselectstyle" 
                      />
                  </v-col>
              </v-row>

              <v-row align="center" justify="center">
                  <v-col cols="12" md="8" align="center">
                    <v-select v-model="SELECT_SCHOOLYEAR" disabled :items="SEARCHSCHOOLYEAR" label="학년" 
                      class="basicselectstyle"  no-data-text="학년정보가 없습니다."
                      dense hide-details outlined item-text="CODE_NAME" item-value="CODE_VALUE"/>
                  </v-col>
              </v-row>

              <v-row align="center" justify="center">
                  <v-col cols="12" md="8" align="center">
                    <v-select v-model="SELECT_EXAM" disabled :items="SEARCHEXAM" label="시험" 
                      class="basicselectstyle" @change="loadsubject" no-data-text="시험정보가 없습니다."
                      dense hide-details outlined item-text="EXAM_NAME" item-value="EXAM_NO"/>
                  </v-col>
              </v-row>

              <v-row align="center" justify="center" v-if="SELECT_EXAMNAME.length > 0">
                <v-col cols="12" md="8" align="center">
                  <v-text-field
                    label="공통과목"
                    v-model="SELECT_EXAMNAME" disabled outlined dense hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row align="center" justify="center" v-if="SEARCHSUBJECT.length > 0">
                <v-col cols="12" md="8" align="center">
                  <v-select v-model="SELECT_SUBJECT" :items="SEARCHSUBJECT" label="선택과목" 
                    class="basicselectstyle" no-data-text="과목정보가 없습니다."
                    dense hide-details outlined item-text="SUBJECT_NAME" item-value="SUBJECT_NO"/>
                </v-col>
              </v-row>

              <v-row align="center" justify="center">
                <v-col cols="12" md="4" align="center">
                  <v-btn width="94%" class="search_btn_freesize" @click="downexcel">엑셀양식 다운로드</v-btn>
                </v-col>
              </v-row>

              <v-row align="center" justify="center">
                <v-col cols="11">
                  <v-divider/>
                </v-col>
              </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">엑셀 업로드</p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-file-input
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
                      v-model="uploadExcelFile" dense></v-file-input>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-progress-linear color="black" :indeterminate="excel_prog"/>
                </v-row>

              </v-col>
            </v-row>              
            </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="excel_dialog = false">취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="uploadexcel">등록</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <v-row  align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="kakao_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
            <v-row dense align="center" justify="center">
              <v-col cols="12"  align="center" style="height:70px;background:#690505;font-size:16px;line-height:18px" class="ma-0 pa-0 d-flex flex-wrap align-center justify-center">
                <div style="color:white">카카오톡 알림톡 발송</div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->
              
                <v-row align="center" justify="center" class="mt-5">
                    <v-col cols="12" md="8" align="center">
                      <label class="menunamestyle">{{editedItem.EXAM_YEAR +' '+editedItem.GRADENAME + ' ' + editedItem.EXAM_NAME }}</label>
                    </v-col>
                </v-row>

                <v-row align="center" justify="center" class="mt-5">
                    <v-col cols="12" align="center">
                      <label class="menunamestyle">{{editedItem.NAME}} 학생의 알림톡을 발송하실 전화번호를 입력하세요</label>
                    </v-col>
                </v-row>

                <v-row align="center" justify="center" class="mt-5">
                  <v-col cols="8" align="center">
                    <v-text-field
                      label="전화번호"
                      v-model="kakao_number" outlined dense hide-details class="popupiputstyle"
                      />
                  </v-col>
                </v-row>

              </v-col>
            </v-row>              
            </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="kakao_dialog = false">취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="sendKakaoAalimTalk">발송</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <v-row align="center" justify="start" class="mt-0 mt-md-5">
      <v-col cols="6" md="3">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-col cols="6" md="2" align="center">
        <v-select id="mySelect"
          v-model="SELECT_AREA_NO" :items="AREA_LIST" label="지점" no-data-text="지점이 없습니다."
          dense hide-details outlined item-text="AREA_NAME" item-value="AREA_NO" class="basicselectstyle" 
          :disabled="grade < 90" @change="search_run"
          />
      </v-col>
      <v-col cols="6" md="2" align="center">
        <v-select id="mySelect"
          v-model="SELECT_YEAR" :items="SEARCHYEAR" label="년도" @change="loadexam(true)"
          dense hide-details outlined item-text="CODE_NAME" item-value="CODE_VALUE" class="basicselectstyle" 
          />
      </v-col>
      <v-col cols="6" md="2" align="center">
        <v-select v-model="SELECT_SCHOOLYEAR" :items="SEARCHSCHOOLYEAR" label="학년" 
          class="basicselectstyle" @change="loadexam(true)"
          dense hide-details outlined item-text="CODE_NAME" item-value="CODE_VALUE"/>
      </v-col>
      <v-col cols="12" md="3" align="center">
        <v-select v-model="SELECT_EXAM" :items="SEARCHEXAM" label="시험" 
          class="basicselectstyle" @change="search_run" no-data-text="시험정보가 없습니다."
          dense hide-details outlined item-text="EXAM_NAME" item-value="EXAM_NO"/>
      </v-col>
    </v-row>

    <v-row align="center" justify="end" dense >
      <v-col md="2" cols="6" class="hidden-sm-and-down">
        <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="12" md="1" align="center">
        <v-btn class="search_btn_freesize" elevation="0" outlined large @click="insertExcel">엑셀등록</v-btn>
      </v-col>
      <v-col cols="12"  md="3" sm="6" xs="6">
        <v-text-field 
          dense single-line clearable
          @keyup.enter="search_run" 
          v-model="search_keyword" 
          outlined height="43"
          color="#808080"
          hide-details
          placeholder="검색어를 입력하세요"
          class="basicsearchstyle"
          />
      </v-col>
      <v-col cols="6" md="1" align="center">
        <v-btn class="search_btn_freesize" elevation="0" outlined large @click="search_run">검색</v-btn>
      </v-col>
      <v-col cols="6" md="2" align="center">
        <v-btn class="search_btn_freesize" elevation="0" outlined large @click="inputscore">정답입력</v-btn>
      </v-col>
    </v-row>


    
  <v-row align="start" dense>
    <v-col > 
      <!-- 테이블 -->
      <v-data-table
        class="datatablestyle"
        :headers="headers"
        :items="itemList"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        :items-per-page="pagerow"
        :search="search"  
        item-key="IDX"
        :loading="loading"
        >

        <template v-slot:header.SCORE>
          <a :class="sort_name=='SCORE'?'cheader_sortselect':'cheader_sort'" @click="customsort('SCORE')">점수</a>
        </template>
        <template v-slot:header.RANK>
          <a :class="sort_name=='RANK'?'cheader_sortselect':'cheader_sort'" @click="customsort('RANK')">석차</a>
        </template>
        <template v-slot:header.GRADE>
          <a :class="sort_name=='GRADE'?'cheader_sortselect':'cheader_sort'" @click="customsort('GRADE')">등급</a>
        </template>
        <template v-slot:header.REG_DATE>
          <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">응시일시</a>
        </template>

        <template v-slot:item.RANK="{ item }">  
          {{ item.CNT > 1 ? item.RANK+'('+item.CNT+')': item.RANK }}
        </template>     
        <template v-slot:item.kakao="{ item }">  
          <a style="color:blue"  @click="sendKakao(item)">알림톡</a>
        </template>     
        <template v-slot:item.print="{ item }">  
          <a style="color:blue"  @click="print(item)">출력</a>
        </template>     
        <template v-slot:item.actions="{ item }">  
            <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
        </template>     
      </v-data-table>
    </v-col>
  </v-row>

  <!-- 하단 페이징 영역 -->
  <v-row align="start">
    <v-col cols="12" md="1"/>
    <v-col cols="12" md="2" class="hidden-sm-and-down">
      <v-text-field
        v-model="search"
        append-icon="search"
        single-line
        hide-details
        outlined height="43"
        color="#808080"
        dense
        placeholder="페이지 내에서 검색"
        class="basicsearchstyle"
        />
    </v-col>
    <v-col cols="12" md="6" class="d-flex justify-center">
      <v-btn v-if="bottompage > 10" icon  small @click="prev()"><v-icon dark>mdi-chevron-left</v-icon></v-btn>
      <span v-for="n in subpage" :key="n.id">
          <span v-if="page == bottompage-1+n">
              <v-btn color="red" class="numberstyle" icon small @click="movepage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
          <span v-else>
              <v-btn icon small class="numberstyle2" @click="movepage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
      </span>
      <v-btn v-if="subpage == 10" icon small @click="next()" ><v-icon dark>mdi-chevron-right</v-icon></v-btn>
    </v-col>
    <v-col cols="12" md="2" class="hidden-sm-and-down">
      <v-select
        v-model="pagerow"
        :items="pagerow_combo"
        item-text="NAME"      
        item-value="VALUE"
        min-width="150px"
        outlined height="43"
        color="#808080"
        dense
        hide-details
        placeholder="목록개수"
        class="basicsearchstyle"
        ></v-select>
    </v-col>
    <v-col cols="12" md="1"/>
  </v-row>

  
  

  </v-container>
</template>

<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=2d34ab8a2ba110eed5c9ab2ba610bea6&libraries=services"></script>
<script>
import XLSX from 'xlsx'
import ScorePage from '../components/ScorePage.vue'
import Vue from 'vue'
export default {

    data: () => ({

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //테이블 정의
      headers: [
        {text: '지점', value: 'AREA_NAME', width:150, sortable: false, align:'center', class:"cheader_normal" },
        {text: '년도', value: 'EXAM_YEAR', width:80, sortable: false, align:'center', class:"cheader_normal"},
        {text: '학년', value: 'GRADENAME', width:80, sortable: false, align:'center', class:"cheader_normal" },
        {text: '이름', value: 'NAME', width:120, sortable: false, align:'center', class:"cheader_normal" },
        {text: '시험명', value: 'EXAM_NAME', width:200, sortable: false, align:'center', class:"cheader_normal"},
        {text: '과목', value: 'COMMON_NAME', width:120, sortable: false, align:'center', class:"cheader_normal"},
        {text: '선택', value: 'SELECT_NAME', width:120, sortable: false, align:'center', class:"cheader_normal"},
        {text: '점수', value: 'SCORE', width:80, sortable: false, align:'center', class:"cheader_normal" },
        {text: '석차', value: 'RANK', width:80, sortable: false, align:'center', class:"cheader_normal" },
        {text: '등급', value: 'GRADE', width:80, sortable: false, align:'center', class:"cheader_normal" },
        {text: '응시일시', value: 'REG_DATE', width:200, sortable: false, align:'center', class:"cheader_normal" },
        {text: '발송', value: 'kakao', width:80, sortable: false, align:'center', class:"cheader_normal"},
        {text: '출력', value: 'print', width:80, sortable: false, align:'center', class:"cheader_normal"},
        {text: '수정', value: 'actions', width:80, sortable: false, align:'center', class:"cheader_normal"}
      ],
      itemList: [],
      
      //검색 파라메터
      sort_name: 'RANK',
      sort_type: false,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
      
      SELECT_AREA_NO: null,
      SELECT_YEAR:null,
      SELECT_SCHOOLYEAR:null,
      SELECT_EXAM:null,
      SELECT_EXAMNAME:"",
      SELECT_SUBJECT:null,

      AREA_LIST:[],
      SEARCHYEAR: [],
      SEARCHSCHOOLYEAR: [],
      SEARCHEXAM: [],
      SEARCHSUBJECT: [],

      //엑셀등록
      excel_dialog: false,
      excel_prog: false,
      uploadExcelFile:null,
      
      loading_dialog: false,
      grade:0,

      kakao_dialog: false,
      kakao_number: "",
      editedItem: {},

    }),

    mounted(){
      this.grade = this.$session.get("GRADE");
      this.SELECT_AREA_NO  = this.$session.get("AREA_NO");
      if(this.grade < 10){
        this.$alert("사용할 수 없는 페이지입니다.").then(()=>{
          this.$router.push("Welcome");
        });
        return;
      }

      
      if(this.$route.query.page == undefined || this.$route.query.page == null){
        this.page = 1;
      }else{
        this.page = this.$route.query.page;
      }
      if(this.$route.query.search == undefined || this.$route.query.search == null){
        this.search_keyword = '';
      }else{
        this.search_keyword = this.$route.query.search;
      }
      if(this.sort_type == undefined || this.sort_type == null){
        this.sort_type = true;
      }else{
        if(this.$route.query.sorttype == "true"){
          this.sort_type = true;
        }else if(this.$route.query.sorttype == "false"){
          this.sort_type = false;
        }
      }
      this.sort_name = this.$route.query.sortname;
      if(this.sort_name == undefined || this.sort_name == null){
        this.sort_name = 'RANK';
      }      
      
      if(this.$route.query.area == undefined || this.$route.query.area == null){
        this.SELECT_AREA_NO = null;
      }else{
        this.SELECT_AREA_NO = Number(this.$route.query.area);
      }
      if(this.$route.query.year == undefined || this.$route.query.year == null){
        this.SELECT_YEAR = null;
      }else{
        this.SELECT_YEAR = Number(this.$route.query.year);
      }
      if(this.$route.query.grade == undefined || this.$route.query.grade == null){
        this.SELECT_SCHOOLYEAR = null;
      }else{
        this.SELECT_SCHOOLYEAR = Number(this.$route.query.grade);
      }
      if(this.$route.query.exam == undefined || this.$route.query.exam == null){
        this.SELECT_EXAM = null;
      }else{
        this.SELECT_EXAM = Number(this.$route.query.exam);
      }
      //console.log("SELECT_EXAM : " + this.SELECT_EXAM);

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {


      //엑셀 등록
      insertExcel(){

        if(this.SELECT_EXAM == null){
          this.$alert("시험을 선택하세요");
          return;
        }
        this.SELECT_SUBJECT = null;
        this.uploadExcelFile = null;
        this.excel_dialog = true;
        this.loadsubject();
      },

      
      //엑셀등록
      async uploadexcel(){
        const exam_object = this.getSelectObject();
        if(exam_object.CNT2 > 0){
          if(this.SELECT_SUBJECT == null){
            this.$alert("선택과목을 선택하세요");
            return;
          }
        }
        if(this.uploadExcelFile == null){
          this.$alert("엑셀파일을 선택해주세요");
          return;
        }

        //엑셀양식 가져오기 (검증용)
        var excelinfo = await this.getExcelInfo();
        if(excelinfo == null || excelinfo.length == 0){
            this.$alert("엑셀양식을 확인할 수 없습니다.");
            return;
        }
        //검증용 infoItem 만들기 TYPE : 0 객관식 숫자만 사용, TYPE : 1 주관식 문자사용 가능
        var infoItem = [];
        infoItem.push({NAME:'이름', TYPE: 1});
        excelinfo.forEach(info=>{
          infoItem.push({NAME:info.ANSWERS_NUMBER, ANSWERS_TYPE: info.ANSWERS_TYPE});
        });

        //엑셀파일 등록
        this.excel_prog = true;
        const reader = new FileReader();
        reader.onload = async () => {

          var fileData = reader.result;
          let workbook = XLSX.read(fileData, {type : 'binary'});  
          var sheetNameList = workbook.SheetNames; // 시트 이름 목록 가져오기 
          var firstSheetName = sheetNameList[0]; // 첫번째 시트명            
          var firstSheet = workbook.Sheets[firstSheetName]; // 첫번째 시트 
          let wrokjson = XLSX.utils.sheet_to_json(firstSheet, {raw:false, defval: ''});

          //빈값 제거
          var uploaditem = [];
          const numberVal1 = /[^0-9]/g;
          
          if(wrokjson.length > 1000){
            this.$alert("한번에 1000명까지 등록이 가능합니다.");
            this.excel_prog = false;
            return;
          }

          wrokjson.forEach(element=>{
            var nullcnt = 0;
            for(var j=0;j<infoItem.length;j++){
              var column_name = infoItem[j].NAME;
              if(element[column_name] == null || String(element[column_name]).length < 1){
                nullcnt++;
              }
            }
            //비교 - 전부다 값이 비어있으면 무시하고 값이 입력된 경우에만 업로드 대상
            if(nullcnt != infoItem.length){
              uploaditem.push(element);
            }
          })

          if(uploaditem.length < 1){
            this.$alert("엑셀파일 입력데이터가 부족합니다.");
            this.excel_prog = false;
            return;
          }

          //데이터 검증 
          var checkflag = true;
          var read_column = Object.keys(wrokjson[0]);

          //컬럼수 비교
          if(read_column.length != infoItem.length){
            this.$alert("엑셀양식이 다릅니다. 양식을 다시 다운로드하세요.");
            this.excel_prog = false;
            return;
          }

          //엑셀파일과 기본양식 컬럼값 비교
          const infoMap = new Map(infoItem.map(item=>[String(item.NAME), item]));
          for(var k=0;k<read_column.length;k++){
            var column_name = read_column[k];
            if(!infoMap.has(column_name)){
              checkflag = false;
              break;
            }
          }
          //컬럼문제번호 비교
          if(!checkflag){
            this.$alert("엑셀양식이 다릅니다. 양식을 다시 다운로드하세요.");
            this.excel_prog = false;
            return;
          }

          for(var i=0;i<uploaditem.length;i++){
            if(!checkflag){
              break;
            }
            var element = uploaditem[i];
            //값 체크
            for(var j=0;j<infoItem.length;j++){
              var column_name = infoItem[j].NAME;
              if(element[column_name] == null || String(element[column_name]).length < 1){
                this.$alert((i+1)+"번째 줄의 "+column_name+" 값을 입력하세요");
                checkflag = false;
                break;
              }
              if(infoItem[j].ANSWERS_TYPE == 0){  
                //객관식
                if(element[column_name].length > 1){
                  this.$alert((i+1)+"번째 줄의 "+column_name+"번 문제는 객관식입니다. 1자리의 숫자를 입력하세요");  
                  checkflag = false;
                  break;
                }
                if(numberVal1.test(element[column_name])){
                  this.$alert((i+1)+"번째 줄의 "+column_name+"번 문제는 객관식입니다. 숫자만 입력하세요");  
                  checkflag = false;
                  break;
                }
              }
            }

            if(!checkflag){
              this.excel_prog   = false;
              this.excel_dialog = false;
              return;
            }

          }

          if(!checkflag){
            this.excel_prog = false;
            this.excel_dialog = false;
            return;
          }
          
          this.$alert("통과!");
          
          //서버로 전송해서 처리
          this.$http.post(this.$host+'/ExcelAnswersInsert',{
              JSONVALUE: escape(JSON.stringify(uploaditem))
            , AREA_NO: this.SELECT_AREA_NO
            , EXAM_NO: this.SELECT_EXAM
            , SUBJECT_NO: this.SELECT_SUBJECT
          },{headers: { firetoken:this.$session.get("FIRETOKEN") }})
          .then((result)=>{
            this.excel_prog   = false;
            this.excel_dialog = false;
            if(result.data.resultCode == 0){
              this.$alert("엑셀 등록이 완료되었습니다.").then(()=>{
                this.loadpage(1);
              });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.loading = false;
              if(result.data.resultMsg != null && result.data.resultMsg != undefined && result.data.resultMsg.length > 0){
                this.$alert(result.data.resultMsg);
              }else{
                this.$alert("엑셀 등록에 실패했습니다.");
              }
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.excel_prog   = false;
            this.excel_dialog = false;
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert("엑셀 등록에 실패했습니다.");
            }
          });

        }

        reader.readAsBinaryString(this.uploadExcelFile);  //비동기로 파일을 읽어서        

      },

      //양식다운
      async downexcel(){
        const exam_object = this.getSelectObject();
        if(exam_object.CNT2 > 0){
          if(this.SELECT_SUBJECT == null){
            this.$alert("선택과목을 선택하세요");
            return;
          }
        }

        var excelinfo = await this.getExcelInfo();
        if(excelinfo == null || excelinfo.length == 0){
            this.$alert("엑셀양식을 확인할 수 없습니다.");
            return;
        }

        // 워크북 생성
        const wb = XLSX.utils.book_new();

        // 워크시트 생성
        var ws = this.createSheetData(excelinfo);
        
        // 워크북에 워크시트 추가
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        
        // 엑셀 파일 저장
        var filename = exam_object.EXAM_NAME;
        if(exam_object.CNT2 > 0){
          const subject_object = this.getSelectSubject();
          filename = exam_object.EXAM_NAME+"("+subject_object.SUBJECT_NAME+")";
        }
        XLSX.writeFile(wb, filename+'.xlsx');

      },

      createSheetData(excelinfo) {
        
        //워크시트 생성
        const ws = XLSX.utils.json_to_sheet([], { skipHeader: true });

        // 컬럼명 정의
        const columns = ['이름'].concat(excelinfo.map(info=>info.ANSWERS_NUMBER));

        // 첫 번째 행은 컬럼명으로 채우기
        columns.forEach((col, idx) => {
          const cellRef = XLSX.utils.encode_cell({ c: idx, r: 0 });
          ws[cellRef] = { t: 's', v: col };
        });

        // 두 번째 행부터는 컬럼값 비워놓기
        for (let r = 1; r <= 1000; r++) {
          for (let c = 0; c < columns.length; c++) {
            const cellRef = XLSX.utils.encode_cell({ c, r });
            ws[cellRef] = { t: 's', v: '', z:'@' };
          }
        }
        
        //워크시트 크기 조정
        ws['!ref'] = XLSX.utils.encode_range({ s: { c: 0, r: 0 }, e: { c: columns.length-1, r: 1000 } });

        return ws;
      },      

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      prev(){
          this.bottompage = this.bottompage - 10 >= 1 ? this.bottompage - 10 : 1;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage + this.subpage - 1; //이전을 누르면 가장 끝 번호 선택
          this.loadpage(temp_page);
      },
      next(){
          this.bottompage = this.bottompage + 10 <= this.maxpage ? this.bottompage + 10 : this.maxpage;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage;
          this.loadpage(temp_page);
      },

      makepaging(){
        var div1 = this.totalcnt / this.pagerow; 
        var div2 = this.totalcnt % this.pagerow;
        if(div2 == 0){  
            this.maxpage = parseInt(div1);    
        }else{
            this.maxpage = parseInt(div1+1);
        }
        if(this.bottompage == 1){
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
        }
      },

      loadpage(p){

          this.loading = true;
          this.page = p;

          this.$http.post(this.$host+'/AdminScoreList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , SELECT_YEAR: this.SELECT_YEAR
              , SELECT_SCHOOLYEAR: this.SELECT_SCHOOLYEAR
              , EXAM_NO: this.SELECT_EXAM
              , SELECT_AREA: this.SELECT_AREA_NO
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            
            //console.log("result : "+ JSON.stringify(result));

            this.loading = false;

            if(result.data.resultCode == 0){
                this.totalcnt = result.data.totalcnt;
                this.makepaging();
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },

      async initpage(){
        
        //지점 리스트 조회
        await this.loadarea();
        if(this.SELECT_AREA_NO == null){
          this.SELECT_AREA_NO = this.AREA_LIST[0].AREA_NO;
        }

        //공통코드 조회
        await this.loadcomcode("YEAR", "ASC", this.SEARCHYEAR);
        if(this.SELECT_YEAR == null){
          this.SELECT_YEAR = this.SEARCHYEAR[0].CODE_VALUE;
        }

        await this.loadcomcode("SCHOOLYEAR", "DESC", this.SEARCHSCHOOLYEAR);
        if(this.SELECT_SCHOOLYEAR == null){
          this.SELECT_SCHOOLYEAR = this.SEARCHSCHOOLYEAR[0].CODE_VALUE;
        }

        await this.loadexam(false);
        if(this.SELECT_EXAM == null){
          this.SELECT_EXAM = this.SEARCHEXAM[0].EXAM_NO;
        }

        //첫페이지 로드
        this.loadpage(this.page);

      },      

      movepage(p){
        this.$router.push({path:"AdminScore", query:{page:p, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name, area:this.SELECT_AREA_NO, year:this.SELECT_YEAR, grade:this.SELECT_SCHOOLYEAR, exam:this.SELECT_EXAM}})
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
        });
      },

      //검색
      search_run(){
        this.movepage(1);
      },

      //엑셀양식정보 가져오기
      async getExcelInfo(){
        var excelinfo = [];
        
        await this.$http.post(this.$host+'/getExcelInfo',{
              EXAM_NO: this.SELECT_EXAM
            , SUBJECT_NO: this.SELECT_SUBJECT
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          if(result.data.resultCode == 0){
            const list = result.data.resultData;
            list.forEach(element => {
                excelinfo.push(element); 
            });
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });
        return excelinfo;
      },


      async loadarea(){

        await this.$http.post(this.$host+'/AreaList',{
              search_keyword: ''
            , sort_name: "AREA_NAME"
            , sort_type: "DESC"
            , page: 1
            , pagerow: 99999
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{

          if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            const list = result.data.resultData;
            this.AREA_LIST.splice(0, this.AREA_LIST.length);   //데이터 초기화
            list.forEach(element => {
                this.AREA_LIST.push(element); 
            });

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.loading = false;
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.loading = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });

      },

      //공통코드 로드 : codetype, DESC, 추가할 어레이 객체
      async loadcomcode(codetype, sorttype, arraylist){

        await this.$http.post(this.$host+'/getCodeList',{
              CODE_TYPE: codetype
            , sort_type: sorttype
        },{headers: { firetoken:this.$session.get("FIRETOKEN") }})
        .then((result)=>{
          

          if(result.data.resultCode == 0){

            const list = result.data.resultData;
            arraylist.splice(0, arraylist.length);   //데이터 초기화
            list.forEach(element => {
                arraylist.push(element); 
            });

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.loading = false;
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.loading = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });

      },              



      async loadexam(clear_flag){

        //초기화
        if(clear_flag){
          this.SELECT_EXAM = null;
          this.SELECT_EXAMNAME = "";
          this.SELECT_SUBJECT = null;
        }

          await this.$http.post(this.$host+'/ExamList',{
                search_keyword: ""
              , SELECT_YEAR: this.SELECT_YEAR
              , SELECT_SCHOOLYEAR: this.SELECT_SCHOOLYEAR
              , sort_name: "EXAM_NAME"
              , sort_type: "ASC" 
              , page: 1
              , pagerow: 9999
          },{headers: { firetoken:this.$session.get("FIRETOKEN") }})
          .then((result)=>{
            
            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                this.SEARCHEXAM.splice(0, this.SEARCHEXAM.length);   //데이터 초기화
                list.forEach(element => {
                    this.SEARCHEXAM.push(element); 
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },      


      getSelectObject(){
        for(var i=0; i < this.SEARCHEXAM.length ; i++){
          const temp = this.SEARCHEXAM[i];
          if(temp.EXAM_NO == this.SELECT_EXAM){
            return temp;
          }
        }
      },


      getSelectSubject(){
        for(var i=0; i < this.SEARCHSUBJECT.length ; i++){
          const temp = this.SEARCHSUBJECT[i];
          if(temp.SUBJECT_NO == this.SELECT_SUBJECT){
            return temp;
          }
        }
      },      

      async loadsubject(){
          
          //선택된 시험값 가져오기
          const exam_object = await this.getSelectObject();
          //console.log("exam object : " + JSON.stringify(exam_object));
          if(exam_object == null || exam_object == undefined){
            return;
          }

          //선택된 시험의 공통과목 이름 넣기
          this.SELECT_EXAMNAME = exam_object.EXAM_NAME;

          //선택된 시험은 선택과목이 없음.
          this.SEARCHSUBJECT.splice(0, this.SEARCHSUBJECT.length);   //데이터 초기화
          if(Number(exam_object.CNT2) < 1){
            return;
          }

          await this.$http.post(this.$host+'/getSelectSubject',{
                EXAM_NO: this.SELECT_EXAM
          },{headers: { firetoken:this.$session.get("FIRETOKEN") }})
          .then((result)=>{
            
            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                list.forEach(element => {
                    this.SEARCHSUBJECT.push(element); 
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },      


      async print(item){
        
        //console.log("print:"+JSON.stringify(item));
        
        //개발용, 개발할때 미리보면서 코딩하기 위한 용도
        //this.$router.push({path:"ScorePage_Preview", query:{takeno:item.TAKE_NO}});
        
        //this.$router.push({path:"ScoreInfo", query:{takeno:item.TAKE_NO}});
        
        //실제 인쇄용
        this.loading_dialog = true;
        const ScorePageConstructor = Vue.extend(ScorePage);
        const vm = new ScorePageConstructor();
        await vm.initpage(item.TAKE_NO);
        this.loading_dialog = false;
        
      },

      //아이템 수정
      edit_item(item){
        this.$router.push({path:"AdminScoreUpdate", query:{take:item.TAKE_NO, exam:item.EXAM_NO}});
      },

      //정답 입력
      inputscore(){
        this.$router.push({path:"AdminSelectExam", query:{area_no:this.SELECT_AREA_NO, year:this.SELECT_YEAR, grade:this.SELECT_SCHOOLYEAR, exam:this.SELECT_EXAM}});
      },

      //알림톡 발송
      sendKakao(item){
        this.kakao_dialog = true;
        this.editedItem = Object.assign({}, item);
      },
      

      sendKakaoAalimTalk(){

        if(this.kakao_number == null || this.kakao_number == undefined || this.kakao_number.trim().length == 0){
          this.$alert('알림톡을 발송하실 전화번호를 입력하세요');
          return;
        }
        if(!this.kakao_number.startsWith("010")){
          this.$alert('전화번호는 010으로 시작해야 합니다.');
          return;
        }

        const vlist = [];
        let person = {"TAKE_NO":this.editedItem.TAKE_NO, "TEL":this.kakao_number.replace(/-/g,'')};
        vlist.push(person);

        this.$http.post(this.$host+'/KAKAOupload',{
          JSONVALUE: escape(JSON.stringify(vlist))
        },{headers: { firetoken:this.$session.get("FIRETOKEN") }})
        .then((result)=>{
          this.kakao_dialog = false;
          this.kakao_number = "";
          if(result.data.resultCode == 0){
            this.$alert('발송 요청이 완료되었습니다.');

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.$alert('알림톡 발송 실패');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.kakao_dialog = false;
          this.kakao_number = "";
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });

      },


    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>