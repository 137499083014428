<template>

  <v-container fluid>

    <v-row dense align="center" justify="start">
        <v-col cols="12">
          <v-btn width="100%" class="search_btn_freesize" elevation="0" outlined large @click="dialog_com = true">보기</v-btn> 
        </v-col>
    </v-row>   

    <v-row style="background:gray">

      <v-dialog persistent scrollable overlay-color="#00000040" v-model="dialog_com" fullscreen>

        <v-row class="mt-5" justify="center" style="background:gray">
            <v-btn class="dialog_cancel_btn" rounded large @click="dialog_com = false">닫기</v-btn>
        </v-row>

        <v-row class="mt-5" justify="center">
          <v-col>
            <div id="prewview"/>
          </v-col>
        </v-row>

      </v-dialog>
    </v-row>  

  </v-container>
</template>

<script>
import intersectRect from 'intersect-rect';
export default {
    //전달받을 프로퍼티 정의, 타입을 정의해도 되고 안해도 되고
    props:{
        selectlist: Array,
    },

    data: () => ({
        prewview_div: null,
        dialog_com:false,
    }),

    mounted(){
      //console.log("dialog mount....");
      
    },

    updated(){
      //console.log("dialog updated....");
      
      if(this.dialog_com){
        //다이얼로그 뜰 때
        this.initpage();
      }
    },

    create(){
      //console.log("dialog create....");
    },

    methods: {

        pxTomm(px){
            return (px * 0.2645833333).toFixed(5);
        },

        async initpage() {
            
            this.prewview_div = document.getElementById("prewview");

            //문제생성 중단용 플래그 (무한루프 방지용)
            var stopFlag = false;

            //환경변수
            const qcnt  = 5;    //문항, 2,3,4,5,6
            const qtype = 2;    //가로 0, 세로 1, 가로장형 2
            const answer_print  = false;    //정답및해설 출력
            const history_print = false;    //오답히스토리 출력

            //용지 사이즈 및 여백등
            const a4width       = 210;
            const a4height      = 297;
            const sidemargin    = this.pxTomm(36);  //좌,우는  36px 공백
            const topmargin     = this.pxTomm(36);
            const centermargin  = qtype == 2 ? 0 : this.pxTomm(16);  //가운데는 16px 공백인데 가로장형은 0px

            var col = 0;    //좌우에 배치되야 하는 문제의 개수
            var row = 0;    //세로 줄 수

            //타입과 문항수를 가지고 한 페이지에 들어가야 하는 문제의 영역수를 계산한다.
            col = qtype == 2 ? 1 : 2; //가로 장형이면 1줄에 1문제이고 그외에는 1줄에 2문제씩
            row = parseInt(qcnt/col);
            if(qcnt % col != 0){
              row++;
            }
            //console.log("col : " + col  + " row : " + row);

            //문제당 넓이와 높이
            //한 문제의 영역 넓이 구하기 - a4사이즈에서 좌우 마진과 가운데 마진을 제거함.
            const question_width  = (a4width - (sidemargin*2) - (centermargin)) / col;

            //한 문제의 영역 높이 구하기 
            const question_height = (a4height - (topmargin*2)) / row;

            //px을mm로 변환하는 함수를 const로 변환해둔다. 콜백안에서 실행해야해서
            const pxTomm = this.pxTomm;
            

            //문제를 페이지에 그리기
            var questionList = this.$selectlist;


            //문제를 돌면서 하나씩 그려보자.
            var page_div = null;

            //충돌 체크용 문제 영역 리스트
            var checkList = [];
            var checkpos = 0; //화면에 배치해야 하는 위치

            ////console.log("=========================== start ======================== ");

            for(var q = 0; q < questionList.length ; q++){
              
              ////console.log("checkpos : " + checkpos);
              if(stopFlag) break;
              
              if(checkpos >= qcnt){
                //홀수 문항의 경우 ex) 3, 5등은 한자리 비워야함.
                checkpos = 0;
              }

              if(checkpos == 0){

                if(page_div != null){
                  this.prewview_div.appendChild(page_div);
                  page_div = null;
                }

                checkList.splice(0, checkList.length);  //초기화

                //A4사이즈 시험지 페이지 생성
                page_div = document.createElement("div");
                page_div.style.width    = "210mm";
                page_div.style.height   = "297mm";
                page_div.style.backgroundColor = "#ffffff";
                page_div.style.padding  = this.pxTomm(36)+"mm";
                page_div.style.margin   = 0;

                //페이지 탑 마진으로 구분해서 보기 (테스트용 나중에 주석처리 필요)
                page_div.style.marginTop = 1+"mm";

                var c = 0, r = 0;
                if(qtype == 0 || qtype == 2){
                  //가로 배열
                  //row for문 - 줄
                  for(r = 0; r < row ; r++ ){
                    if(stopFlag) break;

                    //col for문
                    for(c = 0; c < col ; c++ ){
                      if(stopFlag) break;

                      ////console.log("r : " + r + " / c " + c + " w : " + question_width + " / h " + question_height + "y : " + (r * (question_height) ) + " / x :" + ( c*(question_width) + (c*centermargin) ));
                      const qbg = document.createElement("div");
                      qbg.model = {};
                      qbg.model.type = "bg";  
                      qbg.style.position = "absolute";
                      qbg.style.width  = question_width+"mm";
                      qbg.style.height = question_height+"mm";
                      
                      qbg.style.marginTop  = ( r * (question_height) ) + "mm";                      //y좌표
                      qbg.style.marginLeft = ( c * (question_width) + (c * centermargin) ) + "mm";  //x좌표

                      //계산용 정보 백업
                      qbg.model.width  = question_width;
                      qbg.model.height = question_height;
                      qbg.model.posy = ( r * (question_height) );
                      qbg.model.posx = ( c * (question_width) + ( c * centermargin) );

                      //배경에 공책 줄 넣기 - 이미지로 반복
                      qbg.style.backgroundImage  = `url(${require('../assets/bg_dotted.png')})`;
                      qbg.style.backgroundRepeat = "repeat";
                      //qbg.style.backgroundColor  = "#ff00ff"
                      page_div.appendChild(qbg);

                      checkList.push(qbg);

                    }

                  }

                }else if(qtype == 1){

                  //세로 배열
                  //row for문 - 줄
                  for(c = 0; c < col ; c++ ){
                    if(stopFlag) break;

                    //col for문
                    for(r = 0; r < row ; r++ ){
                      if(stopFlag) break;

                      ////console.log("r : " + r + " / c " + c + " w : " + question_width + " / h " + question_height + "y : " + (r * (question_height) ) + " / x :" + ( c*(question_width) + (c*centermargin) ));
                      const qbg = document.createElement("div");
                      qbg.model = {};
                      qbg.model.type = "bg";  
                      qbg.style.position = "absolute";
                      qbg.style.width  = question_width+"mm";
                      qbg.style.height = question_height+"mm";
                      qbg.style.marginTop  = ( r * (question_height) ) + "mm";                      //y좌표
                      qbg.style.marginLeft = ( c * (question_width) + (c * centermargin) ) + "mm";  //x좌표

                      //계산용 정보 백업
                      qbg.model.width  = question_width;
                      qbg.model.height = question_height;
                      qbg.model.posy = ( r * (question_height) );
                      qbg.model.posx = ( c * (question_width) + ( c * centermargin) );

                      //배경에 공책 줄 넣기 - 이미지로 반복
                      qbg.style.backgroundImage  = `url(${require('../assets/bg_dotted.png')})`;
                      qbg.style.backgroundRepeat = "repeat";
                      //qbg.style.backgroundColor  = "#ff00ff"
                      page_div.appendChild(qbg);

                      checkList.push(qbg);

                    }

                  }

                }

                

              }


              //문제 생성
              const qdiv = document.createElement("div");
              qdiv.model = {};
              qdiv.style.position = "absolute";
              qdiv.style.backgroundColor = "#ff0000";
              qdiv.style.width    = checkList[checkpos].model.width + "mm";

              //실제 문제 이미지
              var temp_img = document.createElement("img");
              temp_img.src = questionList[q].IMAGE;
              temp_img.style.width = "100%";
              qdiv.appendChild(temp_img);

              //사이즈를 문제 표시 영역의 가로 사이즈 기준으로 줄인다.
              //console.log("w : " + temp_img.width + " / " + temp_img.height);
              var wmm = pxTomm(temp_img.width);
              var hmm = pxTomm(temp_img.height);
              var biul = wmm / hmm;
              var scale = checkList[checkpos].model.width / wmm;
              var img_width  = wmm * scale;
              var img_height = img_width / biul;

              //qdiv.style.height = pxTomm(questionList[q].height) + "mm";
              qdiv.style.height = img_height + "mm";
              qdiv.style.backgroundColor = questionList[q].backgroundColor;

              //문제의 위치를 미리 정해진 포지션을 잡아준다.
              qdiv.style.marginTop  = checkList[checkpos].model.posy+"mm"; 
              qdiv.style.marginLeft = checkList[checkpos].model.posx+"mm";  

              //충돌체크 계산용 데이터 백업
              qdiv.model.type   = "question";
              //qdiv.model.width  = pxTomm(questionList[q].width);
              //qdiv.model.height = pxTomm(questionList[q].height);
              qdiv.model.width  = img_width;
              qdiv.model.height = img_height;

              qdiv.model.posx   = checkList[checkpos].model.posx; 
              qdiv.model.posy   = checkList[checkpos].model.posy; 
              qdiv.model.title  = questionList[q].title;

              /*
              const qlabel = document.createElement("label");
              qlabel.textContent = questionList[q].title;
              qdiv.appendChild(qlabel);
              */


              //충돌체크 - 페이지 내부에서 type이 question인 객체들과 충돌체크한다.
              var check_flag = false;

              //차일드가 없는 페이지 첫 문제인 경우에 문제가 화면을 넘어가는지 체크
              var contentAreaHeight = parseInt(a4height) - parseInt(topmargin);
              if(qdiv.model.height > contentAreaHeight){
                stopFlag = true;
              }

              for(var n=0;n<page_div.children.length;n++){

                if(stopFlag) break;

                if(page_div.children[n].model.type == "question"){

                  var rect1 = {
                                  "x": parseInt(page_div.children[n].model.posx)
                                , "y": parseInt(page_div.children[n].model.posy)
                                , "width": parseInt(page_div.children[n].model.width)
                                , "height": parseInt(page_div.children[n].model.height)
                                , "top": parseInt(page_div.children[n].model.posy)
                                , "right": parseInt(page_div.children[n].model.posx)  + parseInt(page_div.children[n].model.width)
                                , "bottom": parseInt(page_div.children[n].model.posy) + parseInt(page_div.children[n].model.height)
                                , "left": parseInt(page_div.children[n].model.posx)
                              }

                  var rect2 = {
                                  "x": parseInt(qdiv.model.posx)
                                , "y": parseInt(qdiv.model.posy)
                                , "width": parseInt(qdiv.model.width)
                                , "height": parseInt(qdiv.model.height)
                                , "top": parseInt(qdiv.model.posy)
                                , "right": parseInt(qdiv.model.posx)  + parseInt(qdiv.model.width)
                                , "bottom": parseInt(qdiv.model.posy) + parseInt(qdiv.model.height)
                                , "left": parseInt(qdiv.model.posx)
                              }

                  //safe area 충돌체크 (문제가 길어서 바닥을 침범하는 경우도 체크해야한다.)
                  var rect3 = {
                                  "x": 0
                                , "y": parseInt(a4height) - parseInt(topmargin)
                                , "width": parseInt(a4width)
                                , "height": parseInt(1)
                                , "top": parseInt(a4height) - parseInt(topmargin)
                                , "right": parseInt(a4width)
                                , "bottom": parseInt(a4height) - parseInt(topmargin)
                                , "left": 0
                              }

                  ////console.log("-------------------------------------------------------------------------------------------");
                  ////console.log("crect : " + JSON.stringify(rect1));
                  ////console.log("qrect : " + JSON.stringify(rect2));
                  if(intersectRect(rect1, rect2)){
                    //충돌
                    ////console.log("qpos : " + q + " intersect checkpos : " + checkpos + " =>  " + JSON.stringify(page_div.children[n].model));
                    check_flag = true;
                    break;
                  }

                  if(intersectRect(rect3, rect2)){
                    //문제가 길어서 바닥을 넘어가는지 체크
                    ////console.log("qpos : " + q + " bottom intersect checkpos : " + checkpos + " =>  " + JSON.stringify(page_div.children[n].model));
                    check_flag = true;
                    break;
                  }

                }
              }

              //충돌 안했으면 추가
              if(check_flag){
                //충돌했으면 다음으로 넘겨라.
                checkpos = checkpos + 1 >= checkList.length ? 0 : checkpos + 1;
                q--;

              }else{
                //충돌 안했으면 추가
                ////console.log("qpos : " + q + " add complete checkpos : "+checkpos);
                page_div.appendChild(qdiv);
                //다음 순서로 변경
                checkpos = checkpos + 1 >= checkList.length ? 0 : checkpos + 1;
              }

            }


            if(stopFlag){
              this.$alert("문제의 이미지 사이즈가 A4사이즈를 초과하여 미리보기를 중단합니다.");
              return;
            }

            //워터마크 찍기

            
            //문제 추가
            this.prewview_div.appendChild(page_div);

            
            //정답및해설 출력
            if(answer_print){
              //console.log("answer_print....");
            }

            //체크리스트 출력
            if(history_print){
              //console.log("history_print....");
            }

        }

    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>