<template>
  <v-container fluid class="pa-0" style="background:#ffffff">

    <v-row>
      <v-dialog persistent  v-model="progress_dialog" max-width="400px">
        <v-container style="background:white">
        <v-row justify="center" class="mt-3">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
              />
        </v-row>
        <v-row justify="center" class="mt-8 mb-3">
            <label>잠시만 기다려주세요</label>
        </v-row>
        </v-container>
      </v-dialog>  
    </v-row>    


  <v-row style="background:#ffffff;" class="ma-md-10" justify="center">
    <v-col cols="12" md=8 >
      <v-stepper
        v-model="e6"
        vertical 
        :elevation="$vuetify.breakpoint.smAndDown ? 0 : 1"
        >
    <v-stepper-step color="#9f0007"
      :complete="e6 > 1"
      step="1"
      >
      서비스약관
    </v-stepper-step>

    <v-stepper-content step="1" :class="$vuetify.breakpoint.smAndDown ? 'pa-0 ma-0':''">
      <v-card
        color="#f0f0f0"
        class="mb-12 overflow-y-auto"
        max-height="500px"
        style="margin-bottom:10px !important"
        >
        <v-card-text>
            <div class="col-sm-12">
                <h3>1.목적</h3>
            </div>
            <div class="col-sm-12">
                <p>본 약관은 (주)감성교육 제공하는 감성수학에 회원으로 가입하고 이를 이용함에 있어 회사와 회원(약관에 동의한 이용자)의 권리와 의무 및 책임사항을 규정함을 목적으로 합니다.</p>
            </div>
            <br>
            <div class="col-sm-12">
                <h3>2.약관의 명시, 효력 및 개정</h3>
            </div>
            <div class="col-sm-12">
                <p>(1) 회사는 이 약관의 내용을 회원이 알 수 있도록 회원가입시 화면에 게시합니다.</p>
                <p>(2) 회사는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 소비자기본법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</p>
                <p>(3) 회사가 약관이 변경되는 경우 귀하는 최소 30일 이전에 알림을 받게 되며, 새로운 약관은 이 기간을 거친 후에 효력을 발휘합니다. 귀하가 이 기간이 지난 후에도 감성수학을 계속 사용하면 새 약관에 동의한 것으로 간주됩니다. 새 약관은 감성수학을 이용하는 모든 부분에 적용됩니다.</p>
            </div>
            <br>
            <div class="col-sm-12">
                <h3>3.회원의 가입 및 관리</h3>
            </div>
            <div class="col-sm-12">
                <p>(1) 서비스 이용자가 회원가입 인증 메일의 링크를 눌러 이메일을 인증하면 본 약관에 동의한 것으로 간주합니다.</p>
                <p>(2) 회사는 아래 각 호의 1에 해당하는 경우에는 회원등록의 승낙을 거부할 수 있습니다.</p>
                <p>&ensp;&ensp;1. 서비스를 제공하는데 기술적인 문제가 있다고 판단되는 경우</p>
                <p>&ensp;&ensp;2. 기타 회사가 재정적 기술적으로 필요하다고 인정하는 경우</p>
                <p>&ensp;&ensp;3. 법인고객의 경우 회사가 요구한 증명서를 제출하지 않은 경우</p>
                <p>&ensp;&ensp;4. 회사가 관련법령 등을 기준으로 하여 명백하게 사회질서 및 미풍양속에 반할 우려가 있음을 인정하는 경우</p>
                <p>(3) 회원은 서비스 이용을 위한 회원 ID, 비밀번호의 관리에 대한 책임, 본인 ID의 제3자에 의한 부정사용 등 회원의 고의∙과실로 인해 발생하는 모든 불이익에 대한 책임을 부담합니다. 단, 이것이 회사의 고의∙과실로 인하여 야기된 경우는 회사가 책임을 부담합니다.</p>
                <p>(4) 회사는 서비스를 제공하기 위하여 관련 법령의 규정에 따라 회원으로부터 필요한 개인정보를 수집합니다.</p>
            </div>
            <br>
            <div class="col-sm-12">
                <h3>4.서비스의 이용</h3>
            </div>
            <div class="col-sm-12">
                <p>(1) 서비스 이용은 회사의 서비스 사용승낙 직후부터 가능합니다. 다만, 유료 서비스의 경우 회사가 요금납입을 확인한 직후부터 가능하게 할 수 있습니다.</p>
                <p>(2) 서비스 이용시간은 회사의 업무상 또는 기술상 불가능한 경우를 제외하고는 연중무휴 1일 24시간(00:00-24:00)으로 함을 원칙으로 합니다. 다만, 서비스설비의 정기점검 등의 사유로 회사가 서비스를 특정범위로 분할하여 별도로 날짜와 시간을 정할 수 있습니다.</p>
                <p>(3) 회사의 서비스가 종료되는 경우 회사는 회원의 등록된 전자우편 주소로 이메일을 통하여 서비스 내용의 변경 사항 또는 종료를 통지할 수 있습니다.</p>
                <p>(4) 회사는 아래 각 호의 1에 해당하는 사유가 발생한 경우에는 회원의 서비스 이용을 제한하거나 중지시킬 수 있습니다.</p>
                <p>&ensp;&ensp;1. 회원이 회사 서비스의 운영을 고의∙과실로 방해하는 경우</p>
                <p>&ensp;&ensp;2. 서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우</p>
                <p>&ensp;&ensp;3. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</p>
                <p>&ensp;&ensp;4. 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때</p>
                <p>&ensp;&ensp;5. 국기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우</p>
                <p>(5) 서비스 이용자가 약관을 위반하는 경우 이용자의 권리는 효력을 상실하며 (주)감성교육은 해당 금액을 귀하에게 환불하지 않고 귀하의 계정에 대한 사용을 종료할 수 있습니다. </p>
            </div>
            <br>
            <div class="col-sm-12">
                <h3>5.회사의 의무</h3>
            </div>
            <div class="col-sm-12">
                <p>(1) 회사는 회사의 서비스를 지속적이고 안정적으로 제공할 수 있도록 유지, 점검 또는 복구 등의 조치를 성실히 이행하여야 합니다.</p>
                <p>(2) 회사는 회원이 수신 동의를 하지 않은 영리 목적의 광고성 전자우편, SMS 문자메시지 등을 발송하지 아니합니다.</p>
                <p>(3) 회사는 서비스의 제공과 관련하여 알게 된 회원의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않고, 이를 보호하기 위하여 노력합니다. 회원의 개인정보보호에 관한 기타의 사항은 정보통신망법 및 회사가 별도로 정한 “개인정보관리지침”에 따릅니다.</p>
            </div>
            <br>
            <div class="col-sm-12">
                <h3>6.회원의 의무</h3>
            </div>
            <div class="col-sm-12">
                <p>(1) 회원은 아래 각 호의 1에 해당하는 행위를 하여서는 아니 됩니다.</p>
                <p>&ensp;&ensp;1.회원가입신청 또는 변경 시 허위내용을 등록하는 행위</p>
                <p>&ensp;&ensp;2.회사나 제3자의 권리를 침해하는 행위</p>
                <p>&ensp;&ensp;3.다른 회원의 ID 및 비밀번호를 도용하여 부당하게 서비스를 이용하는 행위</p>
                <p>(2) 회사는 회원이 제1항의 행위를 하는 경우 서비스의 이용을 제한하거나 일방적으로 본 계약을 해지할 수 있습니다.</p>
                <p>(3) 회원의 서비스 받을 권리는 이를 양도 내지 증여하거나 질권의 목적으로 사용할 수 없습니다.</p>
            </div>
            <br>
            <div class="col-sm-12">
                <h3>7.기타</h3>
            </div>
            <div class="col-sm-12">
                <p>(1) 회사는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 회원에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.</p>
                <p>&ensp;&ensp;1.천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</p>
                <p>&ensp;&ensp;2.서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우</p>
                <p>&ensp;&ensp;3.회원의 귀책사유로 서비스 이용에 장애가 있는 경우</p>
                <p>(2)본 약관은 대한민국법령에 의하여 규정되고 이행되며, 서비스 이용과 관련하여 회사와 회원간에 발생한 분쟁에 대해서는 민사소송법상의 주소지를 관할하는 법원을 합의관할로 합니다.</p>
                <p>(3) 본 약관에 명시되지 않은 사항에 대해서는 관련법령에 의하고, 법에 명시되지 않은 부분에 대하여는 관습에 의합니다.</p>
            </div>
    
        </v-card-text>
      </v-card>
      <v-btn style="color:white" :class="$vuetify.breakpoint.smAndDown ? 'ml-3':''" color="#9f0007" @click="e6 = 2">동의합니다.</v-btn>
      <v-btn text @click="$router.back()">취소</v-btn>
    </v-stepper-content>

    <v-stepper-step color="#c53d45"
      :complete="e6 > 2"
      step="2"
    >
      개인정보처리방침
    </v-stepper-step>

    <v-stepper-content step="2" :class="$vuetify.breakpoint.smAndDown ? 'pa-0 ma-0':''">
      <v-card
        color="#f0f0f0"
        class="mb-12 overflow-y-auto"
        max-height="500px" style="margin-bottom:10px !important"
        >
        <v-card-text>
            <div class="col-sm-12">
                <h3>1. 개인정보 처리방침</h3>
            </div>
            <div class="col-sm-12">
                <p>(주)감성교육은 ‘이용자의 권리 (개인정보 자기결정권)를 적극적으로 보장’ 합니다. 정보통신서비스제공자가 준수하여야 하는 대한민국의 관계 법령 및 개인정보보호 규정, 가이드라인을 
                준수하고 있습니다. “개인정보처리방침”이란 이용자의 소중한 개인정보를 보호함으로써 이용자가 안심하고 서비스를 이용할 수 있도록 회사가 준수해야 할 지침을 의미합니다. 본 개인정보 처리방침은 감성수학의 모든 서비스에 적용됩니다.</p>
            </div>
            <div class="col-sm-12">
                <h3>2. 개인정보 수집</h3>
            </div>
            <div class="col-sm-12">
                <p>(주)감성교육은 회원으로 부터 감성수학의 원활한 서비스를 위해 일부 정보를 수집합니다.</p>
                <p>과도한 개인정보가 아닌 서비스 제공에 필요한 최소한의 정보만을 수집하며 이용자의 인권을 침배할 우려가 있는 민감한 정보는 절대 수집하지 않습니다.</p>
            </div>
            <div class="col-sm-12">
                <h3>3.개인정보 이용</h3>
            </div>
            <div class="col-sm-12">
                <p>이용자의 개인정보를 다음과 같은 목적으로만 이용하며, 목적이 변경될 경우에는 반드시 사전에 이용자에게 동의를 구하도록 하겠습니다.</p>
                <p>&ensp;&ensp;1.이용자 식별, 불량회원 부정이용 방지</p>
                <p>&ensp;&ensp;2.다양한 서비스 제공, 문의사항 또는 불만 처리, 공지사항 전달</p>
                <p>&ensp;&ensp;3.이용자와 약속한 서비스 제공, 유료 서비스 구매 및 이용 시 요금 정산</p>
                <p>&ensp;&ensp;4.신규 서비스 개발, 이벤트 행사 시 정보 전달, 마케팅 및 광고 등에 활용</p>
                <p>&ensp;&ensp;5.서비스 이용 기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 맞춤형 서비스 제공, 서비스 개선에 활용</p>
            </div>
            <div class="col-sm-12">
                <h3>4.개인정보의 위탁</h3>
            </div>
            <div class="col-sm-12">
                <p>개인정보를 제3자에게 제공해야 하는 경우 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 구하도록 하겠습니다. 다만 다음의 경우는 예외로 하고 있습니다.</p>
                <p>&ensp;&ensp;1.서비스 제공에 따른 요금정산을 위해 필요한 경우</p>
                <p>&ensp;&ensp;2.법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</p>
            </div>
            <div class="col-sm-12">
                <h3>5.개인정보의 파기</h3>
            </div>
            <div class="col-sm-12">
                <p>이용자의 개인정보에 대해 개인정보의 수집·이용 목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 다만 내부 방침에 의해 서비스 부정이용기록은 부정 가입 및 이용 방지를 위하여 회원 탈퇴 시점으로부터 최대 1년간 보관 후 파기하며, 관계법령에 의해 보관해야 하는 정보는 법령이 정한 기간 동안 보관한 후 파기합니다.</p>
                <p>개인정보 파기 시에는 복구 및 재생되지 않도록 완전하게 삭제합니다.</p>
            </div>          
        </v-card-text>
      </v-card>
      <v-btn style="color:white" color="#9f0007" :class="$vuetify.breakpoint.smAndDown ? 'ml-3':''" @click="e6=3">
        동의합니다.
      </v-btn>
      <v-btn text @click="e6=1">취소</v-btn>
    </v-stepper-content>

    <v-stepper-step color="#c53d45"
      :complete="e6 > 3"
      step="3"
      >
      마케팅정보동의
    </v-stepper-step>
    <v-stepper-content step="3" :class="$vuetify.breakpoint.smAndDown ? 'pa-0 ma-0':''">
      <v-card
        color="#f0f0f0" class="mb-12" height="80px" style="margin-bottom:10px !important"
      >
      <v-card-text>
        <p>감성수학에서 보내드리는 메일정보 수신에 동의하시겠습니까?</p>
      </v-card-text>
      </v-card>
      <v-btn style="color:white" color="#9f0007" :class="$vuetify.breakpoint.smAndDown ? 'ml-3':''" @click="e6 = 4">동의합니다.</v-btn>
      <v-btn text @click="e6=2">취소</v-btn>
    </v-stepper-content>

    <template v-if="select_type == 1">
      <v-stepper-step color="#c53d45" step="4">
        이메일 계정과 비밀번호를 입력하세요
      </v-stepper-step>
      <v-stepper-content step="4" :class="$vuetify.breakpoint.smAndDown ? 'pa-0 ma-0':''">
        <v-card color="#f0f0f0" class="mb-12" >
          <v-container>
          <v-row dense>
              <div class="col-sm-12 pl-5">
                  <br>
                  <p>가입방법</p>
                  <p>1. 이름, 연락처, 이메일 계정과 비밀번호를 입력하시고 가입하기를 누르시면 입력하신 이메일 계정으로 인증메일을 보내드립니다.</p>
                  <p>2. 인증메일에 첨부된 링크를 누르시면 가입이 완료되어 계정사용이 가능합니다.</p>
              </div>
          </v-row>
          <v-row justify="center" class="mt-5" dense>
            <v-col cols="11" md="4" sm="12" xs="12">
              <v-text-field v-model="user_name" outlined hide-details
                 name="name" label="이름을 입력하세요" type="text" dense solo></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" dense class="mt-5">
            <v-col cols="11" md="4" sm="12" xs="12">
                <v-select v-model="user_gender" :items="GENDERLIST" outlined
                  item-text="name" item-value="value" class="dialogselectstyle"
                  hide-details elevation="0" dense
                  />               
            </v-col>
          </v-row>

          <!-- 
          <v-row justify="center" align="center" dense>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="user_areacode"
                outlined
                dense
                clearable
                label="지점을 입력하세요"
              ></v-autocomplete>
              <v-text-field v-model="user_area" outlined maxlength="20" hide-details disabled
                 name="tel" label="지점을 검색하세요" type="text" dense solo></v-text-field>
            </v-col>
          </v-row>
          -->

          <v-row justify="center" align="center" dense>
            <v-col cols="11" md="4" align="right">
              <areadialog :areaname="user_area" @callback="comareacb"/>
              <!-- <v-btn style="height:30px;width:150px;font-size:16px;color:white" color="#9f0007" rounded @click="searcharea">지점검색</v-btn> -->
            </v-col>
          </v-row>

          <v-row justify="center" dense class="mt-5">
            <v-col cols="11" md="4">
              <v-text-field v-model="user_addr1" outlined maxlength="20" hide-details disabled
                 name="tel" label="주소를 검색하세요" type="text" dense solo></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" dense>
            <v-col cols="11" md="4" align="right">
              <v-btn style="height:30px;width:150px;font-size:16px;color:white" color="#C53D45" @click="postcode">주소검색</v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" dense>
            <v-col cols="11" md="4">
              <v-text-field v-model="user_addr2" outlined maxlength="20" hide-details
                 name="tel" label="나머지 주소를 입력하세요" type="text" dense solo></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center" dense class="mt-5">
            <v-col cols="11" md="4">
              <v-text-field v-model="user_parenttel" outlined maxlength="20" hide-details
                 name="tel" label="부모님 연락처를 입력하세요" type="text" dense solo></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center" align="center" dense>
            <v-col cols="11" md="4">
              <v-text-field v-model="user_tel" outlined maxlength="20" hide-details
                 name="tel" label="휴대폰 번호를 입력하세요" type="text" dense solo></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center" dense class="mt-5">
            <v-col cols="11" align="center">
              <div id="recaptcha-container"/>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" dense v-if="confirmationResult == null">
            <v-col cols="11" md="4" align="right">
              <v-btn style="height:30px;width:150px;font-size:16px;color:white" color="#C53D45" id="sign-in-button" @click="sendopt">인증번호 발송</v-btn>
            </v-col>
          </v-row>

          <v-row justify="center" dense>
            <v-col cols="11" md="4" sm="12" xs="12">
              <v-text-field v-model="user_code" outlined maxlength="20" hide-details
                 name="tel" label="인증번호를 입력하세요" type="text" dense solo></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="timeflag" justify="center" align="center" dense>
            <v-col cols="11" md="4" align="center">
              <label style="font-size:14px;color:red">{{timemsg}} {{ time }}</label>
            </v-col>
          </v-row>

          <v-row justify="center" align="center" dense>
            <v-col cols="11" md="4" align="right">
              <v-btn text @click="e6=3">취소</v-btn>
              <v-btn style="height:30px;width:150px;font-size:16px;color:white" color="#C53D45" id="sign-in-button" @click="join">가입하기</v-btn>
            </v-col>
          </v-row>

          </v-container>
        </v-card>


      </v-stepper-content>
    </template>


      </v-stepper>
    </v-col>
  </v-row>

  </v-container>
</template>

<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=2d34ab8a2ba110eed5c9ab2ba610bea6&libraries=services"></script>
<script>
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, signInWithCustomToken } from "firebase/auth";
export default {

  data: () => ({
        progress_dialog:false,
        select_type:1,
        select_login:false

      , e6: 1
      
      , user_name : ""
      , user_gender : 0
      , user_area : ""
      , user_areacode: null
      , user_addr1 : ""
      , user_addr2 : ""
      , user_parenttel : ""
      , user_tel : ""
      , user_code: ""


      , GENDERLIST: [
          {name:'남성',   value:0}
        , {name:'여성',   value:1}
      ]

      , recaptchaVerifier: null
      , recaptchaWidgetId: null
      , recaptchaCode: null
      , confirmationResult: null

      , timemsg:"인증번호를 입력하세요"
      , time:"10:00"
      , timeflag:false
      , timer:null

      , rules: {
          mobile: value => !value || !/[^0-9-]/.test(value) || '숫자와 -외에는 입력할 수 없습니다.',
          eng: value => !value || !/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value) || '한글을 사용할 수 없습니다.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          notemail: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return !pattern.test(value) || '이메일주소는 사용할 수 없습니다.'
          },
      },      
  }),

  beforeDestroy(){
    if(this.timer != null){
      clearInterval(this.timer);
    }
  },

  mounted(){  
    this.select_login = false;
    this.showcapcha();
  },

  methods: {

    showcapcha(){
      this.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {
        'callback': (response) => {
          //버튼이 눌리면 여기가 콜백되어 recaptcha 토큰이 발급된다. 여러번 누르면 중복으로 작동되지 않아 인증키를 백업한다.
          this.recaptchaCode = response;
        }
        , 'expired-callback': () => {
          //시간이 오래 경과하면 체크가 다시 해제된다.
          this.recaptchaCode = null;
        }
      }, this.$fireauth);
      this.recaptchaVerifier.render().then((widgetId) => {
        this.recaptchaWidgetId = widgetId;
      }).catch((error)=>{
        //console.log("show error : " + error);
      });    
    },

    hidecapcha(){
      this.recaptchaVerifier.clear();
    },


    stopTimer(){
      if(this.timer != null){
        clearInterval(this.timer);
      }
    },

    startTimer(){
      
      //캡차 숨기기
      this.hidecapcha();
      
      this.time = "10:00";
      this.timeflag = true;
      this.timer = setInterval(()=>{
        const timeArray = this.time.split(':');
        let minutes = parseInt(timeArray[0]);
        let seconds = parseInt(timeArray[1]);
        if (minutes === 0 && seconds === 0) {
          clearInterval(this.timer);
          this.timer   = null;
          this.timemsg = "인증번호를 다시 발급받으세요.";
          this.time    = "";
          this.confirmationResult = null;
          this.showcapcha();
          return;
        }
        if (seconds === 0) {
          minutes--;
          seconds = 59;
        } else {
          seconds--;
        }
        this.time = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;        
      }, 1000);
    },
    
    //로그인 체크
    checklogin(){
      const user = this.$fireauth.currentUser;
      if(user){
        //console.log("user : " + JSON.stringify(user));
      }else{
        //console.log("not login");
      }
    },


    //로그아웃으로 토큰 파기하기
    logout(){
      this.$fireauth.signOut().then(() => {
        //console.log('User signed out.');
      }).catch((error) => {
        //console.error('Error signing out:', error);
      });
    },

    valuecheck(){
      if(this.user_name.length < 1){
          this.$alert("이름을 입력하세요");
          return false;
      }
      if(this.user_name.length > 10){
          this.$alert("이름은 10자 이하로 입력하세요");
          return false;
      }

      if(this.user_areacode == null || this.user_areacode == undefined){
          this.$alert("지점을 선택하세요");
          return false;
      }

      if(this.user_addr1.length < 1){
          this.$alert("주소를 검색하세요");
          return false;
      }
      if(this.user_addr2.length < 1){
          this.$alert("상세주소를 입력하세요");
          return false;
      }

      const mobileVal1 = /[^0-9]/g;
      var mobileVal = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
      if(this.user_parenttel == undefined || this.user_parenttel.length < 1){
        this.$alert("부모님 휴대폰 번호를 입력하세요");
        return false;
      } 
      
      if(mobileVal1.test(this.user_parenttel)){
        this.$alert("부모님 휴대폰 번호는 숫자만 입력이 가능합니다.");
        return false;
      } 
      
      if(!mobileVal.test(this.user_parenttel)){
        this.$alert("부모님 휴대폰 번호가 잘못되었습니다.");
        return false;
      } 

      if(this.user_tel  == undefined || this.user_tel.length < 1){
        this.$alert("휴대폰 번호를 입력하세요");
        return false;
      } 
      
      if(mobileVal1.test(this.user_tel)){
        this.$alert("휴대폰 번호는 숫자만 입력이 가능합니다.");
        return false;
      } 
      
      if(!mobileVal.test(this.user_tel)){
        this.$alert("휴대폰 번호가 잘못되었습니다.");
        return false;
      } 

      return true;

    },


    //SMS 인증번호 발송 - 전화번호는 꼭 국가번호가 들어가야한다. +82010으로 해도 됨. +8210으로 굳이 안해도 됨.
    sendopt(){

      const flag = this.valuecheck();
      if(!flag) return;

      if(this.recaptchaCode == null || this.recaptchaCode == undefined){
        this.$alert("reCAPTCHA 인증을 확인하세요");
        return;
      }

      if(this.confirmationResult != null){
        this.$alert("인증번호가 이미 발송되었습니다.");
      }
      
      const auth = getAuth();
      signInWithPhoneNumber(auth, "+82"+this.user_tel, this.recaptchaVerifier)
        .then((result) => {
          
          console.log("signInWithPhoneNumber result : " + JSON.stringify(result));

          this.confirmationResult = result;
          
          //인증번호 발송완료 - 10분 카운트 들어가야한다.
          this.startTimer();

        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          //잘못된 전화번호 알림처리 필요.
          console.log("Error sending OTP:" + JSON.stringify(error));
          if(error.code == "auth/too-many-requests"){
            this.$alert("너무 많은 요청으로 10분뒤 다시 시도하세요.");
          }else if(error.code == "auth/invalid-phone-number"){
            this.$alert("잘못된 전화번호 양식입니다.");
          }else if(error.code == "auth/invalid-app-credential"){
            this.$alert("인증번호 발송을 다시 시도하세요.");
          }else{
            this.$alert("인증코드 발급오류. 잠시 후 다시 시도하세요.");
          }
          
        });
    },


    join(){

      const flag = this.valuecheck();
      if(!flag) return;

      if(this.confirmationResult == null || this.confirmationResult == undefined){
        this.$alert("인증번호를 발급하세요");
        return false;
      }

      if(this.user_code  == undefined || this.user_code.length < 1){
        this.$alert("SMS로 받은 인증번호를 입력하세요.");
        return false;
      } 

        this.progress_dialog = true;
        this.confirmationResult.confirm(this.user_code).then((result)=>{
          
          //이미 이 단계에서 App.js의 onAuthStateChanged와 onIdTokenChanged가 호출된다.
          const user = result.user;
          this.createCustomtoken(user.uid);

        }).catch((error)=>{
          this.progress_dialog = false;
          //console.log("confirm error : " + JSON.stringify(error));
          if(error.code == "auth/invalid-verification-code"){
            this.$alert("잘못된 인증코드입니다.");
          }else if(error.code == "auth/too-many-requests"){
            this.$alert("너무 많은 요청으로 10분뒤 다시 시도하세요.");
          }else if(error.code == "auth/code-expired"){
            this.$alert("잘못된 인증코드입니다.");
          }else{
            this.$alert("인증코드를 입력하세요"); 
          }
        });

      },


      //회원을 추가하고 커스텀 토큰을 발급받는다.
      //실패시 방금 firebase에 추가된 회원을 삭제한다.
      createCustomtoken(localid){

          this.$http.post(this.$host+'/phoneJoin',{
            LOCALID:localid,
            NAME:escape(this.user_name),
            TEL:this.user_tel,
            PARENT_TEL:this.user_parenttel,
            AREA_NO:this.user_areacode,
            GENDER:this.user_gender,
            ADDR1:escape(this.user_addr1),
            ADDR2:escape(this.user_addr2),
          })
          .then((result)=>{
            this.progress_dialog = false;
              if(result.data.resultCode == 0){

                //custom token으로 클라이언트 로그인하기
                signInWithCustomToken(this.$fireauth, result.data.customToken)
                .then(async (userCredential)=>{
                  //로그인 성공
                  const user = userCredential.user;
                  //id토큰 받기
                  const idToken = await user.getIdToken();
                  //console.log("idToken : " + idToken);
                  //console.log("student join idToken : " + idToken);
                  this.$EventBus.$emit('clearsession');
                  this.$alert("회원가입이 완료되었습니다. 가입하신 전화번호로 로그인해 주세요");
                  //this.$router.push("Welcome");
                })

              }else{
                this.$alert(result.data.resultMsg);
                //이미 가입되거나 오류난 경우 다시 캡차와 인증번호를 받아야 한다.
                this.$router.push("StudentLogin");
                //this.failjoin();
              }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.progress_dialog = false;
            //console.log("createToken error : " + error);
            this.$alert("회원가입에 실패했습니다. 다시 시도하세요.");
            //이미 가입되거나 오류난 경우 다시 캡차와 인증번호를 받아야 한다.
            this.failjoin();
          });
      },

      failjoin(){
        this.logout();
        this.stopTimer();
        this.user_code = "";
        this.timer   = null;
        this.timemsg = "인증번호를 입력하세요.";
        this.time    = "";
        this.timeflag = false;
        this.confirmationResult = null;
        this.showcapcha();
      },

      postcodetoAddr(addr){
        this.user_addr1 = addr;
        this.$forceUpdate();
      },

      //주소검색
      postcode(){

        //콜백에서 못찾아서 콘스트로 미리 빼둔다.
        const callback_addrfunction     = this.postcodetoAddr;

        new daum.Postcode({
            oncomplete: function(data) {
                //console.log(JSON.stringify(data));
                var addr = data.address; // 최종 주소 변수
                if(data.userSelectedType == "J"){
                  //지번
                  addr = data.jibunAddress; 
                }
                //주소 정보를 해당 필드에 넣는다.
                callback_addrfunction(addr);
            }
        }).open();

      },

      //지점 설정
      comareacb(val1){
        //console.log(val1);
        if(val1.AREA_NAME.length > 0){
          this.user_area      = val1.AREA_NAME;
          this.user_areacode  = val1.AREA_NO;
        }else{
          this.user_area        = '';
          this.user_areacode    = null;
        }
      },
    
  },


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>