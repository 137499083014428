//클래스 만들기
class DRComponent {

  //생성자
  constructor(model){
      
      this.model = model;
      this.model.TEMP_POSX   = this.model.LAYOUT_POSX;
      this.model.TEMP_POSY   = this.model.LAYOUT_POSY;
      this.model.TEMP_WIDTH  = this.model.LAYOUT_WIDTH;
      this.model.TEMP_HEIGHT = this.model.LAYOUT_HEIGHT;

      this.temp_div = document.createElement("div");
      this.temp_div.style.pointerEvents = "none"; //마우스 이벤트 무시
      this.temp_div.model = model;                //여기에 객체를 강제로 붙여놓는다. 그래야 이벤트에서 접근됨.
      
      //커서
      this.temp_div.style.cursor    = "hand";

      //사이즈 위치 관련
      this.temp_div.style.position  = "absolute";
      this.temp_div.style.height    = this.temp_div.model.LAYOUT_HEIGHT + "px";
      this.temp_div.style.width     = this.temp_div.model.LAYOUT_WIDTH  + "px";

      //transform을 사용할 경우 (ROTATE, SCALE 기능이 필요하면 transform 사용)
      this.temp_div.style.transform = "translate("+this.temp_div.model.LAYOUT_POSX+"px, "+this.temp_div.model.LAYOUT_POSY+"px) scale("+this.temp_div.model.SCALE+") rotateZ("+this.temp_div.model.ROTATE+"deg)";

      //컬러 배경
      this.temp_div.style.backgroundColor = "#"+this.temp_div.model.BGCOLOR;

      this.temp_div.style.userSelect = "none";  //요걸 해줘야 드래그시 텍스트가 자꾸 드래그 되던 현상이 해결됨.
      this.temp_div.mode = "none"; //이동 및 스케일에 따른 모드

      //이벤트 처리
      this.temp_div.selectflag = false;

      

  }

  //유니크값 생성
  createRandomValue(){
      var random = Math.random().toString(16).slice(2,10);
      return '0x'+random;
  }
  
  focuscallback(ev){
    ev.target.style.pointerEvents = "auto";
  }

  focusoutcallback(ev){
    ev.target.style.pointerEvents = "none";
  }

  setSelected(flag){
    if(flag){
      //this.temp_div.style.border = "2px #00dcf0 solid"
      this.temp_div.selectflag = true;
    }else{
      //this.temp_div.style.border = ""
      this.temp_div.selectflag = false;
    }
  }


/*******************************************/
// DRBackground가 호출하는 함수
/*******************************************/

  updateComponent(){
    
    this.model.TEMP_POSX   = this.model.LAYOUT_POSX;
    this.model.TEMP_POSY   = this.model.LAYOUT_POSY;
    this.model.TEMP_WIDTH  = this.model.LAYOUT_WIDTH;
    this.model.TEMP_HEIGHT = this.model.LAYOUT_HEIGHT;

    this.temp_div.style.width  = this.model.LAYOUT_WIDTH  + "px";
    this.temp_div.style.height = this.model.LAYOUT_HEIGHT + "px";
    this.temp_div.style.transform = "translate("+this.model.LAYOUT_POSX+"px, "+this.model.LAYOUT_POSY+"px) scale("+this.model.SCALE+") rotateZ("+this.model.ROTATE+"deg)";
    
    //배경 처리
    this.temp_div.style.backgroundColor = "#"+this.model.BGCOLOR;
    
    
  }


  //이동 시작작업
  setMoveStart(target){
    target.model.TEMP_POSX = target.model.LAYOUT_POSX;
    target.model.TEMP_POSY = target.model.LAYOUT_POSY;
  }

  //이동
  setMove(target, mx, my){
    target.model.TEMP_POSX = target.model.TEMP_POSX + mx;
    target.model.TEMP_POSY = target.model.TEMP_POSY + my;
    target.style.transform = "translate("+target.model.TEMP_POSX+"px, "+target.model.TEMP_POSY+"px) scale("+target.model.SCALE+") rotateZ("+target.model.ROTATE+"deg)";
    
    //원본 - 부하 줄이려고 movestart, move end순으로 해서 인포창 갱신 부하 줄인다.
    //target.model.LAYOUT_POSX = target.model.LAYOUT_POSX + mx;
    //target.model.LAYOUT_POSY = target.model.LAYOUT_POSY + my;
    //target.style.transform = "translate("+target.model.LAYOUT_POSX+"px, "+target.model.LAYOUT_POSY+"px) scale("+target.model.SCALE+") rotateZ("+target.model.ROTATE+"deg)";
  }

  //이동끝
  setMoveEnd(target){
    target.model.LAYOUT_POSX = target.model.TEMP_POSX;
    target.model.LAYOUT_POSY = target.model.TEMP_POSY;
  }

  //넓이 정하기 (풀사이즈)
  setWidth(w){
    this.temp_div.model.TEMP_WIDTH  = w;
    this.temp_div.style.width  = w + "px";
    this.temp_div.model.TEMP_POSX  = 0;
    this.temp_div.style.transform = "translate("+0+"px, "+this.temp_div.model.LAYOUT_POSY+"px) scale("+this.temp_div.model.SCALE+") rotateZ("+this.temp_div.model.ROTATE+"deg)";
    this.temp_div.style.backgroundSize = w + "px " + this.temp_div.model.LAYOUT_HEIGHT + "px";

    this.temp_div.model.LAYOUT_POSX   = 0;
    this.temp_div.model.LAYOUT_WIDTH  = w;

  }

  //중앙위치
  centerPos(){
    var posx = (384/2)-(this.temp_div.model.LAYOUT_WIDTH/2);
    this.temp_div.model.TEMP_POSX  = posx;
    this.temp_div.style.transform = "translate("+posx+"px, "+this.temp_div.model.LAYOUT_POSY+"px) scale("+this.temp_div.model.SCALE+") rotateZ("+this.temp_div.model.ROTATE+"deg)";
    this.temp_div.model.LAYOUT_POSX   = posx;
  }

  //위치와 크기를 강제로 설정하는 좌표방식 - gsmath
  setSizePos(target, x, y, w, h, fix){
    
    target.model.TEMP_WIDTH  = w;
    target.model.TEMP_HEIGHT = h;
    target.model.TEMP_POSX   = x;
    target.model.TEMP_POSY   = y;
    target.style.transform = "translate("+x+"px, "+y+"px) scale("+target.model.SCALE+") rotateZ("+target.model.ROTATE+"deg)";
    target.style.backgroundSize = w + "px " + h + "px";
    
    if(fix){
      //저장
      target.model.LAYOUT_POSX   = x;
      target.model.LAYOUT_POSY   = y;
      target.model.LAYOUT_WIDTH  = w;
      target.model.LAYOUT_HEIGHT = h;
    }

  }

  //스케일 조절
  //target: component
  //edit_div : 편집 더미
  setSize(target, edit_div, scale, mode, fix){
    var tempw, temph, tempx, tempy, subx, suby = 0;
    //mode에 따라서 고정되는 위치가 달라짐. 여기서는 
    if(mode == "nw"){
      //좌측상단 - 마우스 up을 할때까지는 임시사이즈로 보여준다.
      tempw = Math.floor(target.model.LAYOUT_WIDTH  * scale);
      temph = Math.floor(target.model.LAYOUT_HEIGHT * scale);
      target.model.TEMP_WIDTH  = tempw;
      target.model.TEMP_HEIGHT = temph;
      target.style.width  = tempw + "px";
      target.style.height = temph + "px";
      
      //기준점 대비 좌표 (우측 하단이 기준)
      subx = edit_div.LAYOUT_RIGHT  - target.model.LAYOUT_POSX;
      suby = edit_div.LAYOUT_BOTTOM - target.model.LAYOUT_POSY;
      
      //좌표 계산 - 기준점 대비 좌표값을 계산해야한다.
      tempx = Math.floor(edit_div.LAYOUT_RIGHT  - (subx * scale));
      tempy = Math.floor(edit_div.LAYOUT_BOTTOM - (suby * scale));
      target.model.TEMP_POSX  = tempx;
      target.model.TEMP_POSY  = tempy;

      target.style.transform = "translate("+tempx+"px, "+tempy+"px) scale("+target.model.SCALE+") rotateZ("+target.model.ROTATE+"deg)";
      target.style.backgroundSize = tempw + "px " + temph + "px";
    }else if(mode == "sw"){
      //좌측상단 
      tempw = Math.floor(target.model.LAYOUT_WIDTH  * scale);
      temph = Math.floor(target.model.LAYOUT_HEIGHT * scale);
      
      target.model.TEMP_WIDTH  = tempw;
      target.model.TEMP_HEIGHT = temph;
      target.style.width  = tempw + "px";
      target.style.height = temph + "px";
      
      //기준점 대비 좌표 (우측 상단이 기준)
      subx = edit_div.LAYOUT_RIGHT - target.model.LAYOUT_POSX;
      suby = edit_div.LAYOUT_TOP - target.model.LAYOUT_POSY;

      //좌표 계산 - 기준점 대비 좌표값을 계산해야한다.
      tempx = Math.floor(edit_div.LAYOUT_RIGHT  - (subx * scale));
      tempy = Math.floor(edit_div.LAYOUT_TOP - (suby * scale));
      target.model.TEMP_POSX  = tempx;
      target.model.TEMP_POSY  = tempy;

      target.style.transform = "translate("+tempx+"px, "+tempy+"px) scale("+target.model.SCALE+") rotateZ("+target.model.ROTATE+"deg)";
      target.style.backgroundSize = tempw + "px " + temph + "px";
    }else if(mode == "n"){
      //상단 중앙
      tempw = target.model.LAYOUT_WIDTH;  //그대로
      temph = Math.floor(target.model.LAYOUT_HEIGHT * scale);
      target.model.TEMP_WIDTH  = tempw;
      target.model.TEMP_HEIGHT = temph;
      target.style.width  = tempw + "px";
      target.style.height = temph + "px";
      
      //기준점 대비 컴포넌트 좌표의 거리(하단 중앙이 기준)
      subx = target.model.LAYOUT_POSX;
      suby = edit_div.LAYOUT_BOTTOM - target.model.LAYOUT_POSY;

      //스케일에 따른 좌표 계산 - 기준점 대비 거리에 스케일 처리
      tempx = subx;
      tempy = Math.floor(edit_div.LAYOUT_BOTTOM - (suby * scale));
      target.model.TEMP_POSX  = tempx;
      target.model.TEMP_POSY  = tempy;

      target.style.transform = "translate("+tempx+"px, "+tempy+"px) scale("+target.model.SCALE+") rotateZ("+target.model.ROTATE+"deg)";
      target.style.backgroundSize = tempw + "px " + temph + "px";
    }else if(mode == "s"){
      //하단 중앙
      tempw = target.model.LAYOUT_WIDTH;  //그대로
      temph = Math.floor(target.model.LAYOUT_HEIGHT * scale);
      target.model.TEMP_WIDTH  = tempw;
      target.model.TEMP_HEIGHT = temph;
      target.style.width  = tempw + "px";
      target.style.height = temph + "px";
      
      //기준점 대비 컴포넌트 좌표의 거리(상단 중앙이 기준)
      subx = target.model.LAYOUT_POSX;
      suby = target.model.LAYOUT_POSY - edit_div.LAYOUT_TOP;

      //스케일에 따른 좌표 계산 - 기준점 대비 거리에 스케일 처리
      tempx = subx;
      tempy = Math.floor(edit_div.LAYOUT_TOP + (suby * scale));
      target.model.TEMP_POSX  = tempx;
      target.model.TEMP_POSY  = tempy;

      target.style.transform = "translate("+tempx+"px, "+tempy+"px) scale("+target.model.SCALE+") rotateZ("+target.model.ROTATE+"deg)";
      target.style.backgroundSize = tempw + "px " + temph + "px";
    }else if(mode == "e"){
      //우측 중앙
      tempw = Math.floor(target.model.LAYOUT_WIDTH * scale);
      temph = target.model.LAYOUT_HEIGHT; //그대로
      target.model.TEMP_WIDTH  = tempw;
      target.model.TEMP_HEIGHT = temph;
      target.style.width  = tempw + "px";
      target.style.height = temph + "px";
      
      //기준점 대비 컴포넌트 좌표의 거리(좌측 중앙이 기준)
      subx = target.model.LAYOUT_POSX - edit_div.LAYOUT_LEFT;
      suby = target.model.LAYOUT_POSY;

      //스케일에 따른 좌표 계산 - 기준점 대비 거리에 스케일 처리
      tempx = Math.floor(edit_div.LAYOUT_LEFT + (subx * scale));
      tempy = target.model.LAYOUT_POSY;
      target.model.TEMP_POSX  = tempx;
      target.model.TEMP_POSY  = tempy;

      target.style.transform = "translate("+tempx+"px, "+tempy+"px) scale("+target.model.SCALE+") rotateZ("+target.model.ROTATE+"deg)";
      target.style.backgroundSize = tempw + "px " + temph + "px";
    }else if(mode == "w"){
      //좌측 중앙
      tempw = Math.floor(target.model.LAYOUT_WIDTH * scale);
      temph = target.model.LAYOUT_HEIGHT; //그대로
      target.model.TEMP_WIDTH  = tempw;
      target.model.TEMP_HEIGHT = temph;
      target.style.width  = tempw + "px";
      target.style.height = temph + "px";
      
      //기준점 대비 컴포넌트 좌표의 거리(우측 중앙이 기준) 
      subx = edit_div.LAYOUT_RIGHT - target.model.LAYOUT_POSX;
      suby = target.model.LAYOUT_POSY;

      //스케일에 따른 좌표 계산 - 기준점 대비 거리에 스케일 처리
      tempx = Math.floor(edit_div.LAYOUT_RIGHT - (subx * scale));
      tempy = target.model.LAYOUT_POSY;
      target.model.TEMP_POSX  = tempx;
      target.model.TEMP_POSY  = tempy;

      target.style.transform = "translate("+tempx+"px, "+tempy+"px) scale("+target.model.SCALE+") rotateZ("+target.model.ROTATE+"deg)";
      target.style.backgroundSize = tempw + "px " + temph + "px";
    }else if(mode == "ne"){
      //우측상단
      tempw = Math.floor(target.model.LAYOUT_WIDTH  * scale);
      temph = Math.floor(target.model.LAYOUT_HEIGHT * scale);
      target.model.TEMP_WIDTH  = tempw;
      target.model.TEMP_HEIGHT = temph;
      target.style.width  = tempw + "px";
      target.style.height = temph + "px";
      
      //기준점 대비 좌표 (좌측 하단이 기준)
      subx = edit_div.LAYOUT_LEFT   - target.model.LAYOUT_POSX;
      suby = edit_div.LAYOUT_BOTTOM - target.model.LAYOUT_POSY;

      //좌표 계산 - 기준점 대비 좌표값을 계산해야한다.
      tempx = Math.floor(edit_div.LAYOUT_LEFT   - (subx * scale));
      tempy = Math.floor(edit_div.LAYOUT_BOTTOM - (suby * scale));
      target.model.TEMP_POSX  = tempx;
      target.model.TEMP_POSY  = tempy;

      target.style.transform = "translate("+tempx+"px, "+tempy+"px) scale("+target.model.SCALE+") rotateZ("+target.model.ROTATE+"deg)";
      target.style.backgroundSize = tempw + "px " + temph + "px";
    }else if(mode == "se"){
      //우측하단
      tempw = Math.floor(target.model.LAYOUT_WIDTH  * scale);
      temph = Math.floor(target.model.LAYOUT_HEIGHT * scale);
      target.model.TEMP_WIDTH  = tempw;
      target.model.TEMP_HEIGHT = temph;
      target.style.width  = tempw + "px";
      target.style.height = temph + "px";
      
      //기준점 대비 좌표 (좌측 상단이 기준)
      subx = edit_div.LAYOUT_LEFT - target.model.LAYOUT_POSX;
      suby = edit_div.LAYOUT_TOP  - target.model.LAYOUT_POSY;

      //좌표 계산 - 기준점 대비 좌표값을 계산해야한다.
      tempx = Math.floor(edit_div.LAYOUT_LEFT  - (subx * scale));
      tempy = Math.floor(edit_div.LAYOUT_TOP   - (suby * scale));
      target.model.TEMP_POSX  = tempx;
      target.model.TEMP_POSY  = tempy;

      target.style.transform = "translate("+tempx+"px, "+tempy+"px) scale("+target.model.SCALE+") rotateZ("+target.model.ROTATE+"deg)";
      target.style.backgroundSize = tempw + "px " + temph + "px";
    
    }

    if(fix){
      //저장
      target.model.LAYOUT_POSX   = tempx;
      target.model.LAYOUT_POSY   = tempy;
      target.model.LAYOUT_WIDTH  = tempw;
      target.model.LAYOUT_HEIGHT = temph;
    }

  }

  setRotation(target, edit_div, rotate, fix){
    var temp_rotate = rotate;
    var subx, suby = 0;
    
    //edit_div의 중심과 좌표값의 차이를 구한다.
    //기준점 대비 좌표 (좌측 상단이 기준)
    subx = edit_div.LAYOUT_LEFT + (edit_div.LAYOUT_WIDTH/2) - target.model.LAYOUT_POSX;
    suby = edit_div.LAYOUT_TOP + (edit_div.LAYOUT_HEIGHT/2) - target.model.LAYOUT_POSY;
    target.style.transformOrigin = subx+"px "+suby+"px";
    target.style.transform = "translate("+target.model.LAYOUT_POSX+"px, "+target.model.LAYOUT_POSY+"px) scale("+target.model.SCALE+") rotateZ("+temp_rotate+"deg)";
    
    if(fix){
      target.model.ROTATE    = temp_rotate;
      target.model.ORIGIN_X  = subx;
      target.model.ORIGIN_Y  = suby;
    }
  }


}

//해당 js파일에서 DRComponent 클래스를 외부로 export하기 위한 코드
//클래스 외에도 함수나 변수를 외부로 export할 때 사용하며 import나 require로 사용
export default DRComponent;
