<template>

  <v-container fluid class="ma-0 pa-0" style="background:white;min-height:100%">

    <v-row class="ma-0 pa-0 hidden-sm-and-down" no-gutters dense>
      <v-col cols="12">
      <v-parallax
        height="226" 
        :src="require('@/assets/areabg.png')"
        >
        <v-row align="center" justify="center" >
          <v-col cols="12" class="text-center mt-15">
            <label style="color:white;font-size:25px">전국 지점 안내</label>
          </v-col>
        </v-row>
      </v-parallax>    
      </v-col>
    </v-row>

    <v-row justify="center" align="center">
      <v-col class="hidden-sm-and-down" cols="10" md="5" align="center">
        <div>
          <div style="z-index:1;position:relative;left:0px;top:40px;height:13px;width:460px;background:#fcecec"/>
          <label class="areaunderlinestyle">#주변에 가까운 감성수학을 만나보세요</label>
        </div>
      </v-col>
      <v-col cols="10" md="3" align="center">
        <v-text-field
          single-line 
          @keyup.enter="search_run" 
          v-model="search_keyword"
          hide-details
          outlined 
          color="#808080"
          placeholder="지점명을 입력하세요"
          class="landsearchstyle"
          append-icon="mdi-magnify"
          />
      </v-col>
    </v-row>

    <v-row justify="center" dense no-gutters class="ma-0 pa-0 mt-10">
      <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11">
        <v-row align="center">
          <v-col cols="3" md="6">
            <label style="margin-left:15px;color:#3d3d3d;font-weight:bold">지점명</label>
          </v-col>
          <v-col cols="3" class="hidden-sm-and-down">
            <label style="margin-left:5px;color:#3d3d3d;font-weight:bold">전화번호</label>
          </v-col>
          <v-col cols="6" md="6" class="hidden-sm-and-down">
            <label style="margin-left:0px;color:#3d3d3d;font-weight:bold">주소</label>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11">
        <v-row style="height:1px;background:#9f0007"/>
      </v-col>
    </v-row>
    
    <v-row justify="center" style="min-height:400px" dense no-gutters class="ma-0 pa-0">
      <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11">
        <v-expansion-panels  class="ma-0 pa-0">
          <v-expansion-panel class="ma-0 pa-0"
            v-for="(item, index) in itemList" :key="index"
            >
            <v-expansion-panel-header @click="edit_item(index)"
              :style="editedIndex == index? 'background:#9f0007':'background:white'"
              disable-icon-rotate style="areacolumnheaderstyle" 
              >
              <template v-slot:actions>
                <v-icon :color="editedIndex == index ? 'white' : '#9f0007'">mdi-plus</v-icon>
              </template>
              <v-row align="center">
                <v-col cols="12" md="3" sm="3">
                  <label :style="editedIndex == index? 'color:white;font-weight:bold':'color:#3d3d3d;font-weight:bold'">{{ item.AREA_NAME }}</label>
                </v-col>
                <v-col cols="3" class="hidden-xs-only">
                  <label :style="editedIndex == index? 'color:white':'color:#3d3d3d'">{{ item.AREA_TEL }}</label>
                </v-col>
                <v-col cols="6" class="hidden-xs-only">
                  <label :style="editedIndex == index? 'color:white':'color:#3d3d3d'">{{ item.AREA_ADDR1 }}</label>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content style="background:#f9f9f9">
              <v-row class="mt-5 mb-3">
                <v-col cols="6" align="center">
                  <v-row justify="center">
                    <div class="gmap" :id="'map'+item.AREA_NO" />
                  </v-row>
                </v-col>
                <v-col cols="6" align="center">
                  <v-row>
                    <v-col cols="12" xl="3" lg="3"  md="3" xs="12" sm="12" align="left">
                      <label class="areacolumntitletext">매장명</label>
                    </v-col>
                    <v-col cols="12" xl="9" lg="9"  md="9" xs="12" sm="12" align="left">
                      <label class="areacolumnvaluetext">{{item.AREA_NAME}}</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" xl="3" lg="3"  md="3" xs="12" sm="12" align="left">
                      <label class="areacolumntitletext">전화번호</label>
                    </v-col>
                    <v-col cols="12" xl="9" lg="9"  md="9" xs="12" sm="12" align="left">
                      <label class="areacolumnvaluetext">{{item.AREA_TEL}}</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" xl="3" lg="3"  md="3" xs="12" sm="12" align="left">
                      <label class="areacolumntitletext">주소</label>
                    </v-col>
                    <v-col cols="12" xl="9" lg="9"  md="9" xs="12" sm="12" align="left">
                      <label class="areacolumnvaluetext">{{item.AREA_ADDR1}}</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" xl="3" lg="3"  md="3" xs="12" sm="12" align="left">
                      <label class="areacolumntitletext">상세주소</label>
                    </v-col>
                    <v-col cols="12" xl="9" lg="9"  md="9" xs="12" sm="12" align="left">
                      <label class="areacolumnvaluetext">{{item.AREA_ADDR2}}</label>
                    </v-col>
                  </v-row>
                  
                  <v-row v-if="item.AREA_URL != null && item.AREA_URL != undefined && item.AREA_URL.length > 1">
                    <v-col cols="12" xl="6" lg="6"  md="6" xs="12" sm="12" align="left">
                      <v-btn class="homepage_btn_freesize" @click="gotopage(item)">홈페이지 바로가기</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
          
      </v-col>
    </v-row>

  <!-- 하단 페이징 영역 -->
  <v-row align="center" justify="center" class="pb-10">
    <v-col cols="12" md="6" class="d-flex justify-center">
      <v-btn v-if="bottompage > 10" icon  small @click="prev()"><v-icon dark>mdi-chevron-left</v-icon></v-btn>
      <span v-for="n in subpage" :key="n.id">
          <span v-if="page == bottompage-1+n">
              <v-btn color="red" class="numberstyle" icon small @click="loadpage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
          <span v-else>
              <v-btn  icon small class="numberstyle2" @click="loadpage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
      </span>
      <v-btn v-if="subpage == 10" icon small @click="next()" ><v-icon dark>mdi-chevron-right</v-icon></v-btn>
    </v-col>
  </v-row>
    
  </v-container>
</template>

<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=2d34ab8a2ba110eed5c9ab2ba610bea6&libraries=services"></script>
<script>
//import dateFormat from 'dateformat'
export default {
    data: () => ({
      
      //필수 페이징 파라메터
      loading : false,    //로딩 표시
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수

      gmap:null,
      gmarker:null,

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          AREA_NAME:'',
          AREA_TEL:'',
          AREA_URL:'',
          LATITUTE:'37.51205332712675',
          LONGITUTE:'127.05914127516714',
          AREA_ADDR1:'',
          AREA_ADDR2:'',
      },
      editedItem: {
      },


      
      search: '',         //필터링용 검색어

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
      },
      editedItem: {
      },

      //테이블 정의
      headers: [
        {text: '',    value: 'AREA_NAME', sortable: false, align:'left', width:270, class:"cheader_normal" },
      ],
      itemList: [],

      //검색 파라메터
      sort_name: 'AREA_NAME',
      sort_type: false,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    

      marker_position:{lat: 37.512268, lng: 127.058940},
      map_position: {lat: 37.512268, lng: 127.058940},

    }),

    mounted(){
      this.initpage();
    },

    create(){

    },

    methods: {

      gotopage(item){
        window.open(item.AREA_URL, '_blank');
      },

      edit_item(idx){

        this.editedIndex = idx;
        const item = Object.assign({}, this.itemList[this.editedIndex]);

        if(item.LATITUTE != null && item.LONGITUTE != null && item.LATITUTE.length > 0 && item.LONGITUTE.length > 0 ){
          const mposition    = JSON.parse('{"lat":'+parseFloat(item.LATITUTE)+', "lng":'+parseFloat(item.LONGITUTE)+'}');
          const marker_pos   = JSON.parse('{"lat":'+parseFloat(item.LATITUTE)+', "lng":'+parseFloat(item.LONGITUTE)+'}');
          //맵은 화면에 div가 노출된 후에 그려야한다. 안보이면 오류남.
          setTimeout(() => {
            const nmap = new google.maps.Map(document.getElementById("map"+item.AREA_NO),{
              zoom: 16, center:mposition
            });
            const nmarker = new google.maps.Marker({position:marker_pos});
            nmarker.setMap(nmap);
          }, 50)
        }


      },


      //검색
      search_run(){
        this.editedIndex = -1;
        this.loadpage(1);
      },

      initpage(){
        //첫페이지 로드
        this.loadpage(1);
      },

      //정렬
      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      //페이징
      prev(){
          this.bottompage = this.bottompage - 10 >= 1 ? this.bottompage - 10 : 1;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage + this.subpage - 1; //이전을 누르면 가장 끝 번호 선택
          this.loadpage(temp_page);
      },
      next(){
          this.bottompage = this.bottompage + 10 <= this.maxpage ? this.bottompage + 10 : this.maxpage;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage;
          this.loadpage(temp_page);
      },
      makepaging(){
        var div1 = this.totalcnt / this.pagerow; 
        var div2 = this.totalcnt % this.pagerow;
        if(div2 == 0){  
            this.maxpage = parseInt(div1);    
        }else{
            this.maxpage = parseInt(div1+1);
        }
        if(this.bottompage == 1){
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
        }
      },

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      loadpage(p){
        
          if(this.loading){
            return;
          }
          this.itemList.splice(0, this.itemList.length);   //데이터 초기화
          this.loading = true;
          this.page = p;

          this.$http.post(this.$host+'/GuestAreaList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
          }).then((result)=>{
            
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

                this.totalcnt = result.data.totalcnt;
                this.makepaging();
                const list = result.data.resultData;
                
                list.forEach(element => {
                  this.itemList.push(element); 
                });
                this.loading = false;
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },


      //지도
      postcodetoMap(mpos){
        this.map_position    = mpos;
        this.marker_position = mpos;

        this.gmap.panTo(this.map_position);
        this.gmarker.setPosition(this.marker_position);

        //지도위치 저장
        var temp = JSON.stringify(this.marker_position);
        const pos = JSON.parse(temp);
        this.editedItem.LATITUTE  =  String(pos.lat);
        this.editedItem.LONGITUTE =  String(pos.lng);
      },

      postcodetoAddr(addr){
        this.editedItem.AREA_ADDR1 = addr;
        this.$forceUpdate();
      },

      postcode(){

        //콜백에서 못찾아서 콘스트로 미리 빼둔다.
        const callback_mapfunction      = this.postcodetoMap;
        const callback_addrfunction     = this.postcodetoAddr;

        new daum.Postcode({
            oncomplete: function(data) {

                //console.log(JSON.stringify(data));
                var addr = data.address; // 최종 주소 변수
                if(data.userSelectedType == "J"){
                  //지번
                  addr = data.jibunAddress; 
                }

                //주소 정보를 해당 필드에 넣는다.
                callback_addrfunction(addr);
                
                var geocoder = new daum.maps.services.Geocoder();
                
                //주소로 상세 정보를 검색
                geocoder.addressSearch(data.address, function(results, status) {

                    // 정상적으로 검색이 완료됐으면
                    if (status === window.daum.maps.services.Status.OK) {

                        var result = results[0]; //첫번째 결과의 값을 활용

                        // 해당 주소에 대한 좌표를 받아서
                        //var coords = new daum.maps.LatLng(result.y, result.x);
                        var markerpos = JSON.parse('{"lat":'+result.y+', "lng":'+result.x+'}');
                        callback_mapfunction(markerpos);

                        //지도를 보여준다.
                        /*
                        mapContainer.style.display = "block";
                        map.relayout();
                        // 지도 중심을 변경한다.
                        map.setCenter(coords);
                        // 마커를 결과값으로 받은 위치로 옮긴다.
                        marker.setPosition(coords)
                        */

                    }
                });
            }
        }).open();

      },

    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>
.v-main__wrap{
  background: white;
}

  .gmap{
    width:530px;
    height: 420px;
    background-color: gray;
  }
</style>