<template>

  <v-container fluid style="background:white;min-height:100%">

    <v-row align="center" justify="start" dense class="pa-0">
      <v-col cols="1">
        <v-btn text @click="back">뒤로</v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="start" dense class="pa-0 ma-0">
      <v-col cols="12" align="center">
        <label class="menunamestyle" style="font-size:18px;color:#404040">{{EXAM_NAME1}}</label><br>
        <label class="menunamestyle" style="font-size:24px;color:#404040">{{EXAM_NAME2}}</label>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" dense class="pa-0 ma-0">
        <label class="menunamestyle mr-5" style="font-size:14px;color:#404040">{{AREA_NAME}}</label>
        <v-text-field 
          style="max-width:200px"
          v-model="STUDENT_NAME" outlined dense hide-details
          label="이름" placeholder="이름을 입력하세요"
          />
    </v-row>
    <v-row class="mt-0" justify="center">
      <!-- 과목 -->
      <v-col v-if="itemList1.length > 0" cols="12" md="6" class="pa-5">
        <v-row>
          <v-col cols="12" align="center" style="background:#a0a0a0">
            <label class="menunamestyle" style="font-size:16px">{{'공통과목('+this.SUBJECT_NAME1+')'}}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" align="center">
            <table style="width:100%">
              <thead>
                <tr>
                  <th style="text-align:end">번호</th>
                  <th>정답</th>
                </tr>
              </thead>              
              <tbody>
                <tr v-for="item in itemList1" :key="item.ANSWERS_NO">
                  <td style="width:30%; text-align:end">{{ item.ANSWERS_NUMBER +'번 문제'}}{{item.ANSWERS_TYPE == 1 ? '(주)':''}}</td>
                  <td style="width:70%; text-align:center">
                    <template v-if="item.ANSWERS_TYPE == 0">
                      <input class="inputstyle" :id="item.ANSWERS_NO" v-model="item.ANSWERS" oninput="javascript:this.value=this.value.replace(/[^0-9]/g,'');">
                    </template>
                    <template v-else>
                      <input class="inputstyle" :id="item.ANSWERS_NO" v-model="item.ANSWERS">
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-col>
        
      <!-- 문제 -->
      <v-col v-if="itemList2.length > 0" cols="12" md="6" sm="12" xs="12" class="pa-5">
        <v-row align="center">
          <v-col cols="12" align="center" style="background:#a0a0a0">
            <label class="menunamestyle" style="font-size:16px">{{'선택과목('+this.SUBJECT_NAME2+')'}}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" align="center">
            <table style="width:100%">
              <thead>
                <tr>
                  <th style="text-align:end">번호</th>
                  <th>정답</th>
                </tr>
              </thead>              
              <tbody>
                <tr v-for="item in itemList2" :key="item.ANSWERS_NO">
                  <td style="width:30%; text-align:end">{{item.ANSWERS_NUMBER +'번 문제'}}{{item.ANSWERS_TYPE == 1 ? '(주)':''}}</td>
                  <td style="width:70%; text-align:center">
                    <template v-if="item.ANSWERS_TYPE == 0">
                      <input class="inputstyle" :id="item.ANSWERS_NO" v-model="item.ANSWERS" oninput="javascript:this.value=this.value.replace(/[^0-9]/g,'');">
                    </template>
                    <template v-else>
                      <input class="inputstyle" :id="item.ANSWERS_NO" v-model="item.ANSWERS">
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" class="mb-15">
      <v-col cols="12" md="6" align="center">
        <v-btn width="100%" class="search_btn_freesize" @click="next">정답 저장하기</v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

export default {


    data: () => ({
      grade:0,
      loading1:false,
      
      AREA_NO:null,
      AREA_NAME:null,
      EXAM_NO:null,
      SUBJECT_NO:null,

      EXAMINFO:null,
      INPUT_CNT:0,

      EXAM_NAME1:"",
      EXAM_NAME2:"",
      SUBJECT_NAME1: "",
      SUBJECT_NAME2: "",
      STUDENT_NAME: "",
      SUM:0,

      itemList1: [],
      itemList2: [],

      //테이블 정의
      headers1: [
        {text: '번호', value: 'SUBJECT_NAME', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '배점', value: 'SUBJECT_TYPE', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '타입', value: 'SUBJECT_TYPE', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '정답', value: 'SUBJECT_POINTS', sortable: false, align:'center', width:100, class:"cheader_normal"},
      ],

      headers2: [
        {text: '번호', value: 'SUBJECT_NAME', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '배점', value: 'SUBJECT_TYPE', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '타입', value: 'SUBJECT_TYPE', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '정답', value: 'SUBJECT_POINTS', sortable: false, align:'center', width:100, class:"cheader_normal"},
      ],

    }),

    mounted(){
      this.grade = this.$session.get("GRADE");
      if(this.grade < 10){
        this.$alert("사용할 수 없는 페이지입니다.").then(()=>{
          this.$router.push("Welcome");
        });
        return;
      }

      this.AREA_NO    = this.$route.query.area_no;
      this.AREA_NAME  = this.$route.query.area_name;
      this.EXAM_NO    = this.$route.query.exam;
      this.SUBJECT_NO = this.$route.query.subject;
      
      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("StudentLogin");
      }
    },

    create(){

    },

    methods: {
      
      handleKeyDown(event) {
        if (event.key.length === 1 && !isFinite(event.key)) {
          event.preventDefault();
        }        
      },

      back(){
        this.$confirm("입력을 취소하고 뒤로 이동하시겠습니까?")
        .then(() => {
          this.$router.back();
        }).catch(() => {
        });
      },

      async initpage(){

        //시험 검증
        await this.loadexam();

        //문제 읽어오기
        await this.loadsubject();

      },

      next(){
        
        //정답저장하기

        //100점이 안나오는 문제는 리턴
        /*
        if(this.SUM != 100){
          this.$alert("잘못된 시험정보는 정답을 입력할 수 없습니다. 관리자에게 문의하세요.");
          return;
        }
        */

        if(this.STUDENT_NAME == null || this.STUDENT_NAME == undefined || this.STUDENT_NAME.length == 0){
          this.$alert("이름을 입력하세요.");
          return;
        }
        if(this.AREA_NO == null || this.AREA_NO == undefined || this.AREA_NO.length == 0){
          this.$alert("지점정보가 없습니다. 뒤로가기 후 지점을 선택하세요.");
          return;
        }
        if(this.EXAM_NO == null || this.EXAM_NO == undefined || this.EXAM_NO.length == 0){
          this.$alert("시험정보가 없습니다. 뒤로가기 후 지점을 선택하세요.");
          return;
        }

        for(var i=0;i<this.itemList1.length;i++){
          const temp = this.itemList1[i];
          if(temp.ANSWERS == null || temp.ANSWERS == undefined || temp.ANSWERS.length == 0){
            const tempinput = document.getElementById(temp.ANSWERS_NO);
            this.$vuetify.goTo(tempinput, {duration:200, offset:50, easing:"easeInOutCubic"});
            tempinput.focus();
            this.$alert(temp.ANSWERS_NUMBER+"번 문제의 정답을 입력하세요");
            return;
          }
          if(temp.ANSWERS_TYPE == 0){
            if(temp.ANSWERS.length > 1){
              const tempinput = document.getElementById(temp.ANSWERS_NO);
              this.$vuetify.goTo(tempinput, {duration:200, offset:50, easing:"easeInOutCubic"});
              tempinput.focus();
              this.$alert(temp.ANSWERS_NUMBER+"번 문제는 객관식입니다. 1자리의 숫자를 입력하세요");
              return;
            }
          }
        }

        for(var j=0;j<this.itemList2.length;j++){
          const temp = this.itemList2[j];
          if(temp.ANSWERS == null || temp.ANSWERS == undefined || temp.ANSWERS.length == 0){
            const tempinput = document.getElementById(temp.ANSWERS_NO);
            this.$vuetify.goTo(tempinput, {duration:200, offset:50, easing:"easeInOutCubic"});
            tempinput.focus();
            this.$alert(temp.ANSWERS_NUMBER+"번 문제의 정답을 입력하세요");
            return;
          }
          if(temp.ANSWERS_TYPE == 0){
            if(temp.ANSWERS.length > 1){
              const tempinput = document.getElementById(temp.ANSWERS_NO);
              this.$vuetify.goTo(tempinput, {duration:200, offset:50, easing:"easeInOutCubic"});
              tempinput.focus();
              this.$alert(temp.ANSWERS_NUMBER+"번 문제는 객관식입니다. 1자리의 숫자를 입력하세요");
              return;
            }
          }
        }

        //데이터 취합하기
        const answerlist = this.itemList1.concat(this.itemList2);

        //보내기
        this.$http.post(this.$host+'/AdminAnswersInsert',{
            STUDENT_NAME: this.STUDENT_NAME,
            AREA_NO: this.AREA_NO,
            EXAM_NO: this.EXAM_NO,
            JSONVALUE: escape(JSON.stringify(answerlist))
        },{headers: { firetoken:this.$session.get("FIRETOKEN") }})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              this.$alert("정답 입력이 완료되었습니다.").then(()=>{
                //초기화
                //this.STUDENT_NAME = "";
                for(var i=0;i<this.itemList1.length;i++){
                  //const temp = this.itemList1[i];
                  //정답 미리 입력해놓기
                  //element.ANSWERS = "2";
                  //temp.ANSWERS = "";
                }
                for(var j=0;j<this.itemList2.length;j++){
                  //const temp = this.itemList2[j];
                  //정답 미리 입력해놓기
                  //element.ANSWERS = "2";
                  //temp.ANSWERS = "";
                }
              });
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.loading = false;
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');

          }else{
            this.loading = false;
            if(result.data.resultMsg != null && result.data.resultMsg != undefined && result.data.resultMsg.length > 0){
              this.$alert(result.data.resultMsg);
            }else{
              this.$alert("시험정보가 없습니다.");
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert("이미 입력하신 시험입니다.");
          }
        });        

      },

      async loadsubject(){

          await this.$http.post(this.$host+'/getExamAnswerList',{
            EXAM_NO: this.EXAM_NO,
            SUBJECT_NO: this.SUBJECT_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN") }})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

                const list1 = result.data.resultData1;
                this.itemList1.splice(0, this.itemList1.length);  
                list1.forEach(element => {
                    this.itemList1.push(element); 
                });
                if(this.itemList1.length > 0){
                  this.SUBJECT_NAME1 = this.itemList1[0].SUBJECT_NAME;
                }

                const list2 = result.data.resultData2;
                this.itemList2.splice(0, this.itemList2.length);  
                list2.forEach(element => {
                    this.itemList2.push(element); 
                });
                if(this.itemList2.length > 0){
                  this.SUBJECT_NAME2 = this.itemList2[0].SUBJECT_NAME;
                }
                this.$forceUpdate();

                //점수 검증
                let sum1 = 0;
                if(this.itemList1.length > 0){
                  sum1 = this.itemList1.reduce((accumulator, current) => {
                    return accumulator + parseFloat(current.POINT);
                  }, 0);      
                }
                let sum2 = 0;
                if(this.itemList2.length > 0){
                  sum2 = this.itemList2.reduce((accumulator, current) => {
                    return accumulator + parseFloat(current.POINT);
                  }, 0);      
                }
                this.SUM = sum1 + sum2;
                //console.log("sum : " + this.SUM);
                if(this.SUM != 100){
                  this.$alert("합계가 100점이 안되는 잘못된 시험정보입니다.");
                }

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.loading = false;
              this.$alert("시험정보가 없습니다.").then(()=>{
                this.back();
              });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },

   


      async loadexam(){

          if(this.EXAM_NO == null || this.EXAM_NO == undefined){
            this.$alert("시험정보가 없습니다.").then(()=>{
              this.back();
            });
            return;
          }

          await this.$http.post(this.$host+'/AdminCheckExamList',{
            STUDENT_NO: null,
            EXAM_NO: this.EXAM_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN") }})
          .then((result)=>{
            
            if(result.data.resultCode == 0){
                this.EXAMINFO   = result.data.resultData[0];
                this.EXAM_NAME1 = this.EXAMINFO.EXAM_YEAR+"년도 " + this.EXAMINFO.GRADENAME;
                this.EXAM_NAME2 = this.EXAMINFO.EXAM_NAME;
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.loading = false;
              this.$alert("시험정보가 없습니다.").then(()=>{
                this.back();
              });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },



    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.inputstyle{
  border: 2px solid #6c757d;
  border-radius: 5px;
  outline: none;
  padding: 8px 12px;
  font-size: 14px;
  text-align: center;
}
.inputstyle:focus{
  border-color: #9b5de5;
}

th, td{
  margin-top:3px;
  padding-bottom:20px;
}

</style>