<template>

  <v-container fluid>
    
    <!-- 추가/수정 팝업 -->
    <v-row >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog" width="800px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>
                <v-row dense align="center" class="text-center">
                  <v-col cols="1">
                    <label>제목</label>
                  </v-col>
                  <v-col cols="11">
                    <v-text-field 
                      single-line v-model="editedItem.TITLE" dense
                      placeholder="제목을 입력하세요" outlined color="#808080" hide-details        
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" class="text-center">
                  <v-col cols="1">
                    <label>댓글</label>
                  </v-col>
                  <v-col cols="3">
                    <v-select 
                      v-model="editedItem.REPPLE" :items="REPPLELIST" 
                      placeholder="댓글사용" dense
                      item-text="name" item-value="value" 
                      outlined color="#808080" hide-details  
                      />
                  </v-col>
                  <v-col cols="1">
                    <label>상단</label>
                  </v-col>
                  <v-col cols="3">
                    <v-select 
                      v-model="editedItem.FIX" :items="FIXLIST" 
                      placeholder="상단고정" item-text="name" item-value="value"
                      outlined color="#808080" hide-details  dense 
                      />
                  </v-col>
                  <v-col cols="1">
                    <label>읽기</label>
                  </v-col>
                  <v-col cols="3">
                    <v-select 
                      v-model="editedItem.READPM" :items="READPMLIST" 
                      placeholder="읽기권한" 
                      item-text="name" item-value="value" 
                      outlined color="#808080" hide-details  dense
                      />
                  </v-col>
                </v-row>

                <!-- 
                <v-row>
                  <v-col md="12" sm="12" xs="12">
                    <quill-editor v-model="content"
                      ref="myQuillEditor"
                      :options="editorOption"
                      >
                      </quill-editor>
                  </v-col>
                </v-row>
                -->

                <v-row>
                  <v-col md="12" sm="12" xs="12">
                  <editor
                    :initialValue="editorText"
                    :options="editorOptions"
                    height="500px"
                    initialEditType="wysiwyg"
                    previewStyle="vertical"
                    ref="toastuiEditor"
                    />
                  </v-col>
                </v-row>
            </v-container>
          </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">확인</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <!-- 상세내용 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="dialog2" width="800px">
        <v-card class="pa-1 ma-0">

        <v-row dense class="mt-5">
          <v-col cols="12" align="center">
            <span class="dialog_title">{{ editedItem.TITLE }}</span>
          </v-col>
        </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>
                <v-row>
                  <v-col md="12" sm="12" xs="12">
                    <div v-html="editedItem.DESC">
                    </div>
                  </v-col>
                </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center">
            <v-btn color="primary" @click="dialog2 = false">닫기</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>    

    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="progress_dialog" width="400px">
        <v-row justify="center">
          <v-col cols="2">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
              />
          </v-col>
        </v-row>
        <v-row justify="center">
            <label>잠시만 기다려주세요</label>
        </v-row>
      </v-dialog>  
    </v-row>    

    <v-row align="center" justify="start" class="mt-5">

      <v-col cols="12" md="3" sm="12" xs="12">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      
      <v-spacer/>

      <v-col cols="6"  md="3" sm="6" xs="6" class="pa-0">
        <v-text-field 
          dense single-line clearable
          @keyup.enter="search_run" 
          v-model="search_keyword" 
          outlined height="43"
          color="#808080"
          hide-details
          placeholder="검색어를 입력하세요"
          class="basicsearchstyle"
          />
      </v-col>
      <v-col cols="3" md="1" sm="3" xs="3" class="pa-0" align="center">
        <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="search_run">검색</v-btn>
      </v-col>        
      <v-col cols="3" md="1" sm="3" xs="3" class="pa-0" align="center">         
        <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="newinsert">추가</v-btn>
      </v-col>
      
  </v-row>
  
  <v-row dense no-gutters>
    <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
  </v-row>
    
  <v-row align="start" dense>
    <v-col>
      <!-- 테이블 -->
      <v-data-table
        :headers="headers"
        :items="itemList"
        v-model="selected"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        class="datatablestyle"
        :items-per-page="pagerow"
        :search="search"  
        item-key="BOARD_NO"
        :loading="loading"
        item-class="itemstyle"
        >
        <template v-slot:header.BOARD_NO>
          <a :class="sort_name=='BOARD_NO'?'cheader_sortselect':'cheader_sort'" @click="customsort('BOARD_NO')">번호</a>
        </template>
        <template v-slot:header.TITLE>
          <a :class="sort_name=='TITLE'?'cheader_sortselect':'cheader_sort'" @click="customsort('TITLE')">제목</a>
        </template>
        <template v-slot:header.UP_DATE>
          <a :class="sort_name=='UP_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('UP_DATE')">수정일시</a>
        </template>
        <template v-slot:header.REG_DATE>
          <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">등록일시</a>
        </template>

        <template v-slot:item.TITLE="{ item }">  
          <template v-if="item.FIX == 1">
            <a style="color:red" @click="loadnotice(item)" >{{item.TITLE}}{{item.REPPLE_CNT>0?' ('+item.REPPLE_CNT+')':''}}</a>
          </template>
          <template v-else>
            <a @click="loadnotice(item)" >{{item.TITLE}}{{item.REPPLE_CNT>0?' ('+item.REPPLE_CNT+')':''}}</a>
          </template>
        </template>

        <template v-slot:item.REPPLE="{ item }">  
          <label style="text-align:center">{{ item.REPPLE == 1 ? '사용' : '미사용' }}</label>
        </template>     
        <template v-slot:item.FIX="{ item }">  
          <label style="text-align:center">{{ item.FIX == 1 ? '사용' : '미사용' }}</label>
        </template>     
        <template v-slot:item.READPM="{ item }">  
          <label style="text-align:center">{{ item.READPM == 1 ? '전체' : '사용자' }}</label>
        </template>     

        <template v-slot:item.actions="{ item }">  
            <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
            <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
        </template>     
      </v-data-table>
    </v-col>
  </v-row>

  <!-- 하단 페이징 영역 -->
  <v-row align="start">
    <v-col cols="12" md="1"/>
    <v-col cols="12" md="2">
      <v-text-field
        v-model="search"
        append-icon="search"
        single-line
        hide-details
        outlined height="43"
        color="#808080"
        dense
        placeholder="페이지 내에서 검색"
        class="basicsearchstyle"
        />
    </v-col>
    <v-col cols="12" md="6" class="d-flex justify-center">
      <v-btn v-if="bottompage > 10"  icon  small @click="prev()"><v-icon dark>mdi-chevron-left</v-icon></v-btn>
      <span v-for="n in subpage" :key="n.id">
          <span v-if="page == bottompage-1+n">
              <v-btn color="red" class="numberstyle" icon small @click="movepage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
          <span v-else>
              <v-btn  icon small class="numberstyle2" @click="movepage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
      </span>
      <v-btn v-if="subpage == 10"  icon small @click="next()" ><v-icon dark>mdi-chevron-right</v-icon></v-btn>
    </v-col>
    <v-col cols="12" md="2">
      <v-select
        v-model="pagerow"
        :items="pagerow_combo"
        item-text="NAME"      
        item-value="VALUE"
        label="목록개수"
        min-width="150px"
        outlined height="43"
        color="#808080"
        dense
        hide-details
        placeholder="목록개수"
        class="basicsearchstyle"
        ></v-select>
    </v-col>
    <v-col cols="12" md="1"/>
  </v-row>

  
  

  </v-container>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor';
import 'tui-color-picker/dist/tui-color-picker.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
//import { quillEditor } from 'vue-quill-editor'

export default {

    components:{
      'editor':Editor,
      //quillEditor
    },

    data: () => ({


      editorOption: {
          // some quill options
      }      ,


      editor:null,
      editorText: {},
      editorOptions : {
        
                        //링크 클릭시 뉴탭으로 표시되도록 처리
                        linkAttribute: {
                          target: '_blank',
                          contenteditable: 'false',
                          rel: 'noopener noreferrer'          
                        },

                        minHeight: '200px',
                        language: 'ko',
                        useCommandShortcut: true,
                        useDefaultHTMLSanitizer: true,
                        usageStatistics: false,
                        hideModeSwitch: true, //markdown wysiwyg 토글 숨기기
                        plugins:[colorSyntax],
                        toolbarItems: [
                          'heading',
                          'bold',
                          'italic',
                          'strike',
                          'divider',
                          //'hr',
                          'quote',
                          'divider',
                          'ul',
                          'ol',
                          'task',
                          //'indent',
                          //'outdent',
                          'divider',
                          //'table',
                          'image',
                          'link',
                          'divider',
                          //'code',
                          //'codeblock'
                        ],
                      },
      
      uploadFile : [],

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,        //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      dialog2:false,
      progress_dialog:false,
      defaultItem: {
          IDX:0,
          TITLE:'',
          DESC:'',
          FIX:0,
          READPM:1,
          REPPLE:1
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {text: '번호', value: 'BOARD_NO', sortable: false, align:'center', width:'80px', class:"cheader_normal"},
        {text: '제목', value: 'TITLE', sortable: false, align:'center', width:'320px', class:"cheader_normal"},
        {text: '댓글사용', value: 'REPPLE', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '읽기권한', value: 'READPM', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '상단고정', value: 'FIX', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '수정일시', value: 'UP_DATE', sortable: false, align:'center' , width:'200px', class:"cheader_normal"},
        {text: '등록일시', value: 'REG_DATE', sortable: false, align:'center' , width:'200px', class:"cheader_normal"},
        {text: '수정/삭제', value: 'actions', sortable: false, align:'center', width:'100px', class:"cheader_normal"}
      ],
      itemList: [],

      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
         
      REPPLELIST: [
          {name:'사용안함',   value:0}
        , {name:'댓글사용',   value:1}
      ],
      FIXLIST: [
          {name:'사용안함',     value:0}
        , {name:'상단고정',     value:1}
      ],
      READPMLIST: [
          {name:'사용자',     value:0}
        , {name:'전체',       value:1}
      ],

    }),


    beforeDestroy() {
      if(this.editor != null){
        this.editor.destroy()
      }
    },

    mounted(){
      if(this.$session.get("GRADE") < 90){
        this.$alert("사용할 수 없는 등급의 페이지입니다.");
        this.$router.back();
        return;
      }

      if(this.$route.query.page == undefined || this.$route.query.page == null){
        this.page = 1;
      }else{
        this.page = this.$route.query.page;
      }
      if(this.$route.query.search == undefined || this.$route.query.search == null){
        this.search_keyword = '';
      }else{
        this.search_keyword = this.$route.query.search;
      }

      if(this.sort_type == undefined || this.sort_type == null){
        this.sort_type = true;
      }else{
        if(this.$route.query.sorttype == "true"){
          this.sort_type = true;
        }else if(this.$route.query.sorttype == "false"){
          this.sort_type = false;
        }
      }

      this.sort_name = this.$route.query.sortname;
      if(this.sort_name == undefined || this.sort_name == null){
        this.sort_name = 'REG_DATE';
      }

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }

    },

    create(){
      
    },

    methods: {

      movepage(p){
        this.$router.push({path:"BoardList", query:{page:p, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name}})
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
        });
      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      prev(){
          this.bottompage = this.bottompage - 10 >= 1 ? this.bottompage - 10 : 1;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage + this.subpage - 1; //이전을 누르면 가장 끝 번호 선택
          this.loadpage(temp_page);
      },
      next(){
          this.bottompage = this.bottompage + 10 <= this.maxpage ? this.bottompage + 10 : this.maxpage;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage;
          this.loadpage(temp_page);
      },

      makepaging(){
        var div1 = this.totalcnt / this.pagerow; 
        var div2 = this.totalcnt % this.pagerow;
        if(div2 == 0){  
            this.maxpage = parseInt(div1);    
        }else{
            this.maxpage = parseInt(div1+1);
        }
        if(this.bottompage == 1){
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
        }
      },

      loadpage(p){

          this.loading = true;
          this.page = p;

          this.$http.post(this.$host+'/BoardList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{

            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }

                this.totalcnt = result.data.totalcnt;
                this.makepaging();
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    if(element.FILE1 != undefined && element.FILE1 != null && element.FILE1.length > 0){
                      const last = element.FILE1.split("/")
                      element.FILENAME =  decodeURI(last[last.length-1]);
                    }else{
                      element.FILENAME = "";
                    }
                    this.itemList.push(element); 
                });

                this.loading = false;

            }else if(result.data.resultCode == 2){

              //로그인 필요
              this.loading = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.loading = false;
              
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadpage(this.page);
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.uploadFile = [];
        }, 300)
      },

      //추가 저장
      async save () {

        if(this.editedItem.TITLE == undefined || this.editedItem.TITLE.length < 1){
          this.$alert("제목을 입력하세요");
          return;
        } 

        //에디터에서 값 가져오기
        this.editedItem.DESC = this.$refs.toastuiEditor.invoke('getHtml');

        if(this.editedItem.DESC == undefined || this.editedItem.DESC.length < 1){
          this.$alert("내용을 입력하세요");
          return;
        } 

        this.progress_dialog = true;

        if (this.editedIndex > -1) {

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/BoardUpdate', {
              TITLE: escape(this.editedItem.TITLE)
            , DESC: escape(this.editedItem.DESC)
            , REPPLE: this.editedItem.REPPLE
            , FIX: this.editedItem.FIX
            , READPM: this.editedItem.READPM
            , BOARD_NO: this.editedItem.BOARD_NO
          }, {headers:{firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

              this.$alert("수정이 완료되었습니다.").then(()=>{
                this.loadpage(this.page);
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.$alert('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert('수정이 실패했습니다.');
          });

        }else{

          //교체된 내용 다시 넣기
          this.loading = true;
          this.$http.post(this.$host+'/BoardInsert',{
              TITLE: escape(this.editedItem.TITLE)
            , DESC: escape(this.editedItem.DESC)
            , REPPLE: this.editedItem.REPPLE
            , FIX: this.editedItem.FIX
            , READPM: this.editedItem.READPM
          },{headers:{firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                const p = this.page;
                this.page = 0;
                this.$alert("새로운 공지사항이 추가되었습니다.").then(()=>{
                  this.loadpage(p);
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.$alert('추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert('추가 실패');
          });

        }

        this.close()
      },      

      //검색
      search_run(){
        this.movepage(1);
      },
      

      //신규 추가
      newinsert(){
        this.uploadFile = [];
        this.dialogTitle = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem)

        this.editedItem.DESC  = "";
        this.editorText = this.editedItem.DESC;
        this.dialog = true;

        this.$refs.toastuiEditor.invoke('setHtml', this.editedItem.DESC); //팝업이 떠서 에디터가 생성된 후 읽을 수 있다.            
        
      },

      
      //게시글 상세보기
      loadnotice(item){
        
        this.$router.push({path:"BoardDetail", query:{code:item.BOARD_NO}});

      },
      //아이템 수정
      edit_item(item){

        //내용 가져오기
        this.loading = true;
        this.$http.post(this.$host+'/GuestBoardDetail',{
          BOARD_NO: item.BOARD_NO
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){
            
            this.dialogTitle = "수정하기"
            this.editedIndex = this.itemList.indexOf(item)
            //this.editedItem = item;
            this.editedItem = Object.assign({}, item);

            //이미 선택된 아이템에 상세만 교체
            this.editedItem.DESC  = result.data.resultData[0].DESC;

            //2중으로 처리해줘야 해당 에디터가 정상 작동해보인다.
            this.editorText = this.editedItem.DESC;
            this.dialog = true;
            this.$refs.toastuiEditor.invoke('setHtml', this.editedItem.DESC); //팝업이 떠서 에디터가 생성된 후 읽을 수 있다.

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
        });

      },

      //아이템 삭제
      delete_item(item){

        this.$confirm('정말 삭제하시겠습니까?')
        .then(() => {
          this.loading = true;
          this.$http.post(this.$host+'/BoardDelete',{
            BOARD_NO:item.BOARD_NO
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$alert('삭제가 완료되었습니다.').then(()=>{
                const p = this.page;
                this.page = 0;
                this.loadpage(p);
              });
              
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
              
            }else{
              this.$alert('삭제가 실패했습니다.');
            }
            
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              this.loading = false;
              this.$alert('삭제 실패');
          });      

        });

      },

      //추가 함수들
      changefiles(file) {
        this.$alert(JSON.stringify(file.files));
      },

      //base64 이미지 데이터를 파일로 변환
      dataURLtoFile(dataurl, fileName){
 
        var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], fileName, {type:mime});

      },

    },
  
}
</script>

<style lang="css">
  td{
    height: 36px !important;
  }
</style>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>