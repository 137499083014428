<!-- pdfjs-dist를 사용해서 PDF 크롭핑 테스트 -->
<!-- PDF 로드, 확대, 축소, 다음, 크롭 -->
<template >
  <v-container ref="infobox" >

    <div :style="bg_div_style"></div>

    <!-- 이동 -->
    <v-row>
    <v-dialog persistent scrollable overlay-color="#00000040" v-model="dialog_move" max-width="500px">
      <v-card class="pa-1 ma-0">

        <v-row dense class="mt-5">
          <v-col cols="12" align="center">
            <span class="dialog_title">페이지 이동</span>
          </v-col>
        </v-row>

        <v-card-text class="ma-0 pl-5 pr-5 pt-3">
          <v-container>
            <v-row dense>
              <v-col md="12" sm="12" xs="12">
                <v-text-field v-model="curpage" type="number" label="페이지 번호" single-line></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="dialog_move = false">닫기</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="movepage">이동</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>  
    </v-row>


    <!-- 저장 -->
    <v-row>
    <v-dialog persistent scrollable overlay-color="#00000040" v-model="dialog_add" max-width="500px">
      <v-card class="pa-1 ma-0">

        <v-row dense class="mt-5">
          <v-col cols="12" align="center">
            <span class="dialog_title">문제추가</span>
          </v-col>
        </v-row>

        <v-card-text class="ma-0 pl-5 pr-5 pt-3">
          <v-container>
            <v-row dense>
              <v-col md="12" sm="12" xs="12">
                <v-text-field v-model="QUESTION_NAME" label="문제명" single-line></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="dialog_add = false">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="savecheck2">추가</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>  
    </v-row>    

    <v-row align="center" justify="center" class="pa-0" >
      <v-col cols="6" md="1" align="center">
        <v-btn class="smallbtn" small @click="back">닫기</v-btn>
      </v-col>
      <v-col cols="6" md="1" align="center">
        <v-btn class="smallbtn" small @click="help">도움말</v-btn>
      </v-col>
      <v-col cols="8" md="8">
        <v-btn disabled text>{{TITLE}}</v-btn>
      </v-col>
      <v-col cols="4" md="2">
        <v-btn disabled text>{{'최근 : ' + UPNAME}}</v-btn>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" dense>
      <v-col align="center" cols="12" md="4" sm="12" xs="12" >
        <v-file-input label="PDF 선택" v-model="selectFile" @change="loadfile" hide-details dense outlined ></v-file-input>
      </v-col>
      <v-spacer/>
      <v-col md="2" align="center" class="pa-0 ma-0">
        <v-checkbox label="원본 사이즈 저장" v-model="use_scale"/>
      </v-col>
      <v-col md="2" align="center" class="pa-0" v-if="use_autoname">
        <v-text-field v-model="QUESTION_NAME" label="문제명" dense outlined hide-details single-line></v-text-field>
      </v-col>
      <v-col md="2"  class="pa-0 ma-0">
        <v-checkbox label="자동 문제명 사용" align="center" v-model="use_autoname"/>
      </v-col>
    </v-row>

    <!-- 크롭 영역 미리보기용 (실서비스에서 사용 안함 기술 검토용)
    <v-row class="mt-15">
      <div id="temp_div" style="background:green;width:500px;height:150px"/>
    </v-row>
    -->
    
    <!-- pdf 스크롤이 v-app-bar 내부에선 문제되어 position을 absolute로 놔야함. -->
    <!-- 
    <v-row align="center" justify="center" class="mb-10">
      <v-col cols="12" class="mb-10" align="center">
        <div>  
          <div id="pdf_div" style="position:absolute;overflow:auto;">
            <canvas id="the-canvas"></canvas>  
          </div>
          <div id="edit_div" :style="edit_div_style">
          </div>
        </div>
      </v-col>
    </v-row>
    -->
    <v-row>
      <v-col>
      <div>  
        <!-- <div id="pdf_div" style="position:relative;overflow:auto;left:0;right:0;top:0;bottom:0;max-width:1800px;max-height:650px"> -->
          
        <div id="pdf_div" style="position:absolute;overflow:auto;">
          <canvas id="the-canvas" style="margin-bottom:150px"></canvas>  
        </div>

        <div id="edit_div" :style="edit_div_style">
        </div>
      </div>
      </v-col>
    </v-row>
  
    <v-btn
      color="#690505" class="mb-10 mr-10" width="1750px"
      dark fixed bottom right style="background:#00000000" elevation="0"
      >
      <v-btn color="#690505" class="mr-1" small @click="prev">이전</v-btn>
      <v-btn color="#690505" class="mr-1" style="color:black;background:#00000000" 
        elevation="0" small @click="showmove">{{curpage+"/"+pageNumber}}</v-btn>
      <v-btn color="#690505" class="mr-1" small @click="next">다음</v-btn>
      <v-btn color="#690505" class="mr-1" small @click="zoomin">확대</v-btn>
      <v-btn color="#690505" class="mr-1" small @click="zoomout">축소</v-btn>
      <v-btn color="#690505" small @click="savecheck">저장</v-btn>
    </v-btn>

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      >
      {{this.UPNAME+' 추가 완료'}}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">닫기</v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>

import pdfjsLib from "pdfjs-dist/build/pdf";
//import { PDFViewer } from "pdfjs-dist/web/pdf_viewer";
import "pdfjs-dist/web/pdf_viewer.css";
pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/build/pdf.worker.min.js";


import DRBackground from '../cropeditor/DRBackground.js'
//import DRComponent from '../cropeditor/DRComponent.js'
import DRModel from '../cropeditor/DRModel.js'


//import FileSaver from 'file-saver';
//import fs from 'fs';
const { createCanvas } = require('canvas')

// vue.js는 pdf.js 라이브러리 기반으로 개발필요.
// pdf.js api 참고  - https://github.com/mozilla/pdf.js/blob/master/src/display/api.js
// pdf.js 샘플 참고 - https://mozilla.github.io/pdf.js/examples/
import { ref, uploadBytes } from "firebase/storage";
export default {
  
  data(){
    return{
        pdfdoc:null
      , pageNumber:0
      , curpage:1
      , scale:1.0

      , drback: null      //draw background

      , selectFile: null  //select file
      , page:null
      , viewport:null
      , the_canvas:null
      , pdf_div:null
      , edit_div:null

      , canvas_width:0
      , canvas_height:0

      , SELECT_SEMESTER: null
      , SELECT_BOOK: null
      , SELECT_CHAPTER: null
      , SEMESTER_PATH: null
      , BOOK_PATH: null
      , CHAPTER_PATH: null

      , dialog_move: false
      , dialog_add: false
      , AUTO_NAME: '문제-'
      , QUESTION_NAME: ''
      , use_scale: false
      , use_autoname: false
      , snackbar:false
      , UPNAME:''
      , TITLE:''
    }
  },

  mounted() {
    
    this.SELECT_SEMESTER  = this.$route.query.semester.SEMESTER_NO;
    this.SELECT_BOOK      = this.$route.query.book.BOOK_NO;
    this.SELECT_CHAPTER   = this.$route.query.chapter.CHAPTER_NO;
    this.SEMESTER_PATH    = this.$route.query.semester.SEMESTER_PATH;
    this.BOOK_PATH        = this.$route.query.book.BOOK_PATH;
    this.CHAPTER_PATH     = this.$route.query.chapter.CHAPTER_PATH;

    this.TITLE = this.$route.query.semester.SEMESTER_NAME + "/" + this.$route.query.book.BOOK_NAME + "/" + this.$route.query.chapter.CHAPTER_NAME;
    
    if(this.SELECT_SEMESTER == undefined || this.SELECT_BOOK == undefined || this.SELECT_CHAPTER == undefined){
      this.$router.back();
    }


    //PDF CROP EDITOR 설정
    this.pdf_div    = document.getElementById("pdf_div");
    this.the_canvas = document.getElementById("the-canvas");
    this.canvas_width  = this.the_canvas.width;
    this.canvas_height = this.the_canvas.height;

    this.edit_div   = document.getElementById("edit_div");
    //this.edit_div.onwheel  = this.onwheel;
    //this.edit_div.onscroll = this.onscroll;
    
    //this.edit_div.onwheel = this.onwheel;

    const bg = new DRModel();
    bg.LAYOUT_WIDTH   = 1000;
    bg.LAYOUT_HEIGHT  = 1000;
    bg.LAYOUT_POSX    = 0;
    bg.LAYOUT_POSY    = 0;
    bg.ISBG           = 1;  //bg
    bg.COMPONENT_TYPE = 0; 
    bg.BGTYPE         = 0;
    bg.BGCOLOR        = "00000000";
    this.drback  = new DRBackground(bg);
    this.edit_div.appendChild(this.drback.back_div);

    onkeydown = this.keydownevent;
    onwheel   = this.onwheelevent;

  },

  computed:{
    bg_div_style: function(){
      return "min-width:"+this.canvas_width+"px;min-height:"+this.canvas_height+"px;width:100%;height:100%;backgroundColor:white;position:absolute;left:0px;top:0px";
    },
    //canvas의 사이즈가 변경되면 draw background도 사이즈 변경
    edit_div_style: function(){
      //console.log("computed...");
      return "width:"+this.canvas_width+"px;height:"+this.canvas_height+"px;backgroundColor:#00000000;overflow:hidden;position:absolute;";
      //return "width:1750px;height:"+this.canvas_height+"px;backgroundColor:#80000080;overflow:hidden;position:absolute;";
    },
    col_style: function(){
      //console.log("computed...");
      return "width:"+this.canvas_width+"px;height:"+this.canvas_height+"px;background:blue";
      //return "width:1750px;height:"+this.canvas_height+"px;background:blue";
    }

  },

  methods: {
    
    help(){
      alert("단축키 도움말\n이전 페이지 : [ \n다음 페이지 : ] \n확대 : '\n축소 : ;\n크롭 저장 : Ctrl + X");
    },

    showmove(){
      if(this.selectFile != null){
        this.dialog_move = true;
      }else{
        this.$alert("PDF파일을 선택하세요");
      }
    },

    keydownevent(ev){
      //단축키
      //console.log("1111 " + ev.keyCode);
      if(ev.ctrlKey){
        if(ev.keyCode == 88){
          //크롭 저장처리
          this.savecheck();
        }
      }else{
        if(ev.keyCode == 221){
          if(this.selectFile != null){
            this.next();
          }
        }else if(ev.keyCode == 219){
          if(this.selectFile != null){
            this.prev();
          }
        }else if(ev.keyCode == 222){
          if(this.selectFile != null){
            this.zoomin();
          }
        }else if(ev.keyCode == 186){
          if(this.selectFile != null){
            this.zoomout();
          }
        }
      }
      

    },


    back(){
      //뒤로
      this.$router.back();
    },

    //휠 이벤트가 먹어서 디스패칭시도 했는데 실패해서 아래와 같이 처리
    onwheel(ev){
      
      //console.log("onwheel : " + JSON.stringify(ev.deltaY));
      this.pdf_div.scrollTop += ev.deltaY;
      ev.preventDefault();

      //확대 축소 해주자.
      /*
      if(ev.deltaY > 0){
        //양수면 휠을 내리는 상황 (줌)
        this.zoomin();
      }else{
        //음수면 휠을 위로 올리는 상황 (줌아웃)
        this.zoomout();
      }
      */

    },

    loadfile(){

      var reader = new FileReader();
      reader.onload = (event)=>{
        var typedarray = new Uint8Array(event.target.result);
        this.getPdf(typedarray);
      }
      reader.readAsArrayBuffer(this.selectFile); //비동기로 파일을 읽어서      
      
    },
    
    prev(){
      this.curpage = Number.parseInt(this.curpage) - 1 > 1 ? Number.parseInt(this.curpage) - 1 : 1;
      this.loadpage(this.curpage, this.scale);
    },

    next(){
      this.curpage = Number.parseInt(this.curpage) + 1 <= this.pageNumber ? Number.parseInt(this.curpage) + 1 : this.pageNumber;
      this.loadpage(this.curpage, this.scale);
    },

    movepage(){
      //console.log("cur page : " + this.curpage);
      if(this.curpage > this.pageNumber){
        this.$alert("페이지 범위를 확인하세요");
        return;
      }
      if(this.curpage < 1){
        this.$alert("페이지 범위를 확인하세요");
        return;
      }
      this.loadpage(Number.parseInt(this.curpage), this.scale);
      this.dialog_move = false;
    },

    zoomout(){
      this.scale = this.scale - 0.1 > 0.1 ? this.scale - 0.1 : 1.0;
      this.loadpage(this.curpage, this.scale);
    },

    zoomin(){
      this.scale = this.scale + 0.1 <= 3.0 ? this.scale + 0.1 : 3.0;
      this.loadpage(this.curpage, this.scale);
    },

    savecheck(){

      if(this.SELECT_SEMESTER == null || this.SELECT_SEMESTER == undefined || this.SELECT_SEMESTER.length < 1){
        this.$alert("학기 정보가 없습니다. 학기 정보를 다시 선택해주세요");
        return;
      } 
      if(this.SELECT_BOOK == null || this.SELECT_BOOK == undefined || this.SELECT_BOOK.length < 1){
        this.$alert("교재 정보가 없습니다. 교재 정보를 다시 선택해주세요");
        return;
      } 
      if(this.SELECT_CHAPTER == null || this.SELECT_CHAPTER == undefined || this.SELECT_CHAPTER.length < 1){
        this.$alert("챕터 정보가 없습니다. 챕터 정보를 다시 선택해주세요");
        return;
      } 
      if(this.selectFile == null || this.selectFile == undefined || this.selectFile.length < 1){
        this.$alert("PDF 파일을 선택하세요");
        return;
      } 

      //자동 문제명 사용시
      if(this.use_autoname){
        if(this.QUESTION_NAME == null || this.QUESTION_NAME == undefined || this.QUESTION_NAME.length < 1){
          this.$alert("자동 문제명을 입력하세요");
          return;
        }else{
          this.savecrop();
        }
      }else{
        this.dialog_add = true;
      }

    },

    savecheck2(){
      //문제명 체크
      if(this.QUESTION_NAME == null || this.QUESTION_NAME == undefined || this.QUESTION_NAME.length < 1){
        this.$alert("문제명을 입력하세요");
        return;
      }else{
        this.dialog_add = false;
        this.savecrop();
      }
    },


    savecrop(){

      this.viewport  = this.page.getViewport(this.scale);
      //console.log("viewport : " + JSON.stringify(this.viewport));

      var croparea = this.drback.getSelectComponent();
      if(croparea != null){
        //console.log(" crop area : " + croparea.model.LAYOUT_POSX + " / " + croparea.model.LAYOUT_POSY + " / " + croparea.model.LAYOUT_WIDTH + " / " + croparea.model.LAYOUT_HEIGHT);
      }else{
        this.$alert("저장 영역이 지정되지 않았습니다.");
        return;
      }

      var cx = croparea.model.LAYOUT_POSX;
      var cy = croparea.model.LAYOUT_POSY;
      var cw = croparea.model.LAYOUT_WIDTH;
      var ch = croparea.model.LAYOUT_HEIGHT;

      //var pdf_div  = document.getElementById("pdf_div");
      //console.log("sl : " +this.pdf_div.scrollLeft + " / st : " + this.pdf_div.scrollTop);

      //원본으로 저장되는게 좋으면 scale값을 쓰고 싫으면 빼라 그럼 확대해서 크롭하면 크게 저장된다.
      if(this.use_scale){
        //원본 저장
        const canvas    = createCanvas(cw/this.scale, ch/this.scale);
        const drawcontext = canvas.getContext("2d");
        drawcontext.drawImage(this.the_canvas, cx+this.pdf_div.scrollLeft, cy+this.pdf_div.scrollTop, cw, ch, 0, 0, cw/this.scale, ch/this.scale);  //원본이미지, 원본x,y,w,h, 타겟x,y,w,h
        const save_function = this.save;
        canvas.toBlob(function(blob) {
          save_function(blob);
        });

      }else{
        //확대 사이즈로 저장
        const canvas    = createCanvas(cw, ch);
        const drawcontext = canvas.getContext("2d");
        drawcontext.drawImage(this.the_canvas, cx+this.pdf_div.scrollLeft, cy+this.pdf_div.scrollTop, cw, ch, 0, 0, cw, ch);  //원본이미지, 원본x,y,w,h, 타겟x,y,w,h
        const save_function = this.save;
        canvas.toBlob(function(blob) {
          save_function(blob);
        });
      }


      //크롭된 영역 DIV에 넣어서 미리보기 - 기술검토 테스트
      /*
      var temp_div = document.getElementById("temp_div");
      temp_div.childNodes.forEach(node=>{
        temp_div.removeChild(node);
      });
      temp_div.appendChild(canvas);
      */

      //크롭핑 영역 파일로 저장 - 기술검토
      /*
      canvas.toBlob(function(blob) {
          FileSaver.saveAs(blob, "temp.png");
      });
      */

    },


      //추가 및 수정
      save (blob) {

        //firebase object storage에 파일 업로드
        const uploadfile_ref = ref(this.$firestorage, "workbook/"+this.SEMESTER_PATH+"/"+this.BOOK_PATH+"/"+this.CHAPTER_PATH+"/"+Date.now().toString()+".jpg");
        const metadata = {
          contentType: 'image/jpeg',
        };      
        uploadBytes(uploadfile_ref, blob, metadata)
        .then((snapshot) => {
          //console.log('full metadata ' + JSON.stringify(snapshot.metadata));
                           
          this.$http.post(this.$host+'/QuestionInsert',{
                QUESTION_NAME: escape(this.QUESTION_NAME)
              , USEAUTONAME: this.use_autoname
              , QUESTION_IMG: snapshot.metadata.name
              , STATE:   1
              , SEMESTER_NO: this.SELECT_SEMESTER
              , BOOK_NO: this.SELECT_BOOK
              , CHAPTER_NO: this.SELECT_CHAPTER
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            //console.log("result : " + JSON.stringify(result.data));
            if(result.data.resultCode == 0){

              this.UPNAME = result.data.resultName;
              this.snackbar = true;

              //파일 다운로드
              //FileSaver.saveAs(blob, "temp.png");

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.$alert('추가 실패 ' + error);
          });
          
        })
        // eslint-disable-next-line no-unused-vars
        .catch((upload_error)=>{
            this.$alert('추가 실패 ' + upload_error);
        });
        

      },          

    //페이지와 스케일값으로 다시 그리기
    async loadpage(p, s){

      this.page = await this.pdfdoc.getPage(p);
      //console.log("page load...");
          
      this.viewport  = this.page.getViewport(s);
      //console.log("viewport : " + JSON.stringify(this.viewport));

      //var the_canvas       = document.getElementById("the-canvas");
      var context            = this.the_canvas.getContext('2d');
      this.the_canvas.width  = this.viewport.width;
      this.the_canvas.height = this.viewport.height;

      this.canvas_width  = this.viewport.width;
      this.canvas_height = this.viewport.height;
          
      var renderContext = {
        canvasContext:context
      , viewport:this.viewport
      };
          
      await this.page.render(renderContext).promise;
      //console.log("page render...");

    },
    
    async getPdf(typedarray, pw) {

      //pdf 로드
      //let loadingTask = pdfjsLib.getDocument("https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf");

      //console.log("1111");
      let loadingTask = pdfjsLib.getDocument({data:typedarray, password: pw});
      //console.log("2222");

      var pdf = null;
      try{
        
        pdf = await loadingTask.promise;

        //console.log("3333");
        this.pdfdoc = pdf;                //전역으로 객체 백업
        //console.log("4444");
        this.pageNumber = pdf.numPages;   //전체페이지 수 
        //console.log("5555");

        this.page = await pdf.getPage(1);  //페이지 읽기
        //console.log("page load...");
            
        this.viewport  = this.page.getViewport(1.0);  //페이지 뷰포트 정보 읽기 스케일값은 float로 들어가야한다. 정수로 들어가면 값이 깨짐.
        //console.log("viewport : " + JSON.stringify(this.viewport));

        //var the_canvas    = document.getElementById("the-canvas");
        var context       = this.the_canvas.getContext('2d');
        this.the_canvas.width  = this.viewport.width;
        this.the_canvas.height = this.viewport.height;

        this.canvas_width  = this.viewport.width;
        this.canvas_height = this.viewport.height;
            
        //var tf = [Math.cos(radian),Math.sin(radian),-Math.sin(radian),Math.cos(radian),0,0];
        //var tf = [1,0,0,1,0,0];
        var renderContext = {
          canvasContext:context
        , viewport:this.viewport
        };
            
        await this.page.render(renderContext).promise;   //그리기
        //console.log("page render...");
        
      }catch(error){
        if(error.name == 'PasswordException'){
          //this.$alert("PDF로드중 오류가 발생했습니다. " + error.name);
          this.$prompt("PDF 비밀번호를 입력하세요").then(text=>{
            this.getPdf(typedarray, text);
            return;
          });
        }else{
          this.$alert("PDF로드중 오류가 발생했습니다. " + error.name);
        }
      }

    },

    
  }


};
</script>


<style>

#viewerContainer{
  overflow: auto;
  position: absolute;
  top: 32px;
  right: 0;
  bottom: 0;
  left: 0;
  outline: none;
}

#the-canvas {
  border: 1px solid white;
  direction: ltr;
}
.smallbtn{
  background:#690505 !important;
  color:white !important;
}
</style>