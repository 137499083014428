<template>
  <v-container fluid class="ma-0 pa-0" style="background:white;min-height:100%;overflow:hidden">

    <v-row class="ma-0 pa-0 hidden-sm-and-down" no-gutters dense>
      <v-col cols="12">
      <v-parallax
        height="226" 
        :src="require('@/assets/mathbg.jpg')"
        >
        <v-row align="center" justify="center">
          <v-col cols="12" class="text-center mt-15">
            <label style="color:white;font-size:25px">감성수학</label>
          </v-col>
        </v-row>
      </v-parallax>    
      </v-col>
    </v-row>

    <!-- 프로그레스 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading" max-width="300px">
        <v-container class="d-flex flex-column justify-center align-center" 
                     style="background-color:white;width:300px;height:300px">
          <v-row class="ma-8">
            <v-progress-circular
              :size="100"
              :width="15"
              color="#c53d45"
              indeterminate
              >
            </v-progress-circular>
          </v-row>
          <v-row class="ma-5">
            <label style="text-align:center">잠시만 기다려주세요</label>
          </v-row>
        </v-container >
      </v-dialog>
    </v-row>

    <v-row align="center" justify="center">
      
      <v-col class="mt-15" cols="12" md="4" sm="10" xs="10">
        <div class="roundbg text-center">  
            <p id="login_title" style="color:#9f0007;font-size:20px;" class="text-center">학생 로그인</p>

            <v-row justify="center" dense class="mt-2">
              <v-col ccols="12">
                <v-row style="height:1px;background:#9f0007"/>
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center" class="mt-5">
              <v-col cols="12">
                <v-row dense align="center" justify="center">
                  <v-col cols="3" align="center">
                    <label class="mytextstyle">휴대폰</label>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field 
                      outlined v-model="user_tel" prepend-inner-icon="" 
                      name="login" placeholder="휴대폰번호를 입력하세요" 
                      color="#9f0007" hide-details
                      type="text" dense></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center" dense>
              <v-col  align="right">
                <div id="recaptcha-container"/>
              </v-col>
            </v-row>
            <v-row dense align="center" justify="center" v-if="confirmationResult == null">
              <v-col align="right">
                <v-btn  
                  style="height:30px;width:150px;font-size:16px;color:white" color="#9f0007" rounded @click="sendopt">인증번호 발송</v-btn>
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center" class="mt-5" >
              <v-col cols="12">
                <v-row dense align="center" justify="center">
                  <v-col cols="3" align="center">
                    <label class="mytextstyle">인증번호</label>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field 
                    outlined @keyup.enter="login" v-model="user_code" prepend-inner-icon="" 
                    name="password" placeholder="인증번호를 입력하세요"
                    dense color="#9f0007" hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row v-if="timeflag" justify="center" align="center" dense>
              <v-col cols="11" md="6" align="center">
                <label style="font-size:14px;color:red">{{timemsg}} {{ time }}</label>
              </v-col>
            </v-row>

            <v-btn  
            class="mt-5"
            style="height:35px;width:200px;font-size:18px;color:white" color="#9f0007" rounded @click="login">로그인</v-btn>

        </div>
      </v-col>
    </v-row>


    <v-row align="center" justify="center">
      <v-col cols="6" md="6" align="center">
        <a style="color:#9f0007;font-size:16px" class="text-center" @click="runjoin">감성수학 회원가입</a>
      </v-col>
    </v-row>   

    <!-- 
    <v-row style="height:350px" align="center" justify="center">
    </v-row>   
    -->

  </v-container>
</template>
<script>
import { RecaptchaVerifier, signInWithPhoneNumber, signInWithCustomToken } from "firebase/auth";
export default {

    beforeDestroy(){
      if(this.timer != null){
        clearInterval(this.timer);
      }
    },

    mounted() {
      
      //로그인된 상태인지 체크해서 로그인된 경우면 패스해라.
      const user = this.$fireauth.currentUser;
      if(user){
        //console.log("login : " + JSON.stringify(user));
        this.$alert("이미 로그인하셨습니다.");
        this.$router.push("Welcome");
        return;
      }else{
        //console.log("not login...");
      }

      this.showcapcha();

    },

    data: () => ({

        user_tel : ""
      , user_code: ""

      , recaptchaVerifier: null
      , recaptchaWidgetId: null
      , recaptchaCode: null
      , confirmationResult: null

      , timemsg:"인증번호를 입력하세요"
      , time:"10:00"
      , timeflag:false
      , timer:null
      
      , loading:false
      

    }),

    methods: {

      showcapcha(){
        this.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {
          'callback': (response) => {
            //버튼이 눌리면 여기가 콜백되어 recaptcha 토큰이 발급된다. 여러번 누르면 중복으로 작동되지 않아 인증키를 백업한다.
            this.recaptchaCode = response;
          }
          , 'expired-callback': () => {
            //시간이 오래 경과하면 체크가 다시 해제된다.
            this.recaptchaCode = null;
          }
        }, this.$fireauth);
        this.recaptchaVerifier.render().then((widgetId) => {
          this.recaptchaWidgetId = widgetId;
        // eslint-disable-next-line no-unused-vars
        }).catch((error)=>{
          //console.log("show error : " + error);
        });    
      },

      hidecapcha(){
        this.recaptchaVerifier.clear();
      },

      stopTimer(){
        if(this.timer != null){
          clearInterval(this.timer);
        }
      },      

      startTimer(){
        //캡차 숨기기
        this.hidecapcha();
        this.time = "10:00";
        this.timeflag = true;
        this.timer = setInterval(()=>{
          const timeArray = this.time.split(':');
          let minutes = parseInt(timeArray[0]);
          let seconds = parseInt(timeArray[1]);
          if (minutes === 0 && seconds === 0) {
            clearInterval(this.timer);
            this.timer   = null;
            this.timemsg = "인증번호를 다시 발급받으세요.";
            this.time    = "";
            this.confirmationResult = null;
            this.showcapcha();
            return;
          }
          if (seconds === 0) {
            minutes--;
            seconds = 59;
          } else {
            seconds--;
          }
          this.time = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;        
        }, 1000);
      },

      valuecheck(){
        const mobileVal1 = /[^0-9]/g;
        var mobileVal = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

        if(this.user_tel  == undefined || this.user_tel.length < 1){
          this.$alert("휴대폰 번호를 입력하세요");
          return false;
        } 
        
        if(mobileVal1.test(this.user_tel)){
          this.$alert("휴대폰 번호는 숫자만 입력이 가능합니다.");
          return false;
        } 
        
        if(!mobileVal.test(this.user_tel)){
          this.$alert("휴대폰 번호가 잘못되었습니다.");
          return false;
        } 

        return true;
      },


      //SMS 인증번호 발송 - 전화번호는 꼭 국가번호가 들어가야한다. +82010으로 해도 됨. +8210으로 굳이 안해도 됨.
      sendopt(){

        const flag = this.valuecheck();
        if(!flag) return;

        if(this.recaptchaCode == null || this.recaptchaCode == undefined){
          this.$alert("reCAPTCHA 인증을 확인하세요");
          return;
        }

        if(this.confirmationResult != null){
          this.$alert("인증번호가 이미 발송되었습니다.");
        }

        signInWithPhoneNumber(this.$fireauth, "+82"+this.user_tel, this.recaptchaVerifier)
          .then((result) => {
            
            //console.log("signInWithPhoneNumber result : " + JSON.stringify(result));

            this.confirmationResult = result;
            
            //인증번호 발송완료 - 10분 카운트 들어가야한다.
            this.startTimer();

          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            //잘못된 전화번호 알림처리 필요.
            //console.log("Error sending OTP:" + JSON.stringify(error));
            if(error.code == "auth/too-many-requests"){
              this.$alert("너무 많은 요청으로 10분뒤 다시 시도하세요.");
            }else if(error.code == "auth/invalid-phone-number"){
              this.$alert("잘못된 전화번호 양식입니다.");
            }else{
              this.$alert("인증코드 발급오류. 잠시 후 다시 시도하세요.");
            }
            
          });
      },
      
      runjoin(){
        //회원가입 팝업 표시
        this.$router.push('StudentJoin');
      },


      login(){

        const flag = this.valuecheck();
        if(!flag) return;

        if(this.confirmationResult == null || this.confirmationResult == undefined){
          this.$alert("인증번호를 발급하세요");
          return false;
        }

        if(this.user_code  == undefined || this.user_code.length < 1){
          this.$alert("SMS로 받은 인증번호를 입력하세요.");
          return false;
        } 

        this.loading = true;
        this.confirmationResult.confirm(this.user_code).then((result)=>{
          const user = result.user;
          this.createCustomtoken(user.uid);
        }).catch((error)=>{
          this.loading = false;
          //console.log("confirm error : " + JSON.stringify(error));
          if(error.code == "auth/invalid-verification-code"){
            this.$alert("잘못된 인증코드입니다.");
          }else if(error.code == "auth/too-many-requests"){
            this.$alert("너무 많은 요청으로 10분뒤 다시 시도하세요.");
          }else if(error.code == "auth/code-expired"){
            this.$alert("잘못된 인증코드입니다.");
          }else{
            this.$alert("인증코드를 입력하세요"); 
          }
        });

      },
      
      //실패시 방금 firebase에 추가된 회원을 삭제한다.
      createCustomtoken(localid){

          this.$http.post(this.$host+'/phoneLogin',{
            LOCALID:localid,
          })
          .then((result)=>{
            this.loading = false;
              if(result.data.resultCode == 0){

                const userinfo = result.data.resultData[0];

                //custom token으로 클라이언트 로그인하기
                signInWithCustomToken(this.$fireauth, result.data.customToken)
                .then(async (userCredential)=>{
                  
                  //로그인 성공
                  const user = userCredential.user;

                  //id토큰 받기
                  const idToken = await user.getIdToken();
                  this.$session.set("LOCALID",    user.uid);
                  this.$session.set("GRADE",      0);
                  this.$session.set("LOGIN_NAME", userinfo.NAME);
                  this.$session.set("FIRETOKEN",  idToken);

                  this.$router.push("StudentExam");
                  this.$EventBus.$emit('loginresult');

                })
              }else{
                this.$alert(result.data.resultMsg);
                //오류난 경우 다시 캡차와 인증번호를 받아야 한다.
                this.failjoin();
              }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            //console.log("createToken error : " + error);
            this.$alert("로그인에 실패했습니다. 다시 시도하세요.");
            //오류난 경우 다시 캡차와 인증번호를 받아야 한다.
            this.failjoin();
          });
      },

      failjoin(){
        this.stopTimer();
        this.user_code = "";
        this.timer   = null;
        this.timemsg = "인증번호를 입력하세요.";
        this.time    = "";
        this.timeflag = false;
        this.confirmationResult = null;
        this.showcapcha();
      },


    },


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.v-main__wrap{
  background: white;
}

.roundbg{
  border-radius: 25px;
  background: #ffffff;
  padding: 20px;
  padding-bottom: 30px;
}


</style>