<template>

  <v-container fluid>


    <!-- 과목 -->
    <v-row>
    <v-dialog persistent scrollable overlay-color="#000000FF" v-model="dialog1" max-width="500px">

      <v-card class="pa-1 ma-0">

        <v-row dense class="mt-5">
          <v-col cols="12" align="center">
            <span class="dialog_title">{{ dialogTitle }}</span>
          </v-col>
        </v-row>

        <v-card-text class="ma-0 pl-5 pr-5 pt-3">
          <v-container>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">과목명</label>
              </v-col>
              <v-col cols="9">
                <v-text-field 
                  v-model="editedItem1.SUBJECT_NAME" dense single-line
                  outlined height="48px" hide-details class="popupiputstyle"
                  />
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">과목타입</label>
              </v-col>
              <v-col cols="9">
                <v-select v-model="editedItem1.SUBJECT_TYPE" :items="SUBJECT_TYPELIST" outlined
                  item-text="name" item-value="value" class="dialogselectstyle"
                  hide-details elevation="0" dense height="48px"
                  /> 
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">배점</label>
              </v-col>
              <v-col cols="9">
                <v-text-field 
                  v-model="editedItem1.SUBJECT_POINTS" dense single-line
                  outlined height="48px" hide-details class="popupiputstyle"
                  />
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close1">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save1">{{ editedIndex == -1 ? '추가' : '수정' }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>  
    </v-row>


    <!-- 문제 -->
    <v-row>
    <v-dialog persistent scrollable overlay-color="#00000080" v-model="dialog2" max-width="500px">
      
      <v-card class="pa-1 ma-0">

        <v-row dense class="mt-5">
          <v-col cols="12" align="center">
            <span class="dialog_title">{{ dialogTitle }}</span>
          </v-col>
        </v-row>

        <v-card-text class="ma-0 pl-5 pr-5 pt-3">
          <v-container>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">번호</label>
              </v-col>
              <v-col cols="9">
                <v-text-field 
                  v-model="editedItem2.ANSWERS_NUMBER" dense single-line type="number"
                  outlined height="48px" hide-details class="popupiputstyle"
                  />
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">정답</label>
              </v-col>
              <v-col cols="9">
                <v-text-field 
                  v-model="editedItem2.ANSWERS" dense single-line
                  outlined height="48px" hide-details class="popupiputstyle"
                  />
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">배점</label>
              </v-col>
              <v-col cols="9">
                <v-text-field 
                  v-model="editedItem2.POINT" dense single-line
                  outlined height="48px" hide-details class="popupiputstyle"
                  />
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">난이도</label>
              </v-col>
              <v-col cols="9">
                <v-select v-model="editedItem2.ANSWERS_GRADE" :items="ANSWERS_GRADELIST" outlined
                  item-text="name" item-value="value" class="dialogselectstyle"
                  hide-details elevation="0" dense height="48px"
                  /> 
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">문제타입</label>
              </v-col>
              <v-col cols="9">
                <v-select v-model="editedItem2.ANSWERS_TYPE" :items="ANSWERS_TYPELIST" outlined
                  item-text="name" item-value="value" class="dialogselectstyle"
                  hide-details elevation="0" dense height="48px"
                  /> 
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close2">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save2">{{ editedIndex == -1 ? '추가' : '수정' }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>  
    </v-row>


    <!-- 여러문제추가 -->
    <v-row>
    <v-dialog persistent scrollable overlay-color="#00000080" v-model="dialog3" max-width="500px">
      
      <v-card class="pa-1 ma-0">

        <v-row dense class="mt-5">
          <v-col cols="12" align="center">
            <span class="dialog_title">{{ dialogTitle }}</span>
          </v-col>
        </v-row>

        <v-card-text class="ma-0 pl-5 pr-5 pt-3">
          <v-container>

            <v-row dense align="center" justify="center">
              <v-col cols="12" align="center">
                <label>여러개의 문제 포맷을 한번에 추가합니다.<br>추가시 사용할 기본값을 선택하세요.</label>
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">문제수</label>
              </v-col>
              <v-col cols="9">
                <v-text-field 
                  v-model="editedItem3.COUNT" dense single-line type="number"
                  outlined height="48px" hide-details class="popupiputstyle"
                  placeholder="추가하실 문제 수를 입력하세요"
                  />
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">시작번호</label>
              </v-col>
              <v-col cols="9">
                <v-text-field 
                  v-model="editedItem3.ANSWERS_NUMBER" dense single-line type="number"
                  outlined height="48px" hide-details class="popupiputstyle"
                  placeholder="문제가 넘버링될 시작 번호를 입력하세요"
                  />
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">정답</label>
              </v-col>
              <v-col cols="9">
                <v-text-field 
                  v-model="editedItem3.ANSWERS" dense single-line
                  outlined height="48px" hide-details class="popupiputstyle"
                  />
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">배점</label>
              </v-col>
              <v-col cols="9">
                <v-text-field 
                  v-model="editedItem3.POINT" dense single-line
                  outlined height="48px" hide-details class="popupiputstyle"
                  />
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">난이도</label>
              </v-col>
              <v-col cols="9">
                <v-select v-model="editedItem3.ANSWERS_GRADE" :items="ANSWERS_GRADELIST" outlined
                  item-text="name" item-value="value" class="dialogselectstyle"
                  hide-details elevation="0" dense height="48px"
                  /> 
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">문제타입</label>
              </v-col>
              <v-col cols="9">
                <v-select v-model="editedItem3.ANSWERS_TYPE" :items="ANSWERS_TYPELIST" outlined
                  item-text="name" item-value="value" class="dialogselectstyle"
                  hide-details elevation="0" dense height="48px"
                  /> 
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close3">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save3">추가</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>  
    </v-row>

    <v-row align="center" justify="start" >
      <v-col cols="12" md="12">
        <v-btn text @click="back">뒤로</v-btn>
        <label class="menunamestyle">{{TITLE}}</label>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row class="mt-0">

      <!-- 과목 -->
      <v-col cols="12" md="6" sm="12" xs="12" class="pa-5">
        <v-row>
          <v-spacer/>
          <v-col cols="2" class="pa-0 pr-3" align="center">
            <v-btn class="mini_btn" elevation="0" outlined rounded small @click="newinsert1">추가</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col >
              <v-data-table
                :headers="headers1"
                :items="itemList1"
                no-data-text="데이터가 없습니다."
                hide-default-footer
                :items-per-page="99999"
                item-key="SUBJECT_NO"
                :loading="loading1"
                height="700"
                class="datatablestyle"
                >
                
                <template v-slot:body="props">
                  <tbody>
                    <tr v-for="(item, index) in props.items" :key="index" :style="item.SUBJECT_NO == SELECT_SUBJECT ? 'background:#C53D4580' : 'background:#ffffff'">
                      <td style="width:200px; text-align:center"><a style="color:blue" @click="loadanswers(item)">{{ item.SUBJECT_NAME }}</a></td>
                      <td style="width:200px; text-align:center">{{ item.SUBJECT_TYPE == 0 ? '공통' : '선택' }}</td>
                      <td style="width:200px; text-align:center">{{ item.SUBJECT_POINTS }}</td>
                      <td style="width:80px; text-align:center"><v-icon small @click="loadanswers(item)">mdi-pencil</v-icon></td>
                      <td style="width:80px; text-align:center">
                        <v-icon class="mr-2" small @click="edit_item1(item)">mdi-pencil</v-icon>
                        <v-icon small @click="delete_item1(item)">mdi-delete</v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>

              </v-data-table>         
          </v-col>
        </v-row>
      </v-col>
        
      <!-- 문제 -->
      <v-col cols="12" md="6" sm="12" xs="12" class="pa-5">
        <v-row align="center">
          <v-col offset="1" cols="7" class="pa-0" align="start">
            <label v-if="SUBJECT_NAME.length > 0">{{ SUBJECT_NAME }} / 합계 : {{ POINTS_SUM }}</label>
          </v-col>
          <v-col cols="2" class="pa-0 pr-3" align="right">
            <v-btn class="mini_btn" elevation="0" outlined rounded small @click="newinsert3">여러문제추가</v-btn>
          </v-col>
          <v-col cols="2" class="pa-0 pr-3" align="left">
            <v-btn class="mini_btn" elevation="0" outlined rounded small @click="newinsert2">문제추가</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
              <v-data-table
                ref="answers_table"
                :headers="headers2"
                :items="itemList2"
                no-data-text="데이터가 없습니다."
                hide-default-footer
                :items-per-page="99999"
                item-key="ANSWERS_NO"
                :loading="loading2"
                height="700"
                class="datatablestyle"
                >
                <template v-slot:body="props">
                  <tbody>
                    <tr v-for="(item, index) in props.items" :key="index" >
                      <td style="width:200px; text-align:center">{{ item.ANSWERS_NUMBER }}</td>
                      <td style="width:200px; text-align:center">{{ item.ANSWERS }}</td>
                      <td style="width:200px; text-align:center">{{ item.POINT }}</td>
                      <td style="width:200px; text-align:center">{{ item.ANSWERS_TYPE == 0 ? '객관식' : '주관식' }}</td>
                      <td style="width:200px; text-align:center">{{getGrade(item.ANSWERS_GRADE)}}</td>
                      <td style="width:80px; text-align:center">
                        <v-icon class="mr-2" small @click="edit_item2(item)">mdi-pencil</v-icon>
                        <v-icon small @click="delete_item2(item)">mdi-delete</v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>         
          </v-col>
        </v-row>
      </v-col>


    </v-row>

  </v-container>
</template>

<script>

export default {

  watch:{
    itemList2:function(){
      //console.log("watch.... ");
      this.POINTS_SUM = 0;
      if(this.itemList2.length > 0){
        this.POINTS_SUM = this.itemList2.reduce((accumulator, current) => {
          return accumulator + parseFloat(current.POINT);
        }, 0);      
      }
    }
  },

    data: () => ({
      grade:0,

      SUBJECT_TYPELIST: [
          {name:'공통',   value:0}
        , {name:'선택',   value:1}
      ],

      ANSWERS_GRADELIST: [
          {name:'최상', value:0}
        , {name:'상',   value:1}
        , {name:'중',   value:2}
        , {name:'하',   value:4}
      ],

      ANSWERS_TYPELIST: [
          {name:'객관식',   value:0}
        , {name:'주관식',   value:1}
      ],

      EXAMINFO:null,
      EXAM_NO:"",
      TITLE:"",
      
      SELECT_SUBJECT:null,
      SUBJECT_NAME: "",
      POINTS_SUM: 0,

      loading1: false,
      loading2: false,

      itemList1: [],
      itemList2: [],

      editedItem1: {},
      editedItem2: {},
      editedItem3: {},
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트

      defaultItem1: {
        SUBJECT_NO:0,
        EXAM_NO:0,
        SUBJECT_NAME:'',
        SUBJECT_TYPE:0,
        SUBJECT_POINTS:0
      },
      defaultItem2: {
        ANSWERS_NO:0,
        EXAM_NO:0,
        SUBJECT_NO:0,
        ANSWERS_NUMBER:1,
        ANSWERS:0,
        POINT:0,
        ANSWERS_GRADE:0,
        ANSWERS_TYPE:0
      },

      dialog1:false,
      dialog2:false,
      dialog3:false,
      dialogTitle:'추가하기',

      //테이블 정의
      headers1: [
        {text: '과목', value: 'SUBJECT_NAME', sortable: false, align:'center', width:200, class:"cheader_normal"},
        {text: '타입', value: 'SUBJECT_TYPE', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '배점', value: 'SUBJECT_POINTS', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '문제', value: 'answers', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '수정/삭제', value: 'actions', sortable: false, align:'center', width:100, class:"cheader_normal"}
      ],

      headers2: [
        {text: '번호', value: 'ANSWERS_NUMBER', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '정답', value: 'ANSWERS', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '배점', value: 'POINT', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '타입', value: 'ANSWERS_TYPE', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '난이도', value: 'ANSWERS_GRADE', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '수정/삭제', value: 'actions', sortable: false, align:'center', width:100, class:"cheader_normal"}
      ],

      //검색 파라메터
      sort_name: 'SUBJECT_TYPE',
      sort_type: false,    //true : DESC, false: ASC

    }),

    mounted(){
      if(this.$session.get("GRADE") < 90){
        this.$alert("사용할 수 없는 등급의 페이지입니다.").then(()=>{
          this.$router.back();
        });
        return;
      }
      this.grade = this.$session.get("GRADE");
      this.EXAM_NO = this.$route.query.code;
      this.TITLE = this.$route.query.title;

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){

    },

    methods: {

      getGrade(grade){

        for(var i=0;i<this.ANSWERS_GRADELIST.length;i++){
          const item = this.ANSWERS_GRADELIST[i];
          if(item.value == grade){
            return item.name;
          }
        }

      },

      back(){
        this.$router.back();
      },

      initpage(){
        this.loadexam(this.EXAM_NO);
        this.loadsubject();
      },

      //신규 추가
      newinsert1(){
        if(this.EXAMINFO.EXAM_STATE == 1){
          this.$alert("게시된 시험은 수정할 수 없습니다.");
          return;
        }
        this.dialogTitle  = "과목 추가"
        this.editedIndex  = -1;
        this.editedItem1  = Object.assign({}, this.defaultItem1);
        this.dialog1      = true;
      },

      //아이템 수정
      edit_item1(item){
        if(this.EXAMINFO.EXAM_STATE == 1){
          this.$alert("게시된 시험은 수정할 수 없습니다.");
          return;
        }
        this.dialogTitle = "과목 수정";
        this.editedIndex = this.itemList1.indexOf(item);
        this.editedItem1 = Object.assign({}, item);
        this.dialog1 = true;
      },
   
      //추가 및 수정
      save1 () {

        if(this.editedItem1.SUBJECT_NAME  == null || this.editedItem1.SUBJECT_NAME  == undefined || this.editedItem1.SUBJECT_NAME.length < 1 || this.editedItem1.SUBJECT_NAME.trim().length < 1){
          this.$alert("과목명을 입력하세요");
          return;
        } 
        if(this.editedItem1.SUBJECT_NAME.length > 50){
          this.$alert("과목명은 20자까지 입력이 가능합니다.");
          return;
        } 
        if(this.editedItem1.SUBJECT_POINTS  == null || this.editedItem1.SUBJECT_POINTS  == undefined || String(this.editedItem1.SUBJECT_POINTS).trim().length == 0){
          this.$alert("배점을 입력하세요");
          return;
        } 

        if (this.editedIndex > -1) {

          //수정
          this.loading1 = true;
          this.$http.post(this.$host+'/ExamSubjectUpdate',{
                SUBJECT_NAME: escape(this.editedItem1.SUBJECT_NAME)
              , SUBJECT_TYPE: this.editedItem1.SUBJECT_TYPE
              , SUBJECT_POINTS: this.editedItem1.SUBJECT_POINTS
              , SUBJECT_NO: this.editedItem1.SUBJECT_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading1 = false;
            if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$alert('수정이 완료되었습니다.').then(()=>{
                this.loadsubject();
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading1 = false;
            this.$alert('수정이 실패했습니다.');
          });

        }else{
          //신규
          this.loading1 = true;
          this.$http.post(this.$host+'/ExamSubjectInsert',{
                SUBJECT_NAME: escape(this.editedItem1.SUBJECT_NAME)
              , SUBJECT_TYPE: this.editedItem1.SUBJECT_TYPE
              , SUBJECT_POINTS: this.editedItem1.SUBJECT_POINTS
              , EXAM_NO: this.EXAM_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading1 = false;
            if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$alert('추가가 완료되었습니다.').then(()=>{
                this.loadsubject();
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading1 = false;
            this.$alert('추가 실패');
          });

        }

        this.close1();  //팝업닫기

      },           


      close1() {
        this.dialog1 = false
        setTimeout(() => {
          this.editedItem1 = Object.assign({}, this.defaultItem1);
          this.editedIndex = -1
        }, 300)
      },


      //교재
      newinsert2(){
        if(this.EXAMINFO.EXAM_STATE == 1){
          this.$alert("게시된 시험은 수정할 수 없습니다.");
          return;
        }

        if(this.SELECT_SUBJECT == null || this.SELECT_SUBJECT == undefined){
          this.$alert("과목을 선택하세요");
          return;
        }
        
        this.dialogTitle  = "문제 추가"
        this.editedIndex  = -1;
        this.editedItem2  = Object.assign({}, this.defaultItem2);
        this.dialog2      = true;

      },

      //여러정답추가
      newinsert3(){
        if(this.EXAMINFO.EXAM_STATE == 1){
          this.$alert("게시된 시험은 수정할 수 없습니다.");
          return;
        }

        if(this.SELECT_SUBJECT == null || this.SELECT_SUBJECT == undefined){
          this.$alert("과목을 선택하세요");
          return;
        }
        
        this.dialogTitle  = "정답 추가"
        this.editedIndex  = -1;
        this.editedItem3  = Object.assign({}, this.defaultItem2);
        this.dialog3      = true;

      },      

      edit_item2(item){
        if(this.EXAMINFO.EXAM_STATE == 1){
          this.$alert("게시된 시험은 수정할 수 없습니다.");
          return;
        }

        if(this.SELECT_SUBJECT == null || this.SELECT_SUBJECT == undefined){
          this.$alert("과목을 선택하세요");
          return;
        }

        this.dialogTitle = "정답 수정";
        this.editedIndex = this.itemList2.indexOf(item);
        this.editedItem2 = Object.assign({}, item);

        if(this.editedItem2.QUERY_TYPE == "i"){
          //패스
        }else{
          this.editedItem2.QUERY_TYPE == "u";
        }
        
        //console.log("scroll top : "+this.$refs.answers_table.scrollTop);
        this.dialog2 = true;
      },

      //추가 및 수정
      save2() {

        if(this.editedItem2.ANSWERS_NUMBER  == undefined || this.editedItem2.ANSWERS_NUMBER == null || String(this.editedItem2.ANSWERS_NUMBER).trim().length == 0){
          this.$alert("문제번호를 입력하세요");
          return;
        } 
        if(this.editedItem2.ANSWERS  == undefined || this.editedItem2.ANSWERS == null || String(this.editedItem2.ANSWERS).trim().length == 0){
          this.$alert("정답을 입력하세요");
          return;
        } 
        if(this.editedItem2.POINT  == undefined || this.editedItem2.POINT == null || String(this.editedItem2.POINT).trim().length == 0){
          this.$alert("배점을 입력하세요");
          return;
        } 

        if (this.editedIndex > -1) {

          //수정
          this.loading2 = true;
          this.$http.post(this.$host+'/ExamAnswersUpdate',{
                ANSWERS_NUMBER: this.editedItem2.ANSWERS_NUMBER
              , ANSWERS: this.editedItem2.ANSWERS
              , POINT: this.editedItem2.POINT
              , ANSWERS_GRADE: this.editedItem2.ANSWERS_GRADE
              , ANSWERS_TYPE: this.editedItem2.ANSWERS_TYPE              
              , ANSWERS_NO: this.editedItem2.ANSWERS_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading2 = false;
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            if(result.data.resultCode == 0){
              this.$alert('수정이 완료되었습니다.').then(()=>{
                this.loadanswers(null);
              });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading2 = false;
            this.$alert('수정이 실패했습니다.');
          });

        }else{
          //신규
          this.loading2 = true;
          this.$http.post(this.$host+'/ExamAnswersInsert',{
                ANSWERS_NUMBER: this.editedItem2.ANSWERS_NUMBER
              , ANSWERS: this.editedItem2.ANSWERS
              , POINT: this.editedItem2.POINT
              , ANSWERS_GRADE: this.editedItem2.ANSWERS_GRADE
              , ANSWERS_TYPE: this.editedItem2.ANSWERS_TYPE
              , SUBJECT_NO: this.SELECT_SUBJECT
              , EXAM_NO: this.EXAM_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading2 = false;
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }

            //console.log("result : " + JSON.stringify(result));
            if(result.data.resultCode == 0){
              this.$alert('추가가 완료되었습니다.').then(()=>{
                this.loadanswers(null);
              });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('추가 실패2');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading2 = false;
            this.$alert('추가 실패1' +  error);
          });

        }

        this.close2();  //팝업닫기

      },     

      close2() {
        this.dialog2 = false
        setTimeout(() => {
          this.editedItem2 = Object.assign({}, this.defaultItem2);
          this.editedIndex = -1
        }, 300)
      },


      //여러문제추가
      save3() {

        if(this.editedItem3.COUNT  == undefined || this.editedItem3.COUNT == null){
          this.$alert("추가하실 문제의 수를 입력하세요");
          return;
        } 
        if(this.editedItem3.COUNT < 1){
          this.$alert("추가하실 문제의 수는 0보다 커야합니다.");
          return;
        } 

        if(this.editedItem3.ANSWERS  == undefined || this.editedItem3.ANSWERS == null){
          this.$alert("정답을 입력하세요");
          return;
        } 
        if(this.editedItem3.POINT  == undefined || this.editedItem3.POINT == null){
          this.$alert("배점을 입력하세요");
          return;
        } 

        //신규
        this.loading2 = true;
        this.$http.post(this.$host+'/ExamAnswersInsertCount',{
              COUNT: this.editedItem3.COUNT
            , ANSWERS_NUMBER: this.editedItem3.ANSWERS_NUMBER
            , ANSWERS: this.editedItem3.ANSWERS
            , POINT: this.editedItem3.POINT
            , ANSWERS_GRADE: this.editedItem3.ANSWERS_GRADE
            , ANSWERS_TYPE: this.editedItem3.ANSWERS_TYPE
            , SUBJECT_NO: this.SELECT_SUBJECT
            , EXAM_NO: this.EXAM_NO
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          this.loading2 = false;
          if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
            this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
          }
          if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
            this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
          }

          //console.log("result : " + JSON.stringify(result));
          if(result.data.resultCode == 0){
            this.$alert('추가가 완료되었습니다.').then(()=>{
              this.loadanswers(null);
            });
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.$alert('추가 실패2');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading2 = false;
          this.$alert('추가 실패1' +  error);
        });

        this.close3();  //팝업닫기

      },     

      close3() {
        this.dialog3 = false
        setTimeout(() => {
          this.editedItem3 = Object.assign({}, this.defaultItem2);
          this.editedIndex = -1
        }, 300)
      },

      //과목조회
      loadsubject(){

          this.loading1 = true;
          this.$http.post(this.$host+'/ExamSubjectList',{
              EXAM_NO:this.EXAM_NO
            , sort_name: this.sort_name
            , sort_type: this.sort_type==true ? "DESC" : "ASC" 
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            if(result.data.resultCode == 0){
                this.loading1 = false;
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                const list = result.data.resultData;
                this.itemList1.splice(0, this.itemList1.length);   //데이터 초기화
                list.forEach(element => {
                  this.itemList1.push(element); 
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading1 = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.loading1 = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading1 = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },        

      //문제 조회
      loadanswers(item){

        this.itemList2.splice(0, this.itemList2.length);   //데이터 초기화

          if(item != null && item != undefined){
            this.SELECT_SUBJECT  = item.SUBJECT_NO;
            this.SUBJECT_NAME    = item.SUBJECT_NAME;
          }

          this.loading2 = true;
          this.$http.post(this.$host+'/ExamAnswersList',{
              EXAM_NO: this.EXAM_NO
            , SUBJECT_NO: this.SELECT_SUBJECT
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            if(result.data.resultCode == 0){
                this.loading2 = false;
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                //console.log("book : " + JSON.stringify(result.data.resultData));
                const list = result.data.resultData;
                this.itemList2.splice(0, this.itemList2.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList2.push(element); 
                });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading2 = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.loading2 = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading2 = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },        
      
      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadsubject(this.page);
      },



      //아이템 삭제
      delete_item1(item){
        if(this.EXAMINFO.EXAM_STATE == 1){
          this.$alert("게시된 시험은 수정할 수 없습니다.");
          return;
        }

        this.$confirm('과목삭제시 소속된 문제를 더이상 사용할 수 없습니다.\n정말 삭제하시겠습니까?')
        .then(() => {
          this.loading = true;
          this.$http.post(this.$host+'/ExamSubjectDelete',{
              SUBJECT_NO:item.SUBJECT_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

              this.$alert('삭제가 완료되었습니다.').then(()=>{
                this.loadsubject();
              });
              
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('삭제가 실패했습니다.');
            }
            
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              this.loading = false;
              this.$alert('삭제 실패');
          });      

        });

      },      


      //아이템 삭제
      delete_item2(item){
        if(this.EXAMINFO.EXAM_STATE == 1){
          this.$alert("게시된 시험은 수정할 수 없습니다.");
          return;
        }

        this.$confirm('정말 삭제하시겠습니까?')
        .then(() => {
          this.loading = true;
          this.$http.post(this.$host+'/ExamAnswersDelete',{
              ANSWERS_NO:item.ANSWERS_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

              this.$alert('삭제가 완료되었습니다.').then(()=>{
                this.loadanswers();
              });
              
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }else{
              this.$alert('삭제가 실패했습니다.');
            }
            
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              this.loading = false;
              this.$alert('삭제 실패');
          });      

        });

      },            



      loadexam(exam_no){

          this.$http.post(this.$host+'/ExamDetail',{
            EXAM_NO: exam_no
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.EXAMINFO = result.data.resultData[0];
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },


    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

input[type="checkbox"]{
        display: none;
      }
      
input[type="checkbox"] + label{
        display: inline-block;
        width: 25px;
        height: 25px;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 5px;
        border:2px solid #da5058;
        position: relative;
        background:#ffffff;
      }
input[type="checkbox"]:checked + label::after{
        content:'✔';
        font-size: 16px;
        color: white;
        background: #da5058;
        width: 21px;
        height: 21px;        
        text-align: center;
        position: absolute;
        left: 0;
        top:0;      
      }


</style>