<template>

  <v-container fluid class="ma-0 pa-0" style="background:white;min-height:100%">

    <v-row class="ma-0 pa-0 hidden-sm-and-down" no-gutters dense>
      <v-col cols="12">
      <v-parallax
        height="226" 
        :src="require('@/assets/boardbg.png')"
        >
        <v-row align="center" justify="center">
          <v-col cols="12" class="text-center mt-15">
            <label style="color:white;font-size:25px">자유게시판</label>
          </v-col>
        </v-row>
      </v-parallax>    
      </v-col>
    </v-row>

    <v-row class="hidden-sm-and-down" justify="center" align="center">
      <v-col cols="10" align="center">
        <div>
          <div style="z-index:1;position:relative;left:0px;top:40px;height:13px;width:100%;background:#fcecec"/>
          <label class="areaunderlinestyle">#감성수학 Q&A&nbsp;&nbsp;#마음껏 질문하세요!</label>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="progress_dialog" width="400px">
        <v-row justify="center">
          <v-col cols="2">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
              />
          </v-col>
        </v-row>
        <v-row justify="center">
            <label>잠시만 기다려주세요</label>
        </v-row>
      </v-dialog>  
    </v-row>    

    
    <v-row justify="center" dense no-gutters class="ma-0 pa-0 mt-10">
      <v-col cols="10">
        <v-row align="center">
          <v-col cols="3">
            <label style="width:80px;color:#3d3d3d;font-weight:bold">번호</label>
          </v-col>
          <v-col cols="6">
            <label style="width:320px;color:#3d3d3d;font-weight:bold">제목</label>
          </v-col>
          <v-col cols="3" class="hidden-sm-and-down">
            <label style="width:200px;color:#3d3d3d;font-weight:bold">등록일자</label>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <v-row style="height:1px;background:#9f0007"/>
      </v-col>
    </v-row>
    
    <v-row justify="center" style="min-height:400px" dense no-gutters class="ma-0 pa-0 mt-3">
      <v-col cols="10">
          <v-row v-for="(item, index) in itemList" :key="index" align="center" class="broadrow_line">
            <v-col cols="3">
              <label style="color:#3d3d3d;font-size:18px">{{ item.BOARD_NO }}</label>
            </v-col>
            <v-col md="6" lg="6" xl="6" sm="9" xs="9" cols="9">
              <template v-if="item.FIX == 1">
                <a style="color:red;font-size:18px" @click="edit_item(item)" >{{item.TITLE}}{{item.REPPLE_CNT>0?' ('+item.REPPLE_CNT+')':''}}</a>
              </template>
              <template v-else>
                <a style="color:#3d3d3d;font-size:18px" @click="edit_item(item)" >{{item.TITLE}}{{item.REPPLE_CNT>0?' ('+item.REPPLE_CNT+')':''}}</a>
              </template>
            </v-col>
            <v-col cols="3" class="hidden-sm-and-down">
              <label style="color:#3d3d3d;font-size:18px">{{ item.UP_DATE }}</label>
            </v-col>
          </v-row>
      </v-col>
    </v-row>



  <!-- 하단 페이징 영역 -->
  <v-row align="center" justify="center" class="pb-10">
    <v-col cols="12" md="3"/>
    <v-col cols="12" md="4" class="d-flex justify-center">
      <v-btn v-if="bottompage > 10"  icon  small @click="prev()"><v-icon dark>mdi-chevron-left</v-icon></v-btn>
      <span v-for="n in subpage" :key="n.id">
          <span v-if="page == bottompage-1+n">
              <v-btn color="red" class="numberstyle" icon small @click="movepage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
          <span v-else>
              <v-btn  icon small class="numberstyle2" @click="movepage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
      </span>
      <v-btn v-if="subpage == 10"  icon small @click="next()" ><v-icon dark>mdi-chevron-right</v-icon></v-btn>
    </v-col>
    <v-col cols="10" md="3" align="center">
      <v-text-field
        single-line 
        @keyup.enter="search_run" 
        v-model="search_keyword"
        hide-details dense
        outlined 
        color="#808080"
        placeholder="검색어를 입력하세요"
        class="landsearchstyle"
        append-icon="mdi-magnify"
        />
    </v-col>
  </v-row>

  </v-container>
</template>

<script>

export default {


    data: () => ({

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,        //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      dialog2:false,
      progress_dialog:false,
      defaultItem: {
          IDX:0,
          TITLE:'',
          DESC:'',
          FIX:0,
          READPM:1,
          REPPLE:1
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {text: '번호', value: 'BOARD_NO', sortable: false, align:'left', class:"broadheader_normal"},
        {text: '제목', value: 'TITLE', sortable: false, align:'left', class:"broadheader_normal"},
        {text: '등록일자', value: 'UP_DATE', sortable: false, align:'left', class:"broadheader_normal"},
      ],
      itemList: [],

      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
         
      REPPLELIST: [
          {name:'사용안함',   value:0}
        , {name:'댓글사용',   value:1}
      ],
      FIXLIST: [
          {name:'사용안함',     value:0}
        , {name:'상단고정',     value:1}
      ],
      READPMLIST: [
          {name:'사용자',     value:0}
        , {name:'전체',       value:1}
      ],

    }),


    beforeDestroy() {
      if(this.editor != null){
        this.editor.destroy()
      }
    },

    mounted(){
      if(this.$route.query.page == undefined || this.$route.query.page == null){
        this.page = 1;
      }else{
        this.page = this.$route.query.page;
      }
      if(this.$route.query.search == undefined || this.$route.query.search == null){
        this.search_keyword = '';
      }else{
        this.search_keyword = this.$route.query.search;
      }
      if(this.sort_type == undefined || this.sort_type == null){
        this.sort_type = true;
      }else{
        if(this.$route.query.sorttype == "true"){
          this.sort_type = true;
        }else if(this.$route.query.sorttype == "false"){
          this.sort_type = false;
        }
      }
      this.sort_name = this.$route.query.sortname;
      if(this.sort_name == undefined || this.sort_name == null){
        this.sort_name = 'REG_DATE';
      }
      this.initpage();
    },

    create(){
      
    },

    methods: {

      movepage(p){
        this.$router.push({path:"Freeboard", query:{page:p, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name}})
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
        });
      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      prev(){
          this.bottompage = this.bottompage - 10 >= 1 ? this.bottompage - 10 : 1;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage + this.subpage - 1; //이전을 누르면 가장 끝 번호 선택
          this.loadpage(temp_page);
      },
      next(){
          this.bottompage = this.bottompage + 10 <= this.maxpage ? this.bottompage + 10 : this.maxpage;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage;
          this.loadpage(temp_page);
      },

      makepaging(){
        var div1 = this.totalcnt / this.pagerow; 
        var div2 = this.totalcnt % this.pagerow;
        if(div2 == 0){  
            this.maxpage = parseInt(div1);    
        }else{
            this.maxpage = parseInt(div1+1);
        }
        if(this.bottompage == 1){
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
        }
      },

      loadpage(p){

          this.loading = true;
          this.page = p;
          this.$http.post(this.$host+'/GuestBoardList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , token: this.$session.get("FIRETOKEN")
          }).then((result)=>{

            if(result.data.resultCode == 0){
                this.totalcnt = result.data.totalcnt;
                this.makepaging();
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    if(element.FILE1 != undefined && element.FILE1 != null && element.FILE1.length > 0){
                      const last = element.FILE1.split("/")
                      element.FILENAME =  decodeURI(last[last.length-1]);
                    }else{
                      element.FILENAME = "";
                    }
                    this.itemList.push(element); 
                });
                this.loading = false;
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadpage(this.page);
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.uploadFile = [];
        }, 300)
      },


      //검색
      search_run(){
        this.movepage(1);
      },

      
      //아이템 수정
      edit_item(item){

        this.$router.push({path:"BoardDetail", query:{code:item.BOARD_NO}});

      },


      //추가 함수들
      changefiles(file) {
        this.$alert(JSON.stringify(file.files));
      },

      //base64 이미지 데이터를 파일로 변환
      dataURLtoFile(dataurl, fileName){
 
        var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], fileName, {type:mime});

      },

    },
  
}
</script>

<style lang="css">
  td{
    height: 36px !important;
  }
</style>

<style lang="scss" scoped>
.table-header {
  thead {
    background-color: black;
  }
}
</style>
<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>
.v-main__wrap{
  background: white;
}

</style>