<template>

  <v-container fluid>

    <!-- 이미지 크롭 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="crop_dialog_land" max-width="500px">
        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">이미지 편집</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <div style="width:100%;height:500px;">
            <vueCropper
                ref="cropper_land"
                :img="imgSrc"
                :outputType="option.outputType"
                :fixed="option.fixed"
                :fixedNumber="option.fixedNumber"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :fixedBox="option.fixedBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :centerBox="option.centerBox"
                :high="option.high"
                :infoTrue="option.infoTrue"
                :maxImgSize="option.maxImgSize"
                :enlarge="option.enlarge"
                :mode="option.mode"
                :limitMinSize="option.limitMinSize"
                />
              </div>
          </v-card-text>

          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="crop_dialog_land = false">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="cropimg">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>



    <!-- 프로그레스 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading" max-width="300px">
        <v-container class="d-flex flex-column justify-center align-center" 
                     style="background-color:white;width:300px;height:300px">
          <v-row class="ma-8">
            <v-progress-circular
              :size="100"
              :width="15"
              color="purple"
              indeterminate
              >
            </v-progress-circular>
          </v-row>
          <v-row class="ma-5">
            <label style="text-align:center">잠시만 기다려주세요</label>
          </v-row>
        </v-container >
      </v-dialog>
    </v-row>

    <!-- 회원탈퇴 -->
    <v-row >
      <v-dialog scrollable overlay-color="#00000040" persistent v-model="dialog_exit" width="480px">
        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">회원탈퇴</span>
            </v-col>
          </v-row>

          <v-row dense align="center" justify="center" class="mt-5">
            <v-col cols="10">
              <p>회원탈퇴를 위해 계정 비밀번호를 입력후 탈퇴 버튼을 누르세요</p>
            </v-col>
          </v-row>

          <v-row dense align="center" justify="center" class="mt-5">
            <v-col cols="10">
              <v-text-field :type="show1 ? 'text' : 'password'" v-model="user_pw" 
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              dense outlined label="비밀번호" hint="비밀번호를 입력하세요"
              @click:append="show1 = !show1"
              />
            </v-col>
          </v-row>

          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="dialog_exit = false">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="runexit">탈퇴</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    


    <v-row align="center" justify="start">
      <v-col cols="12">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col md="4" sm="6" xs="12">
        <v-row dense align="center" justify="center">
          <v-col cols="3" align="center">
            <label class="mytextstyle">아이디</label>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="editedItem.ACCNT" dense single-line maxlength="20" counter
              outlined height="43" readonly hide-details class="mypageiputstyle"
              />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col md="4" sm="6" xs="12">
        <v-row dense align="center" justify="center">
          <v-col cols="3" align="center">
            <label class="mytextstyle">이름</label>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="editedItem.NAME" dense single-line maxlength="20" counter
              outlined height="43" readonly hide-details class="mypageiputstyle"
              />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col md="4" sm="6" xs="12">
        <v-row dense align="center" justify="center">
          <v-col cols="3" align="center">
            <label class="mytextstyle">전화번호</label>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="editedItem.TEL" dense single-line maxlength="20" counter
              outlined height="43" hide-details class="mypageiputstyle"
              />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col md="4" sm="6" xs="12">
        <v-row dense align="center" justify="center">
          <v-col cols="3" align="center">
            <label class="mytextstyle">지점</label>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="editedItem.AREA_NAME" dense single-line maxlength="20" counter
              outlined height="43" hide-details readonly class="mypageiputstyle"
              />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col md="4" sm="6" xs="12">
        <v-row dense align="center" justify="center">
          <v-col cols="3" align="center">
            <label class="mytextstyle">회원등급</label>
          </v-col>
          <v-col cols="9">
            <v-select dense v-model="editedItem.GRADE" :items="GRADELIST" outlined color="gray"
               item-text="name" item-value="value" readonly append-icon="" hide-details  class="mypageiputstyle"
              />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col md="4" sm="6" xs="12">
        <v-row dense align="center" justify="center">
          <v-col cols="3" align="center">
            <label class="mytextstyle">빅데이터</label>
          </v-col>
          <v-col cols="9">
            <v-select dense v-model="editedItem.DATAGRADE" :items="DATAGRADELIST" outlined color="gray"
               item-text="name" item-value="value" readonly append-icon="" hide-details class="mypageiputstyle" 
              />
          </v-col>
        </v-row>
      </v-col>
    </v-row>


    <v-row align="center" justify="center">
      <v-col md="4" sm="6" xs="12" align="center" class="ma-0">
        <viewer
          class="viewer" ref="viewer" 
          :options="options"
          >
          <img 
            height="150" width="200" style="background:gray;"
            :src="previewImg"
            class="image"
            >
        </viewer>
      </v-col>
    </v-row>                

    <v-row dense align="center" justify="center">
      <v-col md="4" sm="6" xs="12">
        <v-row dense align="center" justify="center">
          <v-col cols="3" align="center">
            <label class="mytextstyle">워터마크</label>
          </v-col>
          <v-col cols="9">
            <v-file-input 
              outlined dense height="43" show-size hide-details v-model="uploadFile" 
              accept="image/png, image/jpeg" @change="changefile" 
              >
            </v-file-input>
          </v-col>
        </v-row>
      </v-col>
    </v-row>



    <v-row dense align="center" justify="center">
      <v-col md="4" sm="6" xs="12">
        <v-row dense align="center" justify="center">
          <v-col cols="3" align="center">
            <label class="mytextstyle">회원상태</label>
          </v-col>
          <v-col cols="9">
            <v-select dense v-model="editedItem.STATE" :items="STATELIST" outlined color="gray"
                item-text="name" item-value="value" readonly append-icon="" hide-details class="mypageiputstyle" 
              />
          </v-col>
        </v-row>
      </v-col>
    </v-row>


    <v-row align="center" justify="center" class="mt-10">
      <v-col md="2" sm="4" xs="4" align="center">
        <v-btn class="dialog_cancel_btn" width="100%" large @click="exit">회원탈퇴</v-btn>
      </v-col>
      <v-col md="2" sm="4" xs="4" align="center">
        <v-btn class="dialog_cancel_btn" width="100%" large @click="changepass">PW변경</v-btn>
      </v-col>
      <v-col md="2" sm="4" xs="4" align="center">
        <v-btn class="dialog_btn" width="100%" large  @click="save">저장</v-btn>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import { ref, uploadBytes, deleteObject } from "firebase/storage";

export default {
    components:{
      VueCropper
    },
    data: () => ({
      
      grade:0,
      user_pw : "",

      //파일 업로드
      uploadFile : null,
      crop_dialog_land: false,
      imgSrc:'',
      ratio: 16/9,
      previewImg:null,
      options: {
        focus:false,
      },      

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: false,          //크롭박스 비율 고정
        fixedNumber: [1,1],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:100,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:100,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 5000,
        limitMinSize: [300, 300]
      },      

      show1:false,
      loading : false,    //로딩 표시
      dialog_exit: false,
      dialog:false,
      defaultItem: {
          ACCNT_NO:'',
          ACCNT:'',
          REFIMAGE:'',
          GRADE:1,
          STATE:1,
          DATAGRADE:1
      },
      editedItem: {
      },

      GRADELIST: [
          {name:'준회원',       value:1}
        , {name:'정회원',       value:10}
        , {name:'관리자',       value:99}
      ],
      DATAGRADELIST: [
          {name:'지점',       value:1}
        , {name:'전체',       value:10}
      ],
      STATELIST: [
          {name:'탈퇴',       value:0}
        , {name:'회원',       value:1}
      ],

    }),

    mounted(){
      if(this.$session.get("GRADE") < 10){
        this.$alert("사용할 수 없는 등급의 페이지입니다.");
        this.$router.back();
        return;
      }
      this.grade = this.$session.get("GRADE");

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){

    },

    methods: {

      changepass(){
        //인증코드 재발송
        this.$confirm("가입하신 이메일로 비밀번호를 변경하기 위한 인증 메일을 발송하시겠습니까?")
        .then(()=>{
          this.$http.post(this.$host+'/resetpassword',{
              ACCNT: this.editedItem.ACCNT
          })
          // eslint-disable-next-line no-unused-vars
          .then((result)=>{

            if(result.data.resultCode == 0){
              this.$alert("비밀번호 변경 인증메일이 발송되었습니다.\n인증메일을 확인하세요");
            }else{
              alert(result.data.resultMsg);      
            }
          });
        })        
      },


      //이미지 크롭시
      cropimg(){
        //이미지 크롭 
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog_land = false;
          this.uploadFile = blob; //크롭된 이미지의 blob데이터
          this.$refs.cropper_land.getCropData(data=>{
            this.previewImg = data; //이미지뷰 미리보기용
          });
        });
      },

      changefile(){

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.crop_dialog_land = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
            //this.$refs.cropper_land.replace(event.target.result);
          }
          reader.readAsDataURL(this.uploadFile);  //비동기로 파일을 읽어서
        }

      },

      initpage(){
        this.loadpage();
      },

      loadpage(){   
        this.loading = true;
        this.$http.post(this.$host+'/AcntDetail',{
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              this.loading = false;
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              const list = result.data.resultData;
              if(list.length > 0){
                this.editedItem = list[0];
                this.previewImg = this.editedItem.FOOTER_IMG;
              }
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.loading = false;
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.loading = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });
        
      },

      exit(){
        this.dialog_exit = true;
      },

      runexit(){

        this.$confirm('탈퇴 후 재가입할 수 없습니다. 정말 탈퇴하시겠습니까?')
        .then(() => {

            if(this.user_pw.length < 1){
              this.$alert('비밀번호를 입력하세요');
              return;
            }
            if(this.user_pw.length < 6){
              this.$alert("비밀번호는 최소 6글자 이상입니다.");
              return;
            }
            
            this.loading = true;
            this.$http.post(this.$host+'/AcntDelete',{
              USER_PW: Buffer.from(this.user_pw).toString('base64')  
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
            .then((result)=>{
                this.loading = false;
                this.dialog_exit = false;
                if(result.data.resultCode == 0){
                  this.$alert('회원탈퇴가 완료되었습니다.').then(()=>{
                    //로그아웃
                    this.$EventBus.$emit('clearsession');
                  });
                }else{
                  this.$alert('회원탈퇴에 실패했습니다.('+result.data.resultMsg+")");
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert('회원탈퇴 실패');
            });      

        }); 

      },


      //추가 저장
      save () {

        if(this.uploadFile){
          //파일이 수정된 경우
          //console.log("edit file...");
          this.loading = true;
          const citem  = this.editedItem;

          //기존 파일 삭제 (파일명이 동일하면 캐싱때문에 이미지가 안바뀜)
          if(citem.REFIMAGE){
            const desertRef = ref(this.$firestorage, citem.REFIMAGE);
            deleteObject(desertRef).then(() => {

            // eslint-disable-next-line no-unused-vars
            }).catch((error) => {
                this.loading = false;
                this.$alert('수정 실패');
            });
          }

          const uploadfile_ref = ref(this.$firestorage, "watermark/"+Date.now().toString()+".png");
          const metadata = {
            contentType: 'image/png',
          };      
          uploadBytes(uploadfile_ref, this.uploadFile, metadata)
          .then((snapshot) => {
            
            //console.log('full path ' + snapshot.metadata.fullPath);
            this.$http.post(this.$host+'/AcntUpdate',{
                  GRADE: citem.GRADE
                , NAME: escape(citem.NAME)
                , TEL: escape(citem.TEL)
                , AREA_NO: citem.AREA_NO
                , DATAGRADE: citem.DATAGRADE
                , FOOTER_IMG: snapshot.metadata.fullPath
                , STATE: citem.STATE
                , ACCNT_NO: citem.ACCNT_NO
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){
                  //토큰 저장
                  if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                    this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                  }
                  if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                    this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                  }
                  this.$alert("수정이 완료되었습니다").then(()=>{
                    const p = this.page;
                    this.page = 0;
                    this.loadpage(p);
                  });

              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
                this.$EventBus.$emit('clearsession');

              }else{
                  this.$alert('수정이 실패했습니다.');
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.loading = false;
              this.$alert('수정이 실패했습니다.');
            });

          })
          // eslint-disable-next-line no-unused-vars
          .catch((upload_error)=>{
              this.loading = false;
              this.$alert('수정 실패');
          });

        }else{

        //수정
        this.loading = true;
        this.$http.post(this.$host+'/AcntUpdate',{
              GRADE: this.editedItem.GRADE
            , NAME: escape(this.editedItem.NAME)
            , TEL: escape(this.editedItem.TEL)
            , AREA_NO: this.editedItem.AREA_NO
            , DATAGRADE: this.editedItem.DATAGRADE
            , STATE: this.editedItem.STATE
            , ACCNT_NO: this.editedItem.ACCNT_NO
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$alert("수정이 완료되었습니다").then(()=>{
                const p = this.page;
                this.page = 0;
                this.loadpage(p);
              });

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');

          }else{
              this.$alert('수정이 실패했습니다.');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          this.$alert('수정이 실패했습니다.');
        });

      }

      
      },      



    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>