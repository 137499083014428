<template>

  <v-container fluid class="ma-0 pa-0" style="background:white;min-height:1000px">
    

    <v-row class="ma-0 pa-0 hidden-sm-and-down" no-gutters dense>
      <v-col cols="12">
      <v-parallax
        height="226" 
        :src="require('@/assets/boardbg.png')"
        >
        <v-row align="center" justify="center">
          <v-col cols="12" class="text-center mt-15">
            <label style="color:white;font-size:25px">감성수학소개</label>
          </v-col>
        </v-row>
      </v-parallax>    
      </v-col>
    </v-row>

    <!-- 댓글 추가/수정 팝업 -->
    <v-row >
      <v-dialog scrollable overlay-color="#00000040" persistent v-model="dialog" width="480px">
        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center" align-self="center">
              <v-icon color="#0d0d0d">mdi-square-edit-outline</v-icon>
              <span class="dialog_title ml-1">{{ dialogTitle }}</span>
            </v-col>
          </v-row>

          <v-row v-if="editedIndex == -1" dense align="center" justify="center" class="mt-5 pr-10">
            <v-col cols="3" align="center">
              <label class="mytextstyle">작성자</label>
            </v-col>
            <v-col cols="7">
              <v-text-field
                v-model="editedItem.NAME" dense single-line counter="10"
                outlined hide-details class="popupiputstyle"
                />
            </v-col>
          </v-row>

          <v-row v-if="grade < 90 || editedIndex == -1" dense align="center" justify="center" class="mt-5 pr-10">
            <v-col cols="3" align="center">
              <label class="mytextstyle">비밀번호</label>
            </v-col>
            <v-col cols="7">
              <v-text-field v-model="editedItem.PW" 
                dense counter="4" outlined label="비밀번호" hint="비밀번호는 숫자 4자리로 입력하세요"
                class="popupiputstyle"
                />
            </v-col>
          </v-row>

          <v-row dense align="center" justify="center" class="mt-5">
            <v-col cols="10">
            <v-textarea counter="250" outlined v-model="editedItem.COMMENT" label="내용" 
              :rules="[ v  => ( v != null && v.length <= 250) || '내용은 250자 까지 입력이 가능합니다.']">
              </v-textarea>
            </v-col>
          </v-row>

          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <!-- 댓글 삭제 팝업 -->
    <v-row >
      <v-dialog scrollable overlay-color="#00000040" persistent v-model="dialog_delete1" width="480px">
        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center" align-self="center">
              <v-icon color="#0d0d0d">mdi-trash-can</v-icon>
              <span class="dialog_title ml-1">댓글삭제</span>
            </v-col>
          </v-row>

          <v-row v-if="grade < 90" dense align="center" justify="center" class="mt-5 pr-10">
            <v-col cols="3" align="center">
              <label class="mytextstyle">비밀번호</label>
            </v-col>
            <v-col cols="7">
              <v-text-field v-model="editedItem.PW" 
                dense counter="4" outlined label="비밀번호" hint="비밀번호는 숫자 4자리로 입력하세요"
                class="popupiputstyle"
                />
            </v-col>
          </v-row>

          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="repple_delete">삭제</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 답글 추가/수정 팝업 -->
    <v-row >
      <v-dialog scrollable overlay-color="#00000040" persistent v-model="dialog2" width="480px">
        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center" align-self="center">
              <v-icon color="#0d0d0d">mdi-square-edit-outline</v-icon>
              <span class="dialog_title ml-1">{{ dialogTitle }}</span>
            </v-col>
          </v-row>

          <v-row dense align="center" justify="center" class="mt-5">
            <v-col cols="10">
            <v-textarea counter="250" outlined v-model="editedItem.COMMENT" label="내용" 
              :rules="[ v  => ( v != null && v.length <= 250) || '내용은 250자 까지 입력이 가능합니다.']">
              </v-textarea>
            </v-col>
          </v-row>

          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save2">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="progress_dialog" width="400px">
        <v-row justify="center">
          <v-col cols="2">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
              />
          </v-col>
        </v-row>
        <v-row justify="center">
            <label>잠시만 기다려주세요</label>
        </v-row>
      </v-dialog>  
    </v-row>    


    
    <v-row justify="center">
      <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11">
        <label style="font-size:35px;color:#121212">{{BOARD_CONTENT.TITLE}}</label>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11" style="display:inline-flex">
        <v-icon size="50">
          mdi-account-circle
        </v-icon>
        <span class="ml-2">
          <div><label style="font-size:20px;color:#626262">관리자</label></div>
          <div><label style="font-size:16px;color:#626262">{{BOARD_CONTENT.UP_DATE}}</label></div>
        </span>
        <v-spacer/>
        <span class="ml-2">
          <v-btn @click="back" elevation="0" text >목록</v-btn>
        </span>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11">
        <div style="height:3px;background:#ececec"/>
      </v-col>
    </v-row>
      
    <!-- 
    <v-row align="start" justify="center">
      <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11">
        <div v-html="BOARD_CONTENT.DESC"/>
      </v-col>
    </v-row>
    -->

    <v-row align="start" justify="center">
      <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11">
        <viewer v-if="BOARD_CONTENT.DESC != null" :initialValue="BOARD_CONTENT.DESC" @load="onEditorLoad"/>
      </v-col>
    </v-row>

    

    <v-row align="center" justify="center" dense>
      <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11" align="end">
        <v-btn v-if="BOARD_CONTENT.REPPLE == 1" @click="newinsert" elevation="0" text style="font-weight:bold">댓글쓰기</v-btn>
        <v-btn @click="back" elevation="0" text >목록</v-btn>
        <v-btn @click="movetop" elevation="0" text >TOP</v-btn>
      </v-col>
    </v-row>

    <v-row justify="center" dense class="mt-2">
      <v-col ccols="11" xl="8" lg="8"  md="10" xs="11" sm="11">
        <v-row style="height:1px;background:#9f0007"/>
      </v-col>
    </v-row>

    <!-- 댓글 사용일때만 댓글 표시 -->
    <template v-if="BOARD_CONTENT.REPPLE == 1">
      <template v-if="itemList.length > 0">
        <div v-for="item in itemList" :key="item.REPPLE_NO">
          <template v-if="item.LEVEL == 1">

            <v-row align="start" justify="center" no-gutters>
              <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11">

                <v-row align="start" justify="center" dense no-gutters class="pt-5 pb-5 repple_line" style="background:#ffffff">
                  <v-col cols="1" align-self="start" align="right">
                    <v-icon size="50">
                      mdi-account-circle
                    </v-icon>
                  </v-col>
                  <v-col cols="9" class="pl-5">
                    <label>{{item.NAME}}</label>
                    <label style="margin-left:20px">{{item.UP_DATE}}</label>
                    <v-row dense>
                      <v-col cols="12">
                        <p style="white-space:pre-line;">{{item.COMMENT}}</p>  
                      </v-col>
                    </v-row>
                    <v-row v-if="grade > 90" dense no-gutters class="ma-0 pa-0">
                      <v-col>
                        <label><a style="color:#a1a1a1" @click="newinsert2(item)">답글</a></label>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="1" align-self="center" align="center">
                    <v-btn icon @click="edit_item(item)"  small><v-icon color="#757575">mdi-square-edit-outline</v-icon></v-btn>
                  </v-col>
                  <v-col cols="1" align-self="center" align="center">
                    <v-btn icon @click="delete_item(item)"  small><v-icon color="#757575">mdi-trash-can</v-icon></v-btn>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>    
          </template>
          <template v-else>
            <v-row align="start" justify="center" no-gutters>
              <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11">
                <v-row align="start" justify="center" dense no-gutters style="background:#fafafa" class="pt-5 pb-5 repple_line">
                  <v-col cols="2" align-self="start" align="right">
                    <v-icon small>mdi-arrow-right-bottom</v-icon>
                    <v-icon size="50" class="pr-3">
                      mdi-account-circle
                    </v-icon>
                  </v-col>
                  <v-col cols="8" class="pl-2">
                    <label>{{item.NAME}}</label>
                    <label style="margin-left:20px">{{item.UP_DATE}}</label>
                    <v-row dense>
                      <v-col cols="12">
                        <p  style="white-space:pre-line;">{{item.COMMENT}}</p>  
                      </v-col>
                    </v-row>
                    <v-row v-if="grade > 90" dense no-gutters class="ma-0 pa-0">
                      <v-col>
                        <label><a style="color:#a1a1a1" @click="newinsert2(item)">답글</a></label>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="1" align-self="center" align="center">
                    <v-btn icon @click="edit_item2(item)" v-if="grade > 90" small><v-icon color="#757575">mdi-square-edit-outline</v-icon></v-btn>
                  </v-col>
                  <v-col cols="1" align-self="center" align="center">
                    <v-btn icon @click="repple_delete2(item)" v-if="grade > 90" small><v-icon color="#757575">mdi-trash-can</v-icon></v-btn>
                  </v-col>

                </v-row>
              </v-col>
            </v-row>
          </template>
        </div>
      </template>
    </template>
    <template v-else-if="BOARD_CONTENT.REPPLE == 0">
      <v-row align="start" justify="center" no-gutters>
        <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11" align="center">
          <label>댓글을 사용할 수 없는 게시글입니다.</label>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row align="start" justify="center" no-gutters>
        <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11" align="center">
        </v-col>
      </v-row>
    </template>>


    <v-row style="height:200px"></v-row>

  </v-container>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/vue-editor';
import youtubeUrl from 'youtube-url'
export default {

    components: {
      viewer: Viewer
    },

    data: () => ({
      show1: false,
      loading : false,    //로딩 표시
      progress_dialog:false,

      grade: 0,
      BOARD_NO:"",        //넘겨받은 게시글 번호
      BOARD_CONTENT:{},   //게시글 상세정보
      itemList: [],       //게시글 답글 댓글 리스트

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      dialog2:false,
      dialog_delete1: false,

      defaultItem: {
        INTROBOARD_NO:null,
        LEVEL:1,
        STEP:1,
        COMMENT:'',
        NAME:'',
        PW:null
      },
      editedItem: {
      },

      numberrules:[v => Number.isInteger(Number(v)) || '숫자만 사용할 수 있습니다.'],

    }),


    beforeDestroy() {
    },

    mounted(){
      this.grade    = this.$session.get("GRADE");
      if(this.grade == undefined || this.grade == null){
        this.grade = 0;
      }
      this.INTROBOARD_NO = this.$route.query.code;
      this.loadpage();
    },

    create(){
      
    },

    methods: {

      onEditorLoad (v) {
        const el  = v.preview.el
        this.html = this.checkimg(el);
        this.html = this.addYoutubeIframe(el);
      },

      //이미지 무조건 100%로 표시
      checkimg(el){
        const len = el.firstChild.children
        for (const p of len) {
          for (const img of p.children) {
            if(img.src){
              img.style.width = "100%";
              ////console.log("img : " + img);
            } 
          }
        }
        return el.innerHTML   
      },

      setIframe(id){
        const div = document.createElement('div');
        div.style.position  = "relative";
        div.style.overflow  = "hidden";
        div.style.width     = "100%";
        div.style.paddingTop = "56.25%";

        const iframe = document.createElement('iframe');
        iframe.style.position = "absolute";
        iframe.style.top = 0;
        iframe.style.left = 0;
        iframe.style.right = 0;
        iframe.style.bottom = 0;
        iframe.style.width = "100%";
        iframe.style.height = "100%";
        iframe.setAttribute('frameborder', '0')
        iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture')
        iframe.setAttribute('allowfullscreen', '')
        iframe.setAttribute('src', 'https://www.youtube.com/embed/' + id);
        div.appendChild(iframe);
        return div;
      },

      addYoutubeIframe(el){
        //모든 child를 돌면서 a href에 유튜브 아이디값이 있으면 해당 태그를 iframe으로 교체한다.
        const len = el.firstChild.children
        const items = []
        for (const p of len) {
          for (const a of p.children) {
            if (!a.href) continue
            const id = youtubeUrl.extractId(a.href)
            if (!id) continue
            p.setAttribute('id', id)
            items.push({
              doc: p,
              id: id
            })
          }
        }
        items.forEach(p => {
          p.doc.parentNode.insertBefore(this.setIframe(p.id), p.doc) 
          p.doc.parentNode.removeChild(p.doc);  //a href 제거
        })
        
        return el.innerHTML        
      },


      movetop(){
        window.scrollTo(0,0);
      },

      back(){
        //뒤로
        this.$router.back();
      },
      
      loadpage(){

        //내용 가져오기
        this.loading = true;
        this.$http.post(this.$host+'/GuestIntroBoardDetail',{
            INTROBOARD_NO: this.INTROBOARD_NO
          , token: this.$session.get("ACCESSTOKEN")
        }).then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){
            //이미 선택된 아이템에 상세만 교체
            window.scrollTo(0,0);
            this.BOARD_CONTENT  = result.data.resultData[0];
            if(this.BOARD_CONTENT == undefined){
              this.$alert("게시글이 없습니다.");
            }else{
              this.loadrepple();
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
        });

      },

      loadrepple(){      
        //댓글 답글 로드
        this.loading = true;
        this.$http.post(this.$host+'/GuestIntroReppleList',{
          INTROBOARD_NO: this.INTROBOARD_NO
        }).then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){
            const list = result.data.resultData;
            this.itemList.splice(0, this.itemList.length);   //데이터 초기화
            list.forEach(element => {
                this.itemList.push(element); 
            });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
        });
      },

      initpage(){
        //첫페이지 로드
        this.loadpage();
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false;
        this.dialog2    = false;
        this.dialog_delete1 = false;
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.uploadFile = [];
        }, 300)
      },

      //추가 저장
      async save () {

        if(this.editedItem.COMMENT == undefined || this.editedItem.COMMENT.length < 1){
          this.$alert("내용을 입력하세요");
          return;
        } 
        if(this.editedItem.COMMENT.length > 250){
          this.$alert("내용은 250자 까지 입력이 가능합니다.");
          return;
        } 

        if(this.editedItem.NAME == undefined || this.editedItem.NAME.length < 1){
          this.$alert("이름을 입력하세요");
          return;
        } 
        if(this.editedItem.NAME.length > 10){
          this.$alert("이름은 10자 까지 입력이 가능합니다.");
          return;
        } 
        
        if(this.grade < 90){
          const numberVal = /[^0-9]/g;
          if(this.editedItem.PW.length != 4){
            this.$alert("비밀번호 4자리를 입력하세요");
            return;
          }
          if(numberVal.test(this.editedItem.PW)){
            this.$alert("비밀번호는 숫자만 입력이 가능합니다.");
            return;
          }
        }

        this.progress_dialog = true;

        if (this.editedIndex > -1) {

          //수정
          this.loading = true;
          if(this.grade < 90){
            this.$http.post(this.$host+'/GuestIntroReppleUpdate', {
                INTROREPPLE_NO: this.editedItem.INTROREPPLE_NO
              , COMMENT: escape(this.editedItem.COMMENT)
              , PW: this.editedItem.PW
            }).then((result)=>{
              this.loading = false;
              this.progress_dialog = false;
              if(result.data.resultCode == 0){
                this.$alert('수정이 완료되었습니다.').then(()=>{
                  this.loadrepple();
                });
              }else{
                this.$alert('수정에 실패했습니다.('+result.data.resultMsg+")");
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.loading = false;
              this.progress_dialog = false;
              this.$alert('수정에 실패했습니다.');
            });
          }else{
            this.$http.post(this.$host+'/IntroReppleUpdate2', {
                INTROREPPLE_NO: this.editedItem.INTROREPPLE_NO
              , COMMENT: escape(this.editedItem.COMMENT)
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
            .then((result)=>{
              this.loading = false;
              this.progress_dialog = false;
              if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }

                this.$alert('수정이 완료되었습니다.').then(()=>{
                  this.loadrepple();
                });

              }else if(result.data.resultCode == 2){
                this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
                this.$EventBus.$emit('clearsession');

              }else{
                this.$alert('수정에 실패했습니다.('+result.data.resultMsg+")");
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.loading = false;
              this.progress_dialog = false;
              this.$alert('댓글수정에 실패했습니다.');
            });
          }

        }else{

          this.loading = true;
          this.$http.post(this.$host+'/GuestIntroReppleInsert',{
              INTROBOARD_NO: this.INTROBOARD_NO
            , LEVEL: 1
            , COMMENT: escape(this.editedItem.COMMENT)
            , NAME: escape(this.editedItem.NAME)
            , PW: this.editedItem.PW
          }).then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
              this.$alert('댓글 작성이 완료되었습니다.').then(()=>{
                this.loadrepple();
              });
            }else{
              this.$alert('댓글추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.progress_dialog = false;
            this.$alert('댓글추가 실패');
          });

        }

        this.close()
      },      

      //검색
      search_run(){
        this.loadpage();
      },
      

      //신규 추가
      newinsert(){
        this.dialogTitle = "댓글쓰기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialog = true;
      },
      
      //아이템 수정
      edit_item(item){
        this.dialogTitle = "댓글수정"
        this.editedIndex = this.itemList.indexOf(item);
        this.editedItem  = Object.assign({}, item);
        this.editedItem.PW = '';
        this.dialog = true;
      },

      delete_item(item){

        if(this.grade < 90){
          this.dialogTitle = "댓글삭제"
          this.editedItem  = Object.assign({}, item);
          this.editedItem.PW = '';
          this.dialog_delete1 = true;
        }else{
          this.repple_delete2(item);
        }

      },

      //아이템 삭제
      repple_delete(){

        //console.log("repple_delete item : "+JSON.stringify(this.editedItem));

        if(this.grade < 90){
          const numberVal = /[^0-9]/g;
          if(this.editedItem.PW.length != 4){
            this.$alert("비밀번호 4자리를 입력하세요");
            return;
          }
          if(numberVal.test(this.editedItem.PW)){
            this.$alert("비밀번호는 숫자만 입력이 가능합니다.");
            return;
          }
        }

        this.$confirm('정말 삭제하시겠습니까?')
        .then(() => {
          this.loading = true;
          if(this.grade < 90){
            this.$http.post(this.$host+'/GuestIntroReppleDelete',{
                INTROREPPLE_NO:this.editedItem.INTROREPPLE_NO
              , PW: this.editedItem.PW
            }).then((result)=>{
                this.loading = false;
                this.dialog_delete1 = false;
                if(result.data.resultCode == 0){
                  this.$alert('댓글 삭제가 완료되었습니다.').then(()=>{
                    this.loadrepple();
                  });
                }else{
                  this.$alert('댓글 삭제가 실패했습니다.('+result.data.resultMsg+")");
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert('삭제 실패');
            });      
          }else{
            this.$http.post(this.$host+'/IntroReppleDelete2',{
                INTROREPPLE_NO:this.editedItem.INTROREPPLE_NO
            },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
            .then((result)=>{
                this.loading = false;
                this.dialog_delete1 = false;
                if(result.data.resultCode == 0){

                  //토큰 저장
                  if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                    this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                  }
                  if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                    this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                  }

                  this.$alert('댓글 삭제가 완료되었습니다.').then(()=>{
                    this.loadrepple();
                  });

                }else if(result.data.resultCode == 2){
                  this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
                  this.$EventBus.$emit('clearsession');

                }else{
                  this.$alert('댓글 삭제가 실패했습니다.('+result.data.resultMsg+")");
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert('삭제 실패');
            });      
          }
        });

      },

      //답글 추가
      newinsert2(item){
        this.dialogTitle = "답글쓰기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.INTROREPPLE_NO = item.INTROREPPLE_NO;
        this.editedItem.STEP      = item.STEP;
        //console.log("new insert 2 : " + JSON.stringify(this.editedItem));
        this.dialog2 = true;
      },
      
      //답글 추가 저장
      async save2 () {


        
        if(this.editedItem.COMMENT == undefined || this.editedItem.COMMENT.length < 1){
          this.$alert("내용을 입력하세요");
          return;
        } 
        if(this.editedItem.COMMENT.length > 250){
          this.$alert("내용은 250자 까지 입력이 가능합니다.");
          return;
        } 

        this.progress_dialog = true;
        if (this.editedIndex > -1) {

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/IntroReppleUpdate2', {
              INTROREPPLE_NO: this.editedItem.INTROREPPLE_NO
            , COMMENT: escape(this.editedItem.COMMENT)
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

              this.$alert('수정이 완료되었습니다.').then(()=>{
                this.loadrepple();
              });

            }else if(result.data.resultCode == 2){
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.$alert('수정에 실패했습니다.('+result.data.resultMsg+")");
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.progress_dialog = false;
            this.$alert('수정에 실패했습니다.');
          });

        }else{

          //console.log("test : " + this.INTROBOARD_NO + " / " + JSON.stringify(this.editedItem));

          this.loading = true;
          this.$http.post(this.$host+'/IntroReppleInsert2',{
              INTROBOARD_NO:   this.INTROBOARD_NO
            , INTROREPPLE_NO:  this.editedItem.INTROREPPLE_NO
            , LEVEL: 2
            , STEP: this.editedItem.STEP + 1
            , COMMENT: escape(this.editedItem.COMMENT)
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

              this.$alert('작성이 완료되었습니다.').then(()=>{
                this.loadrepple();
              });

            }else if(result.data.resultCode == 2){
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.$alert('작성 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.progress_dialog = false;
            this.$alert('작성 실패');
          });

        }

        this.close()
      },    

      //답글 수정
      edit_item2(item){
        this.dialogTitle = "답글수정"
        this.editedIndex = this.itemList.indexOf(item);
        this.editedItem  = Object.assign({}, item);
        this.dialog2 = true;
      },

      //아이템 삭제
      repple_delete2(item){
        this.editedItem  = Object.assign({}, item);
        this.$confirm('정말 삭제하시겠습니까?')
        .then(() => {
          this.loading = true;
          this.$http.post(this.$host+'/IntroReppleDelete2',{
            INTROREPPLE_NO: this.editedItem.INTROREPPLE_NO
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
            this.loading = false;
            this.close();
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

              this.$alert('삭제가 완료되었습니다.').then(()=>{
                this.loadrepple();
              });

            }else if(result.data.resultCode == 2){
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.$alert('삭제가 실패했습니다.('+result.data.resultMsg+")");
            }
        })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              this.loading = false;
              this.close();
              this.$alert('삭제 실패');
          });      

        });

      },

      //추가 함수들
      changefiles(file) {
        this.$alert(JSON.stringify(file.files));
      },

      //base64 이미지 데이터를 파일로 변환
      dataURLtoFile(dataurl, fileName){
 
        var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], fileName, {type:mime});

      },

    },
  
}
</script>

<style lang="css">
  td{
    height: 36px !important;
  }
</style>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>
.v-main__wrap{
  background: white;
}
</style>