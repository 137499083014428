class DRModel {

    constructor(){
        
        //해당 값이 추가되었다.
        this.ISBG   = 0;            //배경 유무 0:컴포넌트 1:배경
        this.COMPONENT_TYPE = 1;    //0 = IMAGE (매칭없음), 1 = rounded large (매칭있음)

        //사이즈 관련 파라메터
        this.LAYOUT_WIDTH   = 200;
        this.LAYOUT_HEIGHT  = 50;
        this.LAYOUT_POSX    = 50;
        this.LAYOUT_POSY    = 10;

        this.TEMP_POSX    = 50;
        this.TEMP_POSY    = 10;

        //표시정보 관련 파라메터
        this.BGTYPE         = 0;        //0 컬러 1 이미지
        this.BGCOLOR        = "00000000";
        this.TEXTCOLOR      = "000000FF";
        this.TEXTSIZE       = "20";
        this.TEXTFONT       = "나눔고딕";
        this.TEXTVALUE      = "내용을 입력하세요";
        this.TEXTALIGN      = "CENTER";
        this.TEXTMATCH      = "INPUT";
        this.ZINDEX         = 1;

        this.LOCALIMG = null;
        this.IMG = null;

        //이 두개의 값은 그냥 두면된다. 현재 사용하지 않고 그냥 기본값으로 처리
        this.ROTATE         = 0;
        this.SCALE          = 1;
        
    }

}

export default DRModel;