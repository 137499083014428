<template>

  <v-container fluid >

    <!-- 추가/수정 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#000000ff" v-model="dialog" max-width="550px">
        
        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">등급</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.GRADE" dense single-line disabled
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">교육특구</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.GRADEVALUE" dense single-line
                    outlined height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>

    <v-row align="center" justify="start" class="mt-5">

      <v-col cols="6" md="2" sm="12" xs="12">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="6" md="2" align="center">
        <v-select id="mySelect"
          v-model="SELECT_AREA_NO" :items="AREA_LIST" label="지점" no-data-text="지점이 없습니다."
          dense hide-details outlined item-text="AREA_NAME" item-value="AREA_NO" class="basicselectstyle" 
          :disabled="grade < 90" @change="loadpage"
          />
      </v-col>
      <v-col cols="6" md="2" sm="6" xs="6"  align="center">
        <v-select id="mySelect"
          v-model="SELECT_YEAR" :items="SEARCHYEAR" label="년도" @change="changeyear"
          dense hide-details outlined item-text="CODE_NAME" item-value="CODE_VALUE" class="basicselectstyle" 
          />
      </v-col>
      <v-col cols="6" md="2"  align="center">
        <v-select v-model="SELECT_SCHOOLYEAR" :items="SEARCHSCHOOLYEAR" label="학년" 
          class="basicselectstyle" @change="changegrade"
          dense hide-details outlined item-text="CODE_NAME" item-value="CODE_VALUE"/>
      </v-col>
      <v-col cols="12" md="4"  align="center">
        <v-select v-model="SELECT_EXAM" :items="SEARCHEXAM" label="시험" 
          class="basicselectstyle" @change="search_run"
          dense hide-details outlined item-text="EXAM_NAME" item-value="EXAM_NO"/>
      </v-col>
  </v-row>
    
  <v-row align="start" dense class="mt-5">
    <v-col > 
      <!-- 테이블 -->
      <v-data-table
        class="datatablestyle"
        :headers="headers"
        :items="itemList"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        :items-per-page="9999"
        item-key="IDX"
        :loading="loading"
        >
        <template v-slot:item.actions="{ item }">  
            <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
        </template>     
      </v-data-table>
    </v-col>
  </v-row>

  </v-container>
</template>

<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=2d34ab8a2ba110eed5c9ab2ba610bea6&libraries=services"></script>
<script>
export default {

    data: () => ({

      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          EXAM_NO:'',
          EXAM_YEAR:null,
          EXAM_GRADE:null,
          EXAM_NAME:'',
          START_DATE:'',
          END_DATE:'',
          EXAM_ADJUST:0,
          EXAM_STATE:0,
      },
      editedItem: {
      },

      //테이블 정의
      headers: [
        {text: '등급', value: 'GRADE', width:100, sortable: false, align:'center', class:"cheader_normal" },
        {text: '지점등급', value: 'AREAVALUE', width:100, sortable: false, align:'center', class:"cheader_normal"},
        {text: '전국등급', value: 'TOTALVALUE', width:100, sortable: false, align:'center', class:"cheader_normal" },
        {text: '교육특구', value: 'GRADEVALUE', width:100, sortable: false, align:'center', class:"cheader_normal" },
        {text: '상위(%)', value: 'GRADEMAX', width:100, sortable: false, align:'center', class:"cheader_normal" },
        {text: '하위(%)', value: 'GRADEMIN', width:100, sortable: false, align:'center', class:"cheader_normal" },
        {text: '수정', value: 'actions', width:80, sortable: false, align:'center', class:"cheader_normal"}
      ],
      itemList: [],
      
      SELECT_AREA_NO: null,
      SELECT_YEAR:null,
      SELECT_SCHOOLYEAR:null,
      SELECT_EXAM:null,

      SEARCHYEAR: [],
      SEARCHSCHOOLYEAR: [],
      SEARCHEXAM: [],
      AREA_LIST:[],

      grade:0,

    }),

    mounted(){

      if(this.$session.get("GRADE") < 90){
        this.$alert("사용할 수 없는 등급의 페이지입니다.").then(()=>{
          this.$router.back();
        });
        return;
      }
      this.grade = this.$session.get("GRADE");

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {

      loadpage(){

          this.loading = true;
          this.$http.post(this.$host+'/ExamGradeList',{
              EXAM_NO:this.SELECT_EXAM
            , AREA_NO:this.SELECT_AREA_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },

      async initpage(){

        //지점 리스트 조회
        await this.loadarea();
        this.SELECT_AREA_NO = this.AREA_LIST[0].AREA_NO;

        //공통코드 조회
        await this.loadcomcode("YEAR", "ASC", this.SEARCHYEAR);
        this.SELECT_YEAR = this.SEARCHYEAR[0].CODE_VALUE;

        await this.loadcomcode("SCHOOLYEAR", "DESC", this.SEARCHSCHOOLYEAR);
        this.SELECT_SCHOOLYEAR = this.SEARCHSCHOOLYEAR[0].CODE_VALUE;

        await this.loadexam();
        this.SELECT_EXAM = this.SEARCHEXAM[0].EXAM_NO;

        //첫페이지 로드
        this.loadpage();

      },      

      async changeyear(){
        await this.loadexam();
        this.SELECT_EXAM = this.SEARCHEXAM[0].EXAM_NO;
        this.loadpage();        
      },

      async changegrade(){
        await this.loadexam();
        this.SELECT_EXAM = this.SEARCHEXAM[0].EXAM_NO;
        this.loadpage();        
      },

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      //추가 저장
      save () {

          if(this.editedItem.GRADEVALUE == null || this.editedItem.GRADEVALUE == undefined){
              this.$alert("교육특구값을 입력하세요");
              return;
          }
          
          const numberVal = /[^0-9]/g;
          if(numberVal.test(this.editedItem.GRADEVALUE)){
            this.$alert("교육특구값은 숫자만 입력이 가능합니다.");
            return;
          }

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/ExamGradeInsert',{
              EXAM_NO: this.SELECT_EXAM
            , GRADE: this.editedItem.GRADE
            , GRADEVALUE: this.editedItem.GRADEVALUE
            , GRADEMIN: null
            , GRADEMAX: null
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$alert("수정이 완료되었습니다").then(()=>{
                  this.loadpage();
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');

            }else{
                this.$alert('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert('수정이 실패했습니다.');
          });


        this.close();

      },


      //검색
      search_run(){
        this.loadpage();
      },

      //아이템 수정
      edit_item(item){
        this.dialogTitle = "수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },
  

      //신규 추가
      newinsert(){
        this.dialogTitle = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem.EXAM_YEAR  = this.SELECT_YEAR;
        this.editedItem.EXAM_GRADE = this.SELECT_SCHOOLYEAR;
        this.dialog = true;
      },


      async loadarea(){

        await this.$http.post(this.$host+'/AreaList',{
              search_keyword: ''
            , sort_name: "AREA_NAME"
            , sort_type: "DESC"
            , page: 1
            , pagerow: 99999
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            const list = result.data.resultData;
            this.AREA_LIST.splice(0, this.AREA_LIST.length);   //데이터 초기화
            list.forEach(element => {
                this.AREA_LIST.push(element); 
            });

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.loading = false;
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.loading = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });

      },

      //공통코드 로드 : codetype, DESC, 추가할 어레이 객체
      async loadcomcode(codetype, sorttype, arraylist){

        await this.$http.post(this.$host+'/getCodeList',{
              CODE_TYPE: codetype
            , sort_type: sorttype
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            const list = result.data.resultData;
            arraylist.splice(0, arraylist.length);   //데이터 초기화
            list.forEach(element => {
                //console.log("element : " + JSON.stringify(element));
                arraylist.push(element); 
            });

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.loading = false;
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.loading = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });

      },           



      async loadexam(){

          await this.$http.post(this.$host+'/ExamList',{
                search_keyword: ""
              , SELECT_YEAR: this.SELECT_YEAR
              , SELECT_SCHOOLYEAR: this.SELECT_SCHOOLYEAR
              , sort_name: "EXAM_NAME"
              , sort_type: "ASC" 
              , page: 1
              , pagerow: 9999
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{

            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                const list = result.data.resultData;
                this.SEARCHEXAM.splice(0, this.SEARCHEXAM.length);   //데이터 초기화
                list.forEach(element => {
                    this.SEARCHEXAM.push(element); 
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },      


    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>