<template>

  <v-container fluid>


  <!-- 추가/수정 팝업 -->
  <v-row>
    <v-dialog persistent scrollable overlay-color="#000000ff" v-model="dialog" max-width="600px">
      
      <v-card class="pa-1 ma-0">

        <v-row dense class="mt-5">
          <v-col cols="12" align="center">
            <span class="dialog_title">오답노트 제목수정</span>
          </v-col>
        </v-row>
        
        <v-card-text class="ma-0 pl-5 pr-5 pt-3">
          <v-container>

            <v-row dense align="center" justify="center">
              <v-col cols="3" align="center">
                <label class="mytextstyle">제목</label>
              </v-col>
              <v-col cols="9">
                <v-text-field 
                  v-model="editedItem.NOTE_TITLE" dense single-line
                  outlined height="48px" hide-details class="popupiputstyle"
                  />
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">확인</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>  
  </v-row>

  <v-row align="center" justify="start" >
    <v-col cols="12" md="1" sm="12" xs="12">
      <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
    </v-col>
    <v-spacer/>
    <v-col cols="12" md="2" sm="6" xs="6"  align="center">
      <v-select v-model="SELECT_AREA_NO" :items="AREA_LIST" label="지점" no-data-text="지점이 없습니다."
        dense hide-details outlined item-text="AREA_NAME" item-value="AREA_NO" class="basicselectstyle" @change="changearea"
        :disabled="grade < 90"
        />
    </v-col>
    <v-col cols="12" md="3" sm="6" xs="6"  align="center">
      <v-select v-model="SELECT_SEMESTER" :items="SEMESTER_LIST" label="학기" no-data-text="학기가 없습니다." class="basicselectstyle"
        dense hide-details outlined item-text="SEMESTER_NAME" item-value="SEMESTER_NO" return-object @change="changesemester" />
    </v-col>
    <v-col cols="12" md="3" sm="6" xs="6"  align="center">
      <v-row class="pa-0" align="center">
        <v-col cols="9" class="ma-0">
          <v-select v-model="SELECT_BOOK" :items="BOOK_LIST" label="교재" no-data-text="교재가 없습니다." class="basicselectstyle"
            dense hide-details outlined  item-text="BOOK_NAME" item-value="BOOK_NO" return-object @change="changebook" />
        </v-col>
        <v-col cols="3" class="pa-0 ma-0" align="center" >
          <bookdialog @callback="bookcomareacb"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="3" sm="6" xs="6" align="center">
      <v-select v-model="SELECT_CHAPTER" :items="CHAPTER_LIST" label="챕터" no-data-text="챕터가 없습니다." class="basicselectstyle"
        dense hide-details outlined item-text="CHAPTER_NAME" item-value="CHAPTER_NO" return-object @change="changechapter" />
    </v-col>
  </v-row>
  <v-row align="center" justify="end">
    <!-- 검색어 -->
    <v-col cols="12" md="3" sm="12" xs="12" class="pa-0">
      <v-text-field 
        dense single-line clearable
        @keyup.enter="search_run" 
        v-model="search_keyword" 
        placeholder="검색어를 입력하세요"
        outlined height="43"
        color="#808080"
        hide-details          
        class="basicsearchstyle" 
        />
    </v-col>
    <v-col cols="6" md="1" sm="6" xs="6" class="pa-0" align="center">
      <v-btn width="94%" class="search_btn_freesize"  elevation="0" outlined large @click="search_run">검색</v-btn>
    </v-col>
  </v-row>

  <v-row dense no-gutters>
    <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
  </v-row>

  <v-row align="start" dense>
    <v-col>
      <!-- 테이블 -->
      <v-data-table
        :headers="headers"
        :items="itemList"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        class="datatablestyle"
        item-key="ACCNT_NO"
        :loading="loading"
        :items-per-page="pagerow"
        :search="search"  
        >
        <template v-slot:header.NAME>
          <a :class="sort_name=='NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('NAME')">등록자</a>
        </template>
        <template v-slot:header.REG_DATE>
          <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">등록일시</a>
        </template>
        <template v-slot:item.NOTE_TITLE="{ item }">
          <a @click="notedetail(item)">{{ item.NOTE_TITLE }}</a>
        </template>
        <template v-slot:item.actions="{ item }">  
            <v-icon small @click="edit_item(item)">mdi-pencil</v-icon>
            <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
        </template>     
      </v-data-table>
    </v-col>
  </v-row>

  <!-- 하단 페이징 영역 -->
  <v-row align="start">
    <v-col cols="12" md="1"/>
    <v-col cols="12" md="2">
      <v-text-field
        v-model="search"
        append-icon="search"
        placeholder="현재 페이지 내에서 검색"
        single-line
        hide-details
        outlined height="43"
        color="#808080"
        dense
        class="basicsearchstyle"
        />
    </v-col>
    <v-col cols="12" md="6" class="d-flex justify-center">
      <v-btn v-if="bottompage > 10"  icon  small @click="prev()"><v-icon dark>mdi-chevron-left</v-icon></v-btn>
      <span v-for="n in subpage" :key="n.id">
          <span v-if="page == bottompage-1+n">
              <v-btn color="red"  icon small @click="movepage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
          <span v-else>
              <v-btn  icon small @click="movepage(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
      </span>
      <v-btn v-if="subpage == 10"  icon small @click="next()" ><v-icon dark>mdi-chevron-right</v-icon></v-btn>
    </v-col>
    <v-col cols="12" md="2">
      <v-select
        v-model="pagerow"
        :items="pagerow_combo"
        item-text="NAME"      
        item-value="VALUE"
        placeholder="목록개수"
        min-width="150px"
        outlined height="43"
        color="#808080"
        dense
        hide-details
        class="basicsearchstyle"
        ></v-select>
    </v-col>
    <v-col cols="12" md="1"/>
  </v-row>

  </v-container>
</template>

<script>
//import dateFormat from 'dateformat'
export default {
    data: () => ({

      //상단 검색 콤보
      AREA_LIST:[],
      SEMESTER_LIST:[],
      BOOK_LIST:[],
      CHAPTER_LIST:[],

      SELECT_SEMESTER: null,
      SELECT_BOOK: null,
      SELECT_CHAPTER: null,
      SELECT_AREA_NO: null,

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,        //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //테이블 정의
      headers: [
        {text: '지점', value: 'AREA_NAME', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '등록자', value: 'NAME', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '제목', value: 'NOTE_TITLE', sortable: false, align:'center', width:'200px', class:"cheader_normal"},
        {text: '워터마크', value: 'NOTE_WATER', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '학기', value: 'SEMESTER_NAME', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '교재', value: 'BOOK_NAME', sortable: false, align:'center', width:'150px', class:"cheader_normal"},
        {text: '챕터', value: 'CHAPTER_NAME', sortable: false, align:'center' , width:'150px', class:"cheader_normal"},
        {text: '등록일시', value: 'REG_DATE', sortable: false, align:'center' , width:'100px', class:"cheader_normal"},
        {text: '수정/삭제', value: 'actions', sortable: false, align:'center', width:'100px', class:"cheader_normal"}
      ],
      itemList: [],

      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
      editedItem: {
      },
      dialog:false,
      grade:0
    }),

    mounted(){
      if(this.$session.get("GRADE") < 10){
        this.$alert("사용할 수 없는 등급의 페이지입니다.");
        this.$router.back();
        return;
      }
      this.grade = this.$session.get("GRADE");
      this.SELECT_AREA_NO  = this.$session.get("AREA_NO");
      this.SELECT_SEMESTER = this.$session.get("SELECT_SEMESTER");
      this.SELECT_BOOK     = this.$session.get("SELECT_BOOK");
      this.SELECT_CHAPTER  = this.$session.get("SELECT_CHAPTER");

      if(this.$route.query.page == undefined || this.$route.query.page == null){
        this.page = 1;
      }else{
        this.page = this.$route.query.page;
      }
      if(this.$route.query.search == undefined || this.$route.query.search == null){
        this.search_keyword = '';
      }else{
        this.search_keyword = this.$route.query.search;
      }
      if(this.sort_type == undefined || this.sort_type == null){
        this.sort_type = true;
      }else{
        if(this.$route.query.sorttype == "true"){
          this.sort_type = true;
        }else if(this.$route.query.sorttype == "false"){
          this.sort_type = false;
        }
      }
      this.sort_name = this.$route.query.sortname;
      if(this.sort_name == undefined || this.sort_name == null){
        this.sort_name = 'REG_DATE';
      }

      if(this.$session.exists("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){

    },

    methods: {


      //교재검색 콜백
      async bookcomareacb(val1){
        //console.log(JSON.stringify(val1));
        if(val1.BOOK_NAME.length > 0){
          this.SELECT_SEMESTER = {"SEMESTER_NAME":val1.SEMESTER_NAME, "SEMESTER_NO":val1.SEMESTER_NO};
          this.$session.set("SELECT_SEMESTER", this.SELECT_SEMESTER);
          this.SELECT_CHAPTER = null;
          await this.loadbook(true);
          this.SELECT_BOOK = {"BOOK_NAME":val1.BOOK_NAME,"BOOK_NO":val1.BOOK_NO};
          this.$session.set("SELECT_BOOK", this.SELECT_BOOK);
          this.loadchapter(true);
          
        }else{
          //this.editedItem.BOOK_NAME = '';
          //this.editedItem.AREA_NO   = null;
        }
      },      

      //아이템 수정
      edit_item(item){
        this.editedIndex = this.itemList.indexOf(item)
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },


      //추가 저장
      save () {
        
        if(this.editedItem.NOTE_TITLE  == null || this.editedItem.NOTE_TITLE  == undefined || this.editedItem.NOTE_TITLE.length < 1){
          this.$alert("오답노트 제목을 입력하세요");
          return;
        } 
        //수정
        this.loading = true;
        this.$http.post(this.$host+'/NoteTitleUpdate',{
              NOTE_TITLE: escape(this.editedItem.NOTE_TITLE)
            , NOTE_NO: this.editedItem.NOTE_NO
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){
              this.$alert('수정이 완료되었습니다.').then(()=>{
                this.loadpage(this.page);
              });
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.$alert('수정이 실패했습니다.');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          this.$alert('수정이 실패했습니다.');
        });

        this.close();

      },     


      movepage(p){
        this.$router.push({path:"NoteList", query:{page:p, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name
                                                  , area:this.SELECT_AREA_NO
                                                  , semester:this.SELECT_SEMESTER.SEMESTER_NO
                                                  , book:this.SELECT_BOOK.BOOK_NO
                                                  , chapter:this.SELECT_CHAPTER.CHAPTER_NO}})
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          //console.log("movepage error : " + error);
        });
      },

      initpage(){

        //지점 리스트 조회
        this.loadarea();
        
        //학기 리스트 조회
        this.loadsemester(false);
      },

      notedetail(item){
        //console.log("push detail : " + JSON.stringify(item));
        this.$router.push({
          path:"NoteDetail", 
          query:{
            note_no:item.NOTE_NO, 
            semester_no:item.SEMESTER_NO, 
            book_no:item.BOOK_NO, 
            chapter_no:item.CHAPTER_NO,
            area_no:item.AREA_NO,
            title:item.NOTE_TITLE,
            water:item.NOTE_WATER
          }
        });
      },

      //검색
      search_run(){
        this.movepage(1);
      },

      //지점 변경시
      changearea(){
        this.$session.set("AREA_NO", this.SELECT_AREA_NO);
      },

      //학기 변경시
      changesemester(){
        this.$session.set("SELECT_SEMESTER", this.SELECT_SEMESTER);
        this.loadbook(true);
      },

      changebook(){
        this.$session.set("SELECT_BOOK", this.SELECT_BOOK);
        this.loadchapter(true);
      },

      changechapter(){
        this.$session.set("SELECT_CHAPTER", this.SELECT_CHAPTER);
        this.movepage(1);
      },

      loadarea(){

        this.$http.post(this.$host+'/AreaList',{
              search_keyword: ''
            , sort_name: "AREA_NAME"
            , sort_type: "DESC"
            , page: 1
            , pagerow: 99999
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            const list = result.data.resultData;
            this.AREA_LIST.splice(0, this.AREA_LIST.length);   //데이터 초기화
            this.AREA_LIST.push({AREA_NAME:"전체", AREA_NO: null});
            list.forEach(element => {
                this.AREA_LIST.push(element); 
            });
            

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.loading = false;
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.loading = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });

      },

      //학기 조회 - select가 true면 손으로 셀렉트 박스를 변경한 경우고 false면 최초에 기존 콤보박스 선택값 복원
      loadsemester(select){

        //전부 초기화
        if(select){
          this.SELECT_SEMESTER = null;
          this.SELECT_BOOK     = null;
          this.SELECT_CHAPTER  = null;
        }
        this.SEMESTER_LIST.splice(0, this.SEMESTER_LIST.length);  //데이터 초기화
        this.BOOK_LIST.splice(0, this.BOOK_LIST.length);          //데이터 초기화
        this.CHAPTER_LIST.splice(0, this.CHAPTER_LIST.length);    //데이터 초기화

        this.$http.post(this.$host+'/UserSearchSemesterList',{
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          if(result.data.resultCode == 0){
          
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              const list = result.data.resultData;
              this.SEMESTER_LIST.splice(0, this.SEMESTER_LIST.length);   //데이터 초기화
              this.SEMESTER_LIST.push({SEMESTER_NAME:"전체", SEMESTER_NO: null});
              list.forEach(element => {
                  this.SEMESTER_LIST.push(element); 
              });
              
              if(this.SEMESTER_LIST.length > 0){
                //기본값을 선택해야 하는 경우
                if(select){
                  this.SELECT_SEMESTER = this.SEMESTER_LIST[0];
                  this.$session.set("SELECT_SEMESTER", this.SELECT_SEMESTER);
                }
                this.loadbook(select);
              }

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            //오류시
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });
      },        


      //교재 조회
      async loadbook(select){

        if(this.SELECT_SEMESTER == undefined || this.SELECT_SEMESTER == null){
          this.$alert("학기를 선택하세요.");
          return;
        }

          //교재, 챕터 초기화
          if(select){
            this.SELECT_BOOK    = null;
            this.SELECT_CHAPTER = null;
          }
          this.BOOK_LIST.splice(0, this.BOOK_LIST.length);          //데이터 초기화
          this.CHAPTER_LIST.splice(0, this.CHAPTER_LIST.length);    //데이터 초기화

          await this.$http.post(this.$host+'/UserSearchBookList',{
            SEMESTER_NO: this.SELECT_SEMESTER.SEMESTER_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                //console.log(JSON.stringify(result.data.resultData));
                this.BOOK_LIST.splice(0, this.BOOK_LIST.length);   //데이터 초기화
                this.BOOK_LIST.push({BOOK_NAME:"전체", BOOK_NO: null});
                list.forEach(element => {
                    this.BOOK_LIST.push(element); 
                });
                if(this.BOOK_LIST.length > 0){
                  if(select){
                    this.SELECT_BOOK = this.BOOK_LIST[0];
                    this.$session.set("SELECT_BOOK", this.SELECT_BOOK);
                  }
                  this.loadchapter(select);
                }

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },        
      

      //챕터 조회
      loadchapter(select){
          
        if(this.SELECT_SEMESTER == undefined || this.SELECT_SEMESTER == null){
          this.$alert("학기를 선택하세요.");
          return;
        }
        if(this.SELECT_BOOK == undefined || this.SELECT_BOOK == null){
          this.$alert("교재를 선택하세요.");
          return;
        }

          //교재, 챕터 초기화
          if(select){
            this.SELECT_CHAPTER = null;
          }
          this.CHAPTER_LIST.splice(0, this.CHAPTER_LIST.length);   //챕터 초기화
          this.$http.post(this.$host+'/ChapterList',{
              SEMESTER_NO: this.SELECT_SEMESTER.SEMESTER_NO
            , BOOK_NO: this.SELECT_BOOK.BOOK_NO
          },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                this.CHAPTER_LIST.splice(0, this.CHAPTER_LIST.length);   
                this.CHAPTER_LIST.push({CHAPTER_NAME:"전체", CHAPTER_NO: null});
                list.forEach(element => {
                    this.CHAPTER_LIST.push(element); 
                });
                if(this.CHAPTER_LIST.length > 0){
                  if(select){
                    this.SELECT_CHAPTER = this.CHAPTER_LIST[0];
                    this.$session.set("SELECT_CHAPTER", this.SELECT_CHAPTER);
                  }
                  this.loadpage(this.page);
                }

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },              


      //조회
      loadpage(p){

        /*
        if(this.SELECT_AREA_NO == undefined || this.SELECT_AREA_NO == null){
          this.$alert("지점 정보가 없습니다.");
          return;
        }
        */

        if(this.loading){
          return;
        }
        this.itemList.splice(0, this.itemList.length);   //데이터 초기화
        this.loading = true;
        this.page = p;

        this.$http.post(this.$host+'/NoteList',{
            search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
          , sort_name:this.sort_name
          , sort_type: this.sort_type==true ? "DESC" : "ASC" 
          , page: this.page
          , pagerow: this.pagerow
          , SEMESTER_NO: this.SELECT_SEMESTER.SEMESTER_NO
          , BOOK_NO: this.SELECT_BOOK.BOOK_NO
          , CHAPTER_NO: this.SELECT_CHAPTER.CHAPTER_NO
          , AREA_NO: this.SELECT_AREA_NO
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
        .then((result)=>{
          this.loading = false;
          if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
            this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
          }
          if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
            this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
          }

          if(result.data.resultCode == 0){

              this.totalcnt = result.data.totalcnt;
              this.makepaging();
              const list = result.data.resultData;
              
              list.forEach(element => {
                  this.itemList.push(element); 
              });
              this.loading = false;

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.loading = false;
            this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
            this.$EventBus.$emit('clearsession');
          }else{
            this.loading = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert(error.message);
          }
        });

      },


      //정렬
      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      //페이징
      prev(){
          this.bottompage = this.bottompage - 10 >= 1 ? this.bottompage - 10 : 1;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage + this.subpage - 1; //이전을 누르면 가장 끝 번호 선택
          this.loadpage(temp_page);
      },
      next(){
          this.bottompage = this.bottompage + 10 <= this.maxpage ? this.bottompage + 10 : this.maxpage;
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
          var temp_page = this.bottompage;
          this.loadpage(temp_page);
      },
      makepaging(){
        var div1 = this.totalcnt / this.pagerow; 
        var div2 = this.totalcnt % this.pagerow;
        if(div2 == 0){  
            this.maxpage = parseInt(div1);    
        }else{
            this.maxpage = parseInt(div1+1);
        }
        if(this.bottompage == 1){
          this.subpage = this.maxpage - this.bottompage >= 10 ? 10 : this.maxpage - this.bottompage+1;
        }
      },

      delete_item(item){

        this.$confirm('정말 삭제하시겠습니까?')
        .then(() => {
          this.loading = true;
          this.$http.post(this.$host+'/NoteDelete',{
            NOTE_NO:item.NOTE_NO
        },{headers: { firetoken:this.$session.get("FIRETOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$alert('삭제가 완료되었습니다.').then(()=>{
                const p = this.page;
                this.page = 0;
                this.loadpage(p);
              });
              
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert("토큰 만료로 인해 다시 로그인해주세요.");
              this.$EventBus.$emit('clearsession');
              
            }else{
              this.$alert('삭제가 실패했습니다.');
            }
            
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              this.loading = false;
              this.$alert('삭제 실패');
          });      

        });        

      },


    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>